import React from "react";
import Routers from '../../experiment-tracking/routes';
import { Canvas } from 'butterfly-dag';
import { Button, Space, Progress, List, Collapse, Card, Tooltip, Modal } from "antd";
import { Link } from 'react-router-dom';
import { RedoOutlined, ExpandOutlined, ZoomInOutlined, ZoomOutOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { EditNodeModal } from './modal/EditNodeModal';

const { Panel } = Collapse;
const { confirm } = Modal;

type Props = {
  graphElement: any;
  concurrentTemplateJson: any;
  concurrentExecutionId: string;
  recoveryConcurrentApi: (...args: any[]) => any;
  reloadPage: (...args: any[]) => any;
}

type State = any;

export class ConcurrentStatusView extends React.Component<Props, State> {
  canvas: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      nodes: props.graphElement.nodes,
      edges: props.graphElement.edges,
      concurrentTemplateJson: props.concurrentTemplateJson,
      activeNode: null,
      showEditNodeModal: false,
      failedNode: null,
    };
  }

  componentDidMount() {
    let root = document.getElementById('concurrent-status-canvas');
    this.canvas = new Canvas({
      root: root,
      disLinkable: false, 
      linkable: false,    
      draggable: true,   
      zoomable: true,    
      moveable: true, 
      autoResizeRootSize: true,
      zoomGap: 0.001,
      layout: {
        type: 'dagreLayout',
        options: {
          rankdir: 'TB',
          nodesep: 40,
          ranksep: 40,
          controlPoints: false,
        },
      }, 
      theme: {
        edge: {
          shapeType: 'Manhattan',
          arrow: true,
          arrowPosition: 1,
          labelPosition: 1,
          labelOffset: -20,
          defaultAnimate: true,
        }
      }
      
    });
    this.canvas.draw({groups: [], nodes: this.state['nodes'], edges: this.state['edges']}, () => {
      this.canvas.focusCenterWithAnimate();
    });
    this.canvas.on('events', (data: any) => {
      if(data.type == 'node:click') {
        this.setState({activeNode: data.node});
      }
    });
  }

  genExtra = (item: any) => (<div style={{display: "flex"}}>
    {/* <SettingOutlined style={{margin: "10px", paddingTop: "5px"}}
      onClick={event => {
        this.setState({
          failedNode: item,
        });
        this.setState({
          showEditNodeModal: true,
        });
      }}
    /> */}
    <div>{item.failedResult > 0 ? <Progress type="circle" width={40} percent={item.sucessPersentage} status="exception"/> : <Progress type="circle" width={40} percent={item.sucessPersentage}/>}</div>
    </div>
  );

  focusCenter = () => {
    this.canvas.focusCenterWithAnimate();
  }

  zoomIn = () => {
    this.canvas.zoom(this.canvas.getZoom() + 0.3)
  }

  zoomOut = () => {
    this.canvas.zoom(this.canvas.getZoom() - 0.3)
  }

  refreshGraph = () => {
    this.props.reloadPage();
  }

  recoveryGraph = () => {
    this.props.reloadPage();
  }

  selectedNodeStatus = () => {
    const { activeNode } = this.state;
    const nodeRunStatus = activeNode.options.runRes;
    let experimentId = '0';
    if(nodeRunStatus) {
      if(nodeRunStatus.run_id){
        experimentId = nodeRunStatus.run_id.split("-")[0]
      }
      return (
        <div className='graph-right-container'>
          <div className='node-info'> 
            <div className="node-input-container">
              <span className="node-input-label"> Artifact uri </span>
              {
                nodeRunStatus.artifact_uri ? <Tooltip title={nodeRunStatus.artifact_uri} >
                <span className="node-input-item text-ellipsis"> {nodeRunStatus.artifact_uri} </span>
                </Tooltip> : '-'
              }
            </div>
            <div className="node-input-container">
              <span className="node-input-label"> Run ID </span>
              <Link to={Routers.getRunPageRoute(experimentId, nodeRunStatus.run_id)}>
                {nodeRunStatus.run_id} 
              </Link>
            </div>
            <div className="node-input-container">
              <span className="node-input-label"> Status </span>
              <span className="node-input-item"> { nodeRunStatus.status } </span>
            </div>
          </div>
        </div>
      );
    } else {
      return(<p> somrthing went wrong.</p>)
    }
  }

  handleRecoveryModal = () => {
    const { concurrentTemplateJson, failedNode } = this.state;
    const { concurrentExecutionId, recoveryConcurrentApi } = this.props;
    confirm({
      title: 'Do you want to recovery a failed nodes?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure Please conform falied node configurations.. You can change failed node configuratiion.',

      async onOk() {
        concurrentTemplateJson.recovery = "true";
        try {
          return await new Promise((resolve, reject) => {
            recoveryConcurrentApi(concurrentTemplateJson.id, concurrentTemplateJson, concurrentExecutionId);
          });
        } catch {
          return console.log('Oops errors!');
        }
      },
  
      onCancel() {},
    });
  }

  handleEditNodeModal = () => {
    this.setState({
      showEditNodeModal: true,
    });
  };

  handleCloseEditNodeModal = () => {
    this.setState({
      showEditNodeModal: false,
    });
  }

  editNode = async (values: any) => { 
    const { concurrentTemplateJson, failedNode } = this.state;
    concurrentTemplateJson.node.forEach((node: { id: any; instanceType: any; parallelization: any; xform_path: any; }) => {
      if(node.id === failedNode.id){
        node.instanceType = values.instanceType;
        node.parallelization = values.parallelization;
        node.xform_path = values.xform_path;
      }
    });
    this.setState({concurrentTemplateJson: concurrentTemplateJson})
  }
  
  render() {
    const { concurrentTemplateJson } = this.state;
    const runningNode = concurrentTemplateJson.node.sort((a: any,b: any) => a.name - b.name); 
    const nodeDisplayInfo = (item: any) => {
      return <div className='node-info-list'> 
      <div className="node-input-container">
        <span className="node-input-label"> Location </span>
        <span className="node-input-item"> {item.instanceType == "eks" ? "Elastic Kubernetes Service (EKS)": item.instanceType == "gke" ? "Google Kubernetes Engine (GKE)" : item.instanceType == "HPE" ? "HP Kubernetes Engine (HPE)" : '-'} </span>
        <span className="node-input-label"> Max Data Partitions </span>
        <span style={{paddingRight: '5px'}}> { item.parallelization} </span>
      </div>
      <div> 
        <div className="node-input-container">
          <span className="node-input-label"> Cluster Name </span>
          <span className="node-input-item"> { item.k8s_params.kube_context} </span>
          <span className="node-input-label"> Namespace </span>
          <span className="node-input-item"> { item.k8s_params.namespace} </span>
        </div>
        <div className="node-input-container">
          <span className="node-input-label"> Memory </span>
          <span className="node-input-item"> {item.k8s_params['resources.requests.memory']} </span>
        
          <span className="node-input-label"> CPU </span>
          <span className="node-input-item"> { item.k8s_params['resources.requests.cpu']} </span>
        
          <span className="node-input-label"> GPU </span>
          <span className="node-input-item"> { item.k8s_params['resources.requests.gpu']} </span>
        </div>
      </div>
    </div>
    };

    return (
      <div className='graph-container'>
        <div className='graph-container-left'> 
          <Card title="Run view" size="small" style={{height: "100%"}} extra={<div className="graph-zoom-btn">
                <Space size="small">
                  <Button style={{padding: "0px"}} onClick={this.focusCenter}> <ExpandOutlined /> </Button>
                  <Button style={{padding: "0px"}} onClick={this.refreshGraph}> <RedoOutlined /> </Button>
                  <Button style={{padding: "0px"}} onClick={this.zoomIn}> <ZoomInOutlined /> </Button>
                  <Button style={{padding: "0px"}} onClick={this.zoomOut}> <ZoomOutOutlined /> </Button>
                </Space>
              </div>}>
            {/* extra={<Button type="primary" onClick={this.handleRecoveryModal}> Recovery </Button>}> */}
            <div className='graph-view'>
              {/* <div className="graph-zoom-btn">
                <Space size="small">
                  <Button onClick={this.focusCenter}> <ExpandOutlined /> </Button>
                  <Button onClick={this.refreshGraph}> <RedoOutlined /> </Button>
                  <Button onClick={this.zoomIn}> <ZoomInOutlined /> </Button>
                  <Button onClick={this.zoomOut}> <ZoomOutOutlined /> </Button>
                </Space>
              </div> */}
              <div className="concurrent-canvas" id="concurrent-status-canvas"></div>
            </div>
          </Card>
        </div>
        <div className='graph-container-right'>
          <Space direction="vertical" size="small" style={{ display: 'flex' }}>
            <Card title="Node Status Inspector" size="small">
              <div className='status-inspector-contant'>
                {this.state['activeNode'] === null ?  <p> Select a node to view run status. </p> : this.selectedNodeStatus()}
              </div>
            </Card>
            <Card  size="small" className='nodelist-card'>
              <div className='nodelist-inspector-contant'>
                <List
                  itemLayout="horizontal"
                  dataSource={runningNode}
                  renderItem={(item: any) => (
                    <List.Item>
                      <List.Item.Meta
                        description={<div> 
                          <Collapse bordered={false} className='node-collapse'>
                            {item.failedResult > 0 ? <Panel showArrow={false}
                            style={{ borderLeft: `5px solid #ff8080` }}
                            header={<div style={{ display: "grid", width: "100%" }}><span className="node-info-label"> {item.name} </span><span> {item.xform_path} </span></div>}
                            ///@ts-expect-err
                            extra={this.genExtra(item)} key={""}>
                              {nodeDisplayInfo(item)}
                            </Panel> : item.sucessPersentage == 100 ?  <Panel showArrow={false} 
                              style={{borderLeft:`5px solid #03cba8`}} 
                              header={<div style={{display: "grid", width:"100%"}}><span className="node-info-label"> { item.name } </span><span> { item.xform_path } </span></div>}
                              extra={this.genExtra(item)} key={""}>
                              {nodeDisplayInfo(item)}
                            </Panel> : <Panel showArrow={false} 
                              style={{borderLeft:`5px solid`, borderColor: item.color}} 
                              header={<div style={{display: "grid", width:"100%"}}><span className="node-info-label"> { item.name } </span><span> { item.xform_path } </span></div>}
                              extra={this.genExtra(item)} key={""}>
                              {nodeDisplayInfo(item)}
                            </Panel> }
                          </Collapse>
                        </div>}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </Space>
        </div>
        {this.state['failedNode'] === null ?  null : <EditNodeModal 
          //@ts-expect-error
          isOpen={this.state['showEditNodeModal']}
          onClose={this.handleCloseEditNodeModal}
          handleSubmit={this.editNode}
          activeNode = {this.state['failedNode']}
          nodes={concurrentTemplateJson.node}
        />}
      </div>
    );
  }
}