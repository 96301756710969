import { InfoCircleOutlined } from '@ant-design/icons';

import { useDesignSystemTheme } from '../Hooks';
import type { TooltipProps } from './Tooltip';
import { Tooltip } from './Tooltip';

export interface InfoTooltipProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'title'> {
  title: React.ReactNode;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
  iconTitle?: string;
}

export const InfoTooltip = ({ title, tooltipProps, iconTitle, ...iconProps }: InfoTooltipProps): JSX.Element => {
  const { theme } = useDesignSystemTheme();
  return (
    <Tooltip title={title} {...tooltipProps}>
      <InfoCircleOutlined
        aria-hidden="false"
        title={iconTitle}
        aria-label={iconTitle}
        css={{ fontSize: theme.typography.fontSizeSm, color: theme.colors.textSecondary }}
        {...iconProps}
      />
    </Tooltip>
  );
};
