import moment from 'moment';

export const renderScheduledTime = (row: any) => {
  const splitSchedule = row.period.split("_");
  const scheduleType = row.type;

  const minuteVal = splitSchedule[0];
  const hourVal = splitSchedule[1];
  const dayVal = splitSchedule[2];
  const monthVal = splitSchedule[3];
  const weekVal = splitSchedule[4];
  const yearVal = splitSchedule[5];
  const splitWeekVal = weekVal.split(",");
  let utcTime = "05-05-2022 00:00:00";
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  if(scheduleType == 'once') {
    utcTime = yearVal + '-' + monthVal + '-' + dayVal + " " + hourVal + ':' + minuteVal + ':00';
    const local_date= moment.utc(utcTime).local().format('YYYY-MM-DD HH:mm:ss');
    return(local_date);
  } else if(scheduleType == 'hourly') {
    const utcTime = moment.utc();
    utcTime.set({'minute': minuteVal });
    const local = moment.utc(utcTime).local();
    return ( local.format("mm") + ' minute every hour');
  } else if(scheduleType == 'daily') {
    const utcTime = moment.utc();
    utcTime.set({'minute': minuteVal, 'hour': hourVal});
    const local = moment.utc(utcTime).local();
    return ( local.format("hh:mm A") + ' on every day');
  } else if(scheduleType == 'weekly') {
    const utcTime = moment.utc();
    utcTime.set({'minute': minuteVal, 'hour': hourVal});
    const local = moment.utc(utcTime).local();
    let weekText = '';

    for (var i = 0; i < splitWeekVal.length; i++) {
      if (utcTime.date() > local.date()) {
        if (splitWeekVal[i] == 0) {
          weekText += days[6] + ",";
          splitWeekVal[i] = 6;
        } else {
          weekText += days[Number(splitWeekVal[i]) - 1] + ", ";
          splitWeekVal[i] = Number(splitWeekVal[i]) - 1;
        }
      } else if (utcTime.date() < local.date()) {
        if (splitWeekVal[i] == 6) {
          weekText += days[0] + ", ";
          splitWeekVal[i] = 0;
        } else {
          weekText += days[Number(splitWeekVal[i]) + 1] + ", ";
          splitWeekVal[i] = Number(splitWeekVal[i]) + 1;
        }
      } else if (utcTime.date() == local.date()) {
        weekText += days[splitWeekVal[i]] + ", ";
      }
    }
    weekText = weekText.slice(0, -1);
    return ( weekText + ' ' + local.format("hh:mm A") + ' on every week');
  } else if(scheduleType == 'monthly') {
    const utcTime = moment.utc();
    utcTime.set({'minute': minuteVal, 'hour': hourVal, 'date': dayVal});
    const local = moment.utc(utcTime).local();
    return ( local.format("Do") + ' day ' + local.format("hh:mm A") + ' on every month'); 
  } else if(scheduleType == 'yearly') {
    const utcTime = moment.utc();
    utcTime.set({'minute': minuteVal, 'hour': hourVal, 'date': dayVal, 'month': monthVal});
    const local = moment.utc(utcTime).local();
    return ( local.format("MMM") + ' ' + local.format("Do") + ' ' + local.format("hh:mm A") + ' on every year'); 
  }
  return '-';
}

export const renderNextRunTime = (schedule: any) => {
  const splitSchedule = schedule.period.split("_");
  const scheduleType = schedule.type;
  const seconds = 0o0;
  const minuteVal = splitSchedule[0];
  const hourVal = splitSchedule[1];
  const dayVal = splitSchedule[2];
  const monthVal = splitSchedule[3];
  const weekVal = splitSchedule[4];
  const yearVal = splitSchedule[5];
  const splitWeekVal = weekVal.split(",");
  let utcTime = "2022-05-05 00:00:00";
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  if(scheduleType == 'once') {
    utcTime = yearVal + '-' + monthVal + '-' + dayVal + " " + hourVal + ':' + minuteVal + ':00';
    let utcdate: any = moment.utc(utcTime).local()
    if (new Date() < new Date(new Date(utcdate))) {
      return moment.utc(utcTime).local().format('DD-MM-YYYY HH:mm:ss');
    } else {
      return('-');
    }
  } else if(scheduleType == 'hourly') {
    const utcTime = moment.utc();
    if (utcTime.minutes() < minuteVal) {
      utcTime.set({'minute': minuteVal });
    } else {
      utcTime.set({'minute': minuteVal, 'seconds': seconds });
      utcTime.set({'hour': utcTime.hours() + 1 });
    }
    return moment.utc(utcTime).local().format('DD-MM-YYYY HH:mm:ss');
  } else if(scheduleType == 'daily') {
    const utcTime = moment.utc();
    if (utcTime.hours() < hourVal) {
      utcTime.set({
        'hour': hourVal, 
        'minute': minuteVal, 
        'seconds': seconds 
      });
    } else if (utcTime.hours() == hourVal) {
      if (utcTime.minutes() < minuteVal) {
        utcTime.set({
          'hour': hourVal, 
          'minute': minuteVal, 
          'seconds': seconds 
        });
      } else {
        utcTime.set({
          'date': utcTime.date() + 1,
          'hour': hourVal, 
          'minute': minuteVal, 
          'seconds': seconds, 
        });
      }
    } else {
      utcTime.set({
        'date': utcTime.date() + 1,
        'hour': hourVal, 
        'minute': minuteVal, 
        'seconds': seconds, 
      });
    }
    return moment.utc(utcTime).local().format('DD-MM-YYYY HH:mm:ss');
  } else if(scheduleType == 'weekly') { 
    var dayset = false;
    if (Array.isArray(splitWeekVal) && splitWeekVal.length) {
      splitWeekVal.forEach(day => {
        
      });
    }
  } else if(scheduleType == 'monthly') { 
    const utcTime = moment.utc();
    if(utcTime.date() < dayVal) {
      utcTime.set({
        'date': dayVal,
        'hour': hourVal, 
        'minute': minuteVal, 
        'seconds': seconds, 
      });
    } else if (utcTime.date() == Number(dayVal)) {
      if (utcTime.hours() < hourVal) {
        utcTime.set({
          'hour': hourVal, 
          'minute': minuteVal, 
          'seconds': seconds 
        });
      } else if (utcTime.hours() == hourVal) {
        if (utcTime.minutes() < minuteVal) {
          utcTime.set({
            'hour': hourVal, 
            'minute': minuteVal, 
            'seconds': seconds 
          });
        } else {
          utcTime.set({
            'month': utcTime.month() + 1,
            'date': dayVal,
            'hour': hourVal, 
            'minute': minuteVal, 
            'seconds': seconds, 
          });
        }
      } else {
        utcTime.set({
          'month': utcTime.month() + 1,
          'date': dayVal,
          'hour': hourVal, 
          'minute': minuteVal, 
          'seconds': seconds, 
        });
      }
    } else { 
      utcTime.set({
        'month': utcTime.month() + 1,
        'date': dayVal,
        'hour': hourVal, 
        'minute': minuteVal, 
        'seconds': seconds, 
      });
    }
    return moment.utc(utcTime).local().format('DD-MM-YYYY HH:mm:ss');
  } else if(scheduleType == 'yearly') { 
    const utcTime = moment.utc();
    if (utcTime.month() < Number(monthVal) - 1) {
      utcTime.set({
        'month': monthVal - 1,
        'date': dayVal,
        'hour': hourVal, 
        'minute': minuteVal, 
        'seconds': seconds, 
      });
    } else if (utcTime.month() == Number(monthVal) - 1) {
      if(utcTime.date() < dayVal) {
        utcTime.set({
          'date': dayVal,
          'hour': hourVal, 
          'minute': minuteVal, 
          'seconds': seconds, 
        });
      } else if (utcTime.date() == Number(dayVal)) {
        if (utcTime.hours() < hourVal) {
          utcTime.set({
            'hour': hourVal, 
            'minute': minuteVal, 
            'seconds': seconds 
          });
        } else if (utcTime.hours() == hourVal) {
          if (utcTime.minutes() < minuteVal) {
            utcTime.set({
              'hour': hourVal, 
              'minute': minuteVal, 
              'seconds': seconds 
            });
          } else {
            utcTime.set({
              'year': utcTime.year() + 1,
              'month': monthVal,
              'date': dayVal,
              'hour': hourVal, 
              'minute': minuteVal, 
              'seconds': seconds, 
            });
          }
        } else {
          utcTime.set({
            'year': utcTime.year() + 1,
            'month': monthVal,
            'date': dayVal,
            'hour': hourVal, 
            'minute': minuteVal, 
            'seconds': seconds, 
          });
        }
      } else { 
        utcTime.set({
          'year': utcTime.year() + 1,
          'month': monthVal,
          'date': dayVal,
          'hour': hourVal, 
          'minute': minuteVal, 
          'seconds': seconds, 
        });
      }
    }
  }
  return '-'
}