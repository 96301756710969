import React from 'react';
import { GatewayRouteListView } from './GatewayRouteListView';
import { connect } from 'react-redux';
import { getUUID } from 'common/utils/ActionUtils';
import { searchModelGatewayRoutesApi } from 'experiment-tracking/actions/ModelGatewayActions';
import { withRouterNext } from 'common/utils/withRouterNext';
import { withErrorBoundary } from 'common/utils/withErrorBoundary';
import { ScrollablePageWrapper } from 'common/components/ScrollablePageWrapper';

import ErrorUtils from 'common/utils/ErrorUtils';
import Utils from 'common/utils/Utils';
import RequestStateWrapper from 'common/components/RequestStateWrapper';

import type { RouteEntity } from '../types';
import type { WithRouterNextProps } from 'common/utils/withRouterNext';

type GatewayRouteListPageImplProps = WithRouterNextProps & {
  routes?: RouteEntity[];
  searchModelGatewayRoutesApi: (...args: any[]) => any;
};

type GatewayRouteListPageImplState = any;

export class GatewayRouteListPageImpl extends React.Component<
  GatewayRouteListPageImplProps,
  GatewayRouteListPageImplState
> {
  constructor(props: GatewayRouteListPageImplProps) {
    super(props);
    this.state = {
      orderByKey: 'name',
      orderByAsc: true,
      currentPage: 1,
      maxResultsSelection: 25,
      pageTokens: {},
      loading: false,
      searchInput: '',
    };
  }
  GatewayRouteListPageStoreKey = 'GatewayRouteListPageStore';
  defaultPersistedPageTokens = { 1: null };
  initialSearchGatewayRoutesApiId = getUUID();
  searchGatewayRoutesApiId = getUUID();
  criticalInitialRequestIds = [this.initialSearchGatewayRoutesApiId];

  getUrlState() {
    return this.props.location ? Utils.getSearchParamsFromUrl(this.props.location.search) : {};
  }

  componentDidMount() {
    const urlState = this.getUrlState();
    this.setState(
      {
        orderByKey: this.state['orderByKey'],
        orderByAsc: this.state['orderByAsc'],
        currentPage: this.state['currentPage']
      },
      () => {
        this.loadPage(this.state['currentPage'], undefined, undefined, true);
      },
    );
  }

  handleSearch = (searchInput: any) => {
    this.setState({ searchInput: searchInput });
    this.setState({ currentPage: 1 });
  };

  handleClickSortableColumn = (
    orderByKey: any,
    sortOrder: any,
    callback: any,
  ) => {
    const orderByAsc = sortOrder !== 'descend'; // default to true
    this.setState({ orderByKey, orderByAsc });
    setTimeout(()=> { callback(); console.log('callbackssss')}, 100);
  };

  handleMaxResultsChange = (key: any, callback: any) => {
    this.setState({ maxResultsSelection: parseInt(key, 10) }, () => {
      const { maxResultsSelection } = this.state;
    });
  };

  handleClickNext = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1 });
  };

  handleClickPrev = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1 });
  };

  loadPage(page: any, callback: any, errorCallback: any, isInitialLoading: any) {
    const { pageTokens } = this.state;
    this.setState({ loading: true });
    this.props
      .searchModelGatewayRoutesApi(
        pageTokens[page],
        isInitialLoading
          ? this.initialSearchGatewayRoutesApiId
          : this.searchGatewayRoutesApiId,
      )
      .then((r: any) => {
        this.setState({ loading: false });
        callback && callback();
      })
      .catch((e: any) => {
        Utils.logErrorAndNotifyUser(e);
        this.setState({ currentPage: 1 });
        errorCallback && errorCallback();
      });
  }

  render() {
    const {
      orderByKey,
      orderByAsc,
      currentPage,
      searchInput,
      maxResultsSelection,
    } = this.state;
    const { routes } = this.props;
    const lowerCasedSearchInput = searchInput.toLowerCase();
    const temproute = routes ? routes : [];
    const filteredGatewayRoutes = temproute.filter(({ name }) =>
      name.toLowerCase().includes(lowerCasedSearchInput),
    );
    
    const paginatedGatewayRoutes =  filteredGatewayRoutes.slice((currentPage - 1) * maxResultsSelection , (maxResultsSelection*currentPage));
    const shortedGatewayRoutes = orderByAsc ? 
      paginatedGatewayRoutes.sort((a, b) => a.name.localeCompare(b.name)) : 
      paginatedGatewayRoutes.sort((a, b) => b.name.localeCompare(a.name));

    return (
      <ScrollablePageWrapper>
        <RequestStateWrapper
          requestIds={[this.criticalInitialRequestIds]}
        >
          <GatewayRouteListView 
            routes = { shortedGatewayRoutes }
            searchInput = { searchInput }
            orderByKey = { orderByKey }
            orderByAsc = { orderByAsc }
            currentPage = { currentPage }
            hasNextPage={ filteredGatewayRoutes.length > currentPage * maxResultsSelection}
            onSearch = {this.handleSearch}
            onClickSortableColumn={this.handleClickSortableColumn}
            onSetMaxResult = {this.handleMaxResultsChange}
            onClickNext={this.handleClickNext}
            onClickPrev={this.handleClickPrev}
          />
        </RequestStateWrapper>
      </ScrollablePageWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  const routes: RouteEntity[]  = Object.values(state.modelGateway.modelGatewayRoutes);
  return {
    routes
  };
};

const mapDispatchToProps = {
  searchModelGatewayRoutesApi,
};

const GatewayRouteListPageWithRouter = withRouterNext(
  connect(mapStateToProps, mapDispatchToProps)(GatewayRouteListPageImpl),
);

export const GatewayRouteListPage = withErrorBoundary(
  ErrorUtils.mlflowServices.MODEL_REGISTRY,
  GatewayRouteListPageWithRouter,
);
