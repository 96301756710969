import type { CSSObject } from '@emotion/react';
import { css } from '@emotion/react';
import type { AlertProps as AntDAlertProps } from 'antd';
import { Alert as AntDAlert } from 'antd';
import cx from 'classnames';

import type { Theme } from '../../theme';
import { DesignSystemAntDConfigProvider, getAnimationCss } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks';
import { CloseIcon } from '../Icon';
import { SeverityIcon } from '../Icon/iconMap';
import type { DangerouslySetAntdProps, HTMLDataAttributes } from '../types';

type AlertType = NonNullable<Exclude<AntDAlertProps['type'], 'success'>>;

export interface AlertProps
  extends Omit<AntDAlertProps, 'closeText' | 'showIcon' | 'type' | 'icon'>,
    HTMLDataAttributes,
    DangerouslySetAntdProps<AntDAlertProps> {
  type: AlertType;
}

export const Alert: React.FC<AlertProps> = ({ dangerouslySetAntdProps, closable = true, ...props }) => {
  const { theme, getPrefixedClassName } = useDesignSystemTheme();
  const clsPrefix = getPrefixedClassName('alert');

  const mergedProps: AntDAlertProps & { type: AlertType } = {
    ...props,
    type: props.type || 'error',
    showIcon: true,
    closable,
  };

  return (
    <DesignSystemAntDConfigProvider>
      <AntDAlert
        {...mergedProps}
        className={cx(mergedProps.className)}
        css={getAlertEmotionStyles(clsPrefix, theme, mergedProps)}
        icon={<SeverityIcon severity={mergedProps.type} />}
        // Antd calls this prop `closeText` but we can use it to set any React element to replace the close icon.
        closeText={
          mergedProps.closable && <CloseIcon aria-label="Close alert" css={{ fontSize: theme.general.iconSize }} />
        }
        // Always set a description for consistent styling (e.g. icon size)
        description={props.description || ' '}
        {...dangerouslySetAntdProps}
      />
    </DesignSystemAntDConfigProvider>
  );
};

const getAlertEmotionStyles = (clsPrefix: string, theme: Theme, props: AntDAlertProps) => {
  const classCloseIcon = `.${clsPrefix}-close-icon`;
  const classCloseButton = `.${clsPrefix}-close-button`;
  const classCloseText = `.${clsPrefix}-close-text`;
  const classDescription = `.${clsPrefix}-description`;
  const classMessage = `.${clsPrefix}-message`;
  const classWithDescription = `.${clsPrefix}-with-description`;
  const classWithIcon = `.${clsPrefix}-icon`;

  const ALERT_ICON_HEIGHT = 16;
  const ALERT_ICON_FONT_SIZE = 16;

  const styles: CSSObject = {
    // General
    padding: theme.spacing.sm,

    [`${classMessage}, &${classWithDescription} ${classMessage}`]: {
      // TODO(giles): These three rules are all the same as the H3 styles. We can refactor them out into a shared object.
      fontSize: theme.typography.fontSizeBase,
      fontWeight: theme.typography.typographyBoldFontWeight,
      lineHeight: theme.typography.lineHeightBase,
    },

    [`${classDescription}`]: {
      lineHeight: theme.typography.lineHeightBase,
    },

    // Icons
    [classCloseButton]: {
      fontSize: ALERT_ICON_FONT_SIZE,
      marginRight: 12,
    },
    [classCloseIcon]: {
      '&:focus-visible': {
        outlineStyle: 'auto',
        outlineColor: theme.colors.primary,
      },
    },
    [`${classCloseIcon}, ${classCloseButton}`]: {
      lineHeight: theme.typography.lineHeightBase,
      height: ALERT_ICON_HEIGHT,
      width: ALERT_ICON_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    [classWithIcon]: {
      fontSize: ALERT_ICON_FONT_SIZE,
      marginTop: 2,
    },
    [`${classCloseIcon}, ${classCloseButton}, ${classCloseText} > span`]: {
      lineHeight: theme.typography.lineHeightBase,
      height: ALERT_ICON_HEIGHT,
      width: ALERT_ICON_HEIGHT,
      fontSize: ALERT_ICON_FONT_SIZE,
      marginTop: 2,

      '& > span': {
        lineHeight: theme.typography.lineHeightBase,
      },
    },

    // No description
    ...(!props.description && {
      [classMessage]: {
        margin: 0,
      },
      [classDescription]: {
        display: 'none',
      },
    }),

    // Warning
    ...(props.type === 'warning' && {
      color: theme.colors.textValidationWarning,
      borderColor: theme.colors.yellow300,
    }),

    // Error
    ...(props.type === 'error' && {
      color: theme.colors.textValidationDanger,
      borderColor: theme.colors.red300,
    }),

    // Banner
    ...(props.banner && {
      borderStyle: 'solid',
      borderWidth: `${theme.general.borderWidth}px 0`,
    }),

    // After closed
    '&[data-show="false"]': {
      borderWidth: 0,
      padding: 0,
      width: 0,
      height: 0,
    },

    ...getAnimationCss(theme.options.enableAnimation),
  };

  return css(styles);
};
