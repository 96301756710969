import React from 'react';
import { GenericInputModal } from '../../experiment-tracking/components/modals/GenericInputModal';
import { AddGatewayRouteForm, MODEL_NAME_FIELD } from './AddGatewayRouteForm';
import { connect } from 'react-redux';
import { addModelGatewayRoutesApi, searchModelGatewayRoutesApi } from '../../experiment-tracking/actions/ModelGatewayActions';
import { getUUID } from '../../common/utils/ActionUtils';
import { debounce } from 'lodash';
import { gatewayRouteNameValidator } from '../../common/forms/validations';
import { IntlShape, injectIntl } from 'react-intl';
import { withRouterNext } from '../../common/utils/withRouterNext';
import type { WithRouterNextProps } from '../../common/utils/withRouterNext';
import { withErrorBoundary } from '../../common/utils/withErrorBoundary';
import ErrorUtils from '../../common/utils/ErrorUtils';
import Routes from 'experiment-tracking/routes'
import Utils from '../../common/utils/Utils';


type Props = WithRouterNextProps & {
  searchModelGatewayRoutesApi: (...args: any[]) => any;
  addModelGatewayRoutesApi: (...args: any[]) => any;
  modalVisible: boolean;
  hideModal: (...args: any[]) => any;
  navigateBackOnCancel?: boolean;
  intl: IntlShape;
};

export class AddGatewayModalImpl extends React.Component<Props> {
  addGatewayRouteRequestId = getUUID();

  handleAddGatewayRoute = async (values: any) => {
    let data = {
      "route_info" : {
        "name": values.name,
        "route_type": values.route_type,
        "model": {
          "provider": values.model_provider,
          "name": values.model_name,
          "config": { }
        }
      }  
    }
    
    if(values.model_provider === 'openai') {
      data.route_info.model.config = {"openai_api_key" : values.api_key };
    } else if(values.model_provider === 'mosaicml') {
      data.route_info.model.config = {"mosaicml_api_key" : values.api_key };
    } else if(values.model_provider === 'anthropic') {
      data.route_info.model.config = {"anthropic_api_key" : values.api_key };
    } else if(values.model_provider === 'cohere') {
      data.route_info.model.config = {"cohere_api_key" : values.api_key };
    }
    
    await this.props.addModelGatewayRoutesApi(
      data,
      this.addGatewayRouteRequestId,
    ).then((re: any) => {
      this.props.navigate(Routes.getGatewayRoutePageRoute(values.name));
    }).catch((ex: Error) => {
      Utils.logErrorAndNotifyUser(ex);
    })
  };

  gatewayRouteNameValidator = debounce(gatewayRouteNameValidator, 400);

  handleOnCancel = () => {
    if (this.props.navigateBackOnCancel) {
      this.props.navigate(-1);
    }
  };

  render() {
    const { modalVisible, hideModal } = this.props;
    return (
      <GenericInputModal
        title={this.props.intl.formatMessage({
          defaultMessage: 'Add Gateway Route',
          description: 'Title text for adding gateway route in the settings',
        })}
        okText={this.props.intl.formatMessage({
          defaultMessage: 'Add',
          description: 'Add button text for adding gateway route in the settings',
        })}
        cancelText={this.props.intl.formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text for adding gateway route in the settings',
        })}
        
        isOpen={modalVisible}
        handleSubmit={this.handleAddGatewayRoute}
        onClose={hideModal}
        onCancel={this.handleOnCancel}
      >
        {/* @ts-expect-error TS(2322): Type '{ visible: boolean; validator: ((rule: any, ... Remove this comment to see the full error message */}
        <AddGatewayRouteForm visible={modalVisible} validator={this.gatewayRouteNameValidator} />
      </GenericInputModal>
    );
  }
}

const mapDispatchToProps = {
  addModelGatewayRoutesApi,
  searchModelGatewayRoutesApi,
};

const AddGatewayModalWithRouter = withRouterNext(
  connect(undefined, mapDispatchToProps)(injectIntl<'intl', Props>(AddGatewayModalImpl)),
);

export const AddGatewayModal = withErrorBoundary(
  ErrorUtils.mlflowServices.AI_GATEWAY,
  AddGatewayModalWithRouter,
);
