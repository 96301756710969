import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgTableIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 1.75A.75.75 0 0 1 1.75 1h12.5a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-.75.75H1.75a.75.75 0 0 1-.75-.75V1.75Zm1.5.75v3h11v-3h-11Zm0 11V7H5v6.5H2.5Zm4 0h3V7h-3v6.5ZM11 7v6.5h2.5V7H11Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
function TableIcon(props: IconProps) {
  return <Icon {...props} component={SvgTableIcon} />;
}
export default TableIcon;
