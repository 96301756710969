import { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { UserOutlined } from '@ant-design/icons';

type Props = {
  getOTP: (...args: any[]) => any;
  setView: (...args: any[]) => any;
  requestPending: boolean;
}

type State = any;

/**
 * Component that renders a form for forgot Password .
 */
class forgotPasswordComponent extends Component<Props, State> {

  render() {
    const { getOTP, setView, requestPending } = this.props;
    return (<div>
        <Form onFinish={getOTP} className="login-form">
          <b>Username</b>
          <Form.Item extra="Enter your username, we will send a verification code to your mail"
            name='userName'
            rules={[
              {
                required: true,
                message: 'Please input your username!.',
              },
            ]}>
            <Input className="login-input"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Enter your username"
            />
          </Form.Item>
          
          <Form.Item>
            <span className="login__link" id="login" onClick={setView} >Back to login.</span>
          </Form.Item>
          <Form.Item>
            <Button type="primary" loading={requestPending} htmlType="submit" className="login-form-button">
              Get verification code
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

// @ts-expect-error TS(2769): No overload matches this call.
export const ForgotPassword = injectIntl(forgotPasswordComponent);
