import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Utils from '../../../common/utils/Utils';
import moment from 'moment';
import { message } from 'antd';
import { FormattedMessage } from 'react-intl';

import { GenericInputModal } from '../../../experiment-tracking/components/modals/GenericInputModal';
import { CreatePeriodicRunForm } from './CreatePeriodicRunForm';

import { getExperiments } from '../../../experiment-tracking/reducers/Reducers';
import { createExperimentApi } from '../../../experiment-tracking/actions';
import { createPeriodicRunApi, periodicRunListApi } from '../../actions';
import { getperiodicRunPageRoute } from '../../routes';

type Props = {
  validator?: (...args: any[]) => any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  createExperimentApi: (...args: any[]) => any;
  createPeriodicRunApi: (...args: any[]) => any;
  periodicRunListApi: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  dagid: string;
  experiments: any;
  isOpen: boolean;
  experimentNames: any;
  history: any;
  periodicRuns: any[];
}

export class CreatePeriodicRunModalImpl extends Component<Props> {

  getPeriodicRunNamesAsSet = () =>
  new Set(this.props.periodicRuns.map((values) => values['name']));

  periodicRunNameValidator = (rule: any, value: any, callback: any) => {
    const parallelsNamesSet = this.getPeriodicRunNamesAsSet();
    callback(
        parallelsNamesSet.has(value)
        ? <FormattedMessage
          defaultMessage='PeriodicRun "{value}" already exists.'
          description='Validation message for periodicRun that already exist'
          values={{ value: value }}
        />
        : undefined,
    );
  };

  craetePeriodicRun = async (values: any) => {
    const { dagid, history, experimentNames, experiments } = this.props;
    const scheduleType = values.scheduleType;
    var periodvalue = "*_*_*_*_*_*";
    if(scheduleType == 'once') {
      const date = new Date(values.scheduleTime);
      periodvalue =
                ("00" + date.getUTCMinutes()).slice(-2) +
                "_" +
                ("00" + date.getUTCHours()).slice(-2) +
                "_" +
                date.getUTCDate() +
                "_" +
                (date.getUTCMonth() + 1) +
                "_*_" +
                date.getUTCFullYear();
    } else if(scheduleType == 'hourly') {
      const momentDate: any = moment().set('minute', values.hourlyRunMinute);
      const date = new Date(momentDate);
      periodvalue = ("00" + date.getUTCMinutes()).slice(-2) + "_*_*_*_*_*";
    } else if(scheduleType == 'daily') {
      const date = new Date(values.dailyRunTime);
      periodvalue =
      ("00" + date.getUTCMinutes()).slice(-2) +
      "_" +
      ("00" + date.getUTCHours()).slice(-2) +
      "_*_*_*_*";
    } else if(scheduleType == 'weekly') {
      const date = new Date(values.weeklyRunTime);      
      let weeklyRun = values.weeklyRunDay.map((day: any) => {
        if (date.getUTCDate() > date.getDate()) {
          if (day == 6) return 0;
          else
            return day + 1;
        } else if (date.getUTCDate() < date.getDate()) {
          if (day == 0) return 6;
          else
            return day - 1;
        } else {
          return day;
        }
      });

      periodvalue =
        ("00" + date.getUTCMinutes()).slice(-2) +
        "_" +
        ("00" + date.getUTCHours()).slice(-2) +
        "_*_*_" +
        weeklyRun.toString() +
        "_*";
    } else if(scheduleType == 'monthly') {
      const monthlyRunTime = new Date(values.monthlyRunTime);
      const momentMonthlyRunTime: any = moment(monthlyRunTime).set('date', values.monthlyRunDay);
      const date = new Date(momentMonthlyRunTime);
      periodvalue =
        ("00" + date.getUTCMinutes()).slice(-2) +
        "_" +
        ("00" + date.getUTCHours()).slice(-2) +
        "_" +
        date.getUTCDate() +
        "_" +
        "*_*_*";
    } else if(scheduleType == 'yearly') {
      const yearlyRunTime = values.yearlyRunTime;
      const momentYearlyRunday = yearlyRunTime.set('date', values.yearlyRunDay);
      const momentYearlyRunTime: any = moment(momentYearlyRunday).set('month', values.yearlyRunMonth);
      const date = new Date(momentYearlyRunTime);
      periodvalue =
      ("00" + momentYearlyRunTime.utc().minutes()).slice(-2) +
      "_" +
      ("00" + momentYearlyRunTime.utc().hours()).slice(-2) +
      "_" +
      momentYearlyRunTime.utc().date() +
      "_" +
      momentYearlyRunTime.utc().month() +
      "_*_*";
    }
    const runjson: any = {};
    runjson["period"] = {type : values.scheduleType, value: periodvalue};    
    runjson["dagid"] = dagid;
    runjson["periodic_run_name"] = values.name;   
    runjson["MLFLOW_TRACKING_URI"] = "infinstor://" + window.InfinStorMlflowServer;
    runjson["MLFLOW_CONCURRENT_URI"] = "https://" + window.ParallelsServer;
    runjson["MLFLOW_TRACKING_TOKEN"] = Utils.accessCookie('aws-accessToken');
    if(experimentNames.has(values['name'])){
      const experiment = experiments.find((element: any) => element.getName() == values['name']);
      const experiment_id = experiment.getExperimentId();
      runjson["experiment_id"] = experiment_id
      await this.props.createPeriodicRunApi(values.name, runjson).then((apiResponse: any) => {
        this.props.periodicRunListApi();
        if(apiResponse.action.type === 'CREATE_PERIODIC_RUN_FULFILLED') {
          message.success(values.name + ' periodicrun created.');
          history.push(getperiodicRunPageRoute(values.name, experiment_id));
        }
      }).catch(Utils.logErrorAndNotifyUser);
    } else {
      await this.props
      .createExperimentApi(values['name'])
      .then(async(apiResponse: any) => {
        const value = apiResponse.value;
        runjson["experiment_id"] = value.experiment_id;
        await this.props.createPeriodicRunApi(values.name, runjson).then((apiResponse: any) => {
          this.props.periodicRunListApi();
          if(apiResponse.action.type === 'CREATE_PERIODIC_RUN_FULFILLED') {
            message.success(values.name + ' periodicrun created.');
            history.push(getperiodicRunPageRoute(values.name, value.experiment_id));
          }
        });
      })
      .catch(Utils.logErrorAndNotifyUser);
    }
  }
  
  render() {
    const { isOpen, experiments } = this.props;
    return (
      <GenericInputModal
        title='Create PeriodicRun'
        okText='Create'
        isOpen={isOpen}
        handleSubmit={this.craetePeriodicRun}
        onClose={this.props.onClose}
      >
        {/*@ts-expect-error */}
        <CreatePeriodicRunForm validator={this.periodicRunNameValidator}/>
      </GenericInputModal>
    );
  }
}

const mapStateToProps = (state: any) => {
  const experiments = getExperiments(state);
  const experimentNames = new Set(experiments.map((e: any) => e.getName()));
  return { experiments, experimentNames };
};

const mapDispatchToProps = {
  createExperimentApi,
  createPeriodicRunApi,
  periodicRunListApi
};

export const CreatePeriodicRunModal: any = withRouter(
  // @ts-expect-error
  connect(mapStateToProps, mapDispatchToProps)(CreatePeriodicRunModalImpl),
);