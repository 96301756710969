import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgColumnsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 1.75A.75.75 0 0 1 1.75 1h12.5a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-.75.75H1.75a.75.75 0 0 1-.75-.75V1.75ZM2.5 13.5v-11H5v11H2.5Zm4 0h3v-11h-3v11Zm4.5-11v11h2.5v-11H11Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
function ColumnsIcon(props: IconProps) {
  return <Icon {...props} component={SvgColumnsIcon} />;
}
export default ColumnsIcon;
