import React, { Component } from 'react';
import Utils from '../utils/Utils';
import PropTypes from 'prop-types';
import { Form, Input, Button, Spacer, Select } from '@databricks/design-system';
import { EditableAuthorizationTable } from './tables/EditableAuthorizationTable';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import './ManageUserAuthorization.css'

type Props = {
  authorizations: any;
  handleAddAuthorization: (...args: any[]) => any;
  handleSaveEdit: (...args: any[]) => any;
  isRequestPending: boolean;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  innerRef?: any;
}

type State = any;

export class ManageUserAuthorizationImpl extends Component<Props,State> {

  tableColumns = [
    {
      title: this.props.intl.formatMessage({
        defaultMessage: 'Type (user/group)',
        description: 'Column title for type column in editable permission table view in MLflow',
      }),
      dataIndex: 'principal_type',
      width: 200,
    },
    {
      title: this.props.intl.formatMessage({
        defaultMessage: 'Name',
        description: 'Column title for name column in editable authorizations table view in MLflow',
      }),
      dataIndex: 'principal_name',
      width: 200,
    },
    {
      title: this.props.intl.formatMessage({
        defaultMessage: 'Role',
        description: 'Column title for role column in editable authorizations table view in MLflow',
      }),
      dataIndex: 'role',
      width: 200,
      editable: true,
    },
  ];

  render() {
    const { isRequestPending, handleSaveEdit, handleAddAuthorization, innerRef } =
      this.props;

    return (
      <>
        <EditableAuthorizationTable
          columns={this.tableColumns}
          data={this.props.authorizations}
          onSaveEdit={handleSaveEdit}
        />
        <Spacer size='sm' />
        <div>
          <Form ref={innerRef} layout='inline' onFinish={handleAddAuthorization} css={styles.form}>
            <Form.Item
              name='principal_type'
              initialValue="user"
              rules={[
                {
                  required: true,
                  message: this.props.intl.formatMessage({
                    defaultMessage: 'Type is required.',
                    description:
                      'Error message for type requirement in editable permissions table view in MLflow',
                  })
                },
              ]}
            >
              <Select
                className='type-select'
                data-test-id='type-select-dropdown'
              >
                <Select.Option value="user" key="user"> User </Select.Option>
                <Select.Option value="group" key="group"> Group </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name='principal_name' rules={[
                {
                  required: true,
                  message: this.props.intl.formatMessage({
                    defaultMessage: 'Name is required.',
                    description:
                      'Error message for name requirement in editable permissions table view in MLflow',
                  })
                },
              ]}>
              <Input
                aria-label='user name'
                placeholder={this.props.intl.formatMessage({
                  defaultMessage: 'Name',
                  description:
                    'Default text for name placeholder in editable permissions table form in MLflow',
                })}
              />
            </Form.Item>
            <Form.Item name="role" rules={[]}
            initialValue="reader">
              <Select
                className='permission-select'
                data-test-id='permission-dropdown'
              >
                <Select.Option className='permission-select-option' value="reader" key="reader"> Reader </Select.Option>
                <Select.Option className='permission-select-option' value="editor" key="editor"> Editor </Select.Option>
                <Select.Option className='permission-select-option' value="manager" key="manager"> Manager </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button loading={isRequestPending} htmlType='submit' data-test-id='add-tag-button'>
                <FormattedMessage
                  defaultMessage='Add'
                  description='Add button text in editable authorizations table view in MLflow'
                />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  }
}

const styles = {
  form: (theme: any) => ({ '& > div': { marginRight: theme.spacing.sm } }),
};

// @ts-expect-error TS(2769): No overload matches this call.
export const ManageUserAuthorization = injectIntl(ManageUserAuthorizationImpl);