import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Typography as AntDTypography } from 'antd';
import type { ComponentProps } from 'react';

import type { Theme } from '../../theme';
import { DesignSystemAntDConfigProvider } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks';
import type { DangerouslySetAntdProps, TypographyColor, HTMLDataAttributes } from '../types';
import { getTypographyColor } from '../utils/css-utils';
import { safex } from '../utils/safex';

const { Title: AntDTitle } = AntDTypography;

type AntDTypographyProps = ComponentProps<typeof AntDTypography>;
type AntDTitleProps = ComponentProps<typeof AntDTypography['Title']>;

export interface TitleProps
  extends AntDTypographyProps,
    Pick<AntDTitleProps, 'level' | 'ellipsis' | 'id' | 'title' | 'aria-label'>,
    HTMLDataAttributes,
    DangerouslySetAntdProps<AntDTitleProps> {
  withoutMargins?: boolean;
  color?: TypographyColor;
}

function getLevelStyles(theme: Theme, props: TitleProps): SerializedStyles {
  const centerIcons = safex('databricks.fe.designsystem.centerIconsInText', false);
  switch (props.level) {
    case 1:
      return css({
        '&&': {
          fontSize: theme.typography.fontSizeXxl,
          lineHeight: theme.typography.lineHeightXxl,
          fontWeight: theme.typography.typographyBoldFontWeight,
        },
        ...(centerIcons && {
          '& > .anticon': {
            lineHeight: theme.typography.lineHeightXxl,
          },
        }),
      });
    case 2:
      return css({
        '&&': {
          fontSize: theme.typography.fontSizeXl,
          lineHeight: theme.typography.lineHeightXl,
          fontWeight: theme.typography.typographyBoldFontWeight,
        },
        ...(centerIcons && {
          '& > .anticon': {
            lineHeight: theme.typography.lineHeightXl,
          },
        }),
      });
    case 3:
      return css({
        '&&': {
          fontSize: theme.typography.fontSizeLg,
          lineHeight: theme.typography.lineHeightLg,
          fontWeight: theme.typography.typographyBoldFontWeight,
        },
        ...(centerIcons && {
          '& > .anticon': {
            lineHeight: theme.typography.lineHeightLg,
          },
        }),
      });
    case 4:
    default:
      return css({
        '&&': {
          fontSize: theme.typography.fontSizeMd,
          lineHeight: theme.typography.lineHeightMd,
          fontWeight: theme.typography.typographyBoldFontWeight,
        },
        ...(centerIcons && {
          '& > .anticon': {
            lineHeight: theme.typography.lineHeightMd,
          },
        }),
      });
  }
}

function getTitleEmotionStyles(theme: Theme, props: TitleProps): SerializedStyles {
  const centerIcons = safex('databricks.fe.designsystem.centerIconsInText', false);
  return css(
    getLevelStyles(theme, props),
    {
      '&&': {
        color: getTypographyColor(theme, props.color, theme.colors.textPrimary),
      },
      ...(centerIcons && {
        '& > .anticon': {
          verticalAlign: 'middle',
        },
      }),
    },
    props.withoutMargins && {
      '&&': {
        marginTop: '0 !important', // override general styling
        marginBottom: '0 !important', // override general styling
      },
    },
  );
}
export function Title(userProps: TitleProps): JSX.Element {
  const { dangerouslySetAntdProps, withoutMargins, color, ...props } = userProps;
  const { theme } = useDesignSystemTheme();

  return (
    <DesignSystemAntDConfigProvider>
      <AntDTitle
        {...props}
        className={props.className}
        css={getTitleEmotionStyles(theme, userProps)}
        {...dangerouslySetAntdProps}
      />
    </DesignSystemAntDConfigProvider>
  );
}
