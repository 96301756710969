import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgStopIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M4.5 4a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-7Z"
      />
    </svg>
  );
}
function StopIcon(props: IconProps) {
  return <Icon {...props} component={SvgStopIcon} />;
}
export default StopIcon;
