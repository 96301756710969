import { forwardRef } from 'react';

import { Checkbox } from '../Checkbox';
import { useDesignSystemTheme } from '../Hooks';
import { InfoIcon } from '../Icon';
import { Tooltip } from '../Tooltip';
import { getComboboxOptionItemWrapperStyles, getInfoIconStyles, getCheckboxStyles } from '../_shared_/Combobox';
import type { HTMLDataAttributes } from '../types';
import { useDialogComboboxContext } from './hooks/useDialogComboboxContext';
import { useDialogComboboxOptionListContext } from './hooks/useDialogComboboxOptionListContext';
import {
  getKeyboardNavigationFunctions,
  dialogComboboxLookAheadKeyDown,
  getDialogComboboxOptionLabelWidth,
} from './shared';

export interface DialogComboboxOptionListCheckboxItemProps
  extends HTMLDataAttributes,
    React.HTMLAttributes<HTMLDivElement> {
  value: string;
  checked?: boolean;
  disabled?: boolean;
  disabledReason?: React.ReactNode;
  indeterminate?: boolean;
  children?: React.ReactNode;
  onChange?: (...args: any[]) => any;
  _TYPE?: string;
}

const DuboisDialogComboboxOptionListCheckboxItem = forwardRef<
  HTMLDivElement,
  DialogComboboxOptionListCheckboxItemProps
>(({ value, checked, indeterminate, onChange, children, disabledReason, _TYPE, ...props }, ref) => {
  const { theme } = useDesignSystemTheme();
  const { textOverflowMode, contentWidth } = useDialogComboboxContext();
  const { isInsideDialogComboboxOptionList, setLookAhead, lookAhead } = useDialogComboboxOptionListContext();

  if (!isInsideDialogComboboxOptionList) {
    throw new Error('`DialogComboboxOptionListCheckboxItem` must be used within `DialogComboboxOptionList`');
  }

  const handleSelect = () => {
    if (onChange) {
      onChange(value);
    }
  };

  let content: React.ReactNode = children ?? value;
  if (props.disabled && disabledReason) {
    content = (
      <div css={{ display: 'flex' }}>
        <div>{content}</div>
        <div>
          <Tooltip title={disabledReason} placement="right">
            <span css={getInfoIconStyles(theme)}>
              <InfoIcon aria-hidden="false" />
            </span>
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      role="option"
      // Using aria-selected instead of aria-checked because the parent listbox
      aria-selected={indeterminate ? false : checked}
      css={[getComboboxOptionItemWrapperStyles(theme)]}
      {...props}
      onClick={(e) => {
        if (props.disabled) {
          e.preventDefault();
        } else {
          handleSelect();
        }
      }}
      tabIndex={-1}
      {...getKeyboardNavigationFunctions(handleSelect, {
        onKeyDown: props.onKeyDown,
        onMouseEnter: props.onMouseEnter,
        onDefaultKeyDown: (e) => dialogComboboxLookAheadKeyDown(e, setLookAhead, lookAhead),
      })}
    >
      <Checkbox
        disabled={props.disabled}
        isChecked={indeterminate ? null : checked}
        css={[
          getCheckboxStyles(theme, textOverflowMode),
          contentWidth
            ? {
                '& > span:last-of-type': {
                  width: getDialogComboboxOptionLabelWidth(theme, contentWidth),
                },
              }
            : {},
        ]}
        tabIndex={-1}
        // Needed because Antd handles keyboard inputs as clicks
        onClick={(e) => {
          e.stopPropagation();
          handleSelect();
        }}
      >
        {content}
      </Checkbox>
    </div>
  );
});

DuboisDialogComboboxOptionListCheckboxItem.defaultProps = {
  _TYPE: 'DialogComboboxOptionListCheckboxItem',
};

export const DialogComboboxOptionListCheckboxItem = DuboisDialogComboboxOptionListCheckboxItem;
