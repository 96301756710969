import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgOverflowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M8 1a1.75 1.75 0 1 0 0 3.5A1.75 1.75 0 0 0 8 1ZM8 6.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM8 11.5A1.75 1.75 0 1 0 8 15a1.75 1.75 0 0 0 0-3.5Z"
      />
    </svg>
  );
}
function OverflowIcon(props: IconProps) {
  return <Icon {...props} component={SvgOverflowIcon} />;
}
export default OverflowIcon;
