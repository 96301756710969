import { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { LockOutlined, CodeOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';

type Props = {
  handleForgotPasswordSubmit: (...args: any[]) => any;
  setView: (...args: any[]) => any;
  requestPending: boolean;
}
type State = any;
/**
 * Component that renders a form for  Code Verify .
 */
class CodeVerifyFormComponent extends Component<Props, State> {
  render() {
    const { handleForgotPasswordSubmit, setView, requestPending } = this.props;
    return (<div>
      <Form onFinish={handleForgotPasswordSubmit} className="login-form">
        <b>Verification Code</b>
        <Form.Item name="code" rules={[{ required: true, message: 'Please input your verification code!' }]}>
          <Input className="login-input"
            prefix={<CodeOutlined className="site-form-item-icon" />} 
            placeholder="Enter your verification code"
          />
        </Form.Item>
        <b>New Password</b>
        <Form.Item name="newpassword" rules={[{ required: true, message: 'Please input your new password!' }]}>
          <Input.Password className="login-input"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Enter New Password"
          />
        </Form.Item>

        <Form.Item>
          <span className="login__link" id="login" onClick={setView} >Back to login.</span>
        </Form.Item>
        <Form.Item>
          <Button loading={requestPending} type="primary" htmlType="submit" className="login-form-button">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>);
  }
}

// @ts-expect-error TS(2769): No overload matches this call.
export const CodeVerifyForm = injectIntl(CodeVerifyFormComponent);
