export const MODEL_VERSION_STATUS_POLL_INTERVAL = 10000;

export const CONCURRENTS_PER_PAGE = 10;

export const MAX_RUNS_IN_SEARCH_MODEL_VERSIONS_FILTER = 75; // request size has a limit of 4KB

export const CONCURRENTS_SEARCH_NAME_FIELD = 'name';

export const CONCURRENTS_SEARCH_TIMESTAMP_FIELD = 'timestamp';

export const CONCURRENT_SCHEMA_TENSOR_TYPE = 'tensor';

export const AntdTableSortOrder = {
  ASC: 'ascend',
  DESC: 'descend',
};
export const EMPTY_CELL_PLACEHOLDER = <div style={{ marginTop: -12 }}>_</div>;
