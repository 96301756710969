import React from 'react';
import { connect } from 'react-redux';
import { PageContainer } from '../../common/components/PageContainer';
import RequestStateWrapper, { triggerError } from '../../common/components/RequestStateWrapper';
import { Spinner } from '../../common/components/Spinner';
import { ErrorView } from '../../common/components/ErrorView';
import { concurrentsListPageRoute } from '../routes';
import { getUUID } from '../../common/utils/ActionUtils';
import Utils from '../../common/utils/Utils';
import { ConcurrentView } from './ConcurrentView';
import {
    concurrentListApi,
    getConcurrentApi,
    getConcurrentExecutionListApi,
    deleteConcurrentApi,
    updateConcurrentApi,
    getConcurrentAuthApi,
    periodicRunListApi
  } from '../actions';
import { searchExperimentsApi } from '../../experiment-tracking/actions';
import { withRouterNext } from '../../common/utils/withRouterNext';

type Props = {
  history: any;
  match: any;
  concurrentID: string;
  concurrent: any;
  concurrentExecutionList: any[];
  concurrentListApi: (...args: any[]) => any;
  getConcurrentApi: (...args: any[]) => any;
  getConcurrentAuthApi: (...args: any[]) => any;
  renameConcurrentApi: (...args: any[]) => any;
  updateConcurrentApi: (...args: any[]) => any;
  searchExperimentsApi: (...args: any[]) => any;
  periodicRunListApi: (...args: any[]) => any;
  getConcurrentExecutionListApi: (...args: any[]) => any;
  deleteConcurrentApi: (...args: any[]) => any;
}

type State = any;

export class ConcurrentPageImpl extends React.Component<Props, State> {
  getConcurrentExecutionListApiId = getUUID();
  getConcurrentApiId = getUUID();
  getConcurrentAuthApiId = getUUID();
  periodicRunApiId= getUUID();
  initialRequestIds = [this.getConcurrentApiId, this.getConcurrentExecutionListApiId, this.getConcurrentAuthApiId, this.periodicRunApiId];

  loadData = () => {
      const { concurrentID } = this.props;
      const promiseValues = [
          this.props.getConcurrentApi(concurrentID, this.getConcurrentApiId),
          this.props.getConcurrentAuthApi(concurrentID, this.getConcurrentAuthApiId),
          this.props.periodicRunListApi(this.periodicRunApiId),
          this.props.getConcurrentExecutionListApi(concurrentID, this.getConcurrentExecutionListApiId),
      ];
      return Promise.all(promiseValues);
  };
  
  handleDelete = () => {
    const { concurrentID } = this.props;
    return this.props.deleteConcurrentApi(concurrentID);
  };

  handleEditDescription = (description: any) => {
    const { concurrentID, concurrent } = this.props;
    return this.props.updateConcurrentApi(concurrentID, JSON.parse(concurrent.dagJson), description);
  };

  componentDidMount() {
    this.loadData();
    this.props.searchExperimentsApi();
  }

  render() {
    const { concurrentExecutionList, history, concurrent } = this.props;
    return(
      <PageContainer>
        <RequestStateWrapper requestIds={this.initialRequestIds}>
          {(loading: any, hasError: any, requests: any): any => {
            if (hasError) {
              if (Utils.concurrent404(requests, [this.initialRequestIds])) {
                return (
                  <ErrorView
                    statusCode={404}
                    subMessage='Concurrent does not exist'
                    fallbackHomePageReactRoute={concurrentsListPageRoute}
                  />
                );
              }
              triggerError(requests);
            } else if (loading) {
              return <Spinner />;
            } else if (concurrent) {
              return (
                <ConcurrentView
                  concurrent={concurrent}
                  concurrentExecutionList={concurrentExecutionList}
                  handleEditDescription={this.handleEditDescription}
                  handleDelete={this.handleDelete}
                  history={history}
                />
              );
            } else {
              return (
                <ErrorView
                  statusCode={404}
                  subMessage='Concurrent does not exist'
                  fallbackHomePageReactRoute={concurrentsListPageRoute}
                />
              );
            }
          }}
        </RequestStateWrapper>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const concurrentID = decodeURIComponent(ownProps.params.concurrentID);
  const concurrent = state.entities.concurrentByID[concurrentID];
  const concurrentExecutionList = state.entities.concurrentExecutionByID[concurrentID];
  const periodicRunByDagid = state.entities.periodicRunByDagid;
  if(concurrent) {
    concurrent['scheduled'] = periodicRunByDagid[concurrent.dagid];
  }

  return {
    concurrentID,
    concurrent,
    concurrentExecutionList
  };
};
  
const mapDispatchToProps = {
  getConcurrentExecutionListApi,
  getConcurrentApi,
  concurrentListApi,
  deleteConcurrentApi,
  updateConcurrentApi, 
  getConcurrentAuthApi,
  searchExperimentsApi,
  periodicRunListApi,
};

export const ConcurrentPage = withRouterNext(
  connect(mapStateToProps, mapDispatchToProps)(ConcurrentPageImpl)
);