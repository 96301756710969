import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgCopyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.75 1a.75.75 0 0 0-.75.75v8.5c0 .414.336.75.75.75H5v3.25c0 .414.336.75.75.75h8.5a.75.75 0 0 0 .75-.75v-8.5a.75.75 0 0 0-.75-.75H11V1.75a.75.75 0 0 0-.75-.75h-8.5ZM9.5 5V2.5h-7v7H5V5.75A.75.75 0 0 1 5.75 5H9.5Zm-3 8.5v-7h7v7h-7Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
function CopyIcon(props: IconProps) {
  return <Icon {...props} component={SvgCopyIcon} />;
}
export default CopyIcon;
