import type { CSSObject, Interpolation, SerializedStyles, Theme as EmotionTheme } from '@emotion/react';
import { css } from '@emotion/react';

import { getAnimationCss } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks';
import type { Icon } from '../Icon';
import { CheckCircleIcon, DangerIcon, WarningIcon } from '../Icon';
import type { ValidationState } from '../types';

export interface FormMessageProps {
  message: React.ReactNode;
  type: ValidationState;
  className?: string;
  css?: Interpolation<EmotionTheme>;
}

const getMessageStyles = (clsPrefix: string, theme: EmotionTheme): SerializedStyles => {
  const errorClass = `.${clsPrefix}-form-error-message`;
  const successClass = `.${clsPrefix}-form-success-message`;
  const warningClass = `.${clsPrefix}-form-warning-message`;

  const styles: CSSObject = {
    '&&': {
      lineHeight: theme.typography.lineHeightSm,
      fontSize: theme.typography.fontSizeSm,
      marginTop: theme.spacing.sm,
      display: 'flex',
      alignItems: 'start',
    },

    [`&${errorClass}`]: {
      color: theme.colors.actionDangerPrimaryBackgroundDefault,
    },

    [`&${successClass}`]: {
      color: theme.colors.textValidationSuccess,
    },

    [`&${warningClass}`]: {
      color: theme.colors.textValidationWarning,
    },

    ...getAnimationCss(theme.options.enableAnimation),
  };

  return css(styles);
};

const VALIDATION_STATE_ICONS: Record<ValidationState, typeof Icon> = {
  error: DangerIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
};

export function FormMessage({ message, type = 'error', className = '', css }: FormMessageProps): JSX.Element {
  const { classNamePrefix, theme } = useDesignSystemTheme();

  const stateClass = `${classNamePrefix}-form-${type}-message`;
  const StateIcon = VALIDATION_STATE_ICONS[type];

  const wrapperClass = `${classNamePrefix}-form-message ${className} ${stateClass}`.trim();

  return (
    <div className={wrapperClass} css={[getMessageStyles(classNamePrefix, theme), css]}>
      <StateIcon />
      <div style={{ paddingLeft: theme.spacing.xs }}>{message}</div>
    </div>
  );
}
