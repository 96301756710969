import React, { Component } from 'react';
import { Form, Row, Col } from 'antd';
import { Input, Select } from '@databricks/design-system';
import { PromptEngineeringUIDocUrl } from '../../common/constants';
import { FormattedMessage, injectIntl } from 'react-intl';

export const MODEL_NAME_FIELD = 'modelName';
const { Option } = Select;

type Props = {
  visible: boolean;
  validator?: (...args: any[]) => any;
  intl?: any;
  innerRef: any;
};

type State = any;

/**
 * Component that renders a form for creating a new gateway route.
 */
class AddGatewayRouteFormImpl extends Component<Props, State> {
  static getLearnMoreLinkUrl = () => PromptEngineeringUIDocUrl;
  state = {
    api_key_type : ''
  }

  render() {
    const { api_key_type } = this.state;
    const learnMoreLinkUrl = AddGatewayRouteFormImpl.getLearnMoreLinkUrl();
    const apiKeyName = () => {
      return api_key_type.charAt(0).toUpperCase() + api_key_type.slice(1) + ' Api key'
    }
    return (
      <Form ref={this.props.innerRef} layout='vertical' data-testid='create-model-form-modal'>
        <Row>
          <Col span={12}>
            <Form.Item
              name="name"
              label={this.props.intl.formatMessage({
                defaultMessage: 'Gateway route name',
                description: 'Text for form title on adding gateway route in the settings',
              })}
              rules={[
                {
                  required: true,
                  message: this.props.intl.formatMessage({
                    defaultMessage: 'Please input a name for the new route.',
                    description:
                      'Error message for having no input for adding gateway route in the settings',
                  }),
                },
                { validator: this.props.validator },
              ]}
            >
              <Input autoFocus />
            </Form.Item>
          </Col>
          <Col span={12} style={{paddingLeft: '5px'}}>
            <Form.Item
              label='Gateway Route type'
              name='route_type'
              rules={[{ required: true, message: 'Please select a route type.' }]}
            >
              <Select style={{width: '100%'}}
                dropdownClassName='route-type-select-dropdown'
                onChange={() => { console.log('chandfs ')}}
                placeholder='Select a route type'
              >
                <Option value='llm/v1/completions' className='create-new-model-option'> Completions </Option>
                <Option value='llm/v1/chat' className='create-new-model-option'> Chat </Option>
                <Option value='llm/v1/embeddings' className='create-new-model-option'> Embeddings </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="model_provider"
              label='Model providers'
              rules={[
                {
                  required: true,
                  message: 'Please select a provider',
                }
              ]}
            >
              <Select style={{width: '100%'}}
                dropdownClassName='route-type-select-dropdown'
                onChange={(value) => { console.log('valse', value) ; this.setState({api_key_type: value})}}
                placeholder='Select a model provider'
                value={api_key_type}
              >
                <Option value='openai' className='create-new-model-option'> OpenAI </Option>
                <Option value='mosaicml' className='create-new-model-option'> MosaicML </Option>
                <Option value='anthropic' className='create-new-model-option'> Anthropic </Option>
                <Option value='cohere' className='create-new-model-option'> Cohere </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} style={{paddingLeft: '5px'}}>
            <Form.Item
              label='Model Name'
              name='model_name'
              rules={[{ required: true, message: 'Please input a model name.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="api_key"
          label={apiKeyName()}
          rules={[
            {
              required: true,
              message: 'Please input api key for the new route.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <p className='add-gateway-route-explanatory-text'>
          <FormattedMessage
            defaultMessage='After adding, you can create run using Prompt Engineering UI .&nbsp;'
            description='Text for form description on adding gateway route in the settings'
          />
          <FormattedMessage
            defaultMessage='<link>Learn more</link>'
            description='Learn more link on the form for adding gateway route in the settings'
            values={{
              link: (
                chunks: any, // Reported during ESLint upgrade
              ) => (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a href={learnMoreLinkUrl} target='_blank'>
                  {chunks}
                </a>
              ),
            }}
          />
          .
        </p>
      </Form>
    );
  }
}

// @ts-expect-error TS(2769): No overload matches this call.
export const AddGatewayRouteForm = injectIntl(AddGatewayRouteFormImpl);
