import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgLoadingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.212 12a.788.788 0 0 1-.789-.788 9.57 9.57 0 0 0-.757-3.751 9.662 9.662 0 0 0-5.129-5.129 9.587 9.587 0 0 0-3.749-.755.788.788 0 0 1 0-1.577c1.513 0 2.983.296 4.365.882a11.128 11.128 0 0 1 3.562 2.403 11.157 11.157 0 0 1 3.283 7.927.785.785 0 0 1-.786.788Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
function LoadingIcon(props: IconProps) {
  return <Icon {...props} component={SvgLoadingIcon} />;
}
export default LoadingIcon;
