import { Component } from 'react';
import { AuthenticationDetails, CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { message } from 'antd';
import { connect } from 'react-redux';
// @ts-expect-error ... Remove this comment to see the full error message
import infinstorLogo from '../../common/static/Concurrent-Logo.png';
// @ts-expect-error ... Remove this comment to see the full error message
import spinner from '../../common/static/mlflow-spinner.png';
import Routes from '../../experiment-tracking/routes';

import { LoginForm } from './LoginForm';
import { ForgotPassword } from './ForgotPassword';
import { CodeVerifyForm } from './CodeVerifyForm';
import { NewPassPage } from './NewPassPage';
import './LoginPage.css';
import { customerInfoApi } from '../actions';
import { withRouter } from "react-router-dom";

type Props = {
  history: any
  dispatch: (...args: any[]) => any;
};

type State = any;

class LoginPage extends Component<Props, State> {

  state = {
    activeView: 'login',
    userAttributes: {},
    cognitoUser: {},
    loginRequestPending: false,
    newPassRequestPending: false,
    forgotPassRequestPending: false,
    codeVerfiyRequestPending: false,
  };

  handleLoginSubmit = (value: any) => {
    let history =this.props.history;
    let me = this;
    this.setState({ loginRequestPending: true });   
    let authenticationData = {
      Username: value.username,
      Password: value.password
    };
    let authenticationDetails = new AuthenticationDetails(
      authenticationData
    );
    let poolData = {
      UserPoolId : window.InfinStorUserPoolId,
      ClientId : window.InfinStorMlflowUiClientId
    };
    const userPool = new CognitoUserPool(poolData);
    let userData = {
      Username: value.username,
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    this.setState({ cognitoUser: cognitoUser });
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async function(result: any) {
        let idToken = result.idToken.jwtToken;
        let refreshToken = result.refreshToken.token;
        let accessToken = result.accessToken.jwtToken;
        createCookie("mlflow-request-header-Authorization", idToken, (24*60));
        createCookie("aws-refreshToken", refreshToken, (24*60));
        createCookie("aws-accessToken", accessToken, (24*60));
        let date = new Date();
        date.setTime(date.getTime()+(57*60*1000));
        localStorage.setItem('tokenTime', date.getTime().toString());
        me.props.dispatch(customerInfoApi()).then((apiResponse: any) => {
          let userInfo = apiResponse.value;
          if(userInfo.userName) {
            me.setState({ loginRequestPending: false });
            history.push(Routes.rootRoute);
          } else {
            me.setState({ loginRequestPending: false }); 
            message.error("Invalid User");
            me.setState({ activeView: 'login' });
          }
        }) .catch(() => {
          me.setState({ loginRequestPending: false }); 
          message.error("Invalid User");
          me.setState({ activeView: 'login' });
        });
      },
      onFailure: function(err) {
        message.info("login failed  : " + err);
        me.setState({ loginRequestPending: false });
      },
      newPasswordRequired: function (userAttributes) {
        message.success("Welcome to Infinstor. You will need to change the password.");
        me.setState({ loginRequestPending: false });
        me.setState({ activeView: 'setNewPass' });            
        me.setState({ userAttributes: userAttributes });
      }
    });
  };

  handleSetNewPassword = (values: { newpassword: string; }) => {
    let history =this.props.history;
    let me = this;
    let userAttributes: any = this.state.userAttributes;
    let cognitoUser: any = this.state.cognitoUser;
    var passformat = /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/;
    if(values.newpassword.match(passformat)){
      me.setState({ newPassRequestPending: true });
      delete userAttributes.email_verified;
      delete userAttributes.email;
      cognitoUser.completeNewPasswordChallenge(values.newpassword, userAttributes, {
        onSuccess: async function (result: { idToken: { jwtToken: any; }; refreshToken: { token: any; }; accessToken: { jwtToken: any; }; }) {
          let idToken = result.idToken.jwtToken;
          let refreshToken = result.refreshToken.token;
          let accessToken = result.accessToken.jwtToken;
          createCookie("mlflow-request-header-Authorization", idToken, (24*60));
          createCookie("aws-refreshToken", refreshToken, (24*60));
          createCookie("aws-accessToken", accessToken, (24*60));
          let date = new Date();
          date.setTime(date.getTime()+(57*60*1000));
          localStorage.setItem('tokenTime', date.getTime().toString());
          me.props.dispatch(customerInfoApi()).then((apiResponse: { value: any; }) => {
            let userInfo = apiResponse.value;
            if(userInfo.userName) {
              me.setState({ loginRequestPending: false }); 
              history.push(Routes.rootRoute);
            } else {
              me.setState({ loginRequestPending: false }); 
              message.error("Invalid User");
              me.setState({ activeView: 'login' });
            }
          }) .catch((ex: any) => {
            me.setState({ loginRequestPending: false }); 
            message.error("Invalid User");
            me.setState({ activeView: 'login' });
          });
        },
        onFailure: function (err: string) {
          me.setState({ newPassRequestPending: false });
          message.error("Set New Password failed :" + err)
        }
      });
    } else {
      message.error("The password must be at least eight characters long and must contain a lowercase letter, an uppercase letter, a number, and a special character");
    }
  }

  getOTP = (values: { userName: any; }) => {
    let me = this;
    me.setState({ forgotPassRequestPending: true });
    let data = {
      UserPoolId : window.InfinStorUserPoolId,
      ClientId : window.InfinStorMlflowUiClientId 
    };
    let userPool = new CognitoUserPool(data);
    let cognitoUser = new CognitoUser({
      Username: values.userName,
      Pool: userPool
    });
    me.setState({ cognitoUser: cognitoUser });
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        me.setState({ forgotPassRequestPending: false });
      },
      onFailure: function(err) {
        me.setState({ forgotPassRequestPending: false });
        message.error('UserNotFoundException : Please enter valied user name');
      },
      inputVerificationCode() { 
        me.setState({ forgotPassRequestPending: false });
        message.success('We sent a verification code to your email address. Please check.');
        me.setState({ activeView: 'changePassword' }); 
      }
    });
  }

  handleForgotPasswordSubmit = (values: { code: any; newpassword: any; }) => {
    let history =this.props.history;
    let me = this;
    me.setState({ codeVerfiyRequestPending: true });
    let cognitoUser: any = this.state.cognitoUser;
    cognitoUser.confirmPassword(values.code, values.newpassword , {
      onSuccess: (result: any) => {
        message.success("Password successfully changed!.");
        let authenticationData = {
          Username: cognitoUser.username,
          Password: values.newpassword
        };
        let authenticationDetails = new AuthenticationDetails(
          authenticationData
        );
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: async function(result: { idToken: { jwtToken: any; }; refreshToken: { token: any; }; accessToken: { jwtToken: any; }; }) {
            let idToken = result.idToken.jwtToken;
            let refreshToken = result.refreshToken.token;
            let accessToken = result.accessToken.jwtToken;
            createCookie("mlflow-request-header-Authorization", idToken, (24*60));
            createCookie("aws-refreshToken", refreshToken, (24*60));
            createCookie("aws-accessToken", accessToken, (24*60));
            let date = new Date();
            date.setTime(date.getTime()+(57*60*1000));
            localStorage.setItem('tokenTime', date.getTime().toString());
            me.props.dispatch(customerInfoApi()).then((apiResponse: { value: any; }) => {
              let userInfo = apiResponse.value;
              if(userInfo.userName) {
                me.setState({ loginRequestPending: false }); 
                history.push(Routes.rootRoute);
              } else {
                me.setState({ loginRequestPending: false }); 
                message.error("Invalid User");
                me.setState({ activeView: 'login' });
              }
            }) .catch((ex: any) => {
              me.setState({ loginRequestPending: false }); 
              me.setState({ activeView: 'login' });
            });
          },
          onFailure: function(err: string) {
            me.setState({ codeVerfiyRequestPending: false });
            message.info("login failed  : " + err);
          }
        });
      },
      onFailure: (error: any) => {
        message.error(error.message);
        me.setState({ codeVerfiyRequestPending: false });
      }
    });
  }

  setView = (active: { target: { id: any; }; }) => {
    this.setState({ activeView: active.target.id });
  };
  formRef: any;

  componentDidMount() {
    const mlflowHeader = document.querySelector("#mlflow-header");
    if(mlflowHeader){
      mlflowHeader.classList.add('hide');
    }
  }

  loginFormRef = (formRef: any) => {
    this.formRef = formRef;
  };

  render() {
    var hash = window.location.hash.substring(1);
    var params: any = {}
    hash.split('?').map(hk => { 
      let temp = hk.split('='); 
        params[temp[0]] = temp[1] 
    });
    let history =this.props.history;
    let me = this;
    if (params.code !== undefined) {
      let code = params.code;
      let urlEncodedData = "",
        urlEncodedDataPairs = [];
        urlEncodedDataPairs.push(
          encodeURIComponent( "grant_type" ) + '=' + encodeURIComponent("authorization_code") );
        urlEncodedDataPairs.push(
          encodeURIComponent( "code" ) + '=' + encodeURIComponent(code) );
        urlEncodedDataPairs.push(
          encodeURIComponent( "client_id" ) + '=' + encodeURIComponent(window.InfinStorMlflowUiClientId) );
        urlEncodedDataPairs.push(
          encodeURIComponent( "redirect_uri" ) + '=' + encodeURIComponent(window.location.origin));
        urlEncodedData = urlEncodedDataPairs.join( '&' ).replace( /%20/g, '+' );

        let xhr = new XMLHttpRequest();
        let cognitoDomain = window.InfinStorServiceServer.replace(/\./g, "-");
        let region = window.InfinStorUserPoolId.split('_')[0];
        let url = 'https://' + cognitoDomain + '.auth.' + region
          + '.amazoncognito.com/oauth2/token';
        xhr.open("POST", url);
        xhr.responseType = 'json';
        xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
        xhr.send(urlEncodedData);        
        xhr.onload = async() => {
          let respj = xhr.response;
          if(respj.id_token) {
            let idToken = respj['id_token'];
            let refreshToken = respj['refresh_token'];
            let accessToken = respj['access_token']
            createCookie("mlflow-request-header-Authorization", idToken, (24*60));
            createCookie("aws-refreshToken", refreshToken, (24*60));
            createCookie("aws-accessToken", accessToken, (24*60));
            let date = new Date();
            date.setTime(date.getTime()+(57*60*1000));
            localStorage.setItem('tokenTime', date.getTime().toString());
            me.props.dispatch(customerInfoApi()).then((apiResponse: { value: any; }) => {
              let userInfo = apiResponse.value;
              if(userInfo.userName) {
                me.setState({ loginRequestPending: false }); 
                history.push(Routes.rootRoute);
              } else {
                me.setState({ loginRequestPending: false }); 
                message.error("Invalid User");
                me.setState({ activeView: 'login' });
              }
            }) .catch((ex: any) => {
              me.setState({ loginRequestPending: false }); 
              message.error("Invalid User");
              me.setState({ activeView: 'login' });
            });
          } 
        }
      return <div className='Spinner'>
      <img alt='Page loading...' src={spinner} />
    </div>
    } else {
      if (window.hasOwnProperty('InfinStorIsExternalOauth') && window.InfinStorIsExternalOauth === "true") {
        let cognitoDomain = window.InfinStorServiceServer.replace(/\./g, "-");
        let region = window.InfinStorUserPoolId.split('_')[0];
        let hash = window.location.hash.substring(1);
        let url = 'https://' + cognitoDomain + '.auth.' + region
          + '.amazoncognito.com/login?response_type=code&scope=openid+email+profile&client_id='
          + window.InfinStorMlflowUiClientId + '&redirect_uri=' + window.location.origin
          + '&state=' + hash;
        window.location.replace(url);
        return <div className='Spinner'>
            <img alt='Page loading...' src={spinner} />
          </div>;
      } else {
        return (
          <div className="login-page">
            <div className="login-box">
              <div className="infin-logo-container">
                <a href="https://www.infinstor.com/"> <img src={infinstorLogo} alt="InfinStor" className="logo"/></a>
              </div>
                { 
                  // @ts-expect-error TS(2322):
                  this.state.activeView === 'login' ? <LoginForm handleLoginSubmit = {this.handleLoginSubmit} setView = {this.setView} requestPending = {this.state.loginRequestPending}/> 
                  // @ts-expect-error TS(2322):
                  : this.state.activeView === 'setNewPass' ? <NewPassPage handleSetNewPassword = {this.handleSetNewPassword} setView = {this.setView} requestPending = { this.state.newPassRequestPending}/> 
                  // @ts-expect-error TS(2322):
                  : this.state.activeView === 'forgotPassword' ? <ForgotPassword getOTP = {this.getOTP} setView = {this.setView} requestPending = {this.state.forgotPassRequestPending}/> 
                  // @ts-expect-error
                  : this.state.activeView === 'changePassword' ? <CodeVerifyForm handleForgotPasswordSubmit = {this.handleForgotPasswordSubmit} setView = {this.setView} requestPending = {this.state.codeVerfiyRequestPending}/>
                  // @ts-expect-error TS(2322):
                  : <LoginForm handleLoginSubmit = {this.handleLoginSubmit} setView = {this.setView} requestPending = {this.state.loginRequestPending}/>
                }
              <div className ="copyright">
                © Copyright 2023 InfinStor, Inc. All Rights Reserved.
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default withRouter(connect()(LoginPage));

function createCookie(cookieName: string, cookieValue: string, minutesToExpire: number) {
  var date = new Date();
  date.setTime(date.getTime()+(minutesToExpire*60*1000));
  document.cookie = cookieName + "=" + cookieValue + "; expires=" + date.toString();
}