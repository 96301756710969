import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgSortUnsortedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M11.5.94 7.47 4.97l1.06 1.06 2.22-2.22v8.38L8.53 9.97l-1.06 1.06 4.03 4.03 4.03-4.03-1.06-1.06-2.22 2.22V3.81l2.22 2.22 1.06-1.06L11.5.94ZM6 3.5H1V5h5V3.5ZM6 11.5H1V13h5v-1.5ZM1 7.5h5V9H1V7.5Z"
      />
    </svg>
  );
}
function SortUnsortedIcon(props: IconProps) {
  return <Icon {...props} component={SvgSortUnsortedIcon} />;
}
export default SortUnsortedIcon;
