import { Component } from 'react';
import { GenericInputModal } from '../../../experiment-tracking/components/modals/GenericInputModal';
import { injectIntl } from 'react-intl';
import { EditNodeInputForm } from './EditNodeInputForm';

type Props = {
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  handleSubmit: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  isOpen: boolean;
  input: any[];
}

type State = any;

export class AddNodeInputModalImpl extends Component<Props, State> {
 
  getNodeInputNamesAsSet = () =>
    new Set(this.props.input.map((values) => values['name']));

  nodeInputNameValidator = (rule: any, value: any, callback: any) => {
    const nodeInputNamesSet = this.getNodeInputNamesAsSet();
    callback(
      nodeInputNamesSet.has(value)
        ? this.props.intl.formatMessage(
            {
              defaultMessage: 'Input "{value}" already exists.',
              description: 'Validation message for inputs that already exist in node inputs list in MLflow Pipeline',
            },
            {
              value: value,
            },
          )
        : undefined,
    );
  };

  render() {
    const { isOpen } = this.props;

    return (
      <GenericInputModal
        title='Add Node Input'
        okText='Add'
        isOpen={isOpen}
        handleSubmit={this.props.handleSubmit}
        onClose={this.props.onClose}
      >
        {/*//@ts-expect-error */}
        <EditNodeInputForm nodeInputNameValidator={this.nodeInputNameValidator} action = 'add' {...this.props}/>
      </GenericInputModal>
    );
  }
}

//@ts-expect-error
export const AddNodeInputModal = injectIntl(AddNodeInputModalImpl);
