import { Component } from 'react';
import { Form, Input } from 'antd';
import { injectIntl } from 'react-intl';

type Props = {
  bucket: any;
  innerRef: any;
}
 
class BucketConfigFormComponent extends Component<Props> {

  componentDidUpdate(prevProps: any) {
    this.resetFields(prevProps);
  }

  resetFields = (prevProps: any) => {
    const formRef = this.props.innerRef;
    // _.isEqual(prevProps.activeNode, this.props.activeNode)
    if (prevProps.bucket.bucket !== this.props.bucket.bucket) {
      // reset input field to reset displayed initialValue
      formRef.current.resetFields();
    }
  };

  render() {
    const { bucket } = this.props;
    return (
      <Form ref={this.props.innerRef} className="login-form" initialValues={bucket}>        
        <div>
          <b>Bucket name</b>
          <Form.Item name="bucket" rules={[{ required: true,message: 'Bucket Name is required!' }]}>
            <Input placeholder="Enter Bucket Name"/>
          </Form.Item>
          <b>IAM Role</b>
          <Form.Item name="iam_role" rules={[{ required: true, message: 'IAM Role is required!' }]}>
            <Input
              placeholder="Enter IAM Role"
            />
          </Form.Item>
          <b>External ID</b>
          <Form.Item name="external_id" rules={[{ required: true, message: 'External ID is required!' }]}>
          <Input
            placeholder="Enter External ID"
          />
          </Form.Item>
        </div>
      </Form>
    );
  }
}

//@ts-expect-error
export const BucketConfigForm = injectIntl(BucketConfigFormComponent);
