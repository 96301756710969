import { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

type Props = {
  handleLoginSubmit: (...args: any[]) => any;
  setView: (...args: any[]) => any;
  requestPending: boolean;
}

type State = any;

/**
 * Component that renders a form for Login Form .
 */
class LoginFormComponent extends Component<Props, State> {
  render() {
    const { handleLoginSubmit, setView, requestPending } = this.props;
    return (<div>
        <h1 style={{fontSize:"24px"}} >Login</h1>
        <div style={{paddingBottom:'10px'}}>
          <p>Enter your account credentials below.</p>
        </div>
        <Form onFinish={handleLoginSubmit} className="login-form">
          <b>Username</b>
          <Form.Item
              name='username'
              rules={[
                {
                  required: true,
                  message: 'Please input your username!.',
                },
              ]}
            >
              <Input className="login-input"
                prefix={<UserOutlined className="site-form-item-icon" />}  
                placeholder="Enter Username"
              />
            </Form.Item>
          <b>Password</b>
          <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!.',
                },
              ]}
            >
              <Input.Password className="login-input"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Enter Password"
              />
            </Form.Item>
          <Form.Item>
            <span className="login__link" id="forgotPassword" onClick={setView} >Forgot Password?</span>
          </Form.Item>
  
          <Form.Item>
            <Button loading={requestPending} type="primary" htmlType="submit" className="login-form-button">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

// @ts-expect-error TS(2769): No overload matches this call.
export const LoginForm = injectIntl(LoginFormComponent);
