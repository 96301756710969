import {
  SearchIcon,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Tooltip,
  Empty,
  PlusIcon,
  TableSkeletonRows,
} from '@databricks/design-system';
import { Interpolation, Theme } from '@emotion/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '../../common/utils/RoutingUtils';
import { gatewayDocUrl } from '../../common/constants';
import { IconButton } from '../../common/components/IconButton';
import { EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import Routes from 'experiment-tracking/routes'

import Utils from '../../common/utils/Utils';
import type {
  KeyValueEntity,
  ModelEntity,
  ModelVersionInfoEntity,
} from '../../experiment-tracking/types';
import type { RouteEntity } from '../types';

enum ColumnKeys {
  NAME = 'name',
  ROUTE_TYPE = 'route_type',
  MODEL_PROVIDER = 'model_provider',
  MODEL_NAME = 'model_name',
  OPENAI_API_KEY = 'openai_api_key',
}

export interface GatewayRouteListTableProps {
  routesData: RouteEntity[];
  pagination: React.ReactElement;
  orderByKey: string;
  orderByAsc: boolean;
  isLoading: boolean;
  isFiltered: boolean;
  onSortChange: (params: { orderByKey: string; orderByAsc: boolean }) => void;
}

type ModelsColumnDef = ColumnDef<RouteEntity> & {
  // Our experiments column definition houses style definitions in the metadata field
  meta?: { styles?: Interpolation<Theme> };
};

export const GatewayRouteListTable = ({
  routesData,
  orderByAsc,
  orderByKey,
  onSortChange,
  isLoading,
  isFiltered,
  pagination,
}: GatewayRouteListTableProps) => {
  const intl = useIntl();
  const [selectedRoute, setSelectedRoute] =  useState({
    // name: 'string',
    // route_type: 'string',
    // model: {
    //   provider: 'string',
    //   name: 'string',
    //   config: {openai_api_key: ''},
    // },
  });

  const editGatewayRoute = (route: RouteEntity) => {
    setSelectedRoute(route);
  }

  const tableColumns = useMemo(() => {
    const columns: ModelsColumnDef[] = [
      {
        id: ColumnKeys.NAME,
        enableSorting: true,
        header: intl.formatMessage({
          defaultMessage: 'Name',
          description: 'Column title for model name in the registered model page',
        }),
        accessorKey: 'name',
        cell: ({ getValue }) => (
          <Link to={Routes.getGatewayRoutePageRoute(getValue())}>
            <Tooltip title={getValue()}>{getValue()}</Tooltip>
          </Link>
        ),
        meta: { styles: { maxWidth: 300, flex: 1 } },
      },
      {
        id: ColumnKeys.ROUTE_TYPE,
        enableSorting: false,
        header: intl.formatMessage({
          defaultMessage: 'Router Type',
          description: 'Column title for router type in the gateway router list page',
        }),
        accessorKey: 'route_type',
        cell: ({ getValue, row: { original } }) => {
          const { route_type } = original;
          return route_type
        },
      },
    ];

    columns.push(
      {
        id: ColumnKeys.MODEL_PROVIDER,
        enableSorting: false,
        header: 'Model Provider',
        cell: ({ row: { original } }) => {
          const { model } = original;
          return model.provider;
        },
      },
      {
        id: ColumnKeys.MODEL_NAME,
        enableSorting: false,
        header: 'Model Name',
        cell: ({ row: { original } }) => {
          const { model } = original;
          return model.name;
        },
      },
      {
        id: ColumnKeys.OPENAI_API_KEY,
        header: 'API Key',
        accessorKey: 'openai_api_key',
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const { model } = original;
          return model.config[ model.provider+'_api_key'];
        },
      },
    );
    return columns;
  }, [
    intl,
  ]);

  const sorting: SortingState = [{ id: orderByKey, desc: !orderByAsc }];

  const setSorting = (stateUpdater: SortingState | ((state: SortingState) => SortingState)) => {
    const [newSortState] =
      typeof stateUpdater === 'function' ? stateUpdater(sorting) : stateUpdater;
    if (newSortState) {
      onSortChange({ orderByKey: newSortState.id, orderByAsc: !newSortState.desc });
    }
  };

  const emptyDescription = (
    <FormattedMessage
      defaultMessage='No gateway route configured yet. <link>Learn more about AI Gateway</link>.'
      description='route table > No gateway route configured yet'
      values={{
        link: (content: any) => (
          <a target='_blank' rel='noopener noreferrer' href={gatewayDocUrl}>
            {content}
          </a>
        ),
      }}
    />
  );
  const noResultsDescription = (
    <FormattedMessage
      defaultMessage='No results. Try using a different keyword or adjusting your filters.'
      description='route table > no results after filtering'
    />
  );
  const emptyComponent = isFiltered ? (
    // Displayed when there is no results, but any filters have been applied
    <Empty
      description={noResultsDescription}
      image={<SearchIcon />}
      data-testid='model-list-no-results'
    />
  ) : (
    // Displayed when there is no results with no filters applied
    <Empty
      description={emptyDescription}
      image={<PlusIcon />}
    />
  );

  const isEmpty = () => !isLoading && table.getRowModel().rows.length === 0;

  const table = useReactTable<RouteEntity>({
    data: routesData,
    columns: tableColumns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getRowId: ({ name }) => name,
    onSortingChange: setSorting,
  });

  return (
    <>
      <Table
        data-testid='gateway-route-list-table'
        pagination={pagination}
        scrollable
        empty={isEmpty() ? emptyComponent : undefined}
      >
        <TableRow isHeader>
          {table.getLeafHeaders().map((header) => (
            <TableHeader
              ellipsis
              key={header.id}
              sortable={header.column.getCanSort()}
              sortDirection={header.column.getIsSorted() || 'none'}
              onToggleSort={() => {
                const [currentSortColumn] = sorting;
                const changingDirection = header.column.id === currentSortColumn.id;
                const sortDesc = changingDirection ? !currentSortColumn.desc : false;
                header.column.toggleSorting(sortDesc);
              }}
              css={(header.column.columnDef as ModelsColumnDef).meta?.styles}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
            </TableHeader>
          ))}
        </TableRow>
        {isLoading ? (
          <TableSkeletonRows table={table} />
        ) : (
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getAllCells().map((cell) => (
                <TableCell
                  ellipsis
                  key={cell.id}
                  css={(cell.column.columnDef as ModelsColumnDef).meta?.styles}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        )}
      </Table>
    </>
  );
};
