import type { HTMLAttributes } from 'react';
import React from 'react';

import type { Theme } from '../../theme';
import type { SecondaryColorToken, TagColorToken } from '../../theme/colorList';
import { lightColorList } from '../../theme/colorList';
import { useDesignSystemTheme } from '../Hooks/useDesignSystemTheme';
import { CloseIcon } from '../Icon';
import type { HTMLDataAttributes } from '../types';
import { safex } from '../utils/safex';

export interface TagProps extends HTMLDataAttributes, HTMLAttributes<HTMLSpanElement> {
  /**
   * The color of the tag.
   */
  color?: TagColors;

  /**
   * Text to be rendered inside the tag.
   */
  children: React.ReactNode;

  /**
   * Whether or not the tag should be closable.
   */
  closable?: boolean;

  /**
   * Function called when the close button is clicked.
   */
  onClose?: () => void;
}

export type TagColors = keyof typeof colorMap;

const colorMap: Record<SecondaryColorToken | 'default', TagColorToken> = {
  default: 'tagDefault',
  brown: 'tagBrown',
  coral: 'tagCoral',
  charcoal: 'tagCharcoal',
  indigo: 'tagIndigo',
  lemon: 'tagLemon',
  lime: 'tagLime',
  pink: 'tagPink',
  purple: 'tagPurple',
  teal: 'tagTeal',
  turquoise: 'tagTurquoise',
};

function getTagEmotionStyles(
  theme: Theme,
  color: SecondaryColorToken | 'default' = 'default',
  clickable = false,
  centerIcons: boolean,
) {
  let textColor = theme.colors.tagText;
  const backgroundColor = theme.colors[colorMap[color]];
  let iconHover = theme.colors.tagIconHover;
  let iconPress = theme.colors.tagIconPress;
  let tagHover = theme.colors.tagHover;
  let tagPress = theme.colors.tagPress;

  // Because the default tag background color changes depending on system theme, so do its other variables.
  if (color === 'default') {
    textColor = theme.colors.textPrimary;
    iconHover = theme.colors.actionTertiaryTextHover;
    iconPress = theme.colors.actionTertiaryTextPress;
  }

  // Because lemon is a light yellow, all its variables pull from the light mode palette, regardless of system theme.
  if (color === 'lemon') {
    textColor = lightColorList.textPrimary;
    iconHover = lightColorList.actionTertiaryTextHover;
    iconPress = lightColorList.actionTertiaryTextPress;
    tagHover = lightColorList.actionTertiaryBackgroundHover;
    tagPress = lightColorList.actionTertiaryBackgroundPress;
  }

  return {
    tag: {
      border: 'none',
      color: textColor,
      padding: '2px 4px',
      backgroundColor,
      borderRadius: theme.borders.borderRadiusMd,
      marginRight: 8,
      display: 'inline-block',
      cursor: clickable ? 'pointer' : 'default',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
    },
    close: {
      height: theme.general.iconFontSize,
      width: theme.general.iconFontSize,
      lineHeight: `${theme.general.iconFontSize}px`,
      padding: 0,
      color: textColor,
      fontSize: theme.general.iconFontSize,
      margin: '-2px -4px -2px 2px',
      borderTopRightRadius: theme.borders.borderRadiusMd,
      borderBottomRightRadius: theme.borders.borderRadiusMd,
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      marginLeft: theme.spacing.xs,
      marginRight: -theme.spacing.xs,

      '&:hover': {
        backgroundColor: tagHover,
        color: iconHover,
      },

      '&:active': {
        backgroundColor: tagPress,
        color: iconPress,
      },

      '&:focus-visible': {
        outlineStyle: 'solid',
        outlineWidth: 1,
        outlineOffset: 1,
        outlineColor: theme.colors.actionDefaultBorderFocus,
      },

      '.anticon': {
        verticalAlign: 0,
      },
    },
    text: {
      padding: 0,
      fontSize: theme.typography.fontSizeBase,
      lineHeight: theme.typography.lineHeightSm,
      ...(centerIcons && {
        '& .anticon': {
          verticalAlign: 'text-top',
        },
      }),
    },
  };
}

export function Tag(props: TagProps): JSX.Element {
  const { theme } = useDesignSystemTheme();
  const { color, children, closable, onClose, role = 'status', ...attributes } = props;
  const isClickable = Boolean(props.onClick);
  const centerIconsInText = safex('databricks.fe.designsystem.centerIconsInText', false);

  const styles = getTagEmotionStyles(theme, color, isClickable, centerIconsInText);

  return (
    <div role={role} {...attributes} css={styles.tag}>
      <div css={[styles.content, styles.text]}>
        {children}

        {closable && (
          <button
            css={styles.close}
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              if (onClose) {
                onClose();
              }
            }}
            onMouseDown={(e) => {
              // Keeps dropdowns of any underlying select from opening.
              e.stopPropagation();
            }}
          >
            <CloseIcon css={{ fontSize: theme.general.iconFontSize - 4 }} />
          </button>
        )}
      </div>
    </div>
  );
}
