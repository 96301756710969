import React from 'react';
import Utils from '../../common/utils/Utils';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { OverflowMenu, PageHeader } from '../../shared/building_blocks/PageHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { concurrentsListPageRoute, getConcurrentPageRoute } from "../routes";
import { getExperiments } from '../../experiment-tracking/reducers/Reducers';
import { ExperimentViewRuns } from '../../experiment-tracking/components/experiment-page/components/runs/ExperimentViewRuns';
import { renderScheduledTime, renderNextRunTime } from '../utils/PeriodicRunUtils';
import { getExperimentApi } from '../../experiment-tracking/actions';
import { Modal } from '@databricks/design-system';
import { Descriptions } from '../../common/components/Descriptions';
export const StageFilters = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
};

type Props = {
  history: any;
  concurrent: any;
  periodicRun: any;
  experiments: any[];
  periodicRunName: string;
  handleDelete: (...args: any[]) => any;
  getExperimentApi: (...args: any[]) => any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
}

type State = any;

export class PeriodicRunViewImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    isDeleteModalVisible: false,
    isDeleteModalConfirmLoading: false,
  };

  componentDidMount() {
    const pageTitle = `${this.props.periodicRunName} - PeriodicRun`;
    Utils.updatePageTitle(pageTitle);
    this.props.getExperimentApi(this.props.periodicRun.experiment_id);
  }

  showDeleteModal = () => {
    this.setState({ isDeleteModalVisible: true });
  };

  hideDeleteModal = () => {
    this.setState({ isDeleteModalVisible: false });
  };

  showConfirmLoading = () => {
    this.setState({ isDeleteModalConfirmLoading: true });
  };

  hideConfirmLoading = () => {
    this.setState({ isDeleteModalConfirmLoading: false });
  };

  handleDeleteConfirm = () => {
    const { history } = this.props;
    this.showConfirmLoading();
    this.props
      .handleDelete()
      .then(() => {
        history.push(concurrentsListPageRoute);
      })
      .catch((e: any) => {
        this.hideConfirmLoading();
        Utils.logErrorAndNotifyUser(e);
      });
  };

  getOverflowMenuItems() {
    const menuItems = [
      {
        id: 'delete',
        itemName: (
          <FormattedMessage
            defaultMessage='Delete'
            // eslint-disable-next-line max-len
            description='Text for disabled delete button due to active versions on model view page header'
          />
        ),
        onClick: this.showDeleteModal,
      },
    ];
    return menuItems;
  }

  renderDetails = () => {
    const { periodicRunName, periodicRun, experiments, concurrent } = this.props;
    return (
      <div css={styles.wrapper}>
        {/* Metadata List */}
        <Descriptions columns={3} data-testid='periodicrun-view-metadata'>
          <Descriptions.Item
            data-testid='periodic-view-metadata-item'
            label={this.props.intl.formatMessage({
              defaultMessage: 'Schedule',
              description:
                'Label name for the schedule time under details tab on the periodic view page',
            })}
          >
            {renderScheduledTime(periodicRun)}
          </Descriptions.Item>
          <Descriptions.Item
            data-testid='periodic-view-metadata-item'
            label={this.props.intl.formatMessage({
              defaultMessage: 'Next Run Time',
              description:
                'Label name for the next run time under details tab on the model view page',
            })}
          >
            {renderNextRunTime(periodicRun)}
          </Descriptions.Item>
          <Descriptions.Item
            data-testid='periodic-view-metadata-item'
            label={concurrent ? 'Concurrent Name' : 'Concurrent ID'}
          >
            <Link to={getConcurrentPageRoute(periodicRun.dagid)} data-test-id='breadcrumbParallel'> { concurrent ? concurrent.dagName : periodicRun.dagid } </Link>
          </Descriptions.Item>
        </Descriptions>
        {/* @ts-expect-error */}
        <div css={styles.experimentViewWrapper}>
          <ExperimentViewRuns experiments={experiments} isLoading={false}/>
        </div>
      </div>
    );
  };

  render() {
    const { periodicRunName, periodicRun } = this.props;
    const {
      isDeleteModalVisible,
      isDeleteModalConfirmLoading,
    } = this.state;

    const breadcrumbs = [
      <Link to={concurrentsListPageRoute}>
        <FormattedMessage
          defaultMessage='Periodic Run'
          description='Text for link back to model page under the header on the model view page'
        />
      </Link>,
      //@ts-expect-error
      <Link data-test-id='breadcrumbParallel'> {periodicRunName} </Link>
    ];
    return (
      <div> 
        <PageHeader title={periodicRunName} breadcrumbs={breadcrumbs}>
          <OverflowMenu menu={this.getOverflowMenuItems()} />
        </PageHeader>
        {this.renderDetails()}
        {/* Delete periodicrun Dialog */}
        <Modal
          data-testid='periodic-delete-modal'
          title={this.props.intl.formatMessage({
            defaultMessage: 'Delete periodicrun',
            description: 'Title text for delete periodicrun modal on periodicrun view page',
          })}
          visible={isDeleteModalVisible}
          confirmLoading={isDeleteModalConfirmLoading}
          onOk={this.handleDeleteConfirm}
          okText={this.props.intl.formatMessage({
            defaultMessage: 'Delete',
            description: 'OK text for delete periodicrun modal on periodicrun view page',
          })}
          cancelText={this.props.intl.formatMessage({
            defaultMessage: 'Cancel',
            description: 'Cancel text for delete periodicrun modal on periodicrun view page',
          })}
          //@ts-expect-error
          okType='danger'
          onCancel={this.hideDeleteModal}
        >
          <span>
            <FormattedMessage
              defaultMessage='Are you sure you want to delete {periodicRunName}? This cannot be undone.'
              description='Confirmation message for delete periodicrun modal on periodicrun view page'
              values={{ periodicRunName: periodicRunName }}
            />
          </span>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { periodicRun  } = ownProps;
  const experiments = getExperiments(state);
  const concurrent = state.entities.concurrentByID[periodicRun.dagid]; 
  return {
    experiments,
    concurrent
  };
};

const mapDispatchToProps = { getExperimentApi };

const styles = {
  experimentViewWrapper: { height: '70vh', display: 'flex', flexDirection: 'column' },
  emailNotificationPreferenceDropdown: (theme: any) => ({ width: 300, marginBottom: theme.spacing.md }),
  emailNotificationPreferenceTip: (theme: any) => ({
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
  }),
  wrapper: (theme: any) => ({
    '.collapsible-panel': {
      marginBottom: theme.spacing.md,
    },
    /**
     * This seems to be a best and most stable method to catch
     * antd's collapsible section buttons without hacks
     * and using class names.
     */
    'div[role="button"][aria-expanded]': {
      height: theme.general.buttonHeight,
    },
  }),
  editButton: (theme: any) => ({
    marginLeft: theme.spacing.md,
  }),
  versionsTabButtons: (theme: any) => ({
    display: 'flex',
    gap: theme.spacing.md,
    alignItems: 'center',
  }),
};

//@ts-expect-error
export const PeriodicRunView = connect(mapStateToProps, mapDispatchToProps)(injectIntl(PeriodicRunViewImpl));
