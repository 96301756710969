/**
 * NOTE: this code file was automatically migrated to TypeScript using ts-migrate and
 * may contain multiple `any` type annotations and `@ts-expect-error` directives.
 * If possible, please improve types while making changes to this file. If the type
 * annotations are already looking good, please remove this comment.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  HashRouterV5,
  Route,
  Routes,
  CompatRouter,
  LinkV5,
  NavLinkV5,
} from '../../common/utils/RoutingUtils';

import AppErrorBoundary from '../../common/components/error-boundaries/AppErrorBoundary';
import { HomePageDocsUrl, Version } from '../../common/constants';
// @ts-expect-error TS(2307): Cannot find module '../../common/static/home-logo.... Remove this comment to see the full error message
import logo from '../../common/static/concurrent-home-logo.png';
import userAvatar from '../../common/static/user.svg';
import ErrorModal from '../../experiment-tracking/components/modals/ErrorModal';
import { CompareModelVersionsPage } from '../../model-registry/components/CompareModelVersionsPage';
import { ModelListPage } from '../../model-registry/components/ModelListPage';
import { ModelPage } from '../../model-registry/components/ModelPage';
import { ModelVersionPage } from '../../model-registry/components/ModelVersionPage';
import {
  compareModelVersionsPageRoute,
  modelListPageRoute,
  modelPageRoute,
  modelSubpageRoute,
  modelSubpageRouteWithName,
  modelVersionPageRoute,
} from '../../model-registry/routes';
import { SettingOutlined, GatewayOutlined, ClusterOutlined, UserOutlined } from '@ant-design/icons';
import { 
  concurrentsListPageRoute, 
  concurrentPageRoute, 
  concurrentTemplatePageRoute,
  periodicRunPageRoute  
} from '../../concurrent/routes';
import { ConcurrentListPage } from '../../concurrent/components/ConcurrentListPage';
import { ConcurrentPage } from '../../concurrent/components/ConcurrentPage'
import { ConcurrentTemplatePage } from '../../concurrent/components/ConcurrentTemplatePage'
import { PeriodicRunPage } from '../../concurrent/components/PeriodicRunPage'
import RequestStateWrapper from '../../common/components/RequestStateWrapper';
import { ConfigPage } from '../../config/component/ConfigPage';
import RoutePaths from '../routes';
import './App.css';
import CompareRunPage from './CompareRunPage';
import { HomePage } from './HomePage';
import { MetricPage } from './MetricPage';
import { PageNotFoundView } from '../../common/components/PageNotFoundView';
import { RunPage } from './RunPage';
import type { MenuProps } from 'antd';
import { Menu, Dropdown, Divider, Button, Avatar } from 'antd';
import LoginPage  from '../../authentication/component/LoginPage';
import { customerInfoApi } from '../../authentication/actions';
import { getUUID } from '../../common/utils/ActionUtils';
import { DirectRunPage } from './DirectRunPage';
import { GatewayRouteListPage } from 'ai-gateway/components/GatewayRouteListPage';
import { GatewayRoutePage } from 'ai-gateway/components/GatewayRoutePage';
import { ChangePasswordPage } from 'config/component/ChangePasswordPage';
import { KubeClusterPage } from 'config/component/KubeClusterPage';
import { UserBucketPage } from 'config/component/UserBucketPage';

const isExperimentsActive = (match: any, location: any) => {
  // eslint-disable-next-line prefer-const
  return match && !(location.pathname.includes('models') 
  || location.pathname.includes('concurrents') || location.pathname.includes('settings')) ;
};

let mlflowHashRouting = false;

export function setMLFlowHashRouting() {
  mlflowHashRouting = true;
}

const classNames = {
  activeNavLink: { borderBottom: '4px solid #43C9ED' },
};

// eslint-disable-next-line no-unused-vars
const InteractionTracker = ({ children }: any) => children;

type AppImplProps = {
  user?: any;
  customerInfoApi: (...args: any[]) => any;
};

export class App extends React.Component<AppImplProps> {


  state = {
    configView: false,
    showConfigEditModel: false,
    showAddCluserModel: false,
    showEditCluserModel: false,
  };

  customerInfoApiId = getUUID();

  handleLogout = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    localStorage.removeItem("tokenTime");
    document.cookie = "mlflow-request-header-Authorization=; expires=Thu, 01 Jan 2000 00:00:00 UTC; path=/;";
    window.location.replace("/#"+RoutePaths.loginPageRoute);
  }

  componentDidMount() {
    this.props.customerInfoApi(this.customerInfoApiId);
  }

  render() {
    const { user } = this.props;
    let userName = this.props.user.userName;
    if(userName === null || userName === undefined) {
      userName = ''
    }

    const menu = (
      <Menu className='user-info-menu'>
        <Menu.Item key='user-name' className='username-menu' >
          <div className="user-type">
            <Divider style={{ margin: 0 }} />
              <span style={{color: '#e7f1fb', fontSize: '20px' }}> {userName} </span>
              <span style={{color: '#e7f1fb', paddingTop: '10px', fontSize: '14px'}}> {user.isSecondaryUser === 'true' ? "Data Scientist": "Administrator" } </span>
            <Divider style={{ margin: 0 }} />
          </div>
        </Menu.Item>
        <Menu.Item key='logout' className='logout-menu'>
          <div className="dropdown-content">
            <Button style={{color: 'red'}} type="link" onClick={this.handleLogout}>Logout</Button>
          </div>
        </Menu.Item>
      </Menu>
    );

    const handleMenuClick: MenuProps['onClick'] = (e) => {
      console.log('click', e);
    };
        
    const settingsMenu= (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="gateway">
          <NavLinkV5 to={RoutePaths.gatewayRouteListPageRoute}>
            <GatewayOutlined style={{ paddingRight:'5px'}} />
            Gateway configuration
          </NavLinkV5>
        </Menu.Item>
        <Menu.Item key="bucket">
          <NavLinkV5 to={RoutePaths.bucketSettingsPageRoute}>
            <SettingOutlined style={{ paddingRight:'5px'}} />
            Manage Bucket
          </NavLinkV5>
        </Menu.Item>
        <Menu.Item key="cluster">
          <NavLinkV5 to={RoutePaths.cluseterSettingsPageRoute}>
            <ClusterOutlined style={{ paddingRight:'5px'}} />
            Cluster configuration
          </NavLinkV5>
        </Menu.Item>
        <Menu.Item key="change_password">
          <NavLinkV5 to={RoutePaths.changePasswordRoute}>
            <UserOutlined style={{ paddingRight:'5px'}} />
            Change password
          </NavLinkV5>
        </Menu.Item>
      </Menu>
    );

    const marginRight = 24;
    return (
      <HashRouterV5
        basename={mlflowHashRouting ? '/mlflow' : undefined}
        hashType={mlflowHashRouting ? 'noslash' : undefined}
      >
        {/* This layer enables intercompatibility between react-router APIs v5 and v6 */}
        {/* TODO: Remove after migrating to react-router v6 */}
        <CompatRouter>
          <div style={{ height: '100%' }}>
            <ErrorModal />
            {/* @ts-expect-error TS(4111): Property 'HIDE_HEADER' comes from an index signatu... Remove this comment to see the full error message */}
            {process.env.HIDE_HEADER === 'true' ? null : (
              <header className='App-header' id='mlflow-header'>
                <div className='mlflow-logo'>
                  <LinkV5 to={RoutePaths.rootRoute} className='App-mlflow'>
                    <img className='mlflow-logo' alt='MLflow' src={logo} />
                  </LinkV5>
                </div>
                <div className='header-route-links'>
                  <NavLinkV5
                    strict
                    to={RoutePaths.rootRoute}
                    css={{ marginRight }}
                    activeStyle={classNames.activeNavLink}
                    isActive={isExperimentsActive}
                    className='header-nav-link'
                  >
                    <div className='experiments'>
                      <span>Experiments</span>
                    </div>
                  </NavLinkV5>
                  <NavLinkV5
                    strict
                    to={modelListPageRoute}
                    css={{ marginRight }}
                    activeStyle={classNames.activeNavLink}
                    className='header-nav-link header-nav-link-models'
                  >
                    <div className='models'>
                      <span>Models</span>
                    </div>
                  </NavLinkV5>
                  <NavLinkV5
                    strict
                    to={concurrentsListPageRoute}
                    css={{ marginRight }}
                    activeStyle={classNames.activeNavLink}
                    className='header-nav-link header-nav-link-models'
                  >
                    <div className='concurrent'>
                      <span>Concurrents</span>
                    </div>
                  </NavLinkV5>
                </div>
                <div className='header-links'>
                  <a href={'https://github.com/concurrent-ai/concurrent'} css={{ marginRight }}>
                    <div className='github'>
                      <span>GitHub</span>
                    </div>
                  </a>
                  <a href={'https://docs.concurrent-ai.org'} css={{ marginRight }}>
                    <div className='docs'>
                      <span>Docs</span>
                    </div>
                  </a>
                  <Dropdown overlay={settingsMenu} trigger={['click']} css={{ marginRight }}  placement="bottomRight">
                    <div className='gateway'>
                      <SettingOutlined style={{ color: '#e7f1fb' ,paddingTop:'2px', fontSize:'22px'}} />
                    </div>
                  </Dropdown>
                  <Dropdown  className='user-icon' overlay={menu} trigger={['click']} css={{ marginRight }}  placement="bottomRight">
                    <Avatar src={userAvatar}/>
                  </Dropdown>
                </div>
              </header>
            )}
            <AppErrorBoundary service='mlflow'>
              <InteractionTracker>
                <Routes>
                  <Route
                    path={RoutePaths.compareExperimentsSearchPageRoute}
                    element={<HomePage />}
                  />
                  <Route path={RoutePaths.experimentPageSearchRoute} element={<HomePage />} />
                  <Route path={RoutePaths.experimentPageRoute} element={<HomePage />} />
                  <Route path={RoutePaths.rootRoute} element={<HomePage />} />
                  <Route path={RoutePaths.runPageWithArtifactSelectedRoute} element={<RunPage />} />
                  <Route path={RoutePaths.runPageRoute} element={<RunPage />} />
                  <Route path={RoutePaths.directRunPageRoute} element={<DirectRunPage />} />

                  <Route path={RoutePaths.metricPageRoute} element={<MetricPage />} />
                  <Route path={RoutePaths.compareRunPageRoute} element={<CompareRunPage />} />

                  <Route path={modelListPageRoute} element={<ModelListPage />} />
                  <Route path={modelVersionPageRoute} element={<ModelVersionPage />} />
                  <Route path={modelPageRoute} element={<ModelPage />} />
                  <Route path={modelSubpageRoute} element={<ModelPage />} />
                  <Route path={modelSubpageRouteWithName} element={<ModelPage />} />
                  <Route
                    path={compareModelVersionsPageRoute}
                    element={<CompareModelVersionsPage />}
                  />

                  {/* Parallels routes  */}
                  <Route path={concurrentsListPageRoute} element={<ConcurrentListPage />} />
                  <Route path={concurrentPageRoute} element={<ConcurrentPage/>} />
                  <Route path={concurrentTemplatePageRoute} element={<ConcurrentTemplatePage/>} />
                  <Route path={periodicRunPageRoute} element={<PeriodicRunPage/>} />
                  
                  {/* login routes  */}
                  <Route path={RoutePaths.loginPageRoute} element={<LoginPage />} />

                  {/* settings routes  */}
                  <Route path={RoutePaths.changePasswordRoute} element={<ChangePasswordPage/>} />
                  <Route path={RoutePaths.bucketSettingsPageRoute} element={<UserBucketPage/>} />
                  <Route path={RoutePaths.cluseterSettingsPageRoute} element={<KubeClusterPage/>} />

                  <Route path={RoutePaths.gatewayRouteListPageRoute} element={<GatewayRouteListPage />} />
                  <Route path={RoutePaths.gatewayRoutePageRoute} element={<GatewayRoutePage />} />

                  {/* <Route element={PageNotFoundView} /> */}
                  <Route path='/*' element={<PageNotFoundView />} />
                </Routes>
                {/* End of React Router v6 routes */}
              </InteractionTracker>
            </AppErrorBoundary>
          </div>
        </CompatRouter>
      </HashRouterV5>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    experiments: Object.values(state.entities.experimentsById),
    user: state.user.userModal,
  };
};

const mapDispatchToProps = {
  customerInfoApi
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
