import { safex } from '../design-system/utils/safex';
import {
  colorPalettePrimary,
  colorPaletteSecondary,
  darkColorList,
  lightColorList,
  newGreys,
  lightColorsUsingNewGreys,
  darkColorsUsingNewGreys,
  secondaryColorsUsingNewGreys,
} from './colorList';

const darkColors = { ...darkColorList, ...colorPalettePrimary, ...colorPaletteSecondary };
const lightColors = { ...lightColorList, ...colorPalettePrimary, ...colorPaletteSecondary };
type DesignSystemColors = typeof lightColors;

function getSemanticColors(isDarkMode: boolean): DesignSystemColors {
  const enableNewGreys = safex('databricks.fe.designsystem.enableNewGreys', false);
  const colorList = isDarkMode ? darkColors : lightColors;
  if (enableNewGreys) {
    return {
      ...colorList,
      ...newGreys,
      ...secondaryColorsUsingNewGreys,
      ...(isDarkMode ? darkColorsUsingNewGreys : lightColorsUsingNewGreys),
    };
  } else {
    return colorList;
  }
}

export interface AntdColors {
  bodyBackground: string;
  textColor: string;
  textColorSecondary: string;
  primaryColor: string;
  infoColor: string;
  errorColor: string;
  successColor: string;
  warningColor: string;
  borderColorBase: string;

  alertErrorTextColor: string;
  alertErrorBgColor: string;
  alertErrorIconColor: string;
  alertErrorBorderColor: string;

  alertInfoBgColor: string;
  alertInfoIconColor: string;
  alertInfoTextColor: string;
  alertInfoBorderColor: string;

  alertWarningTextColor: string;
  alertWarningBorderColor: string;
  alertWarningBgColor: string;
  alertWarningIconColor: string;

  alertTextColor: string;
  alertMessageColor: string;

  spinDotDefault: string;
}

// Maps Dubois colors to AntD names
export function getAntdColors(isDarkMode: boolean): AntdColors {
  const semanticColors = getSemanticColors(isDarkMode);
  return {
    bodyBackground: semanticColors.backgroundPrimary,
    textColor: semanticColors.textPrimary,
    textColorSecondary: semanticColors.textSecondary,
    primaryColor: semanticColors.primary,
    infoColor: '#64727D',
    errorColor: semanticColors.actionDangerPrimaryBackgroundDefault,
    successColor: '#34824F',
    warningColor: '#AF5B23',
    borderColorBase: semanticColors.border,

    // Alert colors (AntD variables)
    alertErrorTextColor: semanticColors.textValidationDanger,
    alertErrorBorderColor: semanticColors.red300,
    alertErrorBgColor: semanticColors.red100,
    alertErrorIconColor: semanticColors.textValidationDanger,

    alertInfoTextColor: semanticColors.textPrimary,
    alertInfoBorderColor: semanticColors.borderDecorative,
    alertInfoBgColor: semanticColors.backgroundSecondary,
    alertInfoIconColor: semanticColors.textSecondary,

    alertWarningTextColor: semanticColors.textValidationWarning,
    alertWarningBorderColor: semanticColors.yellow300,
    alertWarningBgColor: semanticColors.yellow100,
    alertWarningIconColor: semanticColors.textValidationWarning,

    ...(isDarkMode && {
      alertErrorBgColor: 'transparent',
      alertInfoBgColor: 'transparent',
      alertWarningBgColor: 'transparent',
    }),

    alertTextColor: 'inherit',
    alertMessageColor: 'inherit',

    spinDotDefault: semanticColors.textSecondary,
  };
}

// TODO (FEINF-1674): Remove when removing USE_NEW_RADIO_STYLES
/*
Colors that need to be replaced by semantic colors.
 */
const deprecatedColors = {
  // Radio Colors
  radioInteractiveAvailable: colorPalettePrimary.primary,
  radioInteractiveHover: '#186099',
  radioInteractivePress: '#0D4F85',
  radioDisabled: '#A2AEB8',
  radioDefaultBorder: '#64727D',
  radioDefaultBackground: '#FFFFFF',
  radioInteractiveHoverSecondary: 'rgba(34, 115, 181, 0.08)', // Fade of Interactive Available
  radioInteractivePressSecondary: 'rgba(34, 115, 181, 0.16)', // Fade of Interactive Available
};

export function getColors(isDarkMode: boolean): typeof deprecatedColors & DesignSystemColors {
  return {
    ...deprecatedColors,
    ...getSemanticColors(isDarkMode),
  };
}
