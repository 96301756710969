import antdVars from '../antd-vars';
import spacing from './spacing';

const heightBase = 40;
const borderWidth = 1;

const antdGeneralVariables = {
  classnamePrefix: antdVars['ant-prefix'],
  iconfontCssPrefix: 'anticon',
  borderRadiusBase: 4,
  borderWidth: borderWidth,
  heightSm: 32,
  heightBase: heightBase,
  iconSize: 24,
  iconFontSize: 16,
  buttonHeight: heightBase,
  // Height available within button (for label and icon). Same for middle and small buttons.
  buttonInnerHeight: heightBase - spacing.sm * 2 - borderWidth * 2,
};

export const getShadowVariables = (isDarkMode: boolean) => {
  if (isDarkMode) {
    return {
      shadowLow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
      shadowHigh: '0px 8px 24px rgba(0, 0, 0, 0.2);',
    };
  } else {
    return {
      shadowLow: '0px 4px 16px rgba(31, 39, 45, 0.12)',
      shadowHigh: '0px 8px 24px rgba(31, 39, 45, 0.2)',
    };
  }
};

export default antdGeneralVariables;
