import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import * as Popover from '@radix-ui/react-popover';
import React, { useEffect, forwardRef } from 'react';

import type { Theme } from '../../theme';
import { Button } from '../Button';
import { useDesignSystemTheme } from '../Hooks';
import { ChevronDownIcon, CloseIcon } from '../Icon';
import { useSelectContext } from '../SelectV2/hooks/useSelectContext';
import { Tooltip } from '../Tooltip';
import { ClearSelectionButton } from '../_shared_/Combobox/ClearSelectionButton';
import type { FormElementValidationState, HTMLDataAttributes, ValidationState } from '../types';
import { getValidationStateColor, importantify } from '../utils/css-utils';
import { DialogComboboxCountBadge } from './DialogComboboxCountBadge';
import { useDialogComboboxContext } from './hooks/useDialogComboboxContext';

export interface DialogComboboxTriggerProps
  extends Popover.PopoverTriggerProps,
    FormElementValidationState,
    HTMLDataAttributes {
  minWidth?: number | string;
  maxWidth?: number | string;
  width?: number | string;
  removable?: boolean;
  onRemove?: () => void;
  allowClear?: boolean;
  onClear?: () => void;
  showTagAfterValueCount?: number;
  controlled?: boolean;
  wrapperProps?: { css?: SerializedStyles } & React.HTMLAttributes<HTMLDivElement>;
  withChevronIcon?: boolean;
  withInlineLabel?: boolean;
  isBare?: boolean;
}

const getTriggerWrapperStyles = (removable: boolean, width?: number | string): SerializedStyles =>
  css(
    importantify({
      display: 'inline-flex',
      alignItems: 'center',
      ...(width && {
        width: width,
      }),

      ...(removable && {
        '& > button:last-of-type': importantify({
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          marginLeft: -1,
        }),
      }),
    }),
  );

const getTriggerStyles = (
  theme: Theme,
  maxWidth: number | string,
  minWidth: number | string,
  removable: boolean,
  width?: number | string,
  validationState?: ValidationState,
  isBare?: boolean,
  isSelect?: boolean,
): SerializedStyles => {
  const removeButtonInteractionStyles = {
    ...(removable && {
      zIndex: theme.options.zIndexBase + 2,
      '&& + button': {
        marginLeft: -1,
        zIndex: theme.options.zIndexBase + 1,
      },
    }),
  };

  const validationColor = getValidationStateColor(theme, validationState);

  return css(
    importantify({
      position: 'relative',
      display: 'inline-flex',
      alignItems: 'center',
      maxWidth,
      minWidth,
      justifyContent: 'flex-start',
      background: 'transparent',
      padding: isBare ? 0 : '6px 8px 6px 12px',
      boxSizing: 'border-box',
      height: isBare ? theme.typography.lineHeightBase : theme.general.heightSm,
      border: isBare ? 'none' : `1px solid ${theme.colors.actionDefaultBorderDefault}`,
      borderRadius: 4,
      color: theme.colors.textPrimary,
      lineHeight: theme.typography.lineHeightBase,
      cursor: 'pointer',
      ...(width && {
        width: width,
        // Only set flex: 1 to items with width, otherwise in flex containers the trigger will take up all the space and break current usages that depend on content for width
        flex: 1,
      }),

      ...(removable && {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        borderRightColor: 'transparent',
      }),

      '&:hover': {
        background: isBare ? 'transparent' : theme.colors.actionDefaultBackgroundHover,
        borderColor: theme.colors.actionDefaultBorderHover,
        ...removeButtonInteractionStyles,
      },
      '&:focus': {
        borderColor: theme.colors.actionDefaultBorderFocus,
        ...removeButtonInteractionStyles,
      },

      ...(validationState && {
        borderColor: validationColor,

        '&:hover': {
          borderColor: validationColor,
        },

        '&:focus': {
          outlineColor: validationColor,
          outlineOffset: -2,
        },
      }),

      [`&[disabled]`]: {
        background: theme.colors.actionDisabledBackground,
        color: theme.colors.actionDisabledText,
        pointerEvents: 'none',
        userSelect: 'none',
      },

      ...(isSelect && {
        '&&, &&:hover, &&:focus': {
          background: 'transparent',
        },

        '&&:hover': {
          borderColor: theme.colors.actionDefaultBorderHover,
        },

        '&&:focus, &[data-state="open"]': {
          outlineColor: theme.colors.actionPrimaryBackgroundDefault,
          outlineWidth: 2,
          outlineOffset: -2,
          outlineStyle: 'solid',
          borderColor: 'transparent',
          boxShadow: 'none',
        },
      }),
    }),
  );
};

export const DialogComboboxTrigger = forwardRef<HTMLButtonElement, DialogComboboxTriggerProps>(
  (
    {
      removable = false,
      onRemove,
      children,
      minWidth = 0,
      maxWidth = 9999,
      showTagAfterValueCount = 3,
      allowClear = true,
      controlled,
      onClear,
      wrapperProps,
      width,
      withChevronIcon = true,
      validationState,
      withInlineLabel = true,
      placeholder,
      isBare = false,
      ...restProps
    },
    forwardedRef,
  ) => {
    const { theme, classNamePrefix } = useDesignSystemTheme();
    const { label, value, isInsideDialogCombobox, multiSelect, setValue } = useDialogComboboxContext();
    const { isSelect, placeholder: selectPlaceholder } = useSelectContext();

    if (!isInsideDialogCombobox) {
      throw new Error('`DialogComboboxTrigger` must be used within `DialogCombobox`');
    }

    const handleRemove = () => {
      if (!onRemove) {
        console.warn('DialogCombobox.Trigger: Attempted remove without providing onRemove handler');
      } else {
        onRemove();
      }
    };

    const handleClear = (e: any) => {
      e.stopPropagation();

      if (controlled) {
        setValue([]);
        onClear?.();
      } else if (!onClear) {
        console.warn('DialogCombobox.Trigger: Attempted clear without providing onClear handler');
      } else {
        onClear();
      }
    };

    const [showTooltip, setShowTooltip] = React.useState<boolean>();

    const triggerContentRef = React.useRef<HTMLSpanElement>(null);

    useEffect(() => {
      if (value?.length > showTagAfterValueCount) {
        setShowTooltip(true);
      } else if (triggerContentRef.current) {
        const { clientWidth, scrollWidth } = triggerContentRef.current;
        setShowTooltip(clientWidth < scrollWidth);
      }
    }, [showTagAfterValueCount, value]);

    const numValues = value.length;
    const concatenatedValues = Array.isArray(value)
      ? numValues > 10
        ? `${value.slice(0, 10).join(', ')} + ${numValues - 10}`
        : value.join(', ')
      : value;
    const displayedValues = <span>{concatenatedValues}</span>;
    const valuesBeforeBadge = Array.isArray(value) ? value.slice(0, showTagAfterValueCount).join(', ') : value;

    let ariaLabel = React.isValidElement(label) ? 'Dialog Combobox' : `${label}`;

    if (value?.length) {
      ariaLabel += multiSelect
        ? `, multiselectable, ${value.length} options selected: ${concatenatedValues}`
        : `, selected option: ${concatenatedValues}`;
    } else {
      ariaLabel += multiSelect ? ', multiselectable, 0 options selected' : ', no option selected';
    }

    const customSelectContent = isSelect && children ? children : null;
    const dialogComboboxClassname = !isSelect ? `${classNamePrefix}-dialogcombobox` : '';
    const selectV2Classname = isSelect ? `${classNamePrefix}-selectv2` : '';

    const triggerContent = isSelect ? (
      <Popover.Trigger
        aria-label={ariaLabel}
        ref={forwardedRef}
        role="combobox"
        aria-haspopup="listbox"
        {...restProps}
        css={getTriggerStyles(theme, maxWidth, minWidth, removable, width, validationState, isBare, isSelect)}
      >
        {/* Using inline styles to not override styles used with custom content */}
        <span
          css={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: theme.typography.lineHeightBase,
            marginRight: 'auto',
          }}
          ref={triggerContentRef}
        >
          {value?.length ? (
            customSelectContent ?? displayedValues
          ) : (
            <span css={{ color: theme.colors.textPlaceholder }}>{selectPlaceholder}</span>
          )}
        </span>
        {allowClear && value?.length ? <ClearSelectionButton onClick={handleClear} /> : null}
        <ChevronDownIcon css={{ color: theme.colors.textSecondary, marginLeft: theme.spacing.xs }} />
      </Popover.Trigger>
    ) : (
      <Popover.Trigger
        aria-label={ariaLabel}
        ref={forwardedRef}
        role="combobox"
        aria-haspopup="listbox"
        {...restProps}
        css={getTriggerStyles(theme, maxWidth, minWidth, removable, width, validationState, isBare, isSelect)}
      >
        {/* Using inline styles to not override styles used with custom content */}
        <span
          css={{
            display: 'flex',
            alignItems: 'center',
            height: theme.typography.lineHeightBase,
            marginRight: 'auto',

            '&, & > *': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          ref={triggerContentRef}
        >
          {withInlineLabel ? (
            <span
              css={{
                height: theme.typography.lineHeightBase,
                marginRight: theme.spacing.xs,
                whiteSpace: 'unset',
                overflow: 'unset',
                textOverflow: 'unset',
              }}
            >
              {label}
              {value?.length ? ':' : null}
            </span>
          ) : (
            !value?.length && <span css={{ color: theme.colors.textPlaceholder }}>{placeholder}</span>
          )}
          {value?.length > showTagAfterValueCount ? (
            <>
              <span style={{ marginRight: theme.spacing.xs }}>{valuesBeforeBadge}</span>
              <DialogComboboxCountBadge
                countStartAt={showTagAfterValueCount}
                role="status"
                aria-label="Selected options count"
              />
            </>
          ) : (
            displayedValues
          )}
        </span>
        {allowClear && value?.length ? <ClearSelectionButton onClick={handleClear} /> : null}
        {withChevronIcon ? (
          <ChevronDownIcon
            css={{
              color: theme.colors.textSecondary,
              justifySelf: 'flex-end',
              marginLeft: theme.spacing.xs,
            }}
          />
        ) : null}
      </Popover.Trigger>
    );

    return (
      <div
        {...wrapperProps}
        className={`${restProps?.className ?? ''} ${dialogComboboxClassname} ${selectV2Classname}`.trim()}
        css={[getTriggerWrapperStyles(removable, width), wrapperProps?.css]}
      >
        {showTooltip && value?.length ? (
          <Tooltip title={customSelectContent ?? displayedValues}>{triggerContent}</Tooltip>
        ) : (
          triggerContent
        )}
        {removable && (
          <Button aria-label={`Remove ${label}`} onClick={handleRemove} dangerouslySetForceIconStyles>
            <CloseIcon aria-hidden="false" />
          </Button>
        )}
      </div>
    );
  },
);
