import type { CSSObject, SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { InputProps as AntDInputProps } from 'antd';
import { Input as AntDInput } from 'antd';
import type {
  GroupProps as AntDGroupProps,
  PasswordProps as AntDPasswordProps,
  TextAreaProps as AntDTextAreaProps,
} from 'antd/lib/input';
import type { TextAreaRef } from 'antd/lib/input/TextArea';
import React, { forwardRef } from 'react';

import type { Theme } from '../../theme';
import { DesignSystemAntDConfigProvider, getAnimationCss } from '../DesignSystemProvider';
import { useDesignSystemFlags } from '../Hooks';
import { useDesignSystemTheme } from '../Hooks/useDesignSystemTheme';
import type {
  DangerousGeneralProps,
  DangerouslySetAntdProps,
  FormElementValidationState,
  HTMLDataAttributes,
  ValidationState,
} from '../types';
import { getValidationStateColor, importantify } from '../utils/css-utils';

// We pass through the `ref` to Ant's input, so we need to expose that type in case a downstream
// consumer wants to use it.
export type InputRef = AntDInput;
export type { TextAreaRef };

export interface InputProps
  extends Omit<AntDInputProps, 'prefixCls' | 'size' | 'addonAfter' | 'bordered'>,
    FormElementValidationState,
    HTMLDataAttributes,
    DangerouslySetAntdProps<AntDInputProps>,
    DangerousGeneralProps {
  /* Called when the input is cleared by clicking the (X) button. */
  onClear?: () => void;
}

export interface TextAreaProps
  extends Omit<AntDTextAreaProps, 'bordered' | 'showCount' | 'size'>,
    FormElementValidationState,
    HTMLDataAttributes,
    DangerouslySetAntdProps<AntDTextAreaProps>,
    DangerousGeneralProps {}

export interface PasswordProps
  extends Omit<AntDPasswordProps, 'inputPrefixCls' | 'action' | 'visibilityToggle' | 'iconRender'>,
    FormElementValidationState,
    HTMLDataAttributes,
    DangerouslySetAntdProps<AntDPasswordProps>,
    DangerousGeneralProps {}

export interface InputGroupProps
  extends Omit<AntDGroupProps, 'size'>,
    HTMLDataAttributes,
    DangerouslySetAntdProps<AntDGroupProps>,
    DangerousGeneralProps {}

const getInputEmotionStyles = (
  clsPrefix: string,
  theme: Theme,
  { validationState }: { validationState?: ValidationState },
  useTransparent?: boolean,
): SerializedStyles => {
  const inputClass = `.${clsPrefix}-input`;
  const affixClass = `.${clsPrefix}-input-affix-wrapper`;
  const affixClassFocused = `.${clsPrefix}-input-affix-wrapper-focused`;
  const clearIcon = `.${clsPrefix}-input-clear-icon`;
  const prefixIcon = `.${clsPrefix}-input-prefix`;
  const suffixIcon = `.${clsPrefix}-input-suffix`;

  const validationColor = getValidationStateColor(theme, validationState);

  const styles: CSSObject = {
    '&&': {
      lineHeight: theme.typography.lineHeightBase,
      minHeight: theme.general.heightSm,
      ...(validationState && { borderColor: validationColor }),

      '&:hover': {
        borderColor: validationState ? validationColor : theme.colors.actionPrimaryBackgroundHover,
      },

      '&:focus': {
        outlineColor: validationState ? validationColor : theme.colors.actionPrimaryBackgroundDefault,
        outlineWidth: 2,
        outlineOffset: -2,
        outlineStyle: 'solid',
        boxShadow: 'none',
        borderColor: 'transparent',
      },

      '&:disabled': {
        backgroundColor: theme.colors.actionDisabledBackground,
        color: theme.colors.actionDisabledText,
      },

      '&::placeholder': {
        color: theme.colors.textPlaceholder,
      },
    },

    [`&${inputClass}, ${inputClass}`]: {
      ...(useTransparent && { backgroundColor: 'transparent' }),
    },

    [`&${affixClass}`]: {
      ...(useTransparent && { backgroundColor: 'transparent' }),
      lineHeight: theme.typography.lineHeightBase,
      paddingTop: 5,
      paddingBottom: 5,
      minHeight: theme.general.heightSm,

      '::before': {
        lineHeight: theme.typography.lineHeightBase,
      },

      '&:hover': {
        borderColor: theme.colors.actionPrimaryBackgroundHover,
      },

      [`input.${clsPrefix}-input`]: {
        borderRadius: 0,
      },
    },

    [`&${affixClassFocused}`]: {
      boxShadow: 'none',
      '&&, &:focus': {
        outlineColor: theme.colors.actionPrimaryBackgroundDefault,
        outlineWidth: 2,
        outlineOffset: -2,
        outlineStyle: 'solid',
        boxShadow: 'none',
        borderColor: 'transparent',
      },
    },

    [clearIcon]: {
      fontSize: theme.general.iconFontSize,
    },

    [prefixIcon]: {
      marginRight: theme.spacing.sm,
      color: theme.colors.textSecondary,
    },

    [suffixIcon]: {
      marginLeft: theme.spacing.sm,
      color: theme.colors.textSecondary,
    },

    ...getAnimationCss(theme.options.enableAnimation),
  };

  return css(importantify(styles));
};

const getInputGroupStyling = (clsPrefix: string, theme: Theme) => {
  const inputClass = `.${clsPrefix}-input`;
  const buttonClass = `.${clsPrefix}-btn`;

  return css({
    display: 'inline-flex !important',
    width: 'auto',

    [`& > ${inputClass}`]: {
      flexGrow: 1,
      '&:disabled': {
        border: 'none',

        '&:hover': {
          borderRight: `1px solid ${theme.colors.actionDisabledText} !important`,
        },
      },
    },

    [`& > ${buttonClass} > span`]: {
      verticalAlign: 'middle',
    },

    [`& > ${buttonClass}:disabled, & > ${buttonClass}:disabled:hover`]: {
      borderLeft: `1px solid ${theme.colors.actionDisabledText} !important`,
    },
  });
};

const TextArea = forwardRef<TextAreaRef, TextAreaProps>(function TextArea(
  { validationState, autoComplete = 'off', dangerouslySetAntdProps, dangerouslyAppendEmotionCSS, ...props },
  ref,
) {
  const { classNamePrefix, theme } = useDesignSystemTheme();
  const { USE_TRANSPARENT_INPUT: useTransparent } = useDesignSystemFlags();

  return (
    <DesignSystemAntDConfigProvider>
      <AntDInput.TextArea
        ref={ref}
        autoComplete={autoComplete}
        css={[
          getInputEmotionStyles(classNamePrefix, theme, { validationState }, useTransparent),
          dangerouslyAppendEmotionCSS,
        ]}
        {...props}
        {...dangerouslySetAntdProps}
      />
    </DesignSystemAntDConfigProvider>
  );
});

const Password: React.FC<PasswordProps> = forwardRef<AntDInput, PasswordProps>(function Password(
  { validationState, autoComplete = 'off', dangerouslySetAntdProps, dangerouslyAppendEmotionCSS, ...props },
  ref,
) {
  const { classNamePrefix, theme } = useDesignSystemTheme();
  const { USE_TRANSPARENT_INPUT: useTransparent } = useDesignSystemFlags();

  return (
    <DesignSystemAntDConfigProvider>
      <AntDInput.Password
        visibilityToggle={false}
        ref={ref}
        autoComplete={autoComplete}
        css={[
          getInputEmotionStyles(classNamePrefix, theme, { validationState }, useTransparent),
          dangerouslyAppendEmotionCSS,
        ]}
        {...props}
        {...dangerouslySetAntdProps}
      />
    </DesignSystemAntDConfigProvider>
  );
});

const DuboisInput = forwardRef<AntDInput, InputProps>(function Input(
  {
    validationState,
    autoComplete = 'off',
    dangerouslySetAntdProps,
    dangerouslyAppendEmotionCSS,
    onChange,
    onClear,
    ...props
  },
  ref,
) {
  const { classNamePrefix, theme } = useDesignSystemTheme();
  const { USE_TRANSPARENT_INPUT: useTransparent } = useDesignSystemFlags();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // If the input is cleared, call the onClear handler, but only
    // if the event is not an input event -- which is the case when you click the
    // ant-provided (X) button.
    if (!e.target.value && e.nativeEvent instanceof InputEvent === false && onClear) {
      onClear?.();
    } else {
      onChange?.(e);
    }
  };

  return (
    <DesignSystemAntDConfigProvider>
      <AntDInput
        autoComplete={autoComplete}
        ref={ref}
        css={[
          getInputEmotionStyles(classNamePrefix, theme, { validationState }, useTransparent),
          dangerouslyAppendEmotionCSS,
        ]}
        onChange={handleChange}
        {...props}
        {...dangerouslySetAntdProps}
      />
    </DesignSystemAntDConfigProvider>
  );
});

const Group = ({ dangerouslySetAntdProps, dangerouslyAppendEmotionCSS, compact = true, ...props }: InputGroupProps) => {
  const { classNamePrefix, theme } = useDesignSystemTheme();

  return (
    <DesignSystemAntDConfigProvider>
      <AntDInput.Group
        css={[getInputGroupStyling(classNamePrefix, theme), dangerouslyAppendEmotionCSS]}
        compact={compact}
        {...props}
        {...dangerouslySetAntdProps}
      />
    </DesignSystemAntDConfigProvider>
  );
};

// Properly creates the namespace and dot-notation components with correct types.
const InputNamespace = /* #__PURE__ */ Object.assign(DuboisInput, { TextArea, Password, Group });

export const Input = InputNamespace;

// TODO: I'm doing this to support storybook's docgen;
// We should remove this once we have a better storybook integration,
// since these will be exposed in the library's exports.
export const __INTERNAL_DO_NOT_USE__TextArea = TextArea;
export const __INTERNAL_DO_NOT_USE__Password = Password;
export const __INTERNAL_DO_NOT_USE_DEDUPE__Group = Group;
