import { getBigIntJson, postJson } from '../common/utils/FetchUtils';

export class Services {
  static addKubeCluster = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/concurrent/addkubecluster', data });

  static getKubeCluster = (data: any) =>
    getBigIntJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/concurrent/getkubeclusters', data});

  static removekubeCluster = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/concurrent/removekubecluster', data });
    
  static addUserBuckets = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/concurrent/adduserbucket', data });

  static getUserBuckets = (data: any) =>
    getBigIntJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/concurrent/getuserbuckets', data});

  static removeUserBuckets = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/concurrent/removeuserbucket', data });

  static addClusterAccess = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/concurrent/addclusteraccess', data});

  static removeClusterAccess = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/concurrent/removeclusteraccess', data });
}