import { Component } from 'react';
import { Form, Input, Button, message } from 'antd';
import Utils from '../../common/utils/Utils';
import { PageContainer } from '../../common/components/PageContainer';
import { PageHeader } from '../../shared/building_blocks/PageHeader';
export class ChangePasswordPage extends Component {

  state = {
    loading : false,
  }
    
  render() {
    const onFinish = (values: any) => {
      this.setState({ loading: true });
      var postData = "{\n"
          postData += "    \"AccessToken\" : \""+ Utils.accessCookie('aws-accessToken') +"\",\n"
          postData += "    \"PreviousPassword\" : \""+ values.old_password + "\",\n"
          postData += "    \"ProposedPassword\" : \"" + values.confirm_password + "\"\n"
          postData += "}\n"
      var url = "https://cognito-idp.us-east-1.amazonaws.com/";
      var request = new XMLHttpRequest();

      request.open("POST", url, false /* async */);
      request.setRequestHeader("Content-Type", "application/x-amz-json-1.1");
      request.setRequestHeader("x-amz-target", "AWSCognitoIdentityProviderService.ChangePassword");
      try {
        request.send(postData);
      } catch (error) {
        this.setState({ loading: false });
      }

      if (request.status == 200) {
        message.success('password changed !');
        this.setState({ loading: false });
      } else {
        var resp = JSON.parse(request.responseText);
        var err_msg = resp.message;
        if (request.responseText.includes("Incorrect username or password")) {
          err_msg = "Incorrect old password";
        }
        message.error('Failed to change password. Error: ' + err_msg);
        this.setState({ loading: false });
      }
    };

    const onFinishFailed = (errorInfo: any) => {
      console.log('Failed:', errorInfo);
    };

    return (
      <PageContainer data-test-id='user-password-change-container' usesFullHeight>
        <PageHeader title='User Setting'> <> </> </PageHeader>
        <div>
          <b>Change your password</b>
          <div style={{width: 500, padding:10}}> 
            <Form name="basic" requiredMark={false}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Old Password"
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your current password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="New password"
                name="new_password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm password"
                name="confirm_password"
                dependencies={['new_password']}
                hasFeedback
                rules={[
                  {
                      required: true,
                      message: 'Please input your confirm password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
                <Button loading={this.state.loading} type="primary" htmlType="submit">
                  Change password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </PageContainer>
    );
  }
}