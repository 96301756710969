import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgPlayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M12.125 8.864a.75.75 0 0 0 0-1.3l-6-3.464A.75.75 0 0 0 5 4.75v6.928a.75.75 0 0 0 1.125.65l6-3.464Z"
      />
    </svg>
  );
}
function PlayIcon(props: IconProps) {
  return <Icon {...props} component={SvgPlayIcon} />;
}
export default PlayIcon;
