import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { RadioGroupProps as AntDRadioGroupProps, RadioProps as AntDRadioProps } from 'antd';
import { Radio as AntDRadio } from 'antd';
import { forwardRef } from 'react';

import type { ComponentTheme, Theme } from '../../theme';
import { DesignSystemAntDConfigProvider, getAnimationCss, RestoreAntDDefaultClsPrefix } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks';
import type { DangerouslySetAntdProps, HTMLDataAttributes } from '../types';
import { safex } from '../utils/safex';

const getRadioInputStyles = ({
  clsPrefix,
  theme,
  useNewStyles,
}: {
  clsPrefix: string;
  theme: ComponentTheme;
  useNewStyles?: boolean;
}): React.CSSProperties => ({
  [`.${clsPrefix}`]: {
    alignSelf: 'start',
    // Unchecked Styles
    [`> .${clsPrefix}-input + .${clsPrefix}-inner`]: {
      width: theme.spacing.md,
      height: theme.spacing.md,
      background: useNewStyles ? theme.colors.actionDefaultBackgroundDefault : theme.colors.radioDefaultBackground,
      borderStyle: 'solid',
      borderColor: useNewStyles ? theme.colors.actionDefaultBorderDefault : theme.colors.radioDefaultBorder,
      boxShadow: 'unset',
      transform: 'unset', // This prevents an awkward jitter on the border
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      '&:after': {
        all: 'unset',
      },
    },
    // Hover
    [`&:not(.${clsPrefix}-disabled) > .${clsPrefix}-input:hover + .${clsPrefix}-inner`]: {
      borderColor: useNewStyles ? theme.colors.actionPrimaryBackgroundHover : theme.colors.radioInteractiveHover,
      background: useNewStyles
        ? theme.colors.actionDefaultBackgroundHover
        : theme.colors.radioInteractiveHoverSecondary,
    },
    // Focus
    [`&:not(.${clsPrefix}-disabled)> .${clsPrefix}-input:focus + .${clsPrefix}-inner`]: {
      borderColor: useNewStyles ? theme.colors.actionPrimaryBackgroundDefault : theme.colors.primary,
    },
    // Active
    [`&:not(.${clsPrefix}-disabled)> .${clsPrefix}-input:active + .${clsPrefix}-inner`]: {
      borderColor: useNewStyles ? theme.colors.actionPrimaryBackgroundPress : theme.colors.radioInteractivePress,
      background: useNewStyles
        ? theme.colors.actionDefaultBackgroundPress
        : theme.colors.radioInteractivePressSecondary,
    },
    // Disabled
    [`&.${clsPrefix}-disabled > .${clsPrefix}-input + .${clsPrefix}-inner`]: {
      ...(useNewStyles
        ? {
            border: `none !important`, // Ant uses !important
            background: theme.colors.actionDisabledBackground,
          }
        : {
            borderColor: `${theme.colors.radioDisabled}!important`, // Ant uses !important
          }),
      '@media (forced-colors: active)': {
        borderColor: 'GrayText !important',
      },
    },

    // Checked Styles
    [`&.${clsPrefix}-checked`]: {
      '&:after': {
        border: 'unset',
      },
      [`> .${clsPrefix}-input + .${clsPrefix}-inner`]: {
        background: useNewStyles ? theme.colors.actionPrimaryBackgroundDefault : theme.colors.primary,
        borderColor: theme.colors.primary,
        '&:after': {
          content: `""`,
          borderRadius: theme.spacing.xs,
          backgroundColor: useNewStyles ? theme.colors.white : theme.colors.radioDefaultBackground,
          width: theme.spacing.xs,
          height: theme.spacing.xs,
        },
        '@media (forced-colors: active)': {
          borderColor: 'Highlight !important',
          backgroundColor: 'Highlight !important',
        },
      },

      // Hover
      [`&:not(.${clsPrefix}-disabled) > .${clsPrefix}-input:hover + .${clsPrefix}-inner`]: {
        background: useNewStyles ? theme.colors.actionPrimaryBackgroundHover : theme.colors.radioInteractiveHover,
        borderColor: useNewStyles ? theme.colors.actionPrimaryBackgroundPress : theme.colors.radioInteractiveHover,
      },
      // Focus
      [`&:not(.${clsPrefix}-disabled) > .${clsPrefix}-input:focus-visible + .${clsPrefix}-inner`]: {
        background: useNewStyles ? theme.colors.actionDefaultBackgroundPress : theme.colors.primary,
        borderColor: useNewStyles ? theme.colors.actionDefaultBorderFocus : theme.colors.primary,
        boxShadow: `0 0 0 1px ${
          useNewStyles ? theme.colors.actionDefaultBackgroundDefault : theme.colors.radioDefaultBackground
        }, 0 0 0 3px ${theme.colors.primary}`,
      },
      // Active
      [`&:not(.${clsPrefix}-disabled) > .${clsPrefix}-input:active + .${clsPrefix}-inner`]: {
        background: useNewStyles ? theme.colors.actionDefaultBackgroundPress : theme.colors.radioInteractivePress,
        borderColor: useNewStyles ? theme.colors.actionDefaultBorderPress : theme.colors.radioInteractivePress,
      },
      // Disabled
      [`&.${clsPrefix}-disabled > .${clsPrefix}-input + .${clsPrefix}-inner`]: {
        background: useNewStyles ? theme.colors.actionDisabledBackground : theme.colors.radioDisabled,
        border: useNewStyles ? 'none !important' : `2px solid ${theme.colors.radioDisabled}!important`, // !important inherited from ant
        '@media (forced-colors: active)': {
          borderColor: 'GrayText !important',
          backgroundColor: 'GrayText !important',
        },
      },
    },
  },
});

const getCommonRadioGroupStyles = ({
  theme,
  clsPrefix,
  classNamePrefix,
  useNewStyles,
}: {
  theme: ComponentTheme;
  clsPrefix: string;
  classNamePrefix: string;
  useNewStyles?: boolean;
}): SerializedStyles =>
  css({
    '& > label': {
      ...(useNewStyles && {
        [`&.${classNamePrefix}-radio-wrapper-disabled > span`]: {
          color: theme.colors.textPrimary,
        },
      }),
    },

    [`& > label + .${classNamePrefix}-hint`]: {
      paddingLeft: theme.spacing.lg,
    },

    ...getRadioInputStyles({ theme, clsPrefix, useNewStyles }),
    ...getAnimationCss(theme.options.enableAnimation),
  });

const getHorizontalRadioGroupStyles = ({
  theme,
  classNamePrefix,
  useNewStyles,
}: {
  theme: ComponentTheme;
  classNamePrefix: string;
  useNewStyles?: boolean;
}): SerializedStyles =>
  css({
    '&&': {
      display: 'grid',
      gridTemplateRows: '[label] auto [hint] auto',
      gridAutoColumns: 'max-content',
      gridColumnGap: useNewStyles ? theme.spacing.md : theme.spacing.sm,
    },

    '& > label': {
      gridRow: 'label / label',
      marginRight: 0,
    },

    [`& > label + .${classNamePrefix}-hint`]: {
      display: 'inline-block',
      gridRow: 'hint / hint',
    },
  });

const getVerticalRadioGroupStyles = ({
  theme,
  classNamePrefix,
  useNewStyles,
}: {
  theme: Theme;
  classNamePrefix: string;
  useNewStyles?: boolean;
}) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    '& > label': {
      fontWeight: 'normal',
      ...(useNewStyles && {
        paddingBottom: theme.spacing.sm,
      }),
    },

    [`& > label:last-of-type`]: {
      paddingBottom: 0,
    },

    [`& > label + .${classNamePrefix}-hint`]: {
      marginBottom: theme.spacing.sm,
      paddingLeft: theme.spacing.lg,
      ...(useNewStyles && {
        marginTop: `-${theme.spacing.sm}px`,
      }),
    },

    [`& > label:last-of-type + .${classNamePrefix}-hint`]: {
      ...(useNewStyles && {
        marginTop: 0,
      }),
    },
  });

export const getRadioStyles = ({
  theme,
  clsPrefix,
  useNewStyles,
}: {
  theme: Theme;
  clsPrefix: string;
  useNewStyles?: boolean;
}): SerializedStyles => {
  // Default as bold for standalone radios
  const fontWeight = 'normal';

  const styles = {
    fontWeight,
  };

  return css({ ...getRadioInputStyles({ theme, clsPrefix, useNewStyles }), ...styles });
};

export interface RadioProps
  extends Omit<AntDRadioProps, 'prefixCls' | 'type' | 'skipGroup'>,
    DangerouslySetAntdProps<AntDRadioGroupProps>,
    HTMLDataAttributes {}

export interface RadioGroupProps
  extends Omit<AntDRadioGroupProps, 'optionType' | 'buttonStyle' | 'size' | 'prefixCls' | 'skipGroup'>,
    DangerouslySetAntdProps<AntDRadioGroupProps>,
    HTMLDataAttributes {
  layout?: 'vertical' | 'horizontal';
}

interface OrientedRadioGroupProps extends Omit<RadioGroupProps, 'layout'> {}

export interface RadioInterface extends React.FC<RadioProps> {
  Group: typeof Group;
  HorizontalGroup: typeof HorizontalGroup;
}

const DuboisRadio = forwardRef<HTMLElement, RadioProps>(function Radio(
  { children, dangerouslySetAntdProps, ...props },
  ref,
) {
  const { theme, getPrefixedClassName } = useDesignSystemTheme();
  const useNewStyles = safex('databricks.fe.designsystem.enableNewRadioStyles', false);

  const clsPrefix = getPrefixedClassName('radio');

  return (
    <DesignSystemAntDConfigProvider>
      <AntDRadio
        css={getRadioStyles({ theme, clsPrefix, useNewStyles })}
        {...props}
        {...dangerouslySetAntdProps}
        ref={ref}
      >
        <RestoreAntDDefaultClsPrefix>{children}</RestoreAntDDefaultClsPrefix>
      </AntDRadio>
    </DesignSystemAntDConfigProvider>
  );
});

const StyledRadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(function StyledRadioGroup(
  { children, dangerouslySetAntdProps, ...props }: RadioGroupProps,
  ref,
) {
  const { theme, getPrefixedClassName, classNamePrefix } = useDesignSystemTheme();
  const useNewStyles = safex('databricks.fe.designsystem.enableNewRadioStyles', false);
  const clsPrefix = getPrefixedClassName('radio');

  return (
    <DesignSystemAntDConfigProvider>
      <AntDRadio.Group
        {...props}
        css={getCommonRadioGroupStyles({
          theme,
          clsPrefix,
          classNamePrefix,
          useNewStyles,
        })}
        {...dangerouslySetAntdProps}
        ref={ref}
      >
        <RestoreAntDDefaultClsPrefix>{children}</RestoreAntDDefaultClsPrefix>
      </AntDRadio.Group>
    </DesignSystemAntDConfigProvider>
  );
});

const HorizontalGroup: React.FC<OrientedRadioGroupProps> = forwardRef<HTMLDivElement, OrientedRadioGroupProps>(
  function HorizontalGroup({ dangerouslySetAntdProps, ...props }, ref) {
    const { theme, classNamePrefix } = useDesignSystemTheme();
    const useNewStyles = safex('databricks.fe.designsystem.enableNewRadioStyles', false);

    return (
      <StyledRadioGroup
        css={getHorizontalRadioGroupStyles({ theme, classNamePrefix, useNewStyles })}
        {...props}
        ref={ref}
        {...dangerouslySetAntdProps}
      />
    );
  },
);

const Group: React.FC<RadioGroupProps> = forwardRef<HTMLDivElement, RadioGroupProps>(function HorizontalGroup(
  { dangerouslySetAntdProps, layout = 'vertical', ...props },
  ref,
) {
  const { theme, classNamePrefix } = useDesignSystemTheme();
  const useNewStyles = safex('databricks.fe.designsystem.enableNewRadioStyles', false);

  return (
    <StyledRadioGroup
      css={
        layout === 'horizontal'
          ? getHorizontalRadioGroupStyles({ theme, classNamePrefix, useNewStyles })
          : getVerticalRadioGroupStyles({
              theme,
              classNamePrefix,
              useNewStyles,
            })
      }
      {...props}
      ref={ref}
      {...dangerouslySetAntdProps}
    />
  );
});

// Note: We are overriding ant's default "Group" with our own.
const RadioNamespace = /* #__PURE__ */ Object.assign(DuboisRadio, { Group, HorizontalGroup });
export const Radio: RadioInterface = RadioNamespace;

// TODO: I'm doing this to support storybook's docgen;
// We should remove this once we have a better storybook integration,
// since these will be exposed in the library's exports.
// We should ideally be using __Group instead of __VerticalGroup, but that exists under Checkbox too and conflicts, therefore
// we show a wrong component name in "Show code" in docs, fix included in story to replace this with correct name
export const __INTERNAL_DO_NOT_USE__VerticalGroup = Group;
export const __INTERNAL_DO_NOT_USE__HorizontalGroup = HorizontalGroup;
