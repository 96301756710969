import type { CheckboxGroupProps } from 'antd/lib/checkbox';
import type { CheckboxValueType } from 'antd/lib/checkbox/Group';
import type { HTMLAttributes, ReactElement } from 'react';
import React, { Children, useEffect, useState } from 'react';
import type { Control, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';

import type { CheckboxProps } from '../Checkbox';
import { Checkbox } from '../Checkbox';
import type {
  DialogComboboxContentProps,
  DialogComboboxOptionListProps,
  DialogComboboxProps,
  DialogComboboxTriggerProps,
} from '../DialogCombobox';
import {
  DialogCombobox,
  DialogComboboxContent,
  DialogComboboxOptionList,
  DialogComboboxTrigger,
} from '../DialogCombobox';
import { useDesignSystemTheme } from '../Hooks';
import type { InputProps, PasswordProps, TextAreaProps } from '../Input';
import { Input } from '../Input';
import type { RadioGroupProps } from '../Radio';
import { Radio } from '../Radio';
import type { SelectProps, SelectValue } from '../Select';
import { Select } from '../Select';
import type { SelectV2ContentProps, SelectV2OptionProps, SelectV2Props, SelectV2TriggerProps } from '../SelectV2';
import { SelectV2, SelectV2Content, SelectV2Option, SelectV2Trigger } from '../SelectV2';
import type { ValidationState } from '../types';

// These props are omitted, as `useController` handles them.
type OmittedOriginalProps = 'name' | 'onChange' | 'onBlur' | 'value' | 'defaultValue' | 'checked';

interface RHFControlledInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<InputProps, OmittedOriginalProps>,
    UseControllerProps<TFieldValues, TName> {
  control: Control<TFieldValues>;
}

type AntInputValueType = string | ReadonlyArray<string> | number | undefined;

function RHFControlledInput<TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  ...restProps
}: React.PropsWithChildren<RHFControlledInputProps<TFieldValues>>) {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  return (
    <Input
      {...restProps}
      {...field}
      value={field.value as AntInputValueType}
      defaultValue={restProps.defaultValue as AntInputValueType}
    />
  );
}

interface RHFControlledPasswordInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<PasswordProps, OmittedOriginalProps>,
    UseControllerProps<TFieldValues, TName> {
  control: Control<TFieldValues>;
}

function RHFControlledPasswordInput<TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  ...restProps
}: React.PropsWithChildren<RHFControlledPasswordInputProps<TFieldValues>>) {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  return <Input.Password {...restProps} {...field} value={field.value} defaultValue={restProps.defaultValue} />;
}

interface RHFControlledTextAreaProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<TextAreaProps, OmittedOriginalProps>,
    UseControllerProps<TFieldValues, TName> {
  control: Control<TFieldValues>;
}

function RHFControlledTextArea<TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  ...restProps
}: React.PropsWithChildren<RHFControlledTextAreaProps<TFieldValues>>) {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  return <Input.TextArea {...restProps} {...field} value={field.value} defaultValue={restProps.defaultValue} />;
}

interface RHFControlledSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<SelectProps<unknown>, OmittedOriginalProps>,
    UseControllerProps<TFieldValues, TName> {
  control: Control<TFieldValues>;
}

function RHFControlledSelect<TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  ...restProps
}: React.PropsWithChildren<RHFControlledSelectProps<TFieldValues>>) {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  return (
    <Select
      {...restProps}
      {...field}
      value={field.value as SelectValue}
      defaultValue={field.value as SelectValue | undefined}
    />
  );
}

interface RHFControlledSelectV2Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<SelectV2Props, OmittedOriginalProps>,
    UseControllerProps<TFieldValues, TName> {
  control: Control<TFieldValues>;
  options?: {
    label: string;
    value: string;
  }[];
  validationState?: ValidationState;
  width?: string | number;
  children?: ({ onChange }: { onChange: (value: string) => void }) => React.ReactNode;
  triggerProps?: Pick<SelectV2TriggerProps, 'minWidth' | 'maxWidth' | 'disabled' | 'style' | 'className'>;
  contentProps?: Pick<SelectV2ContentProps, 'maxHeight' | 'minHeight' | 'loading' | 'style' | 'className'>;
  optionProps?: Pick<SelectV2OptionProps, 'disabled' | 'disabledReason' | 'style' | 'className'>;
}

function RHFControlledSelectV2<TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  options,
  validationState,
  children,
  width,
  triggerProps,
  contentProps,
  optionProps,
  ...restProps
}: React.PropsWithChildren<RHFControlledSelectV2Props<TFieldValues>> & HTMLAttributes<HTMLDivElement>) {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  const [selectedValueLabel, setSelectedValueLabel] = useState<string | undefined>(
    field.value ? (field.value.label ? field.value.label : field.value) : '',
  );

  const handleOnChange = (option: string | { label: string; value: string }) => {
    field.onChange(typeof option === 'object' ? option.value : option);
  };

  useEffect(() => {
    if (!field.value) {
      return;
    }

    // Find the appropriate label for the selected value
    if (!options?.length && children) {
      const renderedChildren = children({ onChange: handleOnChange }) as ReactElement;

      const child = Children.toArray(renderedChildren.props.children).find(
        (child) => React.isValidElement(child) && child.props.value === field.value,
      );

      if (child && (child as ReactElement).props?.children !== field.value) {
        setSelectedValueLabel((child as ReactElement).props.children as string);
      }
    } else if (options?.length) {
      const option = options.find((option) => option.value === field.value);
      setSelectedValueLabel(option?.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <SelectV2 {...restProps} value={field.value}>
      <SelectV2Trigger {...triggerProps} width={width} onBlur={field.onBlur} validationState={validationState}>
        {selectedValueLabel}
      </SelectV2Trigger>
      <SelectV2Content {...contentProps} side="bottom" width={width}>
        {options && options.length > 0
          ? options.map((option) => (
              <SelectV2Option {...optionProps} key={option.value} value={option.value} onChange={handleOnChange}>
                {option.label}
              </SelectV2Option>
            ))
          : // SelectV2Option out of the box gives users control over state and in this case RHF is controlling state
            // We expose onChange through a children renderer function to let users pass this down to SelectV2Option
            children?.({
              onChange: handleOnChange,
            })}
      </SelectV2Content>
    </SelectV2>
  );
}

interface RHFControlledDialogComboboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<DialogComboboxProps, OmittedOriginalProps>,
    UseControllerProps<TFieldValues, TName> {
  control: Control<TFieldValues>;
  validationState?: ValidationState;
  allowClear?: boolean;
  width?: string | number;
  children?: ({
    onChange,
    value,
  }: {
    onChange: (value: string) => void;
    value: string | string[];
    isChecked: (value: string) => boolean;
  }) => React.ReactNode;
  triggerProps?: Pick<DialogComboboxTriggerProps, 'minWidth' | 'maxWidth' | 'disabled' | 'style' | 'className'>;
  contentProps?: Pick<DialogComboboxContentProps, 'maxHeight' | 'minHeight' | 'style' | 'className'>;
  optionListProps?: Pick<DialogComboboxOptionListProps, 'loading' | 'withProgressiveLoading' | 'style' | 'className'>;
}

function RHFControlledDialogCombobox<TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  children,
  allowClear,
  validationState,
  placeholder,
  width,
  triggerProps,
  contentProps,
  optionListProps,
  ...restProps
}: React.PropsWithChildren<RHFControlledDialogComboboxProps<TFieldValues>> & HTMLAttributes<HTMLDivElement>) {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  const [valueMap, setValueMap] = useState<Record<string, boolean>>({}); // Used for multi-select

  const updateValueMap = (updatedValue?: string | string[]) => {
    if (updatedValue) {
      if (Array.isArray(updatedValue)) {
        setValueMap(
          updatedValue.reduce((acc: Record<string, boolean>, value: string) => {
            acc[value] = true;
            return acc;
          }, {}),
        );
      } else {
        setValueMap({ [updatedValue]: true });
      }
    } else {
      setValueMap({});
    }
  };

  const handleOnChangeSingleSelect = (option: string) => {
    let updatedValue: string | string[] | undefined = field.value;

    if (field.value === option) {
      updatedValue = undefined;
    } else {
      updatedValue = option;
    }

    field.onChange(updatedValue);
    updateValueMap(updatedValue);
  };

  const hanldeOnChangeMultiSelect = (option: string) => {
    let updatedValue: string | string[] | undefined = field.value;

    if (field.value?.includes(option)) {
      updatedValue = field.value.filter((value: string) => value !== option);
    } else {
      updatedValue = [...(field.value as string[]), option];
    }

    field.onChange(updatedValue);
    updateValueMap(updatedValue);
  };

  const handleOnChange = (option: string) => {
    if (restProps.multiSelect) {
      hanldeOnChangeMultiSelect(option);
    } else {
      handleOnChangeSingleSelect(option);
    }
  };

  useEffect(() => {
    if (field.value) {
      updateValueMap(field.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChecked = (option: string) => {
    return valueMap[option];
  };

  const handleOnClear = () => {
    field.onChange(Array.isArray(field.value) ? [] : '');
    setValueMap({});
  };

  return (
    <DialogCombobox
      {...restProps}
      value={field.value ? (Array.isArray(field.value) ? field.value : [field.value]) : undefined}
    >
      <DialogComboboxTrigger
        {...triggerProps}
        onBlur={field.onBlur}
        allowClear={allowClear}
        validationState={validationState}
        onClear={handleOnClear}
        withInlineLabel={false}
        placeholder={placeholder}
        width={width}
      />
      <DialogComboboxContent {...contentProps} side="bottom" width={width}>
        <DialogComboboxOptionList {...optionListProps}>
          {children?.({
            onChange: handleOnChange,
            value: field.value,
            isChecked,
          })}
        </DialogComboboxOptionList>
      </DialogComboboxContent>
    </DialogCombobox>
  );
}

interface RHFControlledCheckboxGroupProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<CheckboxGroupProps, OmittedOriginalProps>,
    UseControllerProps<TFieldValues, TName> {
  control: Control<TFieldValues>;
}

function RHFControlledCheckboxGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  ...restProps
}: React.PropsWithChildren<RHFControlledCheckboxGroupProps<TFieldValues, TName>>) {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  return <Checkbox.Group {...restProps} {...field} value={field.value as CheckboxValueType[] | undefined} />;
}

interface RHFControlledCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<CheckboxProps, OmittedOriginalProps>,
    UseControllerProps<TFieldValues, TName> {
  control: Control<TFieldValues>;
}

function RHFControlledCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ name, control, rules, ...restProps }: React.PropsWithChildren<RHFControlledCheckboxProps<TFieldValues, TName>>) {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });
  const { theme } = useDesignSystemTheme();

  return (
    <div css={{ marginTop: theme.spacing.sm }}>
      <Checkbox {...restProps} {...field} checked={field.value} />
    </div>
  );
}

interface RHFControlledRadioGroupProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<RadioGroupProps, OmittedOriginalProps>,
    UseControllerProps<TFieldValues, TName> {
  control: Control<TFieldValues>;
}

function RHFControlledRadioGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ name, control, rules, ...restProps }: React.PropsWithChildren<RHFControlledRadioGroupProps<TFieldValues, TName>>) {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  return <Radio.Group {...restProps} {...field} />;
}

export const RHFControlledComponents = {
  Input: RHFControlledInput,
  Password: RHFControlledPasswordInput,
  TextArea: RHFControlledTextArea,
  Select: RHFControlledSelect,
  SelectV2: RHFControlledSelectV2,
  DialogCombobox: RHFControlledDialogCombobox,
  Checkbox: RHFControlledCheckbox,
  CheckboxGroup: RHFControlledCheckboxGroup,
  RadioGroup: RHFControlledRadioGroup,
};
