import type { Interpolation } from '@emotion/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import type { CSSObject } from '@storybook/theming';
import type { ComponentPropsWithRef, ReactElement } from 'react';
import React, { Children, forwardRef, useImperativeHandle, useRef } from 'react';

import type { Theme } from '../../theme';
import { useDesignSystemTheme } from '../Hooks';
import { useDesignSystemContext } from '../Hooks/useDesignSystemContext';
import { CheckIcon, ChevronRightIcon, InfoIcon } from '../Icon';
import { Tooltip } from '../Tooltip';
import { importantify } from '../utils/css-utils';

// WRAPPED RADIX-UI-COMPONENTS
export const Root = DropdownMenu.Root; // Behavioral component only

export interface DropdownMenuProps extends DropdownMenu.MenuContentProps {
  minWidth?: number;
  isInsideModal?: boolean;
}

export interface DropdownMenuItemProps extends DropdownMenu.DropdownMenuItemProps {
  danger?: boolean;
  disabledReason?: React.ReactNode;
}

export interface DropdownMenuSubTriggerProps extends DropdownMenu.DropdownMenuSubTriggerProps {
  disabledReason?: React.ReactNode;
}

export interface DropdownMenuCheckboxItemProps extends DropdownMenu.DropdownMenuCheckboxItemProps {
  disabledReason?: React.ReactNode;
}

export interface DropdownMenuRadioItemProps extends DropdownMenu.DropdownMenuRadioItemProps {
  disabledReason?: React.ReactNode;
}

export const Content = forwardRef<HTMLDivElement, DropdownMenuProps>(function Content(
  { children, minWidth = 220, isInsideModal, onEscapeKeyDown, onKeyDown, ...props },
  ref,
): ReactElement {
  const { getPopupContainer } = useDesignSystemContext();

  return (
    <DropdownMenu.Portal container={getPopupContainer && getPopupContainer()}>
      <DropdownMenu.Content
        ref={ref}
        loop={true}
        css={[contentStyles, { minWidth }]}
        sideOffset={4}
        align="start"
        onKeyDown={(e) => {
          // This is a workaround for Radix's DropdownMenu.Content not receiving Escape key events
          // when nested inside a modal. We need to stop propagation of the event so that the modal
          // doesn't close when the DropdownMenu should.
          if (e.key === 'Escape') {
            if (isInsideModal) {
              e.stopPropagation();
            }
            onEscapeKeyDown?.(e.nativeEvent);
          }
          onKeyDown?.(e);
        }}
        {...props}
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  );
});

export const SubContent = forwardRef<HTMLDivElement, Omit<DropdownMenuProps, 'isInsideModal'>>(function Content(
  { children, minWidth = 220, ...props },
  ref,
): ReactElement {
  const { getPopupContainer } = useDesignSystemContext();

  return (
    <DropdownMenu.Portal container={getPopupContainer && getPopupContainer()}>
      <DropdownMenu.SubContent
        ref={ref}
        loop={true}
        css={[contentStyles, { minWidth }]}
        sideOffset={-2}
        alignOffset={-5}
        {...props}
      >
        {children}
      </DropdownMenu.SubContent>
    </DropdownMenu.Portal>
  );
});

export const Trigger = forwardRef<HTMLButtonElement, DropdownMenu.DropdownMenuTriggerProps>(function Trigger(
  { children, ...props },
  ref,
): ReactElement {
  return (
    <DropdownMenu.Trigger ref={ref} {...props}>
      {children}
    </DropdownMenu.Trigger>
  );
});

export const Item = forwardRef<HTMLDivElement, DropdownMenuItemProps>(function Item(
  { children, disabledReason, danger, onClick, ...props },
  ref,
): ReactElement {
  const itemRef = useRef<HTMLDivElement>(null);
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => itemRef.current);

  return (
    <DropdownMenu.Item
      css={(theme) => [dropdownItemStyles, danger && dangerItemStyles(theme)]}
      ref={itemRef}
      onClick={(e) => {
        if (props.disabled) {
          e.preventDefault();
        } else {
          onClick?.(e);
        }
      }}
      {...props}
    >
      {getNewChildren(children, props, disabledReason, itemRef)}
    </DropdownMenu.Item>
  );
});

export const Label = forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuLabelProps>(function Label(
  { children, ...props },
  ref,
): ReactElement {
  return (
    <DropdownMenu.Label
      ref={ref}
      css={[
        dropdownItemStyles,
        (theme) => ({
          color: theme.colors.textSecondary,
          '&:hover': {
            cursor: 'default',
          },
        }),
      ]}
      {...props}
    >
      {children}
    </DropdownMenu.Label>
  );
});

export const Separator = forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuSeparatorProps>(function Separator(
  { children, ...props },
  ref,
): ReactElement {
  return (
    <DropdownMenu.Separator ref={ref} css={dropdownSeparatorStyles} {...props}>
      {children}
    </DropdownMenu.Separator>
  );
});

export const SubTrigger = forwardRef<HTMLDivElement, DropdownMenuSubTriggerProps>(function TriggerItem(
  { children, disabledReason, ...props },
  ref,
): ReactElement {
  const subTriggerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => subTriggerRef.current);

  return (
    <DropdownMenu.SubTrigger
      ref={subTriggerRef}
      css={[
        dropdownItemStyles,
        (theme) => ({
          '&[data-state="open"]': {
            backgroundColor: theme.colors.actionTertiaryBackgroundHover,
          },
        }),
      ]}
      {...props}
    >
      {getNewChildren(children, props, disabledReason, subTriggerRef)}
      <HintColumn
        css={(theme) => ({
          margin: CONSTANTS.subMenuIconMargin(theme),
          display: 'flex',
          alignSelf: 'stretch',
          alignItems: 'center',
        })}
      >
        <ChevronRightIcon css={(theme) => ({ fontSize: CONSTANTS.subMenuIconSize(theme) })} />
      </HintColumn>
    </DropdownMenu.SubTrigger>
  );
});

/**
 * Deprecated. Use `SubTrigger` instead.
 * @deprecated
 */
export const TriggerItem = SubTrigger;

export const CheckboxItem = forwardRef<HTMLDivElement, DropdownMenuCheckboxItemProps>(function CheckboxItem(
  { children, disabledReason, ...props },
  ref,
): ReactElement {
  const checkboxItemRef = useRef<HTMLDivElement>(null);
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => checkboxItemRef.current);

  return (
    <DropdownMenu.CheckboxItem
      ref={checkboxItemRef}
      css={(theme) => [dropdownItemStyles, checkboxItemStyles(theme)]}
      {...props}
    >
      {getNewChildren(children, props, disabledReason, checkboxItemRef)}
    </DropdownMenu.CheckboxItem>
  );
});

export const ItemIndicator = forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuItemIndicatorProps>(
  function ItemIndicator({ children, ...props }, ref): ReactElement {
    return (
      <DropdownMenu.ItemIndicator
        ref={ref}
        css={(theme) => ({
          marginLeft: -(CONSTANTS.checkboxIconWidth(theme) + CONSTANTS.checkboxPaddingRight(theme)),
          position: 'absolute',
          fontSize: theme.general.iconFontSize,
        })}
        {...props}
      >
        {children ?? (
          <CheckIcon
            css={(theme) => ({
              color: theme.colors.textSecondary,
            })}
          />
        )}
      </DropdownMenu.ItemIndicator>
    );
  },
);

export const Arrow = forwardRef<SVGSVGElement, DropdownMenu.DropdownMenuArrowProps>(function Arrow(
  { children, ...props },
  ref,
): ReactElement {
  const { theme } = useDesignSystemTheme();
  return (
    <DropdownMenu.Arrow
      css={{
        fill: theme.colors.backgroundPrimary,
        stroke: theme.colors.borderDecorative,
        strokeDashoffset: -CONSTANTS.arrowBottomLength(),
        strokeDasharray: CONSTANTS.arrowBottomLength() + 2 * CONSTANTS.arrowSide(),
        strokeWidth: CONSTANTS.arrowStrokeWidth(),
        // TODO: This is a temporary fix for the alignment of the Arrow;
        // Radix has changed the implementation for v1.0.0 (uses floating-ui)
        // which has new behaviors for alignment that we don't want. Generally
        // we need to fix the arrow to always be aligned to the left of the menu (with
        // offset equal to border radius)
        position: 'relative',
        top: -1,
      }}
      ref={ref}
      width={12}
      height={6}
      {...props}
    >
      {children}
    </DropdownMenu.Arrow>
  );
});

export const RadioItem = forwardRef<HTMLDivElement, DropdownMenuRadioItemProps>(function RadioItem(
  { children, disabledReason, ...props },
  ref,
): ReactElement {
  const radioItemRef = useRef<HTMLDivElement>(null);
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => radioItemRef.current);

  return (
    <DropdownMenu.RadioItem
      ref={radioItemRef}
      css={(theme) => [dropdownItemStyles, checkboxItemStyles(theme)]}
      {...props}
    >
      {getNewChildren(children, props, disabledReason, radioItemRef)}
    </DropdownMenu.RadioItem>
  );
});

// UNWRAPPED RADIX-UI-COMPONENTS
export const Group = DropdownMenu.Group;
export const RadioGroup = DropdownMenu.RadioGroup;
export const Sub = DropdownMenu.Sub;

// EXTRA COMPONENTS
export const HintColumn = forwardRef<HTMLDivElement, ComponentPropsWithRef<'div'>>(function HintColumn(
  { children, ...props },
  ref,
): ReactElement {
  return (
    <div
      ref={ref}
      css={[
        metaTextStyles,
        {
          marginLeft: 'auto',
        },
      ]}
      {...props}
    >
      {children}
    </div>
  );
});

export const HintRow = forwardRef<HTMLDivElement, ComponentPropsWithRef<'div'>>(function HintRow(
  { children, ...props },
  ref,
): ReactElement {
  return (
    <div
      ref={ref}
      css={[
        metaTextStyles,
        {
          minWidth: '100%',
        },
      ]}
      {...props}
    >
      {children}
    </div>
  );
});

export const IconWrapper = forwardRef<HTMLDivElement, ComponentPropsWithRef<'div'>>(function IconWrapper(
  { children, ...props },
  ref,
): ReactElement {
  return (
    <div
      ref={ref}
      css={(theme) => ({
        fontSize: 16,
        color: theme.colors.textSecondary,
        paddingRight: theme.spacing.sm,
      })}
      {...props}
    >
      {children}
    </div>
  );
});

const getNewChildren = (
  children: React.ReactNode,
  props:
    | DropdownMenuItemProps
    | DropdownMenuCheckboxItemProps
    | DropdownMenuRadioItemProps
    | DropdownMenuSubTriggerProps,
  disabledReason: React.ReactNode,
  ref: React.RefObject<HTMLElement>,
) => {
  const childCount = Children.count(children);
  const tooltip = (
    <Tooltip
      title={disabledReason}
      placement="right"
      dangerouslySetAntdProps={{ getPopupContainer: () => ref.current || document.body }}
    >
      <span css={(theme) => infoIconStyles(theme)}>
        <InfoIcon aria-hidden="false" />
      </span>
    </Tooltip>
  );

  if (childCount === 1) {
    return getChild(children, Boolean(props['disabled']), disabledReason, tooltip, 0, childCount);
  }

  return Children.map(children, (child, idx) => {
    return getChild(child, Boolean(props['disabled']), disabledReason, tooltip, idx, childCount);
  });
};

const getChild = (
  child: React.ReactNode,
  isDisabled: boolean,
  disabledReason: React.ReactNode,
  tooltip: React.ReactElement,
  index: number,
  siblingCount: number,
) => {
  const HintColumnType = (<HintColumn />).type;
  const isHintColumnType = Boolean(
    child &&
      typeof child !== 'string' &&
      typeof child !== 'number' &&
      typeof child !== 'boolean' &&
      'type' in child &&
      child?.type === HintColumnType,
  );

  if (isDisabled && disabledReason && child && isHintColumnType) {
    return (
      <>
        {tooltip}
        {child}
      </>
    );
  } else if (index === siblingCount - 1 && isDisabled && disabledReason) {
    return (
      <>
        {child}
        {tooltip}
      </>
    );
  }
  return child;
};

// CONSTANTS
const CONSTANTS = {
  itemPaddingVertical(theme: Theme) {
    // The number from the mocks is the midpoint between constants
    return 0.5 * theme.spacing.xs + 0.5 * theme.spacing.sm;
  },
  itemPaddingHorizontal(theme: Theme) {
    return theme.spacing.sm;
  },
  checkboxIconWidth(theme: Theme) {
    return theme.general.iconFontSize;
  },
  checkboxPaddingLeft(theme: Theme) {
    return theme.spacing.sm + theme.spacing.xs;
  },
  checkboxPaddingRight(theme: Theme) {
    return theme.spacing.sm;
  },
  subMenuIconMargin(theme: Theme) {
    // Negative margin so the icons can be larger without increasing the overall item height
    const iconMarginVertical = this.itemPaddingVertical(theme) / 2;
    const iconMarginRight = -this.itemPaddingVertical(theme) + theme.spacing.sm * 1.5;
    return `${-iconMarginVertical}px ${-iconMarginRight}px ${-iconMarginVertical}px auto`;
  },
  subMenuIconSize(theme: Theme) {
    return theme.spacing.lg;
  },
  arrowBottomLength() {
    // The built in arrow is a polygon: 0,0 30,0 15,10
    return 30;
  },
  arrowHeight() {
    return 10;
  },
  arrowSide() {
    return 2 * (this.arrowHeight() ** 2 * 2) ** 0.5;
  },
  arrowStrokeWidth() {
    // This is eyeballed b/c relative to the svg viewbox coordinate system
    return 2;
  },
};

export const dropdownContentStyles = (theme: Theme): CSSObject => ({
  backgroundColor: theme.colors.backgroundPrimary,
  color: theme.colors.textPrimary,
  lineHeight: theme.typography.lineHeightBase,
  border: `1px solid ${theme.colors.borderDecorative}`,
  borderRadius: theme.borders.borderRadiusMd,
  padding: `${theme.spacing.xs}px 0`,
  boxShadow: theme.general.shadowLow,
  userSelect: 'none',
  // Ant Design uses 1000s for their zIndex space; this ensures Radix works with that, but
  // we'll likely need to be sure that all Radix components are using the same zIndex going forward.
  //
  // Additionally, there is an issue where macOS overlay scrollbars in Chrome and Safari (sometimes!)
  // overlap other elements with higher zIndex, because the scrollbars themselves have zIndex 9999,
  // so we have to use a higher value than that: https://github.com/databricks/universe/pull/232825
  zIndex: 10000,
  a: importantify({
    color: theme.colors.textPrimary,
    '&:hover, &:focus': {
      color: theme.colors.textPrimary,
      textDecoration: 'none',
    },
  }),
});

const contentStyles = (theme: Theme): Interpolation<Theme> => ({
  ...dropdownContentStyles(theme),
});

export const dropdownItemStyles: (theme: Theme) => Interpolation<Theme> = (theme) => ({
  padding: `${CONSTANTS.itemPaddingVertical(theme)}px ${CONSTANTS.itemPaddingHorizontal(theme)}px`,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  outline: 'unset',
  '&:hover': {
    cursor: 'pointer',
  },
  '&:focus': {
    backgroundColor: theme.colors.actionTertiaryBackgroundHover,
  },
  '&[data-disabled]': {
    pointerEvents: 'none',
    color: theme.colors.actionDisabledText,
  },
});

const dangerItemStyles = (theme: Theme): Interpolation<Theme> => ({
  color: theme.colors.textValidationDanger,
  '&:hover, &:focus': {
    backgroundColor: theme.colors.actionDangerDefaultBackgroundHover,
  },
});

const infoIconStyles = (theme: Theme): Interpolation<Theme> => ({
  display: 'inline-flex',
  paddingLeft: theme.spacing.xs,
  color: theme.colors.textSecondary,
  pointerEvents: 'all',
});

const checkboxItemStyles = (theme: Theme): Interpolation<Theme> => ({
  position: 'relative',
  paddingLeft:
    CONSTANTS.checkboxIconWidth(theme) + CONSTANTS.checkboxPaddingLeft(theme) + CONSTANTS.checkboxPaddingRight(theme),
});

const metaTextStyles = (theme: Theme): Interpolation<Theme> => ({
  color: theme.colors.textSecondary,
  fontSize: theme.typography.fontSizeSm,
  '[data-disabled] &': {
    color: theme.colors.actionDisabledText,
  },
});

export const dropdownSeparatorStyles = (theme: Theme) => ({
  height: 1,
  margin: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
  backgroundColor: theme.colors.borderDecorative,
});
