import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgSidebarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.75 1a.75.75 0 0 0-.75.75v12.5c0 .414.336.75.75.75h12.5a.75.75 0 0 0 .75-.75V1.75a.75.75 0 0 0-.75-.75H1.75Zm.75 12.5v-11H4v11H2.5Zm3 0h8v-11h-8v11Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
function SidebarIcon(props: IconProps) {
  return <Icon {...props} component={SvgSidebarIcon} />;
}
export default SidebarIcon;
