import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Input, Form, Select, AutoComplete } from 'antd';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;

type Props = {
  nodeNameValidator: (...args: any[]) => any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  innerRef: any;
  clusters: any;
};

/**
 * Component that renders a form for adding a new node for concurrent.
 */
class AddNodeFormComponent extends Component<Props> {  
  render() {
    const learnMoreLinkUrl = 'https://docs.concurrent-ai.org/files/free-helloworld/#add-node-to-dag';
    const options = [{ value: 'gold' }, { value: 'lime' }, { value: 'green' }, { value: 'cyan' }];
    return (
      <Form ref={this.props.innerRef} layout='vertical'>
        <Row>
          <Col span={12}>
            <Form.Item label='Enter node name' name='name'
              rules={[{ required: true, message:'Node name is required' },
                {
                  validator: this.props.nodeNameValidator,
                },
              ]}
            >
              <Input 
                placeholder={this.props.intl.formatMessage({
                  defaultMessage: 'Enter node name',
                  description: 'Input placeholder to enter node name for create dag',
                })}
                autoFocus
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{paddingLeft: '10px'}}>
            <Form.Item
              name='instanceType'
              label='Specify Node Location'
              rules={[
                {
                  required: true,
                  message: this.props.intl.formatMessage({
                    defaultMessage: "Node Location is required",
                    description: 'Error message for node location',
                  }),
                },
              ]}
            >
              <Select placeholder="Select Node Location">
                <Option key="eks" value="eks"> Elastic Kubernetes Service (EKS) </Option>
                <Option key="gke" value="gke"> Google Kubernetes Engine (GKE) </Option>
                <Option key="HPE" value="HPE"> HP Kubernetes Engine (HPE) </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div> 
          <Row>
            <Col span={12}>
              <Form.Item
                label="Choose kubernetes cluster"
                name='kube_context'
                rules={[{ required: true, message: 'Kubernetes cluster is required'}]}
              >
                <AutoComplete
                  placeholder="Select kubernetes cluster"
                  options={this.props.clusters}
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '10px'}}>
              <Form.Item
                name='namespace'
                label="Enter cluster's namespace"
                rules={[
                  {
                    required: true,
                    message: this.props.intl.formatMessage({
                      defaultMessage: "Cluster's namespace is required",
                      description: 'Error message for cluster namespace requirement in add node',
                    }),
                  },
                ]}
              >
                <Input 
                  placeholder={this.props.intl.formatMessage({
                    defaultMessage: "Enter cluster's namespace",
                    description: 'Input placeholder to enter cluster namespace for node',
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name='resources.requests.memory'
                label='Choose pod memory limit'
                rules={[{ required: true, message: 'Memory limit is required' }]}
              >
                <AutoComplete
                  placeholder="Select memory limit"
                  options={[{ value: '256Mi' }, { value: '512Mi' }, { value: '768Mi' }, { value: '1024Mi' }]}
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '10px'}}> 
              <Form.Item
                name='resources.requests.cpu'
                label='Choose pod cpu limit'
                rules={[
                  {
                    required: true,
                    message: this.props.intl.formatMessage({
                      defaultMessage: 'cpu limit is required',
                      description: 'Error message for cpu limit in cluster configuration',
                    }),
                  },
                ]}
              >
                <AutoComplete
                  placeholder="Select cpu limit"
                  options={[{ value: '250m' }, { value: '500m' }, { value: '750m' }, { value: '1000m' }]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name='resources.requests.gpu'
                label='Choose pod GPUs'
                rules={[{ required: true, message: 'GPUs limit is required' }]}
              >
                <AutoComplete
                  placeholder="Select gpu limit"
                  options={[{ value: '0' }, { value: '1' }, { value: '2' }, { value: '3' }]}
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '10px'}}> 
              <Form.Item
                name='parallelization'
                label='Specify max data partitions'
                rules={[
                  {
                    required: true,
                    message: this.props.intl.formatMessage({
                      defaultMessage: 'Max data partitions is required',
                      description: 'Error message for parallelization requirement in add node',
                    }),
                  },
                ]}
              >
                <Input type="number"
                  placeholder={this.props.intl.formatMessage({
                    defaultMessage: "Enter max data partitions",
                    description: 'Input placeholder to enter cluster namespace for node',
                  })}
                />
              </Form.Item>
            </Col>
          </Row> 
        </div> 
        <Row>
          <Col span={24}>
            <Form.Item
              name='transform'
              label='Enter Git URL'
              rules={[
                { required: true, message: 'Git URL is required'}, 
                // @ts-expect-error TS(2322)
                { type: 'url', warningOnly: true, message: 'Git URL is not a valid url' }]}
            >
              <Input
                placeholder={this.props.intl.formatMessage({
                  defaultMessage: 'Enter Git URL',
                  description: 'Input placeholder to enter git url for create dag',
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='xform_path'
          label='Enter Mlproject path'
          rules={[
            {
              required: true,
              message: this.props.intl.formatMessage({
                defaultMessage: 'Mlproject path is required.',
                description: 'Error message for project path requirement in create dag',
              }),
            },
          ]}
        >
          <Input 
            placeholder={this.props.intl.formatMessage({
              defaultMessage: 'Enter project path',
              description: 'Input placeholder to enter project path for create dag',
            })}
          />
        </Form.Item>
        <p className='create-modal-explanatory-text'>
          <FormattedMessage
            defaultMessage='After creation, you can add connection to this node and specify inputs.&nbsp;'
            description='Text for form description on creating model in the model registry'
          />
          <FormattedMessage
            defaultMessage='<link>Learn more</link>'
            description='Learn more link on the form for creating model in the model registry'
            values={{
              link: (chunks) => (
                <a href={learnMoreLinkUrl} target='_blank'>
                  {chunks}
                </a>
              ),
            }}
          />
          .
        </p>
      </Form>
    );
  }
}

//@ts-expect-error
export const AddNodeForm = injectIntl(AddNodeFormComponent);
