import { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { LockOutlined } from '@ant-design/icons';

type Props = {
  handleSetNewPassword: (...args: any[]) => any;
  setView: (...args: any[]) => any;
  requestPending: boolean;
}

type State = any;

/**
 * Component that renders a form for NewPass Page.
 */
class NewPassPageComponent extends Component<Props, State> {
  render() {
    const { handleSetNewPassword, setView, requestPending } = this.props;
    return (<div>
        <Form onFinish={handleSetNewPassword} className="login-form">
          <b>New Password</b>
          <Form.Item name="newpassword" rules={[{ required: true, message: 'Please input your new password!' }]}>
            <Input.Password className="login-input"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Enter New Password"
            />
          </Form.Item>
          <Form.Item>
            <span className="login__link" id="login" onClick={setView} >Back to login.</span>
          </Form.Item>
          <Form.Item>
            <Button loading={requestPending} type="primary" htmlType="submit" className="login-form-button">
              Set New Password
            </Button>
          </Form.Item>
        </Form>
    </div>);
  }
}

// @ts-expect-error TS(2769): No overload matches this call
export const NewPassPage = injectIntl(NewPassPageComponent);
