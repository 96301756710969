import type { CSSObject } from '@emotion/react';
import { css } from '@emotion/react';
import type { RadioGroupProps } from 'antd';
import { Radio as AntDRadio } from 'antd';
import type { RadioButtonProps } from 'antd/lib/radio/radioButton';
import { createContext, forwardRef, useContext } from 'react';

import type { Theme } from '../../theme';
import type { ButtonSize } from '../Button';
import { DesignSystemAntDConfigProvider, getAnimationCss } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks/useDesignSystemTheme';
import type { DangerouslySetAntdProps, HTMLDataAttributes } from '../types';
import { importantify } from '../utils/css-utils';

// TODO(GP): Add this to common spacing vars; I didn't want to make a decision on the value right now,
// so copied it from `Button`.
const SMALL_BUTTON_HEIGHT = 24;

function getSegmentedControlGroupEmotionStyles(clsPrefix: string) {
  const classSmallGroup = `.${clsPrefix}-radio-group-small`;
  const classButtonWrapper = `.${clsPrefix}-radio-button-wrapper`;

  const styles: CSSObject = {
    [`&${classSmallGroup} ${classButtonWrapper}`]: {
      padding: '0 12px',
    },
  };

  const importantStyles = importantify(styles);

  return css(importantStyles);
}

function getSegmentedControlButtonEmotionStyles(clsPrefix: string, theme: Theme, size: ButtonSize) {
  const classWrapperChecked = `.${clsPrefix}-radio-button-wrapper-checked`;
  const classWrapper = `.${clsPrefix}-radio-button-wrapper`;
  const classWrapperDisabled = `.${clsPrefix}-radio-button-wrapper-disabled`;
  const classButton = `.${clsPrefix}-radio-button`;

  // Note: Ant radio button uses a 1px-wide `before` pseudo-element to recreate the left border of the button.
  // This is because the actual left border is disabled to avoid a double-border effect with the adjacent button's
  // right border.
  // We must override the background colour of this pseudo-border to be the same as the real border above.

  const styles: CSSObject = {
    backgroundColor: theme.colors.actionDefaultBackgroundDefault,
    borderColor: theme.colors.actionDefaultBorderDefault,
    color: theme.colors.actionDefaultTextDefault,

    '::before': {
      backgroundColor: theme.colors.actionDefaultBorderDefault,
    },

    '&:hover': {
      backgroundColor: theme.colors.actionDefaultBackgroundHover,
      borderColor: theme.colors.actionDefaultBorderHover,
      color: theme.colors.actionDefaultTextHover,

      '::before': {
        backgroundColor: theme.colors.actionDefaultBorderHover,
      },

      // Also target the same pseudo-element on the next sibling, because this is used to create the right border
      [`& + ${classWrapper}::before`]: {
        backgroundColor: theme.colors.actionDefaultBorderPress,
      },
    },

    '&:active': {
      backgroundColor: theme.colors.actionTertiaryBackgroundPress,
      borderColor: theme.colors.actionDefaultBorderPress,
      color: theme.colors.actionTertiaryTextPress,
    },

    [`&${classWrapperChecked}`]: {
      backgroundColor: theme.colors.actionTertiaryBackgroundPress,
      borderColor: theme.colors.actionDefaultBorderPress,
      color: theme.colors.actionTertiaryTextPress,
      boxShadow: 'none',

      '::before': {
        backgroundColor: theme.colors.actionDefaultBorderPress,
      },
    },

    [`&${classWrapperChecked}:focus-within`]: {
      '::before': {
        width: 0,
      },
    },

    [`&${classWrapperDisabled}`]: {
      color: theme.colors.actionDisabledText,
      backgroundColor: theme.colors.actionDisabledBackground,
      '&:hover': {
        color: theme.colors.actionDisabledText,
        backgroundColor: theme.colors.actionDisabledBackground,
      },
      '&:active': {
        color: theme.colors.actionDisabledText,
        backgroundColor: theme.colors.actionDisabledBackground,
      },
    },

    [`&${classWrapper}`]: {
      padding: size === 'middle' ? '0 16px' : '0 8px',
      display: 'inline-flex',
      verticalAlign: 'middle',

      '&:focus-within': {
        outlineStyle: 'solid',
        outlineWidth: '2px',
        outlineOffset: '-2px',
        outlineColor: theme.colors.primary,
      },
    },

    [`&${classWrapper}, ${classButton}`]: {
      height: size === 'middle' ? theme.general.heightSm : SMALL_BUTTON_HEIGHT,
      lineHeight: theme.typography.lineHeightBase,
      alignItems: 'center',
    },

    ...getAnimationCss(theme.options.enableAnimation),
  };

  const importantStyles = importantify(styles);

  return css(importantStyles);
}

const SegmentedControlGroupContext = createContext<{
  size: ButtonSize;
}>({
  size: 'middle',
});
export interface SegmentedControlButtonProps
  extends Omit<RadioButtonProps, 'optionType' | 'buttonStyle' | 'prefixCls' | 'skipGroup'>,
    DangerouslySetAntdProps<RadioButtonProps>,
    HTMLDataAttributes {}

export const SegmentedControlButton = forwardRef<HTMLButtonElement, SegmentedControlButtonProps>(
  function SegmentedControlButton(
    { dangerouslySetAntdProps, ...props }: SegmentedControlButtonProps,
    ref,
  ): JSX.Element {
    const { classNamePrefix, theme } = useDesignSystemTheme();
    const { size } = useContext(SegmentedControlGroupContext);

    return (
      <DesignSystemAntDConfigProvider>
        <AntDRadio.Button
          css={getSegmentedControlButtonEmotionStyles(classNamePrefix, theme, size)}
          {...props}
          {...dangerouslySetAntdProps}
          ref={ref}
        />
      </DesignSystemAntDConfigProvider>
    );
  },
);

export interface SegmentedControlGroupProps
  extends Omit<RadioGroupProps, 'size'>,
    DangerouslySetAntdProps<RadioGroupProps>,
    HTMLDataAttributes {
  size?: ButtonSize;
}

export const SegmentedControlGroup = forwardRef<HTMLDivElement, SegmentedControlGroupProps>(
  function SegmentedControlGroup(
    { dangerouslySetAntdProps, size = 'middle', ...props }: SegmentedControlGroupProps,
    ref,
  ): JSX.Element {
    const { classNamePrefix } = useDesignSystemTheme();

    return (
      <DesignSystemAntDConfigProvider>
        <SegmentedControlGroupContext.Provider value={{ size }}>
          <AntDRadio.Group
            {...props}
            css={getSegmentedControlGroupEmotionStyles(classNamePrefix)}
            {...dangerouslySetAntdProps}
            ref={ref}
          />
        </SegmentedControlGroupContext.Provider>
      </DesignSystemAntDConfigProvider>
    );
  },
);
