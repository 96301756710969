import type { IconType as AntDIconType } from 'antd/lib/notification';

import type { IconProps } from './Icon';
import { CheckCircleFillIcon, DangerFillIcon, InfoFillIcon, WarningFillIcon } from './__generated/icons';

interface MinimalIconProps {
  className?: string;
}

// TODO: Replace with custom icons
// TODO: Reuse in Alert
const filledIconsMap: Record<AntDIconType, (props: IconProps) => JSX.Element> = {
  error: DangerFillIcon,
  warning: WarningFillIcon,
  success: CheckCircleFillIcon,
  info: InfoFillIcon,
};

export interface SeverityIconProps extends MinimalIconProps {
  severity: AntDIconType;
}

export function SeverityIcon(props: SeverityIconProps): JSX.Element {
  const FilledIcon = filledIconsMap[props.severity];
  return <FilledIcon {...props} />;
}
