import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgFullscreenIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M6 1H1.75a.75.75 0 0 0-.75.75V6h1.5V2.5H6V1ZM10 2.5V1h4.25a.75.75 0 0 1 .75.75V6h-1.5V2.5H10ZM10 13.5h3.5V10H15v4.25a.75.75 0 0 1-.75.75H10v-1.5ZM2.5 10v3.5H6V15H1.75a.75.75 0 0 1-.75-.75V10h1.5Z"
      />
    </svg>
  );
}
function FullscreenIcon(props: IconProps) {
  return <Icon {...props} component={SvgFullscreenIcon} />;
}
export default FullscreenIcon;
