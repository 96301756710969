import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgVisibleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <g fill="currentColor" fillRule="evenodd" clipPath="url(#VisibleIcon_svg__a)" clipRule="evenodd">
        <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM6.5 8a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" />
        <path d="M8 2A8.389 8.389 0 0 0 .028 7.777a.75.75 0 0 0 0 .466 8.389 8.389 0 0 0 15.944 0 .75.75 0 0 0 0-.466A8.389 8.389 0 0 0 8 2Zm0 10.52a6.888 6.888 0 0 1-6.465-4.51 6.888 6.888 0 0 1 12.93 0A6.888 6.888 0 0 1 8 12.52Z" />
      </g>
      <defs>
        <clipPath id="VisibleIcon_svg__a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
function VisibleIcon(props: IconProps) {
  return <Icon {...props} component={SvgVisibleIcon} />;
}
export default VisibleIcon;
