import { AnyAction, combineReducers } from 'redux';
import { 
  ModelGatewayRoute, 
  SearchModelGatewayRouteResponse, 
  GateWayRouteAuthorization, 
  ModelGatewayRouteLimits 
} from '../sdk/ModelGatewayService';
import { fulfilled, pending, rejected } from '../../common/utils/ActionUtils';

import { AsyncAction, AsyncFulfilledAction, Fulfilled } from '../../redux-types';
import {
  GET_MODEL_GATEWAY_ROUTE_API,
  GetModelGatewayRouteAction,
  SEARCH_MODEL_GATEWAY_ROUTES_API,
  SearchModelGatewayRoutesAction,
  GET_MODEL_GATEWAY_ROUTE_AUTHORIZATION_API,
  GET_MODEL_GATEWAY_ROUTE_LIMITS_API
} from '../actions/ModelGatewayActions';

export interface ModelGatewayReduxState {
  modelGatewayRoutes: Record<string, ModelGatewayRoute>;
  modelGatewayRoutesLoading: boolean;
  modelGatewayRoutesAuthorization: Record<string, GateWayRouteAuthorization>;
  modelGatewayRouteslimits : Record<string, ModelGatewayRouteLimits>;
}

export const modelGatewayRoutesLoading = (
  state = false,
  action: AsyncAction<SearchModelGatewayRouteResponse>,
) => {
  switch (action.type) {
    case pending(SEARCH_MODEL_GATEWAY_ROUTES_API):
      return true;
    case fulfilled(SEARCH_MODEL_GATEWAY_ROUTES_API):
    case rejected(SEARCH_MODEL_GATEWAY_ROUTES_API):
      return false;
  }
  return state;
};

export const modelGatewayRoutes = (
  state: Record<string, ModelGatewayRoute> = {},
  {
    payload,
    type,
  }:
    | AsyncFulfilledAction<SearchModelGatewayRoutesAction>
    | AsyncFulfilledAction<GetModelGatewayRouteAction>,
): Record<string, ModelGatewayRoute> => {
  switch (type) {
    case fulfilled(SEARCH_MODEL_GATEWAY_ROUTES_API):
      let newState = Object.assign({}, state);
      if (!payload.routes) {
        return state;
      }
      newState = {};
      payload.routes.forEach((route: any) => {
        newState = Object.assign(newState, { [route.name]: route });
      });
      return newState;
    case fulfilled(GET_MODEL_GATEWAY_ROUTE_API):
      return { ...state, [payload.name]: payload };
    default:
  }
  return state;
};

const routeAuthorization = (authorization: any) => {
  let routeAuth: { principal_name: string; principal_type: string; role: string; key: string }[] = [];
  const userRoles = authorization.user_roles || {};
  const groupRoles = authorization.group_roles || {};

  Object.keys(userRoles)
  .forEach(function eachKey(key) { 
    let auth = {
      'principal_name': key,
      'principal_type': 'user',
      'role': userRoles[key],
      'key': 'user'+key
    }
    routeAuth.push(auth);
  });

  Object.keys(groupRoles)
  .forEach(function eachKey(key) { 
    let auth = {
      'principal_name': key,
      'principal_type': 'group',
      'role': groupRoles[key],
      'key': 'group'+key
    }
    routeAuth.push(auth);
  });

  return routeAuth;
}

export const modelGatewayRoutesAuthorization = (
  state: Record<string, GateWayRouteAuthorization[]> = {},
  action: any,
) => {
  switch (action.type) {
    case fulfilled(GET_MODEL_GATEWAY_ROUTE_AUTHORIZATION_API):
      const authorization = action.payload.route_auth || {user_roles : {}, group_roles: {}};
      const routeName = action.meta.routeName;
      const newState = { ...state };
      newState[routeName] = routeAuthorization(authorization);
      return newState;
    default:
  }
  return state;
};

export const modelGatewayRouteslimits = (
  state: Record<string, ModelGatewayRouteLimits[]> = {},
  action: any) => {
    switch (action.type) {
      case fulfilled(GET_MODEL_GATEWAY_ROUTE_LIMITS_API):
        const limits = action.payload.limits || [];
        const routeName = action.meta.routeName;
        const newState = { ...state };
        newState[routeName] = limits;
        return newState;
      default:
    }
    return state;
}

export const modelGatewayReducer = combineReducers({
  modelGatewayRoutesLoading,
  modelGatewayRoutes,
  modelGatewayRoutesAuthorization,
  modelGatewayRouteslimits
});
