import { Component } from 'react';
import { GenericInputModal } from '../../../experiment-tracking/components/modals/GenericInputModal';
import { EditConnectionForm } from './EditConnectionForm';
import { injectIntl } from 'react-intl';

type Props = {
  nodes: any[];
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  isOpen: boolean;
  selectedConnection: any;
  handleSubmit: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  input : any[];
}

export class EditConnectionModalImpl extends Component<Props> {
  getNodeInputNamesAsSet = () =>
    new Set(this.props.input.map((values) => values['name']));

  nodeInputNameValidator = (rule: any, value: any, callback: any) => {
    const nodeInputNamesSet = this.getNodeInputNamesAsSet();
    callback(
      nodeInputNamesSet.has(value)
        ? this.props.intl.formatMessage(
            {
              defaultMessage: 'Input "{value}" already exists.',
              description: 'Validation message for inputs that already exist in node inputs list in MLflow Pipeline',
            },
            {
              value: value,
            },
          )
        : undefined,
    );
  };
    
  render() {
    const { isOpen, selectedConnection } = this.props;
    return (
      <GenericInputModal
        title='Edit Connection'
        okText='OK'
        isOpen={isOpen}
        handleSubmit={this.props.handleSubmit}
        onClose={this.props.onClose}
      >
        {/*@ts-expect-error */}
        <EditConnectionForm selectedConnection={selectedConnection} nodeInputNameValidator={this.nodeInputNameValidator}/>
      </GenericInputModal>
    );
  }
}

//@ts-expect-error
export const EditConnectionModal = injectIntl(EditConnectionModalImpl);
