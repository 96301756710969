import { Component } from 'react';
import { connect } from 'react-redux';
import { addUserBucketsApi, getUserBucketsApi, removeUserBucketsApi } from '../actions';
import { Table, Button, Popconfirm } from 'antd';
import { GenericInputModal } from '../../experiment-tracking/components/modals/GenericInputModal';
import { BucketConfigForm } from './modals/BucketConfigForm';
import { IconButton } from '../../common/components/IconButton';
import { getUUID } from '../../common/utils/ActionUtils';
import { Spinner } from '../../common/components/Spinner';
import RequestStateWrapper from '../../common/components/RequestStateWrapper';
import { PageContainer } from '../../common/components/PageContainer';
import { PageHeader } from '../../shared/building_blocks/PageHeader';

type Props = {
  bucketList: any[];
  addUserBucketsApi: (...args: any[]) => any;
  getUserBucketsApi: (...args: any[]) => any;
  removeUserBucketsApi: (...args: any[]) => any;
}

type State = any;

export class UserBucketImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      modelTitle: 'Add Bucket',
      showAddBucketModel: false,
      currentBucket: {},
      buckets: [],
    }
  }

  getUserBucketsApiId = getUUID();
  criticalInitialRequestIds = [this.getUserBucketsApiId];

  componentDidMount() {
    this.loadBucket();
  }

  loadBucket = () => {
    this.setState({ loading: true });
    this.props.getUserBucketsApi(this.getUserBucketsApiId).then(() => {
      this.setState({ loading: false });
    })
    .catch(() => {
      this.setState({ loading: false });
    });
  };

  openEditBucketModel = (bucket: any) => {
    this.setState({ currentBucket: bucket });
    this.setState({ modelTitle: "Edit Bucket" });
    this.setState({ showAddBucketModel: true });
  };

  openAddBucketModel = () => {
    this.setState({ modelTitle: "Add Bucket" });
    this.setState({ currentBucket: {} });
    this.setState({ showAddBucketModel: true });
  };
  
  closeAddBucketModel = () => {
    this.setState({ showAddBucketModel: false });
  };
  
  addBucket = async (values: any) => {
    await this.props.addUserBucketsApi(values).then(() => {
      this.setState({ currentBucket: {} });
      this.setState({ showAddBucketModel: false });
      this.loadBucket();
    }).catch((e: any) => {
      console.log("error", e);
    });
  }

  deleteBucket = (bucket: any) => {
    this.setState({ loading: true });
    this.props.removeUserBucketsApi({bucket: bucket}).then(() => {
      this.loadBucket();
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  getColumns = () => {
    const columns = [
      {
        title:'Bucket name',
        render: (row: any) => <span>{row}</span>,
      },
      {
        title:'IAM Role',
        render: () => <span>-</span>,
      },
      {
        title:'External ID',
        render: () => <span>-</span>,
      },
      {
        title: 'Action',
        className: 'table-tag-container',
        render: (row: any, index: number) => {
          return <Popconfirm
          title='Are you sure you want to delete this bucket'
          okText='Yes'
          cancelText='No'
          onConfirm={() => this.deleteBucket(row)}
        >
          <IconButton icon={<i className='far fa-trash-o' />} />
        </Popconfirm>
        },
      },
    ];
    return columns;
  };

  render() {
    const { loading, modelTitle, currentBucket } = this.state;
    const { bucketList } = this.props;
    return (
      <PageContainer data-test-id='user-password-change-container' usesFullHeight>
        <PageHeader title='Manage Bucket'>
          <Button
            style={{height: '30px'}}
            type={'primary'}
            onClick={this.openAddBucketModel}
            data-testid='add-bucket-button'
          >
            Add Bucket
          </Button>
        </PageHeader>
        <RequestStateWrapper requestIds={this.criticalInitialRequestIds}>
          <div>
          <Table
              className='parallels-template-table'
              dataSource={bucketList}
              columns={this.getColumns()}
              loading={loading && { indicator: <Spinner /> }}
              showSorterTooltip={false}
              locale={{ emptyText: <div> <span>No Buckets. <Button type="link" style={{padding: '0px'}} onClick={this.openAddBucketModel}> click here </Button> to add bucket.</span>  </div> }}
              pagination={{
                pageSize: 10,
              }}
            />
          <GenericInputModal
            title= {modelTitle}
            okText= {modelTitle == 'Add Bucket' ? 'Add' : 'Save' }
            isOpen= {this.state['showAddBucketModel']}
            handleSubmit= {this.addBucket}
            onClose= {this.closeAddBucketModel}
          >
            {/*@ts-expect-error */}
            <BucketConfigForm bucket={currentBucket}/>
          </GenericInputModal>
        </div>
        </RequestStateWrapper>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  const bucketList = state.user.buckets;

  return {
    bucketList,
  };
}

const mapDispatchToProps = {
  addUserBucketsApi,
  getUserBucketsApi,
  removeUserBucketsApi,
}

export const UserBucketPage = connect(mapStateToProps, mapDispatchToProps)(UserBucketImpl);