import { Component } from 'react';
import { GenericInputModal } from '../../../experiment-tracking/components/modals/GenericInputModal';
import { AddKwargForm } from './AddKwargForm';
import { injectIntl } from 'react-intl';

type Props = {
  kwarg: any[];
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  handleSubmit: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  innerRef: any;
  isOpen: boolean;
  title: string;
  okText: string;
  param: any;
}

export class AddKwargModalImpl extends Component<Props> {

  getNodekwargKeysAsSet = () =>
    new Set(this.props.kwarg.map((values) => values['key']));

  nodeKwargKeysValidator = (rule: any, value: any, callback: any) => {
    const nodekwargKeysSet = this.getNodekwargKeysAsSet();
    callback(
      nodekwargKeysSet.has(value)
        ? this.props.intl.formatMessage(
            {
              defaultMessage: 'Key "{value}" already exists.',
              description: 'Validation message for kwarg that already exist in node kwarg list in MLflow Pipeline',
            },
            {
              value: value,
            },
          )
        : value === undefined ? this.props.intl.formatMessage(
          {
            defaultMessage: 'Key is required.',
            description: 'Validation message for kwarg that already exist in node kwarg list in MLflow Pipeline',
          }
        ): value === '' ? this.props.intl.formatMessage(
          {
            defaultMessage: 'Key is required.',
            description: 'Validation message for kwarg that already exist in node kwarg list in MLflow Pipeline',
          }
        ) : undefined,
    );
  };

  render() {
    const { isOpen, title, param, okText, innerRef  } = this.props;
    return (
      <GenericInputModal
        title = {title}
        okText= {okText}
        isOpen={isOpen}
        handleSubmit={this.props.handleSubmit}
        onClose={this.props.onClose}
      > {/*@ts-expect-error */}
        <AddKwargForm param={param} />
      </GenericInputModal>
    );
  }
}

//@ts-expect-error
export const AddKwargModal = injectIntl(AddKwargModalImpl);
