import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Input, Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  innerRef: any;
  selectedConnection: any;
  nodeInputNameValidator: (...args: any[]) => any;
}

type State = any;

class EditConnectionFormComponent extends Component<Props, State> {

  state = {
    showCustomlamda: false,
  };

  componentDidUpdate(prevProps: any) {
    this.resetFields(prevProps);
  }

  resetFields = (prevProps: any) => {
    const formRef = this.props.innerRef;
    if (prevProps.selectedConnection.id !== this.props.selectedConnection.id) {
      // reset input field to reset displayed initialValue
      formRef.current.resetFields();
    }
  };

  changePartitioner = (value: any) => {
    if(value === 'custom') {
      this.setState({showCustomlamda : true});
    } else {
      this.setState({showCustomlamda : false});
    }
  }

  render() {
    const learnMoreLinkUrl = 'https://docs.concurrent-ai.org/files/free-helloworld/#add-node-to-dag';
    return (
      <Form ref={this.props.innerRef} layout='vertical' initialValues={this.props.selectedConnection} requiredMark='optional'>
        <Form.Item
            label='Enter input name'
            name='name'
            rules={[
              { validator: this.props.nodeInputNameValidator },{required: true, message: 'Input name is required.'}
            ]}
        >
            <Input 
            placeholder={this.props.intl.formatMessage({
                defaultMessage: 'Enter input name',
                description: 'Input placeholder to input name',
            })}
            autoFocus
            />
        </Form.Item>
        <Form.Item
          name='partitioner'
          label='Specify input partitioner'
          requiredMark='optional'
        >
          <Select placeholder="Select node partitioner" onChange={this.changePartitioner}>
            <Option key="null" value="null"> no partitioners </Option>
            <Option key="object" value="object"> object </Option>
            <Option key="directory" value="directory"> directory </Option>
            <Option key="custom" value="custom"> custom </Option>
          </Select>
        </Form.Item>

        {this.state.showCustomlamda ? <Form.Item
          name='lambda'
          label='Enter custom partition lambda code'          
        >
            <TextArea rows={4} placeholder={this.props.intl.formatMessage({
              defaultMessage: 'Enter custom partition lambda code',
              description: 'Input placeholder to enter lambda code',
            })} />
        </Form.Item> : null}
                
        <p className='create-modal-explanatory-text'>
          <FormattedMessage
            defaultMessage='once created, a connection can be edited anytime.&nbsp;'
            description='Text for form description on creating model in the model registry'
          />
          <FormattedMessage
            defaultMessage='<link>Learn more</link>'
            description='Learn more link on the form for creating model in the model registry'
            values={{
              link: (chunks) => (
                <a href={learnMoreLinkUrl} target='_blank'>
                  {chunks}
                </a>
              ),
            }}
          />
          .
        </p>
      </Form>
    );
  }
}

//@ts-expect-error
export const EditConnectionForm = injectIntl(EditConnectionFormComponent);
