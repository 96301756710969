import React from "react";
import { connect } from "react-redux";
import { getConcurrentStatusForRunApi, recoveryConcurrentApi } from '../actions';

import { ConcurrentStatusView } from './ConcurrentStatusView';
import { getUUID } from '../../common/utils/ActionUtils';
import { Spinner } from '../../common/components/Spinner';

import RequestStateWrapper from '../../common/components/RequestStateWrapper';
import { getStatusGraphElement, getConcurrentTemplateJson } from '../utils/TemplateUtils';
import Routes from '../../experiment-tracking/routes';

type Props = {
  getConcurrentStatusForRunApi: (...args: any[]) => any;
  recoveryConcurrentApi: (...args: any[]) => any;
  graphElement: any;
  concurrentID: string;
  concurrentExecutionId: string;
  concurrentTemplateJson: any;
  runUuid: string;
}

type State = any;

export class ConcurrentStatusPageImpl extends React.Component<Props, State> {

  state = {
    refreshLoading : false,
    recoveryLoading : false
  }

  getConcurrentStatusRequestId = getUUID();

  componentDidMount() {
    this.loadPage();
  }
  
  loadPage = () => {
    const { runUuid, concurrentExecutionId, concurrentID } = this.props;
    this.props.getConcurrentStatusForRunApi(concurrentID, concurrentExecutionId, this.getConcurrentStatusRequestId);
  }

  renderConcurrentStatusView = (isLoading: any, shouldRenderError: any) =>{
    if (isLoading) {
      return <Spinner />
    } else if(shouldRenderError) {
      return <div>
        <p css={classNames.wrapper}>
          <i className='fa fa-exclamation-triangle icon-fail' />
          <span> Something went wrong with this section. </span>
          <span>Concurrent info does not exist </span>
        </p>
      </div>
    } else {
      return <ConcurrentStatusView {...this.props} graphElement={this.props.graphElement} concurrentTemplateJson={this.props.concurrentTemplateJson} reloadPage={this.loadPage}/>
    }
  }

  render() {
    const requestIds = [this.getConcurrentStatusRequestId];    
    return (
    <RequestStateWrapper requestIds={requestIds}>
      {this.renderConcurrentStatusView}
    </RequestStateWrapper>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { runUuid, concurrentExecutionId, concurrentID } = ownProps;
  const graphElement = getStatusGraphElement(runUuid, state);
  const concurrentTemplateJson = getConcurrentTemplateJson(runUuid,state);
  return {
      runUuid, concurrentExecutionId, concurrentID, graphElement, concurrentTemplateJson
  };
};

const mapDispatchToProps = {
  getConcurrentStatusForRunApi,
  recoveryConcurrentApi
};

export const ConcurrentStatusPage = connect(mapStateToProps, mapDispatchToProps)(ConcurrentStatusPageImpl);

const classNames = {
  wrapper: {
    marginLeft: 50
  },
};
