import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgGridDashIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M1 1.75V4h1.5V2.5H4V1H1.75a.75.75 0 0 0-.75.75ZM15 14.25V12h-1.5v1.5H12V15h2.25a.75.75 0 0 0 .75-.75ZM12 1h2.25a.75.75 0 0 1 .75.75V4h-1.5V2.5H12V1ZM1.75 15H4v-1.5H2.5V12H1v2.25a.75.75 0 0 0 .75.75ZM10 2.5H6V1h4v1.5ZM6 15h4v-1.5H6V15ZM13.5 10V6H15v4h-1.5ZM1 6v4h1.5V6H1Z"
      />
    </svg>
  );
}
function GridDashIcon(props: IconProps) {
  return <Icon {...props} component={SvgGridDashIcon} />;
}
export default GridDashIcon;
