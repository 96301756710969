import * as Popover from '@radix-ui/react-popover';
import { useState, useEffect } from 'react';

import type { HTMLDataAttributes } from '../../design-system/types';
import { useDialogComboboxContext } from './hooks/useDialogComboboxContext';
import { DialogComboboxContextProvider } from './providers/DialogComboboxContext';

export interface DialogComboboxProps extends Popover.PopoverProps, HTMLDataAttributes {
  label: string | React.ReactNode;
  value?: string[];
  stayOpenOnSelection?: boolean;
  multiSelect?: boolean;
  emptyText?: string;
  scrollToSelectedElement?: boolean;
  rememberLastScrollPosition?: boolean;
}

export const DialogCombobox = ({
  children,
  label,
  value = [],
  open,
  emptyText,
  scrollToSelectedElement = true,
  rememberLastScrollPosition = false,
  ...props
}: DialogComboboxProps) => {
  // Used to avoid infinite loop when value is controlled from within the component (DialogComboboxOptionControlledList)
  // We can't remove setValue altogether because uncontrolled component users need to be able to set the value from root for trigger to update
  const [isControlled, setIsControlled] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string[]>(value);
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(open));
  const [contentWidth, setContentWidth] = useState<number | string | undefined>();
  const [textOverflowMode, setTextOverflowMode] = useState<'ellipsis' | 'multiline'>('multiline');

  useEffect(() => {
    if (
      ((!Array.isArray(selectedValue) || !Array.isArray(value)) && selectedValue !== value) ||
      (selectedValue && value && selectedValue.length === value.length && selectedValue.every((v, i) => v === value[i]))
    ) {
      return;
    }

    if (!isControlled) {
      setSelectedValue(value);
    }
  }, [value, isControlled, selectedValue]);

  return (
    <DialogComboboxContextProvider
      value={{
        label,
        value: selectedValue,
        setValue: setSelectedValue,
        setIsControlled,
        contentWidth,
        setContentWidth,
        textOverflowMode,
        setTextOverflowMode,
        isInsideDialogCombobox: true,
        multiSelect: props.multiSelect,
        stayOpenOnSelection: props.stayOpenOnSelection,
        isOpen,
        setIsOpen,
        emptyText,
        scrollToSelectedElement,
        rememberLastScrollPosition,
      }}
    >
      <Root open={open !== undefined ? open : isOpen} {...props}>
        {children}
      </Root>
    </DialogComboboxContextProvider>
  );
};

const Root = (props: Partial<DialogComboboxProps>) => {
  const { children, stayOpenOnSelection, multiSelect, ...restProps } = props;
  const { value, setIsOpen } = useDialogComboboxContext();

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  useEffect(() => {
    if (!stayOpenOnSelection && (typeof stayOpenOnSelection === 'boolean' || !multiSelect)) {
      setIsOpen(false);
    }
  }, [value, stayOpenOnSelection, multiSelect, setIsOpen]);

  return (
    <Popover.Root onOpenChange={handleOpenChange} {...restProps}>
      {children}
    </Popover.Root>
  );
};
