import {
  getBigIntJson,
  getJson,
  postJson,
} from '../common/utils/FetchUtils';

export class Services {

  static listConcurrent = (data: any) => 
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/listdag', data });

  static getConcurrent = (data: any) => 
    getJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/get', data });
  
  static searchConcurrent = (data: any) => 
    getJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/search', data });

  static renameConcurrent = (data: any) => 
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/rename', data });
 
  static createConcurrent = (data: any) => 
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/createparallel', data });
  
  static updateConcurrent = (data: any) => 
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/updateparallel', data });

  static saveConcurrent = (data: any) => 
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/createparallel', data });
  
  static runConcurrent = (data: any) => 
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/execdag', data });

  static getConcurrentExecution = (data: any) => 
    getBigIntJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/getdagexecinfo', data });

  static deleteConcurrent = (data: any) => 
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/delete', data });
     
  static getConcurrentTemplateForRun = (data: any) => 
    getBigIntJson({ relativeUrl: 'ajax-api/2.0/preview/mlflow/infinstor/get_dag_for_run', data });

  static setTag = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/settag', data });

  static deleteTag = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/removetag', data });

  static getConcurrentAuth = (data: any) => 
    getBigIntJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/concurrent/getconcurrentauth', data });

  static addConcurrentAuth = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/concurrent/addconcurrentauth', data });

  static getPeriodicRun = (data: any) => 
    getJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/list-periodicruns', data });
    
  static createPeriodicRun = (data: any) => 
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/add-mod-periodicrun', data });
  
  static deletePeriodicRun = (data: any) => 
    postJson({ relativeUrl: 'https://'+ window.ParallelsServer +'/api/2.0/mlflow/parallels/del-periodicrun', data });
}