import type { UseComboboxReturnValue } from 'downshift';
import { forwardRef } from 'react';

import { Checkbox } from '../Checkbox';
import { useDesignSystemTheme } from '../Hooks';
import { InfoTooltip } from '../Tooltip';
import {
  getComboboxOptionItemWrapperStyles,
  HintRow,
  getInfoIconStyles,
  getCheckboxStyles,
} from '../_shared_/Combobox';
import { getMenuItemStyles, type TypeaheadComboboxMenuItemProps } from './TypeaheadComboboxMenuItem';

export interface TypeaheadComboboxCheckboxItemProps<T> extends TypeaheadComboboxMenuItemProps<T> {
  comboboxState: UseComboboxReturnValue<T>;
  selectedItems: T[];
  _TYPE?: string;
}

export const TypeaheadComboboxCheckboxItem = forwardRef<HTMLLIElement, TypeaheadComboboxCheckboxItemProps<any>>(
  (
    {
      item,
      index,
      comboboxState,
      selectedItems,
      textOverflowMode = 'multiline',
      isDisabled,
      disabledReason,
      hintContent,
      children,
      ...restProps
    },
    ref,
  ) => {
    const { highlightedIndex, getItemProps } = comboboxState;
    const isHighlighted = highlightedIndex === index;
    const { theme } = useDesignSystemTheme();
    const isSelected = selectedItems.includes(item);

    return (
      <li
        ref={ref}
        role="option"
        aria-selected={isSelected}
        disabled={isDisabled}
        css={[getComboboxOptionItemWrapperStyles(theme), getMenuItemStyles(theme, isHighlighted, isDisabled)]}
        {...getItemProps({
          item,
          index,
          disabled: isDisabled,
          onMouseUp: (e) => {
            e.stopPropagation();
            restProps.onMouseUp?.(e);
          },
        })}
        {...restProps}
      >
        <Checkbox
          disabled={isDisabled}
          isChecked={isSelected}
          css={getCheckboxStyles(theme, textOverflowMode)}
          tabIndex={-1}
          // Needed because Antd handles keyboard inputs as clicks
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <label>
            {isDisabled && disabledReason ? (
              <div css={{ display: 'flex' }}>
                <div>{children}</div>
                <div css={getInfoIconStyles(theme)}>
                  <InfoTooltip title={disabledReason} />
                </div>
              </div>
            ) : (
              children
            )}
            <HintRow disabled={isDisabled}>{hintContent}</HintRow>
          </label>
        </Checkbox>
      </li>
    );
  },
);

TypeaheadComboboxCheckboxItem.defaultProps = {
  _TYPE: 'TypeaheadComboboxCheckboxItem',
};

export default TypeaheadComboboxCheckboxItem;
