import * as Popover from '@radix-ui/react-popover';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useDesignSystemTheme } from '../Hooks';
import { useDesignSystemContext } from '../Hooks/useDesignSystemContext';
import type { WithLoadingState } from '../LoadingState/LoadingState';
import { EmptyResults, LoadingSpinner, getComboboxContentWrapperStyles } from '../_shared_/Combobox';
import type { HTMLDataAttributes } from '../types';
import { useDialogComboboxContext } from './hooks/useDialogComboboxContext';

export interface DialogComboboxContentProps extends Popover.PopoverContentProps, HTMLDataAttributes, WithLoadingState {
  width?: number | string;
  loading?: boolean;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  side?: 'top' | 'bottom';
  matchTriggerWidth?: boolean;
  textOverflowMode?: 'ellipsis' | 'multiline';
}

export const DialogComboboxContent = forwardRef<HTMLDivElement, DialogComboboxContentProps>(
  (
    {
      children,
      loading,
      loadingDescription = 'DialogComboboxContent',
      matchTriggerWidth,
      textOverflowMode,
      maxHeight = 'var(--radix-popover-content-available-height)',
      maxWidth,
      minHeight,
      minWidth = 240,
      width,
      align = 'start',
      side = 'bottom',
      sideOffset = 4,
      ...restProps
    },
    forwardedRef,
  ) => {
    const { theme } = useDesignSystemTheme();
    const {
      label,
      isInsideDialogCombobox,
      contentWidth,
      setContentWidth,
      textOverflowMode: contextTextOverflowMode,
      setTextOverflowMode,
      multiSelect,
      isOpen,
      rememberLastScrollPosition,
    } = useDialogComboboxContext();
    const { getPopupContainer } = useDesignSystemContext();
    const [lastScrollPosition, setLastScrollPosition] = useState<number>(0);
    if (!isInsideDialogCombobox) {
      throw new Error('`DialogComboboxContent` must be used within `DialogCombobox`');
    }

    const contentRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => contentRef.current as HTMLDivElement);

    useEffect(() => {
      if (rememberLastScrollPosition) {
        if (!isOpen && contentRef.current) {
          setLastScrollPosition(contentRef.current.scrollTop);
        } else {
          // Wait for the popover to render and scroll to the last scrolled position
          const interval = setInterval(() => {
            if (contentRef.current) {
              // Verify if the popover's content can be scrolled to the last scrolled position
              if (lastScrollPosition && contentRef.current.scrollHeight >= lastScrollPosition) {
                contentRef.current.scrollTo({ top: lastScrollPosition, behavior: 'smooth' });
              }
              clearInterval(interval);
            }
          }, 50);

          return () => clearInterval(interval);
        }
      }

      return;
    }, [isOpen, rememberLastScrollPosition, lastScrollPosition]);

    useEffect(() => {
      if (contentWidth !== width) {
        setContentWidth(width);
      }
    }, [width, contentWidth, setContentWidth]);

    useEffect(() => {
      if (textOverflowMode !== contextTextOverflowMode) {
        setTextOverflowMode(textOverflowMode ? textOverflowMode : 'multiline');
      }
    }, [textOverflowMode, contextTextOverflowMode, setTextOverflowMode]);

    return (
      <Popover.Portal container={getPopupContainer && getPopupContainer()}>
        <Popover.Content
          aria-label={`${label} options`}
          aria-busy={loading}
          role="listbox"
          aria-multiselectable={multiSelect}
          css={getComboboxContentWrapperStyles(theme, {
            maxHeight,
            maxWidth,
            minHeight,
            minWidth,
            width: matchTriggerWidth ? 'var(--radix-popover-trigger-width)' : width,
          })}
          align={align}
          side={side}
          sideOffset={sideOffset}
          {...restProps}
          ref={contentRef}
        >
          <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
            {loading ? (
              <LoadingSpinner aria-label="Loading" alt="Loading spinner" loadingDescription={loadingDescription} />
            ) : children ? (
              children
            ) : (
              <EmptyResults />
            )}
          </div>
        </Popover.Content>
      </Popover.Portal>
    );
  },
);
