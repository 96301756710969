import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageContainer } from '../../common/components/PageContainer';
import RequestStateWrapper, { triggerError } from '../../common/components/RequestStateWrapper';
import { Spinner } from '../../common/components/Spinner';
import { Spacer } from '../../shared/building_blocks/Spacer';
import { Link } from 'react-router-dom';
import { Button } from '@databricks/design-system';
import { ErrorView } from '../../common/components/ErrorView';
import { getUUID } from '../../common/utils/ActionUtils';
import Utils  from '../../common/utils/Utils';
import { concurrentsListPageRoute, getConcurrentPageRoute } from "../routes";
import { createConcurrentApi, updateConcurrentApi, runConcurrentApi, getConcurrentApi } from '../actions';
import { searchExperimentsApi, searchRunsApi } from '../../experiment-tracking/actions';
import { PageHeader } from '../../shared/building_blocks/PageHeader';
import { FormattedMessage } from 'react-intl';
import { ConcurrentTemplateView } from './ConcurrentTemplateView';
import { message } from 'antd';
import Routers from '../../experiment-tracking/routes';
import './ConcurrentTemplateView.css';
import { getKubeClusters } from '../utils/TemplateUtils';
import { getExperiments } from '../../experiment-tracking/reducers/Reducers';
import { CreateParallelModal } from './modal/CreateParallelModal';
import { WithRouterNextProps, withRouterNext } from '../../common/utils/withRouterNext';

type Props = WithRouterNextProps & {
  history: any;
  concurrentID: string;
  concurrent: any;
  searchExperimentsApi: (...args: any[]) => any;
  searchRunsApi: (...args: any[]) => any;
  updateConcurrentApi: (...args: any[]) => any;
  createConcurrentApi: (...args: any[]) => any;
  getConcurrentApi: (...args: any[]) => any;
  runConcurrentApi: (...args: any[]) => any;
  clusters: any[];
  experimentNames: string[]
  concurrentAction: string;
  experiments: any[];
  parallels: any[];
}

type State = any;

export class ConcurrentTemplatePageImpl extends React.Component<Props, State> {
  child: React.RefObject<any>;
  constructor(props: Props) {
      super(props);
      this.child = React.createRef();
      this.state = {
        concurrentActionLoading: false,
        showCreateConcurrentModal: false,
        edge: [],
        node: []
      };
    }

  getConcurrentApiId = getUUID();
  initialRequestIds = [this.getConcurrentApiId];

  loadData = () => {
    const { concurrentID } = this.props;
    this.props.searchExperimentsApi();
    if(concurrentID !== 'new_concurrent') {
      this.props.getConcurrentApi(concurrentID, this.getConcurrentApiId);
    }
  };

  handleConcurrentAction = () => {
    this.setState({ concurrentActionLoading: true });
    const { concurrentAction, concurrent } = this.props;
    this.child.current.saveGraph().then((dagJson: any) => {
      dagJson.id = concurrent.parallel_id;
      dagJson.name = concurrent.parallel_name;
      if (Array.isArray(dagJson.node) && dagJson.node.length > 0) {
        if( concurrentAction == 'run'){
          this.props.runConcurrentApi(concurrent.parallel_id, dagJson).then((apiResponse: any) => {
            this.setState({ concurrentActionLoading: false });
            let parentRunId = apiResponse.value.parentRunId;
            this.props.navigate(Routers.getRunPageRoute(parentRunId.split("-")[0], parentRunId));
          }).catch((e: any) => {
            this.setState({ concurrentActionLoading: false });
            Utils.logErrorAndNotifyUser(e);
          });
        } else if( concurrentAction == 'edit'){
          dagJson.id = concurrent.parallel_id;
          let description = "";
          if(concurrent.description) {
            description = concurrent.description;
          }
          this.props.updateConcurrentApi(concurrent.parallel_id, dagJson, description).then(() => {
            this.setState({ concurrentActionLoading: false });
            this.props.navigate(getConcurrentPageRoute(concurrent.dagid));
          });
        } else if( concurrentAction == 'create'){
          this.setState({edge : dagJson.edge });
          this.setState({node : dagJson.node });
          this.setState({showCreateConcurrentModal : true });
        } 
      } else {
        message.info('There are no nodes in this concurrent');
        this.setState({ concurrentActionLoading: false });
      }
    })
  }

  componentDidMount() {
    this.loadData();
  }

  handleCloseCreateConcurrentModal = () => {
    this.setState({ concurrentActionLoading: false });
    this.setState({showCreateConcurrentModal : false });
  }

  craeteConcurrent = async (values: any) => {
    const dagJson = {
      name: values.name,
      node: this.state['node'],
      edge: this.state['edge'],
    };
    let description = "";
    if(values.description) {
      description = values.description;
    }
    await this.props.createConcurrentApi(values.name, dagJson, description).then((apiResponse: any) => {
      const parallel_id = apiResponse.value.parallel_id;
      this.setState({ concurrentActionLoading: false });
      if(parallel_id) {
        this.props.navigate(getConcurrentPageRoute(apiResponse.value.parallel_id));
      } else {
        message.error('Create concurrent failed. please try again');
      }
    });
  }

  render() {
    const { concurrentAction, history, concurrent, concurrentID, clusters, experiments, parallels } = this.props;
    let breadcrumbs = [
      <Link to={concurrentsListPageRoute}>
        <FormattedMessage
          defaultMessage='Concurrents Templates'
          description='Text for link back to concurrent page under the header on the paralel view page'
        />
      </Link>
    ];
    
    if(concurrentID === 'new_concurrent') {
      return(
        <PageContainer>
          <div className='concurrent-template-view-content'>
            <PageHeader title={concurrent.dagName} breadcrumbs={breadcrumbs}>
            </PageHeader>
            <div className='descriptionContainer'>
              <div className='description-section'>
                <Spacer size={1} direction='horizontal'>
                  {concurrent.description ? <p> {concurrent.description} </p>: <p> </p> }
                </Spacer>
              </div>
              <div className='des-action-button'>
                <Button type='primary' size="middle" onClick = {this.handleConcurrentAction} loading={this.state['concurrentActionLoading']}>
                  {concurrentAction == "run" ? <FormattedMessage
                      defaultMessage='Run Template'
                      description='Run Template'
                    /> : concurrentAction == "PeriodicRun" ? <FormattedMessage
                      defaultMessage='Save PeriodicRun'
                      description='Save PeriodicRun'
                  /> : <FormattedMessage
                    defaultMessage='Save Template'
                    description='Save Template'
                  />}
                </Button>
              </div>
            </div>
            <ConcurrentTemplateView 
              ref= {this.child} 
              graphElement= {JSON.parse(concurrent.dagJson)}
              clusters= {clusters} 
              experiments= {experiments}
              concurrentAction= {concurrentAction}/>
          </div> 
          <CreateParallelModal
            //@ts-expect-error
            isOpen= {this.state['showCreateConcurrentModal']}
            onClose= {this.handleCloseCreateConcurrentModal}
            handleSubmit= {this.craeteConcurrent}
            parallels= {parallels}
          />  
        </PageContainer>
      );
    } else {
      
      if(concurrent) {
        breadcrumbs = [
          <Link to={concurrentsListPageRoute}>
            <FormattedMessage
              defaultMessage='Concurrents Templates'
              description='Text for link back to concurrent page under the header on the paralel view page'
            />
          </Link>,
          <Link to={getConcurrentPageRoute(concurrent.dagid)}>
            { concurrent.dagName }
          </Link>
        ];
      }
      return(
        <PageContainer>
          <RequestStateWrapper requestIds={this.initialRequestIds}>
            {(loading: any, hasError: any, requests: any): any => {
              if (hasError) {
                triggerError(requests);
              } else if (loading) {
                return <Spinner />;
              } else if (concurrent) {
                // Null check to prevent NPE after delete operation
                return (
                  <div className='concurrent-template-view-content'>
                    <PageHeader title={concurrent.dagName} breadcrumbs={breadcrumbs}>
                    </PageHeader>
                    <div className='descriptionContainer'>
                      <div className='description-section'>
                        <Spacer size={1} direction='horizontal'>
                          {concurrent.description ? <p> {concurrent.description} </p>: <p> </p> }
                        </Spacer>
                      </div>
                      <div className='des-action-button'>
                        <Button type='primary' size="middle" onClick = {this.handleConcurrentAction} loading={this.state['concurrentActionLoading']}>
                          {concurrentAction == "run" ? <FormattedMessage
                              defaultMessage='Run Template'
                              description='Run Template'
                            /> : concurrentAction == "PeriodicRun" ? <FormattedMessage
                              defaultMessage='Save PeriodicRun'
                              description='Save PeriodicRun'
                          /> : <FormattedMessage
                            defaultMessage='Save Template'
                            description='Save Template'
                          />}
                        </Button>
                      </div>
                    </div>
                    <ConcurrentTemplateView 
                      ref={this.child} 
                      graphElement={JSON.parse(concurrent.dagJson)}
                      clusters={clusters} 
                      experiments={experiments}
                      concurrentAction={concurrentAction}/>
                  </div>
                );
              } else {
                return (
                  <ErrorView
                    statusCode={400}
                    subMessage='Concurrent does not exist'
                    fallbackHomePageReactRoute={concurrentsListPageRoute}
                  />
                );
              }
            }}
          </RequestStateWrapper>
        </PageContainer>
      );
    }
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const concurrentID = decodeURIComponent(ownProps.params.concurrentID);
  let concurrent = state.entities.concurrentByID[concurrentID];
  const parallels= Object.values(state.entities.concurrentByID);
  const concurrentAction = decodeURIComponent(ownProps.params.action);
  // const clusters = getKubeClusters(state.user.cluster.kube_clusters);
  if(concurrentID === 'new_concurrent') {
    concurrent = {
      "parallel_id": "new_concurrent",
      "dagid": "new_concurrent",
      "dagName": "New concurrent Template",
      "dagJson": "{\"id\": \"new_concurrent\", \"name\": \"new_concurrent\", \"node\": [], \"edge\": []}",
      "creation_time": "1665543027246",
      "update_time": "1666866803501",
      "description": "",
      "tags": []
    }
  }
  const clusters: any[] = [];
  const experiments = getExperiments(state);
  return {
    concurrentID,
    concurrent,
    concurrentAction,
    clusters,
    experiments,
    parallels,
  };
};
  
const mapDispatchToProps = {
  getConcurrentApi,
  createConcurrentApi,
  updateConcurrentApi,
  runConcurrentApi,
  searchExperimentsApi,
  searchRunsApi
};
  
export const ConcurrentTemplatePage = withRouterNext(
  connect(mapStateToProps, mapDispatchToProps)(ConcurrentTemplatePageImpl)
);