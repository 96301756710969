import type { ExtendedRefs } from '@floating-ui/react';
import { createContext } from 'react';

export interface TypeaheadComboboxContextType {
  isInsideTypeaheadCombobox: boolean;
  multiSelect?: boolean;
  floatingUiRefs?: ExtendedRefs<Element>;
  floatingStyles?: React.CSSProperties;
}

const typeaheadComboboxContextDefaults: TypeaheadComboboxContextType = {
  isInsideTypeaheadCombobox: false,
  multiSelect: false,
};

export const TypeaheadComboboxContext = createContext<TypeaheadComboboxContextType>(typeaheadComboboxContextDefaults);

export const TypeaheadComboboxContextProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: TypeaheadComboboxContextType;
}) => {
  return <TypeaheadComboboxContext.Provider value={value}>{children}</TypeaheadComboboxContext.Provider>;
};
