import classnames from 'classnames';
import type { CSSProperties } from 'react';
import React, { createContext, forwardRef } from 'react';

import { useDesignSystemFlags, useDesignSystemTheme } from '../Hooks';
import type { HTMLDataAttributes } from '../types';
import tableStyles from './tableStyles';

export const TableContext = createContext<{
  size: 'default' | 'small';
  someRowsSelected?: boolean;
  grid?: boolean;
}>({
  size: 'default',
  grid: false,
});

export interface TableProps extends HTMLDataAttributes {
  size?: 'default' | 'small';
  /** Are any rows currently selected? You must specify this if using `TableRowSelectCell` in your table. */
  someRowsSelected?: boolean;
  /** Style property */
  style?: CSSProperties;
  /** Class name property */
  className?: string;
  /** Content slot for providing a pagination component */
  pagination?: React.ReactNode;
  /** Content slot for providing an Empty component */
  empty?: React.ReactNode;
  /** Child nodes for the table */
  children?: React.ReactNode | React.ReactNode[];
  /** Is this `Table` scrollable? Only use if `Table` is placed within a container of determinate height. */
  scrollable?: boolean;
  /** Adds grid styling to the table (e.g. border around cells and no hover styles) */
  grid?: boolean;
}

export const Table = forwardRef<HTMLDivElement, TableProps>(function Table(
  {
    children,
    size = 'default',
    someRowsSelected,
    style,
    pagination,
    empty,
    className,
    scrollable = false,
    grid = false,
    ...rest
  },
  ref,
): JSX.Element {
  const { theme } = useDesignSystemTheme();
  const flags = useDesignSystemFlags();

  return (
    <TableContext.Provider value={{ size, someRowsSelected, grid }}>
      <div
        {...rest}
        // This is a performance optimization; we want to statically create the styles for the table,
        // but for the dynamic theme values, we need to use CSS variables.
        // See: https://emotion.sh/docs/best-practices#advanced-css-variables-with-style
        style={{
          ...style,
          ['--table-background-color' as any]: theme.colors.backgroundPrimary,
          ['--table-header-active-color' as any]: theme.colors.actionDefaultTextPress,
          ['colorScheme' as any]: theme.isDarkMode ? 'dark' : undefined,
          // This hex is pulled directly from the old table as a temporary style-matching measure.
          ['--table-header-background-color' as any]:
            flags.USE_UPDATED_TABLE_STYLES || theme.isDarkMode ? theme.colors.backgroundPrimary : '#F2F5F7',
          ['--table-header-focus-color' as any]: theme.colors.actionDefaultTextHover,
          ['--table-header-sort-icon-color' as any]: theme.colors.textSecondary,
          ['--table-header-text-color' as any]: theme.colors.actionDefaultTextDefault,
          ['--table-row-hover' as any]: theme.colors.tableRowHover,
          ['--table-separator-color' as any]: theme.colors.borderDecorative,
          ['--table-resize-handle-color' as any]: flags.USE_UPDATED_TABLE_STYLES
            ? theme.colors.borderDecorative
            : theme.colors.grey400,
          ['--table-spacing-md' as any]: `${theme.spacing.md}px`,
          ['--table-spacing-sm' as any]: `${theme.spacing.sm}px`,
          ['--table-spacing-xs' as any]: `${theme.spacing.xs}px`,
        }}
        css={tableStyles.tableWrapper}
        className={classnames(
          {
            'table-isScrollable': scrollable,
            'table-isGrid': grid,
          },
          className,
        )}
      >
        <div role="table" ref={ref} css={tableStyles.table}>
          {children}
          {empty && <div css={{ padding: theme.spacing.lg }}>{empty}</div>}
        </div>
        {!empty && pagination && <div css={tableStyles.paginationContainer}>{pagination}</div>}
      </div>
    </TableContext.Provider>
  );
});
