import { Component } from 'react';
import { GenericInputModal } from '../../../experiment-tracking/components/modals/GenericInputModal';
import { EditNodeInputForm } from './EditNodeInputForm';
import { injectIntl } from 'react-intl';

type Props = {
  input: any[];
  selectedInput: any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  isOpen: boolean;
  handleSubmit: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
}

export class EditNodeInputModalImpl extends Component<Props> {
 
  getNodeInputNamesAsSet = () => {
    let inputList = this.props.input.filter(input => input.name !== this.props.selectedInput.name);
    return new Set(inputList.map((values) => values['name']));
  }

  nodeInputNameValidator = (rule: any, value: any, callback: any) => {
    const nodeInputNamesSet = this.getNodeInputNamesAsSet();
    callback(
      nodeInputNamesSet.has(value)
        ? this.props.intl.formatMessage(
            {
              defaultMessage: 'Input "{value}" already exists.',
              description: 'Validation message for inputs that already exist in node inputs list in MLflow Pipeline',
            },
            {
              value: value,
            },
          )
        : undefined,
    );
  };

  render() {
    const { isOpen } = this.props;

    return (
      <GenericInputModal
        title='Edit Node Input'
        isOpen={isOpen}
        okText= 'OK'
        handleSubmit={this.props.handleSubmit}
        onClose={this.props.onClose}
      >{/*@ts-expect-error */}
        <EditNodeInputForm nodeInputNameValidator={this.nodeInputNameValidator} action = 'edit' {...this.props}/>
      </GenericInputModal>
    );
  }
}

//@ts-expect-error
export const EditNodeInputModal = injectIntl(EditNodeInputModalImpl);
