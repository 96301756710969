import type { Interpolation, SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Typography as AntDTypography } from 'antd';
import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

import type { Theme } from '../../theme';
import { DesignSystemAntDConfigProvider } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks';
import { NewWindowIcon } from '../Icon';
import type { DangerouslySetAntdProps, HTMLDataAttributes } from '../types';
import { safex } from '../utils/safex';

type AntDLinkProps = ComponentProps<typeof AntDTypography['Link']>;

export interface LinkProps extends AntDLinkProps, DangerouslySetAntdProps<AntDLinkProps>, HTMLDataAttributes {
  /**
   * Configures a link to be opened in a new tab by setting `target` to `'_blank'`
   * and `rel` to `'noopener noreferrer'`, which is necessary for security, and
   * rendering an "external link" icon next to the link when `true`.
   */
  openInNewTab?: boolean;
}

const getLinkStyles = (theme: Theme, clsPrefix: string): SerializedStyles => {
  const centerIcons = safex('databricks.fe.designsystem.centerIconsInText', false);
  const classTypography = `.${clsPrefix}-typography`;

  const styles: Interpolation<Theme> = {
    [`&${classTypography}, &${classTypography}:focus`]: {
      color: theme.colors.actionTertiaryTextDefault,
    },

    [`&${classTypography}:hover, &${classTypography}:hover .anticon`]: {
      color: theme.colors.actionTertiaryTextHover,
      textDecoration: 'underline',
    },

    [`&${classTypography}:active, &${classTypography}:active .anticon`]: {
      color: theme.colors.actionTertiaryTextPress,
      textDecoration: 'underline',
    },

    [`&${classTypography}:focus-visible`]: {
      textDecoration: 'underline',
    },

    '.anticon': {
      fontSize: 12,
      ...(centerIcons ? { verticalAlign: 'baseline' } : {}),
    },
  };

  return css(styles);
};

const getEllipsisNewTabLinkStyles = (): SerializedStyles => {
  const styles: Interpolation<Theme> = {
    paddingRight: 'calc(2px + 1em)', // 1em for icon
    position: 'relative',
  };

  return css(styles);
};

const getIconStyles = (theme: Theme): SerializedStyles => {
  const styles: Interpolation<Theme> = {
    marginLeft: 2,
    color: theme.colors.actionTertiaryTextDefault,
  };

  return css(styles);
};

const getEllipsisIconStyles = (useNewIcons?: boolean): SerializedStyles => {
  const styles: Interpolation<Theme> = {
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    ...(useNewIcons && {
      fontSize: 12,
    }),
  };

  return css(styles);
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link({ dangerouslySetAntdProps, ...props }, ref) {
  const { children, openInNewTab, ...restProps } = props;
  const { theme, classNamePrefix } = useDesignSystemTheme();

  const newTabProps = {
    rel: 'noopener noreferrer',
    target: '_blank',
  };

  const linkProps = openInNewTab ? { ...restProps, ...newTabProps } : { ...restProps };

  const linkStyles =
    props.ellipsis && openInNewTab
      ? [getLinkStyles(theme, classNamePrefix), getEllipsisNewTabLinkStyles()]
      : getLinkStyles(theme, classNamePrefix);
  const iconStyles = props.ellipsis ? [getIconStyles(theme), getEllipsisIconStyles()] : getIconStyles(theme);

  return (
    <DesignSystemAntDConfigProvider>
      <AntDTypography.Link
        aria-disabled={linkProps.disabled}
        css={linkStyles}
        ref={ref}
        {...linkProps}
        {...dangerouslySetAntdProps}
      >
        {children}
        {openInNewTab ? <NewWindowIcon css={iconStyles} {...newTabProps} /> : null}
      </AntDTypography.Link>
    </DesignSystemAntDConfigProvider>
  );
});
