import { css, keyframes } from '@emotion/react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { useRef } from 'react';

import { Button } from '../Button';
import { ApplyDesignSystemContextOverrides } from '../DesignSystemProvider';
import { useDesignSystemContext } from '../Hooks/useDesignSystemContext';
import { useDesignSystemTheme } from '../Hooks/useDesignSystemTheme';
import { CloseIcon } from '../Icon';
import { Spacer } from '../Spacer';
import { Typography } from '../Typography';

export interface DrawerContentProps {
  /** Contents displayed in the drawer */
  children: React.ReactNode;

  /**
   * Drawer header to be announced when the dialog is opened
   * If passing in a string styling will be provided, otherwise caller is responsible for proper title styling
   **/
  title: React.ReactNode;

  /**
   * The content width with a min width of 320 and a max width of 90vw
   * @default 320
   */
  width?: number | string;

  /**
   * The layout direction, on which side the drawer will appear
   * @default 'right'
   */
  position?: 'left' | 'right';

  /**
   * Provide a footer; using this property will ensure the correct scrolling behavior
   * @default 'undefined'
   */
  footer?: React.ReactNode;

  /**
   * Delegates all content scroll behavior to the caller if true
   *    Disable the default scroll drop shadow
   *    Hide the vertical content overflow
   *    Sets content right padding to 0 to leave room for caller to do so for proper scrollbar placement
   * @default false
   */
  useCustomScrollBehavior?: boolean;

  /**
   * If true the content of the Drawer will take up all available vertical space.
   * This is to keep the footer at the bottom of the drawer
   * @default false
   */
  expandContentToFullHeight?: boolean;

  /**
   * Disable auto focus on open
   * @default false
   */
  disableOpenAutoFocus?: boolean;

  /**
   * If true, the drawer and the backdrop will both be hidden. They will remain mounted, but not visible.
   * @default false
   */
  seeThrough?: boolean;

  /**
   * Event handler called when an interaction (pointer or focus event) happens outside the bounds of the component.
   * It can be prevented by calling event.preventDefault.
   */
  onInteractOutside?: DialogPrimitive.DialogContentProps['onInteractOutside'];

  /**
   * If true, the "x" icon in the header will be hidden
   * @default false
   */
  hideClose?: boolean;
}

const DEFAULT_WIDTH = 320;
const MIN_WIDTH = 320;
const MAX_WIDTH = '90vw';
const DEFAULT_POSITION = 'right';
const ZINDEX_OVERLAY = 10;
const ZINDEX_CONTENT = ZINDEX_OVERLAY + 10;

export const Content = ({
  children,
  footer,
  title,
  width,
  position: positionOverride,
  useCustomScrollBehavior,
  expandContentToFullHeight,
  disableOpenAutoFocus,
  onInteractOutside,
  seeThrough,
  hideClose,
}: DrawerContentProps) => {
  const { getPopupContainer } = useDesignSystemContext();
  const { theme } = useDesignSystemTheme();
  const horizontalContentPadding = theme.spacing.lg;
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const position = positionOverride ?? DEFAULT_POSITION;
  const overlayShow =
    position === 'right'
      ? keyframes({
          '0%': { transform: 'translate(100%, 0)' },
          '100%': { transform: 'translate(0, 0)' },
        })
      : keyframes({
          '0%': { transform: 'translate(-100%, 0)' },
          '100%': { transform: 'translate(0, 0)' },
        });

  const dialogPrimitiveContentStyle = css({
    color: theme.colors.textPrimary,
    backgroundColor: theme.colors.backgroundPrimary,
    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    position: 'fixed',
    top: 0,
    left: position === 'left' ? 0 : undefined,
    right: position === 'right' ? 0 : undefined,
    boxSizing: 'border-box',
    width: width ?? DEFAULT_WIDTH,
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
    zIndex: theme.options.zIndexBase + ZINDEX_CONTENT,
    height: '100vh',
    paddingTop: theme.spacing.md,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    overflow: 'hidden',
    '&:focus': { outline: 'none' },
    '@media (prefers-reduced-motion: no-preference)': {
      animation: `${overlayShow} 350ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
  });

  return (
    <DialogPrimitive.Portal container={getPopupContainer && getPopupContainer()}>
      <DialogPrimitive.Overlay
        css={{
          backgroundColor: theme.colors.overlayOverlay,
          position: 'fixed',
          inset: 0,
          // needed so that it covers the PersonaNavSidebar
          zIndex: theme.options.zIndexBase + ZINDEX_OVERLAY,
          opacity: seeThrough ? 0 : 1,
        }}
      />
      <DialogPrimitive.DialogContent
        css={dialogPrimitiveContentStyle}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          opacity: seeThrough ? 0 : 1,
        }}
        aria-hidden={seeThrough}
        ref={contentContainerRef}
        onOpenAutoFocus={(event) => {
          if (disableOpenAutoFocus) {
            event.preventDefault();
          }
        }}
        onInteractOutside={onInteractOutside}
      >
        <ApplyDesignSystemContextOverrides getPopupContainer={() => contentContainerRef.current ?? document.body}>
          <div
            css={{
              flexGrow: 0,
              flexShrink: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: horizontalContentPadding,
              paddingLeft: horizontalContentPadding,
              marginBottom: theme.spacing.sm,
            }}
          >
            <DialogPrimitive.Title
              title={typeof title === 'string' ? title : undefined}
              asChild={typeof title === 'string'}
              css={{
                flexGrow: 1,
                marginBottom: 0,
                marginTop: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {typeof title === 'string' ? (
                <Typography.Title level={2} withoutMargins ellipsis>
                  {title}
                </Typography.Title>
              ) : (
                title
              )}
            </DialogPrimitive.Title>
            {!hideClose && (
              <DialogPrimitive.Close asChild css={{ flexShrink: 1, marginLeft: theme.spacing.xs }}>
                <Button aria-label="Close" icon={<CloseIcon />} />
              </DialogPrimitive.Close>
            )}
          </div>
          <div
            css={{
              // in order to have specific content in the drawer scroll with fixed title
              // hide overflow here and remove padding on the right side; content will be responsible for setting right padding
              // so that the scrollbar will appear in the padding right gutter
              paddingRight: useCustomScrollBehavior ? 0 : horizontalContentPadding,
              paddingLeft: horizontalContentPadding,
              overflowY: useCustomScrollBehavior ? 'hidden' : 'auto',
              height: expandContentToFullHeight ? '100%' : undefined,
              ...(theme.isDarkMode === false && !useCustomScrollBehavior
                ? {
                    // Achieves an inner shadow on the content, but only when there is more left to scroll. When the content fits
                    // in the container without scrolling, no shadow will be shown.
                    // Taken from: https://css-tricks.com/scroll-shadows-with-javascript/
                    background: `linear-gradient(
                    white 30%,
                    rgba(255, 255, 255, 0)
                  ) center top,
  
                  linear-gradient(
                    rgba(255, 255, 255, 0),
                    white 70%
                  ) center bottom,
      
                  radial-gradient(
                    farthest-side at 50% 0,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0)
                  ) center top,
      
                  radial-gradient(
                    farthest-side at 50% 100%,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0)
                  ) center bottom`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
                    backgroundAttachment: 'local, local, scroll, scroll',
                    backgroundOrigin: 'content-box',
                  }
                : {}),
            }}
          >
            {children}
            {!footer && <Spacer size="lg" />}
          </div>

          {footer && (
            <div
              style={{
                paddingTop: theme.spacing.md,
                paddingRight: horizontalContentPadding,
                paddingLeft: horizontalContentPadding,
                paddingBottom: theme.spacing.lg,
                flexGrow: 0,
                flexShrink: 1,
              }}
            >
              {footer}
            </div>
          )}
        </ApplyDesignSystemContextOverrides>
      </DialogPrimitive.DialogContent>
    </DialogPrimitive.Portal>
  );
};

export function Root(props: Pick<DialogPrimitive.DialogProps, 'onOpenChange' | 'children' | 'open' | 'modal'>) {
  return <DialogPrimitive.Root {...props} />;
}

export function Trigger(props: Omit<DialogPrimitive.DialogTriggerProps, 'asChild'>) {
  return <DialogPrimitive.Trigger asChild {...props} />;
}
