import React from 'react';
import './GatewayRouteListView.css';
import Utils from '../../common/utils/Utils';
import LocalStorageUtils from '../../common/utils/LocalStorageUtils';
import { PageHeader } from '../../shared/building_blocks/PageHeader';
import { FormattedMessage, type IntlShape, injectIntl } from 'react-intl';
import {
  CursorPagination,
  Button,
  Input,
  SearchIcon,
} from '@databricks/design-system';
import { PageContainer } from '../../common/components/PageContainer';
import { GatewayRouteListTable } from './GatewayRouteListTable';
import { AddGatewayModal } from './AddGatewayRouteModal';

type GatewayRouteListViewImplProps = {
  routes : any[];
  searchInput: string;
  orderByKey: string;
  orderByAsc: boolean;
  currentPage: number;
  hasNextPage: boolean;
  onSearch: (...args: any[]) => any;
  onClickSortableColumn: (...args: any[]) => any;
  onSetMaxResult: (...args: any[]) => any;
  onClickNext: (...args: any[]) => any;
  onClickPrev: (...args: any[]) => any;
  intl: IntlShape;
};

type GatewayRouteListViewImplState = any;

export class GatewayRouteListViewImpl extends React.Component<
  GatewayRouteListViewImplProps,
  GatewayRouteListViewImplState
> {
  constructor(props: GatewayRouteListViewImplProps) {
    super(props);

    this.state = {
      loading: false,
      modalVisible: false,
    };
  }

  static defaultProps = {
    routes: [],
    searchInput: '',
  };

  /**
   * Returns a LocalStorageStore instance that can be used to persist data associated with the
   * GatewayRoute component.
   */
  static getLocalStore(key: any) {
    return LocalStorageUtils.getStoreForComponent('GatewayRouteListView', key);
  }

  componentDidMount() {
    const pageTitle = 'Gateway Routes';
    Utils.updatePageTitle(pageTitle);
  }

  setLoadingFalse = () => {
    this.setState({ loading: false });
  };

  unifiedTableSortChange = ({ orderByKey, orderByAsc }: any) => {
    const fieldMappedToSortKey = orderByKey;
    this.handleTableChange(undefined, undefined, {
      field: fieldMappedToSortKey,
      order: orderByAsc ? 'undefined' : 'descend',
    });
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    this.setState({ loading: true });
    this.props.onClickSortableColumn('name', sorter.order, this.setLoadingFalse);
  };

  handleClickNext = () => {
    this.props.onClickNext();
  };

  handleClickPrev = () => {
    this.props.onClickPrev();
  };

  handleSetMaxResult = ({ item, key, keyPath, domEvent }: any) => {
    this.props.onSetMaxResult(key, this.setLoadingFalse);
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  }

  render() {
    const {
      routes,
      orderByKey,
      orderByAsc,
      currentPage,
      hasNextPage,
      onSearch,
    } = this.props;
    const { loading, modalVisible } = this.state;
    const title = (
      <>
        <FormattedMessage
          defaultMessage='AI Gateway Configuration'
          description='Header for displaying AI gateway route page'
        />
      </>
    );
    return (
      <>
        <PageContainer data-test-id='GatewayRouteListView-container' usesFullHeight>
            <PageHeader title={title}>
              <Input
                prefix={<SearchIcon />}
                onChange={(e) => {onSearch(e.target.value)}}
                placeholder= 'Search gateway route by name.'
                data-test-id='gateway-rooute-search-box'
              />
              <Button
                className={`add-gateway-route-btn`}
                type={'primary'}
                onClick={() => {this.setState({ modalVisible: true})}}
                data-testid='add-gateway-route-btn-button'
              >
                Create Route
              </Button>
            </PageHeader>
            
            <GatewayRouteListTable
              routesData={routes}
              onSortChange={this.unifiedTableSortChange}
              orderByKey={orderByKey}
              orderByAsc={orderByAsc}
              isLoading={loading}
              pagination={
                <div
                  data-testid='gateway-route-list-view-pagination'
                  css={{ alignItems: 'center', display: 'flex' }}
                >
                  <div>
                    <CursorPagination
                      hasNextPage={hasNextPage}
                      hasPreviousPage={currentPage > 1}
                      onNextPage={this.handleClickNext}
                      onPreviousPage={this.handleClickPrev}
                      pageSizeSelect={{
                        onChange: (num) => this.handleSetMaxResult({ key: num }),
                        default: 25,
                        options: [10, 25, 50, 100],
                      }}
                    />
                  </div>
                </div>
              }
              isFiltered={false}
            />
          </PageContainer>
        <AddGatewayModal modalVisible={modalVisible} hideModal={this.hideModal} />
      </>
    );
  }
}
export const GatewayRouteListView = injectIntl(GatewayRouteListViewImpl);
