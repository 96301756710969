import { postJson } from '../common/utils/FetchUtils';

export class Services {
  static createFreeUser = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.InfinStorApiServer +'/addsecondaryuser', data });

  static getCustomerInfo = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.InfinStorApiServer +'/customerinfo', data });

  static selfServe = (data: any) =>
    postJson({ relativeUrl: 'https://'+ window.InfinStorApiServer +'/customerinfo', data });
}