import { Component } from 'react';
import { Form, Input, Button, Spacer, Select } from '@databricks/design-system';
import { EditableLimitsTable } from './EditableLimitsTable';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

type Props = {
  limits: any[];
  setGatewayRouteLimits: (...args: any[]) => any;
  handleSaveEditRouteLimits: (...args: any[]) => any;
  removeRouteLimits: (...args: any[]) => any;
  isRequestPending: boolean;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  innerRef?: any;
}

type State = any;

export class GatewayRouteLimitsImpl extends Component<Props, State> {
  tableColumns = [
    {
      title: "Key",
      dataIndex: 'key',
      width: 200,
    },
    {
      title: 'Calls',
      dataIndex: 'calls',
      width: 200,
      editable: true,
    },
    {
      title: 'Renewal Period',
      dataIndex: 'renewal_period',
      width: 200,
    },
  ];

  render() {
    const { isRequestPending, setGatewayRouteLimits, handleSaveEditRouteLimits, removeRouteLimits, innerRef, limits } = this.props;
    const routeLimit = limits.find(({key}) => key === 'route');
    const userLimit = limits.find(({key}) => key === 'user');
    return (
      <>
        <EditableLimitsTable
          columns={this.tableColumns}
          data={limits}
          onSaveEdit={handleSaveEditRouteLimits}
          onDelete={removeRouteLimits}
        />
        <Spacer size='sm' />
        { limits.length < 2 ?
          <div>
            <Form ref={innerRef} layout='inline' onFinish={setGatewayRouteLimits} css={styles.form}>
              <Form.Item
                name='key'
                rules={[
                  {
                    required: true,
                    message: 'key is required!'
                  },
                ]}
              >
                <Select
                  className='route-key-select'
                  data-test-id='route-key-dropdown'
                  placeholder='select key for set limits'
                >
                  {routeLimit? "" : <Select.Option className='renewal_period-select-option' value="route" key="route"> Route </Select.Option> }
                  {userLimit? "" : <Select.Option className='renewal_period-select-option' value="user" key="user"> User </Select.Option> }
                </Select>
                {/* <Input aria-label='key' placeholder='key' /> */}
              </Form.Item>
              <Form.Item name='calls' rules={[
                  {
                    required: true,
                    message: 'Calls is required!'
                  },
                ]}>
                <Input
                  aria-label='calls'
                  placeholder='calls'
                  type='number'
                  max={1000}
                  min={1}
                  style={{width: '180px'}}
                />
              </Form.Item>
              <Form.Item name="renewal_period" rules={[]} initialValue="minute">
                <Select
                  className='renewal_period-select'
                  data-test-id='renewal_period-dropdown'
                >
                  <Select.Option className='renewal_period-select-option' value="minute" key="minute"> Minute </Select.Option>
                  {/* <Select.Option className='renewal_period-select-option' value="hours" key="hours"> Hours </Select.Option> */}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button loading={isRequestPending} htmlType='submit' data-test-id='add-tag-button'>
                  <FormattedMessage
                    defaultMessage='Set Limits'
                    description='Set Limits button text in editable limits table'
                  />
                </Button>
              </Form.Item>
            </Form>
          </div> : ''
        }
      </>
    );
  }
}

const styles = {
  form: (theme: any) => ({ '& > div': { marginRight: theme.spacing.sm } }),
};

// @ts-expect-error TS(2769): No overload matches this call.
export const GatewayRouteLimits = injectIntl(GatewayRouteLimitsImpl);