import type { CSSObject, SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { DatePicker as AntDDatePicker } from 'antd';
import type {
  DatePickerProps as AntDDatePickerProps,
  MonthPickerProps as AntDMonthPickerProps,
  RangePickerProps as AntDRangePickerProps,
} from 'antd/lib/date-picker';
import { forwardRef } from 'react';

import type { Theme } from '../../theme';
import { DesignSystemAntDConfigProvider } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks/useDesignSystemTheme';

export type DatePickerRef = {
  focus: () => void;
  blur: () => void;
};

export type DatePickerProps = AntDDatePickerProps & React.RefAttributes<DatePickerRef>;
export type RangePickerProps = AntDRangePickerProps & React.RefAttributes<DatePickerRef>;
export type TimePickerProps = AntDMonthPickerProps & React.RefAttributes<DatePickerRef>;
export type QuarterPickerProps = AntDMonthPickerProps & React.RefAttributes<DatePickerRef>;
export type WeekPickerProps = AntDMonthPickerProps & React.RefAttributes<DatePickerRef>;
export type MonthPickerProps = AntDMonthPickerProps & React.RefAttributes<DatePickerRef>;
export type YearPickerProps = AntDMonthPickerProps & React.RefAttributes<DatePickerRef>;

function getEmotionStyles(clsPrefix: string, theme: Theme): SerializedStyles {
  const classFocused = `.${clsPrefix}-focused`;
  const classActiveBar = `.${clsPrefix}-active-bar`;
  const classSeparator = `.${clsPrefix}-separator`;
  const classSuffix = `.${clsPrefix}-suffix`;

  const styles: CSSObject = {
    height: 32,
    borderRadius: theme.borders.borderRadiusMd,
    borderColor: theme.colors.border,
    color: theme.colors.textPrimary,
    transition: 'border 0s, box-shadow 0s',
    [`&${classFocused},:hover`]: {
      borderColor: theme.colors.actionDefaultBorderHover,
    },
    '&:active': {
      borderColor: theme.colors.actionDefaultBorderPress,
    },
    [`&${classFocused}`]: {
      boxShadow: `none !important`,
      outline: `${theme.colors.actionDefaultBorderFocus} solid 2px !important`,
      borderColor: 'transparent !important',
    },
    [`& ${classActiveBar}`]: {
      background: `${theme.colors.actionDefaultBorderPress} !important`,
    },
    [`& input::placeholder, & ${classSeparator}, & ${classSuffix}`]: {
      color: theme.colors.textPrimary,
    },
  };

  return css(styles);
}

const getDropdownStyles = (theme: Theme) => {
  return {
    zIndex: theme.options.zIndexBase + 50,
  };
};

function useDatePickerStyles(): SerializedStyles {
  const { theme, getPrefixedClassName } = useDesignSystemTheme();
  const clsPrefix = getPrefixedClassName('picker');

  return getEmotionStyles(clsPrefix, theme);
}

export const DuboisDatePicker: React.VFC<DatePickerProps> = forwardRef(
  (props: DatePickerProps, ref: React.ForwardedRef<DatePickerRef>) => {
    const styles = useDatePickerStyles();
    const { theme } = useDesignSystemTheme();
    return (
      <DesignSystemAntDConfigProvider>
        <AntDDatePicker
          css={styles}
          ref={ref as any}
          {...props}
          popupStyle={{ ...getDropdownStyles(theme), ...(props.popupStyle || {}) }}
        />
      </DesignSystemAntDConfigProvider>
    );
  },
);

const RangePicker: React.VFC<RangePickerProps> = forwardRef(
  (props: RangePickerProps, ref: React.ForwardedRef<DatePickerRef>) => {
    const styles = useDatePickerStyles();
    const { theme } = useDesignSystemTheme();
    return (
      <DesignSystemAntDConfigProvider>
        <AntDDatePicker.RangePicker
          css={styles}
          {...props}
          ref={ref as any}
          popupStyle={{ ...getDropdownStyles(theme), ...(props.popupStyle || {}) }}
        />
      </DesignSystemAntDConfigProvider>
    );
  },
);

const TimePicker: React.VFC<TimePickerProps> = forwardRef(
  (props: TimePickerProps, ref: React.ForwardedRef<DatePickerRef>) => {
    const styles = useDatePickerStyles();
    const { theme } = useDesignSystemTheme();
    return (
      <DesignSystemAntDConfigProvider>
        <AntDDatePicker.TimePicker
          css={styles}
          {...props}
          ref={ref as any}
          popupStyle={{ ...getDropdownStyles(theme), ...(props.popupStyle || {}) }}
        />
      </DesignSystemAntDConfigProvider>
    );
  },
);

const QuarterPicker: React.VFC<QuarterPickerProps> = forwardRef(
  (props: QuarterPickerProps, ref: React.ForwardedRef<DatePickerRef>) => {
    const styles = useDatePickerStyles();
    const { theme } = useDesignSystemTheme();
    return (
      <DesignSystemAntDConfigProvider>
        <AntDDatePicker.QuarterPicker
          css={styles}
          {...props}
          ref={ref as any}
          popupStyle={{ ...getDropdownStyles(theme), ...(props.popupStyle || {}) }}
        />
      </DesignSystemAntDConfigProvider>
    );
  },
);

const WeekPicker: React.VFC<WeekPickerProps> = forwardRef(
  (props: WeekPickerProps, ref: React.ForwardedRef<DatePickerRef>) => {
    const styles = useDatePickerStyles();
    const { theme } = useDesignSystemTheme();
    return (
      <DesignSystemAntDConfigProvider>
        <AntDDatePicker.WeekPicker
          css={styles}
          {...props}
          ref={ref as any}
          popupStyle={{ ...getDropdownStyles(theme), ...(props.popupStyle || {}) }}
        />
      </DesignSystemAntDConfigProvider>
    );
  },
);

const MonthPicker: React.VFC<MonthPickerProps> = forwardRef(
  (props: MonthPickerProps, ref: React.ForwardedRef<DatePickerRef>) => {
    const styles = useDatePickerStyles();
    const { theme } = useDesignSystemTheme();
    return (
      <DesignSystemAntDConfigProvider>
        <AntDDatePicker.MonthPicker
          css={styles}
          {...props}
          ref={ref as any}
          popupStyle={{ ...getDropdownStyles(theme), ...(props.popupStyle || {}) }}
        />
      </DesignSystemAntDConfigProvider>
    );
  },
);

const YearPicker: React.VFC<YearPickerProps> = forwardRef(
  (props: YearPickerProps, ref: React.ForwardedRef<DatePickerRef>) => {
    const styles = useDatePickerStyles();
    const { theme } = useDesignSystemTheme();
    return (
      <DesignSystemAntDConfigProvider>
        <AntDDatePicker.YearPicker
          css={styles}
          {...props}
          ref={ref as any}
          popupStyle={{ ...getDropdownStyles(theme), ...(props.popupStyle || {}) }}
        />
      </DesignSystemAntDConfigProvider>
    );
  },
);

/**
 * `LegacyDatePicker` was added as a temporary solution pending an
 * official Du Bois replacement. Use with caution.
 * @deprecated
 */
export const LegacyDatePicker = /* #__PURE__ */ Object.assign(DuboisDatePicker, {
  /**
   * See deprecation notice for `LegacyDatePicker`.
   * @deprecated
   */
  RangePicker,
  /**
   * See deprecation notice for `LegacyDatePicker`.
   * @deprecated
   */
  TimePicker,
  /**
   * See deprecation notice for `LegacyDatePicker`.
   * @deprecated
   */
  QuarterPicker,
  /**
   * See deprecation notice for `LegacyDatePicker`.
   * @deprecated
   */
  WeekPicker,
  /**
   * See deprecation notice for `LegacyDatePicker`.
   * @deprecated
   */
  MonthPicker,
  /**
   * See deprecation notice for `LegacyDatePicker`.
   * @deprecated
   */
  YearPicker,
});
