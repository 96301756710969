import { Component } from 'react';
import { GenericInputModal } from '../../../experiment-tracking/components/modals/GenericInputModal';
import { CreateParallelForm } from './CreateParallelForm';
import { injectIntl } from 'react-intl';

type Props = {
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  handleSubmit: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  isOpen: boolean;
  innerRef: any;
  parallels: any[];
}

export class CreateParallelModalImpl extends Component<Props> {
  getParallelsNamesAsSet = () =>
    new Set(this.props.parallels.map((values) => values['dagName']));

  parallelNameValidator = (rule: any, value: any, callback: any) => {
    const parallelsNamesSet = this.getParallelsNamesAsSet();
    callback(
        parallelsNamesSet.has(value)
        ? this.props.intl.formatMessage(
            {
              defaultMessage: 'Concurrent "{value}" already exists.',
              description: 'Validation message for Concurrent that already exist in Concurrent for MLflow',
            },
            {
              value: value,
            },
          )
        : undefined,
    );
  };
  render() {
    const { isOpen } = this.props;
    return (
      <GenericInputModal
        title='Create Concurrent'
        okText='Create'
        isOpen={isOpen}
        handleSubmit={this.props.handleSubmit}
        onClose={this.props.onClose}
      >
        {/*//@ts-expect-error */}
        <CreateParallelForm nameValidator={this.parallelNameValidator}/>
      </GenericInputModal>
    );
  }
}

//@ts-expect-error
export const CreateParallelModal = injectIntl(CreateParallelModalImpl);
