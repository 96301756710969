import Node from '../components/templates/node';
import Edge from '../components/templates/edge';

export const getGraphNodes = (nodes: any) => {
  nodes.forEach((node: any) => {
    node.Class = Node;
    node.top = node.geometry.y;
    node.left = node.geometry.x;
    node.transform = decodeURIComponent(node.transform);
    if(!node.color){
      node.color = getRandomColor();
    }
    node.endpoints = [{
      id: 'top',
      orientation: [0, -1],
      pos: [0.5, 0]
    }, {
      id: 'bottom',
      orientation: [0, 1],
      pos: [0.5, 0]
    }]
  });
  return nodes;
};

export const encodeNodeURI = (nodes: any) => {
  nodes.forEach((node: any) => {
    node.transform =  encodeURIComponent(node.transform);    
  });
  return nodes;
};

export const getGraphEdges = (edges: any) => {
  edges.forEach((edge: any) => {
    edge.id = edge.source+'_'+edge.target;
    edge.Class = Edge;
    edge.sourceNode = edge.source;
    edge.targetNode = edge.target;
    edge.source = 'bottom';
    edge.target = 'top';
    edge.arrow = true;
    edge.type = 'endpoint';
    edge.arrowPosition = 1;
  });
  return edges;
};

export const getTrimmedNodes = (nodes: any) => {
  let trimmedNodes: any[] = [];
  nodes.forEach((node: any) => {
    let data = {
      geometry: {
        x:node.left,
        y:node.top
      },
      id:node.id,
      name: node.options.name,
      instanceType: node.options.instanceType,
      transform: node.options.transform,
      xform_path: node.options.xform_path,
      input: node.options.input,
      kwarg: node.options.kwarg,
      parallelization: node.options.parallelization,
      color: node.options.color,
      // location: node.options.location,
      k8s_params:node.options.k8s_params,
    }

    const splitTransformURL = node.options.transform.split(":");
    const passString = splitTransformURL[2] || "";
    const index = passString.indexOf("@git-codecommit");
    if(index > 1) {
      const gitPass = passString.slice(0, index);
      const encodedPass = encodeURIComponent(encodeURIComponent(gitPass));
      const encodedTransformURL = node.options.transform.replace(gitPass, encodedPass);
      data.transform = encodedTransformURL;
    }

    // if(node.options.location === "AWS EKS") {
    //   data.instanceType = "k8s";
    //   data.k8s_params = {
    //     "resources.requests.memory": node.options.k8s_params['resources.requests.memory'],
    //     "kube_context": node.options.k8s_params.kube_context,
    //     "namespace": node.options.k8s_params.namespace,
    //     "MLFLOW_TRACKING_URI": "infinstor://" + window.InfinStorMlflowServer,
    //     "resources.requests.cpu": node.options.k8s_params['resources.requests.cpu'],
    //     "resources.requests.gpu" : node.options.k8s_params['resources.requests.gpu'],
    //   }
    // } else if(node.options.location == undefined) {
    //   data.location = "eks";
    // }
    trimmedNodes.push(data);
  });
  return trimmedNodes;
};

export const getTrimmedEdges = (edges: any) => {
  let trimmedEdges: any[] = [];
  edges.forEach((edge: any) => {
    let data: any = {
      id : edge.options.sourceNode+"_"+edge.options.targetNode,
      source: edge.options.sourceNode,
      target: edge.options.targetNode,
      name: edge.options.name,
    }
    if(edge.options.partitioner) {
      data.partitioner = edge.options.partitioner;
    }
    if(edge.options.lambda) {
      data.lambda = edge.options.lambda;
    }
    trimmedEdges.push(data);
  });
  return trimmedEdges;
};

export const getRandomColor = () => {
  return "#" + ((1<<24)*Math.random() | 0).toString(16);
};

export const getOccurrence = (array: any, value: any) => {
  return array.filter((v: any) => (v === value)).length;
}

export const getKubeClusters = (clusters: any) => {
  if(clusters) {
    let clusterNames = clusters.map((cluster: any) => {
      return {value: cluster.cluster_name}
    });
    return clusterNames;
  }
  return [];
}

export const getStatusGraphElement = (runUuid: any, state: any) => {
  if(state.entities.concurrentStatusforRun[runUuid]) {
    const dag_execution_info = state.entities.concurrentStatusforRun[runUuid];
    const dagJson = dag_execution_info.dag_json;
    const runStatus = dag_execution_info.run_status;
  
    if(runStatus) {
      dagJson.nodes.forEach(function(node: any, index: number) {
        node.Class = Node;
        node.top = node.geometry.y;
        node.left = node.geometry.x;
        //node.color = node.color;
        node.endpoints = [{
          id: 'top',
          orientation: [0, -1],
          pos: [0.5, 0]
        }, {
          id: 'bottom',
          orientation: [0, 1],
          pos: [0.5, 0]
        }]
        node.runRes = dag_execution_info.run_status.nodes[node.id];
        if (node.runRes.status === "FINISHED") {
          node.type = "sucessNode";
          node.color = '#03cba8';
        } else if (node.runRes.status === "FAILED") {
          node.type = "failedNode";
          node.color = '#ff8080';
        } else if (node.runRes.status === "RUNNING") {
          node.type = "progressNode";
        } else {
          node.type = "pendingNode";
        }
      });
    
      dagJson.edges.forEach((edge: any) => {
        edge.id = edge.source+'_'+edge.target;
        edge.Class = Edge;
        edge.sourceNode = edge.source;
        edge.targetNode = edge.target;
        edge.source = 'bottom';
        edge.target = 'top';
        edge.arrow = true;
        edge.type = 'endpoint';
        edge.arrowPosition = 0.9;
      });
    }
    return dagJson;
  }
}

export const getConcurrentTemplateJson = (runUuid: any, state: any) => {
  if(state.entities.concurrentStatusforRun[runUuid]) {
    const fullDagJson = state.entities.concurrentStatusforRun[runUuid].dag_json;
    if(fullDagJson) {
      let runResult: any = {};
      fullDagJson.nodes.forEach(function(node: any, index: number) {
        let node_id = node.id;
        if(node.original_node_id) {
          node_id = node.original_node_id;
        }
        if (!runResult[node_id]) { 
          runResult[node_id] = [node.runRes.status];
        } else {
          if (runResult[node_id].length === 0) { 
            runResult[node_id] = [node.runRes.status];
          } else {
            runResult[node_id].push(node.runRes.status);
          }
        }
      });

      const unique: any = [];
      fullDagJson.nodes.map((x: any) => unique.filter((a: any) => (a.original_node_id == x.original_node_id && a.name == x.name )).length > 0 ? null : unique.push(x));
      let parallel_json = JSON.parse(fullDagJson.parallel_json);
      parallel_json.node = unique;
      parallel_json.node.forEach(function(node: any, index: number) {
        let node_id = node.id;
        if(node.original_node_id) {
          node_id = node.original_node_id;
        }
        const nodeRunResult = runResult[node_id];
        if(nodeRunResult) {
          const sucessResult = getOccurrence(nodeRunResult,'FINISHED');
          const failedResult = getOccurrence(nodeRunResult,'FAILED');
          const nodesucessPersentage = Math.round((sucessResult/nodeRunResult.length)*100);
          node.sucessPersentage = nodesucessPersentage;
          node.failedResult = failedResult;
        } else {
          node.sucessPersentage = 0;
          node.failedResult = 0;
        }
      });
      return parallel_json;
    }
  }
}