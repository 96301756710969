import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Input, Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  nodeInputNameValidator: (...args: any[]) => any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  innerRef: any;
};

class AddConnectionFormComponent extends Component<Props> {

  state = {
    showCustomlamda: false,
  };

  changePartitioner = (value: any) => {
    if(value === 'custom') {
      this.setState({showCustomlamda : true});
    } else {
      this.setState({showCustomlamda : false});
    }
  }

  render() {
    const learnMoreLinkUrl = 'https://docs.concurrent-ai.org/files/free-helloworld/#add-node-to-dag';
    return (
      <Form ref={this.props.innerRef} layout='vertical' requiredMark='optional'>
        <Form.Item label='Enter input name' name='name' 
        rules={[
          { validator: this.props.nodeInputNameValidator },{required: true, message: 'Input name is required.'}
        ]}>
          <Input 
            placeholder={this.props.intl.formatMessage({
              defaultMessage: 'Enter input name',
              description: 'Input placeholder to enter inpu name',
            })} autoFocus
          />
        </Form.Item>
        <Form.Item name='partitioner' label='Specify input partitioner' requiredMark='optional'>
          <Select placeholder="Select node partitioner" onChange={this.changePartitioner}>
            <Option key="null" value="null"> no partitioners </Option>
            <Option key="object" value="object"> object </Option>
            <Option key="directory" value="directory"> directory </Option>
            <Option key="custom" value="custom"> custom </Option>
          </Select>
        </Form.Item>

        {this.state.showCustomlamda ? <Form.Item name='lambda' label='Enter custom partition lambda code'>
          <TextArea rows={4} placeholder={this.props.intl.formatMessage({
            defaultMessage: 'Enter custom partition lambda code',
            description: 'Input placeholder to enter lambda code',
          })} />
        </Form.Item> : null}
                
        <p className='create-modal-explanatory-text'>
          <FormattedMessage
            defaultMessage='once created, a connection can be edited anytime..&nbsp;'
            description='Text for form description on creating model in the model registry'
          />
          <FormattedMessage
            defaultMessage='<link>Learn more</link>'
            description='Learn more link on the form for creating model in the model registry'
            values={{
              link: (chunks) => (
                <a href={learnMoreLinkUrl} target='_blank'>
                  {chunks}
                </a>
              ),
            }}
          />
          .
        </p>
      </Form>
    );
  }
}

//@ts-expect-error
export const AddConnectionForm = injectIntl(AddConnectionFormComponent);
