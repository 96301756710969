import {
  GET_KUBECLUSTER_API,
  GET_USERBUCKETS
} from './actions';
import _ from 'lodash';
import { fulfilled, rejected } from '../common/utils/ActionUtils';


const cluster = (state = {}, action: any) => {
  switch (action.type) {
    case fulfilled(GET_KUBECLUSTER_API): { 
      let newState = Object.assign({}, state);
      if (action.payload) {
        newState = action.payload;
      }
      return newState;
    }
    default:
      return state;
  }
}

const buckets = (state = [], action: any) => {
  switch (action.type) {
    case fulfilled(GET_USERBUCKETS): { 
      let newState = Object.assign({}, state);
      if (action.payload) {
        newState = action.payload.buckets;
      }
      return newState;
    }
    default:
      return state;
  }
}

const reducers = {
  cluster,
  buckets
};


export default reducers;