import { MlflowService } from 'experiment-tracking/sdk/MlflowService';
import { getUUID } from '../../common/utils/ActionUtils';
import type { AsyncAction } from '../../redux-types';
import {
  ModelGatewayQueryPayload,
  ModelGatewayRoute,
  ModelGatewayRouteLimits,
  ModelGatewayService,
  SearchModelGatewayRouteResponse,
  ModelGatewayResponseType,
} from '../sdk/ModelGatewayService';

export const SEARCH_MODEL_GATEWAY_ROUTES_API = 'SEARCH_MODEL_GATEWAY_ROUTES_API';
export interface SearchModelGatewayRoutesAction
  extends AsyncAction<SearchModelGatewayRouteResponse> {
  type: 'SEARCH_MODEL_GATEWAY_ROUTES_API';
}

// prettier-ignore
export const searchModelGatewayRoutesApi = (next_token?: string, id = getUUID() ): SearchModelGatewayRoutesAction => ({
  type: SEARCH_MODEL_GATEWAY_ROUTES_API,
  payload: MlflowService.searchGatewayRoutes({page_token: next_token}) as Promise<SearchModelGatewayRouteResponse>,
  meta: { id: id},
});

export const ADD_MODEL_GATEWAY_ROUTES_API = 'ADD_MODEL_GATEWAY_ROUTES_API';

export const addModelGatewayRoutesApi = (data: any, id = getUUID() ) => ({
  type: ADD_MODEL_GATEWAY_ROUTES_API,
  payload: MlflowService.addModelGatewayRoute(data) as Promise<any>,
  meta: { id: id},
});

export const REMOVE_MODEL_GATEWAY_ROUTES_API = 'REMOVE_MODEL_GATEWAY_ROUTES_API';

export const removeModelGatewayRoutesApi = (data: any, id = getUUID() ) => ({
  type: REMOVE_MODEL_GATEWAY_ROUTES_API,
  payload: MlflowService.removeModelGatewayRoute(data) as Promise<any>,
  meta: { id: id},
});

export const GET_MODEL_GATEWAY_ROUTE_API = 'GET_MODEL_GATEWAY_ROUTE_API';
export interface GetModelGatewayRouteAction extends AsyncAction<ModelGatewayRoute> {
  type: 'GET_MODEL_GATEWAY_ROUTE_API';
}

export const getModelGatewayRouteApi = (routeName: string, id = getUUID()): GetModelGatewayRouteAction => ({
  type: GET_MODEL_GATEWAY_ROUTE_API,
  payload: MlflowService.getModelGatewayRoute({routeName: routeName }) as Promise<ModelGatewayRoute>,
  meta: { id: id },
});

export const QUERY_MODEL_GATEWAY_ROUTE_API = 'QUERY_MODEL_GATEWAY_ROUTE_API';

export const queryModelGatewayRouteApi = (
  route: ModelGatewayRoute,
  data: ModelGatewayQueryPayload,
) => {
  const { inputText } = data;
  const textPayload = ModelGatewayService.createEvaluationTextPayload(inputText, route);
  const processed_data = {
    ...textPayload,
    ...data.parameters,
  };

  return {
    type: QUERY_MODEL_GATEWAY_ROUTE_API,
    payload: MlflowService.queryModelGatewayRoute(route.name, processed_data) as Promise<ModelGatewayResponseType>,
    meta: { id: getUUID(), startTime: performance.now() },
  };
};

export const SET_MODEL_GATEWAY_ROUTE_LIMITS_API = 'SET_MODEL_GATEWAY_ROUTE_LIMITS_API';
export interface SetModelGatewayRouteLimitsAction extends AsyncAction<ModelGatewayRouteLimits> {
  type: 'SET_MODEL_GATEWAY_ROUTE_LIMITS_API';
}

export const setModelGatewayRouteLimitsApi = (data: any, id = getUUID()): SetModelGatewayRouteLimitsAction => ({
  type: SET_MODEL_GATEWAY_ROUTE_LIMITS_API,
  payload: MlflowService.setModelGatewayRouteLimits(data) as Promise<ModelGatewayRouteLimits>,
  meta: { id: id },
});

export const GET_MODEL_GATEWAY_ROUTE_LIMITS_API = 'GET_MODEL_GATEWAY_ROUTE_LIMITS_API';
export interface GetModelGatewayRouteLimitsAction extends AsyncAction<ModelGatewayRouteLimits> {
  type: 'GET_MODEL_GATEWAY_ROUTE_LIMITS_API';
}

export const getModelGatewayRouteLimitsApi = (routeName: string, id = getUUID()): GetModelGatewayRouteLimitsAction => ({
  type: GET_MODEL_GATEWAY_ROUTE_LIMITS_API,
  payload: MlflowService.getModelGatewayRouteLimits({routeName: routeName }) as Promise<ModelGatewayRouteLimits>,
  meta: { id: id, routeName: routeName },
});



// set and get gateway authorization.

export const SET_MODEL_GATEWAY_ROUTE_AUTHORIZATION_API = 'SET_MODEL_GATEWAY_ROUTE_AUTHORIZATION_API';
export interface SetModelGatewayRouteAuthorizationAction extends AsyncAction<any> {
  type: 'SET_MODEL_GATEWAY_ROUTE_AUTHORIZATION_API';
}

export const setModelGatewayRouteAuthorizationApi = (data: any, id = getUUID()): SetModelGatewayRouteAuthorizationAction => ({
  type: SET_MODEL_GATEWAY_ROUTE_AUTHORIZATION_API,
  payload: MlflowService.setModelGatewayRouteAuthorization(data) as Promise<any>,
  meta: { id: id },
});

export const GET_MODEL_GATEWAY_ROUTE_AUTHORIZATION_API = 'GET_MODEL_GATEWAY_ROUTE_AUTHORIZATION_API';
export interface GetModelGatewayRouteAuthorizationAction extends AsyncAction<any> {
  type: 'GET_MODEL_GATEWAY_ROUTE_AUTHORIZATION_API';
}

export const getModelGatewayRouteAuthorizationApi = (routeName: string, id = getUUID()): GetModelGatewayRouteAuthorizationAction => ({
  type: GET_MODEL_GATEWAY_ROUTE_AUTHORIZATION_API,
  payload: MlflowService.getModelGatewayRouteAuthorization({route_name: routeName }) as Promise<any>,
  meta: { id: id, routeName: routeName },
});