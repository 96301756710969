import React, {Component} from 'react';
import RequestStateWrapper from '../../common/components/RequestStateWrapper';
import { PageContainer } from '../../common/components/PageContainer';
import { PageHeader } from '../../shared/building_blocks/PageHeader';
import { connect } from 'react-redux';
import { addKubeClusterApi, getKubeClusterApi, removekubeClusterApi, addClusterAccessApi, removeClusterAccessApi } from '../actions';
import { getUUID } from '../../common/utils/ActionUtils';
import { Table, Modal, Button, Popconfirm, Tooltip, Collapse, message } from 'antd'

import { GenericInputModal } from '../../experiment-tracking/components/modals/GenericInputModal';
import { ClusterConfigForm } from './modals/ClusterConfigForm';
import { IconButton } from '../../common/components/IconButton';
import { EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Spinner } from '../../common/components/Spinner';
import { ManageClusterAccess } from './ManageClusterAccess';
import { PlotNumber } from 'plotly.js';

const { Panel } = Collapse;

type Props = {
  userName: string;
  isAdmin: boolean;
  kube_clusters: any;
  addKubeClusterApi: (...args: any[]) => any;
  getKubeClusterApi: (...args: any[]) => any;
  removekubeClusterApi: (...args: any[]) => any;
  addClusterAccessApi: (...args: any[]) => any;
  removeClusterAccessApi: (...args: any[]) => any;
}

type State = any;

export class KubeClusterPageImpl extends Component<Props,State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      showAddCluserModel: false,
      showEditCluserModel: false,
      showClusterAccessModel: false,
      currentCluster: {},
      clusterType: 'EKS',
      use_eks_role: true,
      accessRequestPending: false,
    }
  }

  getKubeClusterApiId = getUUID();
  criticalInitialRequestIds = [this.getKubeClusterApiId];
  accessFormRef = React.createRef();

  componentDidMount() {
    this.loadCluster();
  }

  changeClusterType = (value: any) => {
    this.setState({clusterType : value});
  }

  changeUseEKSCheckbox = (e: any) => {
    this.setState({use_eks_role : e.target.checked});
  }

  loadCluster = () => {
    this.setState({ loading: true });
    this.props.getKubeClusterApi(this.getKubeClusterApiId).then(() => {
      this.setState({ loading: false });
    })
    .catch(() => {
      this.setState({ loading: false });
    });
  };

  openClusterAccessModel = (cluster: any) => {
    this.setState({ currentCluster: cluster });
    this.setState({ showClusterAccessModel: true });
  }

  closeClusterAccessModel = () => {
    this.setState({ showClusterAccessModel: false });
  }

  openEditClusterModel = (cluster: any) => {
    const { kube_clusters } = this.props;
    if(cluster.eks_role) {
      this.setState({clusterType : "EKS"});
      cluster.cluster_type = "EKS";
    } else {
      this.setState({clusterType : "GKE"});
      cluster.cluster_type = "GKE";
    }
    this.setState({ currentCluster: cluster });
    this.setState({ showEditCluserModel: true });
    if(cluster.eks_role === cluster.ecr_role) {
      this.setState({ use_eks_role: true });
    } else {
      this.setState({ use_eks_role: false });
    }
  };
  
  closeEditClusterModel = () => {
    this.setState({ showEditCluserModel: false });
  };

  openAddClusterModel = () => {
    this.setState({ 
      currentCluster: {},
      showAddCluserModel: true,
      use_eks_role: true
    });
  };
  
  closeAddClusterModel = () => {
    this.setState({ showAddCluserModel: false });
  };

  addCluster = async (values: any) => {
    if(values.cluster_type === "EKS" ){
      values.ecr_region = values.region;
      if(!values.ecr_role) {
        values.ecr_role = values.role;
      }
      if(!values.ecr_role_ext) {
        values.ecr_role_ext = values.role_ext;
      }
    }
    await this.props.addKubeClusterApi(values).then((r: any) => {
      this.setState({ currentCluster: {} });
      this.setState({ showAddCluserModel: false });
      this.setState({ showEditCluserModel: false });
      this.loadCluster();
    }).catch(() => {
      console.log('error');
    });
  }

  deleteCluster = (cluster: any) => {
    this.setState({ loading: true });
    this.props.removekubeClusterApi({cluster_name: cluster.cluster_name, namespace: cluster.namespace, owner: cluster.owner}).then(() => {
      this.loadCluster();
    }).catch(() => {
      console.log('error');
    });
  }

  displayInfo = (item: any) => {
    return(<div>
      <div className="node-input-container">
        <div>
          <span className="node-input-label"> Namespace </span>
          <span className="node-input-item" style={{paddingRight: '5px'}}> {item.namespace}</span>
        </div>
        {item.region ? <div>
          <span className="node-input-label"> Region </span>
          <span className="node-input-item"> {item.region} </span>
        </div>: null }
        {item.gke_location_type ? <div>
          <span className="node-input-label"> Location type </span>
          <span > {item.gke_location_type} </span>
        </div>: null }
      </div>
      {item.ecr_type ? <div className="node-input-container">
        <span className="node-input-label"> ECR Type </span>
        <span className="node-input-item">  {item.ecr_type }</span>
      </div>: null }
      {item.role ? <div className="node-input-container">
        <span className="node-input-label"> Role </span>
        <span className="node-input-item">{item.role } </span>
      </div>: null }
      {item.role_ext ? <div className="node-input-container">
        <span className="node-input-label"> External ID </span>
        <span className="node-input-item"> {item.role_ext } </span>
      </div> : null }
      {item.role != item.ecr_role ? <div className="node-input-container">
        <span className="node-input-label"> ECR Role </span>
        <span className="node-input-item">{item.ecr_role } </span>
      </div>: null }
      {item.role_ext != item.ecr_role_ext ? <div className="node-input-container">
        <span className="node-input-label"> ECR External ID </span>
        <span className="node-input-item"> {item.ecr_role_ext } </span>
      </div> : null }
      
      <div className="node-input-container">
        {item.gke_location ? <div>
          <span className="node-input-label"> Location </span>
          <span className="node-input-item" style={{paddingRight: '5px'}}> {item.gke_location} </span>
        </div>: null }
        {item.gke_project ? <div>
          <span className="node-input-label"> Project </span>
          <span className="node-input-item" style={{paddingRight: '5px'}}> {item.gke_project} </span>
        </div>: null }
      </div>
      {item.gke_creds ? <div className="node-input-container">
        <span className="node-input-label"> GKE Creds </span>
        <Tooltip title={item.gke_creds} >
          <span className="node-input-item text-ellipsis">{item.gke_creds } </span>
        </Tooltip>
      </div>: null }
    </div>);
  }

  getColumns = () => {
    const { isAdmin, userName } = this.props;
    const columns = [
      {
        title:'Cluster Name',
        render: (row: any) => <span>{row.cluster_name}</span>,
      },
      {
        title:'Namespace',
        render: (row: any) => <span>{row.namespace}</span>,
      },
      {
        title:'Cluster Type',
        render: (row: any) => <span>{row.cluster_type}</span>,
      },
      {
        title:'Owner',
        render: (row: any) => <span>{row.owner}</span>,
      },
      {
        title: 'Action',
        className: 'table-tag-container',
        render: (row: any, index: Number) => {
          return <div style={{display : "flex"}}> 
            {isAdmin ? <IconButton
            icon={<UsergroupAddOutlined />}
            onClick={() => this.openClusterAccessModel(row)}
            style={{ marginRight: 10 }}
          /> : null } 

          {(isAdmin || row.owner == userName ) ? <div> <IconButton
            icon={<EditOutlined />}
            onClick={() => this.openEditClusterModel(row)}
            style={{ marginRight: 10 }}
          /><Popconfirm
            title='Are you sure you want to delete this cluster？'
            okText='Yes'
            cancelText='No'
            onConfirm={() => this.deleteCluster(row)}
          >
            <IconButton icon={<i className='far fa-trash-o' />} />
          </Popconfirm> </div> : '-' }  
        </div>
        },
      },
    ];
    return columns;
  };

  loadAccess = () => {
    const { currentCluster } = this.state;
    const { kube_clusters } = this.props;
    const updateValue = kube_clusters.find((element: any) => element.cluster_name == currentCluster.cluster_name);
    this.setState({ currentCluster: updateValue });
  }

  addClusterAccess = (value: any) => {
    const { currentCluster } = this.state;
    const { kube_clusters } = this.props;
    const form: any = this.accessFormRef.current;
    let cluser_access = [];
    if(currentCluster.cluser_access) {
      cluser_access = currentCluster.cluser_access;
    }
    this.setState({ accessRequestPending: true });
    const data = {
      cluster_name: currentCluster.cluster_name,
      namespace: currentCluster.namespace,
      principal_type: value.type,
      principal_name: value.user,
    }
    return this.props
      .addClusterAccessApi(data)
      .then(() => {
        this.props.getKubeClusterApi().then(() => {
          this.setState({ accessRequestPending: false });
          form.resetFields();
          this.loadAccess();
        });
      })
      .catch((ex: any) => {
        this.setState({ accessRequestPending: false });
        console.error(ex);
        message.error('Failed to add permission. Error: ' + ex.text);
      });
  }

  deleteClusterAccess = (access: any) => {
    const { currentCluster } = this.state;
    const data = {
      cluster_name: currentCluster.cluster_name,
      namespace: currentCluster.namespace,
      principal_type: access.principal_type,
      principal_name: access.principal_name,
    }
    return this.props
      .removeClusterAccessApi(data)
      .then(() => {
        this.props.getKubeClusterApi().then(() => {
          this.loadAccess();
        });
      })
      .catch((ex: any) => {
        console.error(ex);
        message.error('Failed to add permission. Error: ' + ex.text);
      });
  }

  render() {
    const { loading, clusterType, use_eks_role, accessRequestPending } = this.state;
    const { kube_clusters } = this.props;

    return(
      <PageContainer data-test-id='user-password-change-container' usesFullHeight>
        <PageHeader title='Kubernetes Cluster Configuration'>
          <Button
            style={{height: '30px'}}
            type={'primary'}
            onClick={this.openAddClusterModel}
            data-testid='add-cluster-button'
          >
            Add Cluster
          </Button>
        </PageHeader>
        <RequestStateWrapper requestIds={this.criticalInitialRequestIds}>
          <Table
            className='parallels-template-table'
            dataSource={kube_clusters}
            columns={this.getColumns()}
            loading={loading && { indicator: <Spinner /> }}
            showSorterTooltip={false}
            locale={{ emptyText: <div> <span>No cluster. <Button type="link" style={{padding: '0px'}} onClick={this.openAddClusterModel}> click here </Button> to add cluster.</span>  </div> }}
            pagination={{
              pageSize: 10,
            }}
          />
          <GenericInputModal
            title='Add Kubernetes Cluster'
            okText='Save'
            isOpen={this.state['showAddCluserModel']}
            handleSubmit={this.addCluster}
            onClose={this.closeAddClusterModel}
          >
            {/*@ts-expect-error*/}
            <ClusterConfigForm cluster={{use_fargate: 'no'}} 
              clusterType={clusterType} 
              changeClusterType = {this.changeClusterType} 
              use_eks_role = {use_eks_role}
              changeUseEKSCheckbox = {this.changeUseEKSCheckbox}/>
          </GenericInputModal>
          <GenericInputModal
            title='Edit Kubernetes Cluster'
            okText='Save'
            isOpen={this.state['showEditCluserModel']}
            handleSubmit={this.addCluster}
            onClose={this.closeEditClusterModel}
          >
            {/*@ts-expect-error*/}
            <ClusterConfigForm cluster={this.state['currentCluster']} 
              clusterType={clusterType} 
              changeClusterType = {this.changeClusterType}
              use_eks_role = {use_eks_role}
              changeUseEKSCheckbox = {this.changeUseEKSCheckbox}/>
          </GenericInputModal>
          <Modal title="Cluster Access" visible={this.state['showClusterAccessModel']} 
            width={800}
            onCancel={this.closeClusterAccessModel} 
            onOk={this.closeClusterAccessModel}
            footer={[
              <Button key="back" onClick={this.closeClusterAccessModel}>
                Cancel
              </Button>
          ]}>
            <div className='cluster-access'>
              <ManageClusterAccess
                //@ts-expect-error
                innerRef={this.accessFormRef}
                handleAddAccess={this.addClusterAccess}
                handleDeleteAccess={this.deleteClusterAccess}
                cluster_access={this.state['currentCluster']['cluser_access'] ? this.state['currentCluster']['cluser_access'] : [] }
                isRequestPending={accessRequestPending}
              />
            </div>
          </Modal>
        </RequestStateWrapper>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  const clusters = state.user.cluster;
  const user = state.user.userModal;
  const userName = user.userName;
  let isAdmin = false;
  if(user.isSecondaryUser === "false" ) {
    isAdmin = true;
  }
  let kube_clusters = [];
  if(clusters.kube_clusters) {
    kube_clusters = clusters.kube_clusters.map((cluster: any) => {
      return {
        ...cluster,
        region: cluster.eks_region,
        role: cluster.eks_role,
        role_ext: cluster.eks_role_ext,
      }
    });
  }
  return {
    kube_clusters,
    userName,
    isAdmin
  };
}

const mapDispatchToProps = {
  addKubeClusterApi,
  getKubeClusterApi,
  removekubeClusterApi,
  addClusterAccessApi,
  removeClusterAccessApi
}

export const KubeClusterPage = connect(mapStateToProps, mapDispatchToProps)(KubeClusterPageImpl);