import React from 'react';
import { connect } from 'react-redux';
import { periodicRunListApi, deletePeriodicRunApi } from '../actions';
import { PeriodicRunView } from './PeriodicRunView';
import { PageContainer } from '../../common/components/PageContainer';
import RequestStateWrapper, { triggerError } from '../../common/components/RequestStateWrapper';
import { Spinner } from '../../common/components/Spinner';
import { ErrorView } from '../../common/components/ErrorView';
import { concurrentsListPageRoute } from '../routes';
import { getUUID } from '../../common/utils/ActionUtils';
import { injectIntl } from 'react-intl';
import { withRouterNext } from '../../common/utils/withRouterNext';

type Props = {
  history: any;
  periodicRun: any;
  periodicRunName: string;
  periodicRunListApi: (...args: any[]) => any;
  deletePeriodicRunApi: (...args: any[]) => any;
}

type State = any;

export class PeriodicRunPageImpl extends React.Component<Props, State> {
  getPeriodicRunApiId = getUUID();
  initialRequestIds = [this.getPeriodicRunApiId];

  handleEditDescription = (description: any) => { };

  handleDelete = () => {
    const { periodicRunName } = this.props;
    return this.props.deletePeriodicRunApi(periodicRunName);
  };

  componentDidMount() {
    this.props.periodicRunListApi(this.getPeriodicRunApiId);
  }

  render() {
    const { periodicRunName, periodicRun, history } = this.props;
    return (
      <PageContainer>
          <RequestStateWrapper requestIds={this.initialRequestIds}>
            {(loading: any, hasError: any, requests: any): any => {
              if (hasError) {
                triggerError(requests);
              } else if (loading) {
                return <Spinner />;
              } else if (periodicRun) {
                // Null check to prevent NPE after delete operation
                return (
                  <PeriodicRunView 
                    periodicRunName = {periodicRunName}
                    periodicRun = {periodicRun}
                    handleDelete = {this.handleDelete}
                    history={history}
                  />
                );
              } else {
                return (
                  <ErrorView
                    statusCode={400}
                    subMessage='Concurrent does not exist'
                    fallbackHomePageReactRoute={concurrentsListPageRoute}
                  />
                );
              }
            }}
          </RequestStateWrapper>
        </PageContainer>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const periodicRunName = decodeURIComponent(ownProps.params.periodicRunName);
  const periodicRun = state.entities.periodicRunByName[periodicRunName];
  return {
    periodicRunName,
    periodicRun,
  };
};

const mapDispatchToProps = { periodicRunListApi, deletePeriodicRunApi };

export const PeriodicRunPage = withRouterNext(
  //@ts-expect-error
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(PeriodicRunPageImpl))
);
