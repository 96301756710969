import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import classnames from 'classnames';

import type { Theme } from '../../theme';
import { useDesignSystemTheme } from '../Hooks';
import type { HTMLDataAttributes } from '../types';

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement>, HTMLDataAttributes {
  inline?: boolean;
}

const getLabelStyles = (classNamePrefix: string, theme: Theme, { inline }: { inline?: boolean }): SerializedStyles => {
  const styles = {
    '&&': {
      color: theme.colors.textPrimary,
      fontWeight: theme.typography.typographyBoldFontWeight,
      display: inline ? 'inline' : 'block',
      lineHeight: theme.typography.lineHeightBase,
    },

    [`&& + .${classNamePrefix}-input, && + .${classNamePrefix}-select, && + .${classNamePrefix}-selectv2, && + .${classNamePrefix}-dialogcombobox, && + .${classNamePrefix}-checkbox-group, && + .${classNamePrefix}-radio-group`]:
      {
        marginTop: theme.spacing.sm,
      },
  };

  return css(styles);
};

export const Label = (props: LabelProps) => {
  const { children, className, inline, ...restProps } = props;
  const { classNamePrefix, theme } = useDesignSystemTheme();

  return (
    <label
      css={getLabelStyles(classNamePrefix, theme, { inline })}
      className={classnames(`${classNamePrefix}-label`, className)}
      {...restProps}
    >
      <span css={{ display: 'flex', alignItems: 'center' }}>{children}</span>
    </label>
  );
};
