import React from 'react';
import PropTypes from 'prop-types';
import { Table, Divider, Badge } from 'antd';
import { Link } from 'react-router-dom';
import './ConcurrentListView.css';
import { getConcurrentPageRoute, getperiodicRunPageRoute } from '../routes';
import Utils from '../../common/utils/Utils';
import {
  AntdTableSortOrder,
  EMPTY_CELL_PLACEHOLDER,
  CONCURRENTS_PER_PAGE,
  CONCURRENTS_SEARCH_NAME_FIELD,
  CONCURRENTS_SEARCH_TIMESTAMP_FIELD,
} from '../constants';
import {
  ExperimentSearchSyntaxDocUrl,
  ModelRegistryDocUrl,
  ModelRegistryOnboardingString,
  onboarding,
} from '../../common/constants';
import { renderScheduledTime, renderNextRunTime } from '../utils/PeriodicRunUtils';
import { Spinner } from '../../common/components/Spinner';
import LocalStorageUtils from '../../common/utils/LocalStorageUtils';
import { PageHeader } from '../../shared/building_blocks/PageHeader';
import { FlexBar } from '../../shared/building_blocks/FlexBar';
import { Spacer } from '../../shared/building_blocks/Spacer';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PageContainer } from '../../common/components/PageContainer';
import {
  Alert,
  Button,
  Input,
  Spacer as DuBoisSpacer,
} from '@databricks/design-system';

const NAME_COLUMN_INDEX = 'name';
const LAST_MODIFIED_COLUMN_INDEX = 'last_updated_timestamp';

type Props = {
  orderByKey: string;
  orderByAsc: boolean;
  searchInput: any;
  concurrents: any[];
  periodicRuns: any[];
  createConcurrent: (...args: any[]) => any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
}

type State = any;

export class ConcurrentListViewImpl extends React.Component<Props,State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      searchInput: '',
      periodicRunSearchInput: '',
    };
  }

  static propTypes = {
    getMaxResultValue: PropTypes.func.isRequired,
  };

  static defaultProps = {
    concurrents: [],
  };

  static getLocalStore(key: any) {
    return LocalStorageUtils.getStoreForComponent('ConcurrentListView', key);
  }

  componentDidMount() {
    const pageTitle = 'Infinstor Concurrents';
    Utils.updatePageTitle(pageTitle);
  }


  getSortOrder = (key: any) => {
    const { orderByKey, orderByAsc } = this.props;
    if (key !== orderByKey) {
      return null;
    }
    return { sortOrder: orderByAsc ? AntdTableSortOrder.ASC : AntdTableSortOrder.DESC };
  };

  getPeriodicTableColumns = () => {
    const columns = [
      {
        title: this.props.intl.formatMessage({
          defaultMessage: 'Name',
          description: 'Column title for periodic name in the periodic list page',
        }),
        className: 'periodic-name',
        dataIndex: NAME_COLUMN_INDEX,
        render: (text: any, row: any) => {
          return <Link to={getperiodicRunPageRoute(row.name, row.experiment_id )}>{row.name}</Link>;
        },
        sorter: true,
        //@ts-expect-error
        sorter: (a: any, b: any) => {
          let x = a.name.toUpperCase(),
              y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        }
      },

      {
        title: this.props.intl.formatMessage({
          defaultMessage: 'Concurrent Name',
          description: 'Column title for concurrent name for periodic run list page',
        }),
        render: (row: any) => <span>{ row.concurrent ? row.concurrent.dagName : "-" }</span>,
      },
      {
        title: this.props.intl.formatMessage({
          defaultMessage: 'Schedule Time',
          description: 'Column title for Schedule for periodic run list page',
        }),
        className: 'table-tag-container',
        render: (text: any, row: any) => renderScheduledTime(row),
      },
      // {
      //   title: this.props.intl.formatMessage({
      //     defaultMessage: 'Next Run Time',
      //     description: 'Column title for next run for periodic run list page',
      //   }),
      //   className: 'latest-version',
      //   render: (text, row) => renderNextRunTime(row),
      // },
    ];
    return columns;
  };
  
  getColumns = () => {
    const columns = [
      {
        title: this.props.intl.formatMessage({
          defaultMessage: 'Name',
          description: 'Column title for concurrent name in the concurrent list page',
        }),
        className: 'concurrent-name',
        dataIndex: NAME_COLUMN_INDEX,
        render: (text: any, row: any) => {
          return <Link to={getConcurrentPageRoute(row.dagid)}>{row.dagName}</Link>;
        },
        sorter: true,
        //@ts-expect-error
        sorter: (a: any, b: any) => {
          let x = a.dagName.toUpperCase(),
              y = b.dagName.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        }
      },

      {
        title: this.props.intl.formatMessage({
          defaultMessage: 'Concurrent ID',
          description: 'Column title for concurrent id in the concurrents list page',
        }),
        render: (row: any) => <span>{row.dagid}</span>,
      },
      {
        title: this.props.intl.formatMessage({
          defaultMessage: 'Last Modified',
          description:
            'Column title for last modified timestamp for a dag in the concurrent list page',
        }),
        className: 'last-modified',
        dataIndex: LAST_MODIFIED_COLUMN_INDEX,
        render: (text: any, row: any) => Utils.formatTimestamp(row.update_time),
        sorter: true,
        //@ts-expect-error
        sorter: (a: any, b: any) => Number(a.update_time) - Number(b.update_time), 
      },
      {
        title: this.props.intl.formatMessage({
          defaultMessage: 'PeriodicRun',
          description: 'Column title for latest Periodic Run in the concurrents list page',
        }),
        render: (text: any, row: any) => {
          const scheduled = row.scheduled;
          if(scheduled) {
            if(scheduled.length == 1) {
              return <Link to={getperiodicRunPageRoute(scheduled[0].name, scheduled[0].experiment_id )}>{scheduled[0].name}</Link>;
            } else  {
              return (<div>  
                <Link to={getperiodicRunPageRoute(scheduled[0].name, scheduled[0].experiment_id )}>{scheduled[0].name}</Link>
                {/* <Divider type="vertical" />
                {scheduled.length-1} more */}
              </div>);
            }
          } else {
            return('-');
          }
        },
      },
      {
        title: this.props.intl.formatMessage({
          defaultMessage: 'Creator',
          description: 'Column title for latest parallel version in the concurrents list page',
        }),
        className: 'latest-version',
        render: (text: any, row: any) => <span>{row.creator}</span>,
      },
      {
        title: this.props.intl.formatMessage({
          defaultMessage: 'Description',
          description: 'Column title for concurrent tags in the concurrents list page',
        }),
        className: 'table-tag-container',
        render: (row: any, index: any) => {
          return row.description
        },
      },
    ];
    return columns;
  };

  getRowKey = (record: any) => record.name;

  setLoadingFalse = () => {
    this.setState({ loading: false });
  };

  static getSortFieldName = (column: any) => {
    switch (column) {
      case NAME_COLUMN_INDEX:
        return CONCURRENTS_SEARCH_NAME_FIELD;
      case LAST_MODIFIED_COLUMN_INDEX:
        return CONCURRENTS_SEARCH_TIMESTAMP_FIELD;
      default:
        return null;
    }
  };

  // handleTableChange = (pagination, filters, sorter) => {
  //   this.setState({ loading: true, lastNavigationActionWasClickPrev: false });
  // };

  renderOnboardingContent() {
    const learnMoreLinkUrl = ConcurrentListViewImpl.getLearnMoreLinkUrl();
    const learnMoreDisplayString = ConcurrentListViewImpl.getLearnMoreDisplayString();
    const content = (
      <div>
        {learnMoreDisplayString}{' '}
        <FormattedMessage
          defaultMessage='<link>Learn more</link>'
          description='Learn more link on the concurrent list page with cloud-specific link'
          values={{
            link: (chunks) => (
              <a
                href={learnMoreLinkUrl}
                target='_blank'
                rel='noopener noreferrer'
                className='LinkColor'
              >
                {chunks}
              </a>
            ),
          }}
        />
      </div>
    );

    return this.state['showOnboardingHelper'] ? (
      <div>
        <Alert message={content} type='info' />
        <DuBoisSpacer />
      </div>
    ) : null;
  }

  getEmptyTextComponent() {
    const { searchInput } = this.props;
    const { lastNavigationActionWasClickPrev } = this.state;
    // Handle the case when emptiness is caused by search filter
    if (searchInput) {
      if (lastNavigationActionWasClickPrev) {
        return (
          'No concurrents found for the page. Please refresh the page as the underlying data may ' +
          'have changed significantly.'
        );
      } else {
        return 'No concurrents found.';
      }
    }
    return (
      <div>
        <span>
          <FormattedMessage
            defaultMessage='No concurrents yet. <link>Create a concurrent</link> to get started.'
            description='Placeholder text for empty concurrents table in the concurrents list page'
            values={{
              link: (chunks) => <div> test </div>
            }}
          />
        </span>
      </div>
    );
  }

  static getLearnMoreLinkUrl = () => ModelRegistryDocUrl;

  static getLearnMoreDisplayString = () => ModelRegistryOnboardingString;

  handleClickNext = () => {
    this.setState({ loading: true, lastNavigationActionWasClickPrev: false });
  };

  handleClickPrev = () => {
    this.setState({ loading: true, lastNavigationActionWasClickPrev: true });
  };

  handleConcurrentSearch = (event: any) => {
    this.setState({ searchInput: event.target.value });
  };

  handlePeriodicRunSearch = (event: any) => {
    this.setState({ periodicRunSearchInput: event.target.value });
  };

  getMaxResultsSelection = () => {
    return 10;
  };

  searchInputHelpTooltipContent = () => {
    return (
      <div className='search-input-tooltip-content'>
        <FormattedMessage
          // eslint-disable-next-line max-len
          defaultMessage='To search by tags or by names and tags, please use <link>MLflow Search Syntax</link>.{newline}Examples:{examples}'
          description='Tooltip string to explain how to search concurrents from the concurrent table'
          values={{
            newline: <br />,
            link: (chunks) => (
              <a href={ExperimentSearchSyntaxDocUrl} target='_blank' rel='noopener noreferrer'>
                {chunks}
              </a>
            ),
            examples: (
              <ul>
                <li>tags.key = "value"</li>
                <li>name ilike "%my_model_name%" and tags.key = "value"</li>
              </ul>
            ),
          }}
        />
      </div>
    );
  };

  render() {
    const { concurrents, periodicRuns } = this.props;
    const { loading, searchInput, periodicRunSearchInput } = this.state;

    const title = (
      <FormattedMessage
        defaultMessage='Concurrents'
        description='Header for displaying concurrents in the concurrent registry'
      />
    );

    const lowerCasedSearchInput = searchInput.toLowerCase();
    const filteredConcurrents = concurrents.filter(({ dagName }) =>
      dagName.toLowerCase().includes(lowerCasedSearchInput),
    );

    const lowerCasedPeriodicSearchInput = periodicRunSearchInput.toLowerCase();
    const filteredPeriodicRuns = periodicRuns.filter(({ name }) =>
      name.toLowerCase().includes(lowerCasedPeriodicSearchInput),
    );

    return (
      <PageContainer data-test-id='concurrentListView-container'>
        {this.renderOnboardingContent()}
        <div css={styles.searchFlexBar}>
          <FlexBar
            left={
              <Spacer size='small' direction='horizontal'>
                <PageHeader title='Concurrents'>
                  <Button
                    data-test-id='clear-button'
                    onClick={this.props.createConcurrent}
                    type='primary'
                  >
                    <FormattedMessage
                      defaultMessage='Create'
                      // eslint-disable-next-line max-len
                      description='String for the clear button to clear the text for searching concurrents'
                    />
                  </Button>
                </PageHeader>
              </Spacer>
            }
            right={
              <Spacer direction='horizontal' size='small'>
                <Spacer direction='horizontal' size='small'>
                  <div css={styles.nameSearchBox}>
                    <Input 
                      prefix={<i className='fas fa-search' style={{ fontStyle: 'normal' }} />}
                      placeholder='Search by consurrent name'
                      aria-label='Search by consurrent name'
                      value={searchInput}
                      onChange={this.handleConcurrentSearch}
                      data-test-id='search-consurrent'
                    />
                  </div>
                </Spacer>
              </Spacer>
            }
          />
        </div>
        <Table
          className='consurrent-template-table'
          dataSource={filteredConcurrents}
          columns={this.getColumns()}
          loading={loading && { indicator: <Spinner /> }}
          showSorterTooltip={false}
          locale={{ emptyText: <div style={{padding: '10%'}}> <span> No concurrents found. <Button type="link" style={{padding: '0px'}} onClick={this.props.createConcurrent}> Create Concurrent</Button> to get started.</span>  </div> }}
          pagination={{
            pageSize: 10,
          }}
        />
        <div css={styles.searchFlexBar}>
          <FlexBar 
            left={
              <Spacer size='small' direction='horizontal'>
                <PageHeader title='Periodic Run'> </PageHeader>
              </Spacer>
            }
            right={
              <Spacer direction='horizontal' size='small'>
                <Spacer direction='horizontal' size='small'>
                  <div css={styles.nameSearchBox}>
                    <Input 
                      prefix={<i className='fas fa-search' style={{ fontStyle: 'normal' }} />}
                      placeholder='Search by periodic run name'
                      aria-label='Search by periodic run name'
                      value={periodicRunSearchInput}
                      onChange={this.handlePeriodicRunSearch}
                      data-test-id='search-scheduled-concurrent-run'
                    />
                  </div>
                </Spacer>
              </Spacer>
            }
          />
        </div>
        <Table
            className='periodicrun-template-table'
            dataSource={filteredPeriodicRuns}
            columns={this.getPeriodicTableColumns()}
            loading={loading && { indicator: <Spinner /> }}
            showSorterTooltip={false}
            locale={{ emptyText: <div> <span> No periodic run found..</span>  </div> }}
            pagination={{
              pageSize: 10,
            }}
          />
      </PageContainer>
    );
  }
}

//@ts-expect-error
export const ConcurrentListView = injectIntl(ConcurrentListViewImpl);

const styles = {
  nameSearchBox: {
    width: '446px',
  },
  searchFlexBar: {
    marginBottom: '24px',
  },
  questionMark: {
    marginLeft: 4,
    cursor: 'pointer',
    color: '#888',
  },
};
