import {Node} from 'butterfly-dag';
import $ from 'jquery';
import './node.css';
class BaseNode extends Node {
  constructor(opts) {
    super(opts);
    this.options = opts;
  }

  draw = (opts) => {
    const container = $('<div class="base-node" ></div>')
      .attr('id', opts.id)
      .css('top', opts.top + 'px')
      .css('left', opts.left + 'px')
      .css('border', '3px solid '+opts.options.color);
    this._createText(container);
    container.on('click', (event) => {
      $(".base-node").css({ 'background-color' : '', 'color' : '#1a192b' });
      let id = event.currentTarget.id;
      if(opts.options.color.length > 6) {
        $('#'+id).css({"background-color": opts.options.color,"color": "#fff"});
      } else {
        $('#'+id).css({"background-color": '#1a192b',"color": "#fff"});
      }
    });
    return container[0];
  }
  _createTypeIcon(dom = this.dom) {
    const iconContainer = $(`<span class="icon-box ${this.options.className}"></span>`)[0];
    const icon = $(`<i class="iconfont ${this.options.iconType}"></i>`)[0];

    iconContainer.append(icon);
    $(dom).append(iconContainer);
  }

  _createText(dom = this.dom) {
    $('<span class="text-box"></span>').text(this.options.name).appendTo(dom);
  }
}

export default BaseNode;
