import { css } from '@emotion/react';
import * as Toggle from '@radix-ui/react-toggle';
import React, { useEffect, forwardRef } from 'react';

import type { Theme } from '../../theme';
import { useDesignSystemTheme } from '../Hooks';
import { CheckIcon } from '../Icon';

const getStyles = (theme: Theme) => {
  return css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',

    border: `1px solid ${theme.colors.actionDefaultBorderDefault}`,
    borderRadius: theme.general.borderRadiusBase,

    backgroundColor: 'transparent',
    color: theme.colors.actionDefaultTextDefault,

    height: theme.general.heightSm,
    padding: '0 12px',

    fontSize: theme.typography.fontSizeBase,
    lineHeight: `${theme.typography.lineHeightBase}px`,

    '&[data-state="on"]': {
      backgroundColor: theme.colors.actionDefaultBackgroundPress,
      color: theme.colors.actionDefaultTextPress,
      borderColor: theme.colors.actionDefaultBorderPress,
    },

    '&:hover': {
      cursor: 'pointer',
      color: theme.colors.actionDefaultTextHover,
      backgroundColor: theme.colors.actionDefaultBackgroundHover,
      borderColor: theme.colors.actionDefaultBorderHover,

      '& > svg': {
        stroke: theme.colors.actionDefaultBorderHover,
      },
    },

    '&:disabled': {
      cursor: 'default',
      border: 'transparent',
      color: theme.colors.actionDisabledText,
      backgroundColor: theme.colors.actionDisabledBackground,

      '& > svg': {
        stroke: theme.colors.border,
      },
    },
  });
};

const RectangleSvg = (props: React.SVGAttributes<any>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" />
  </svg>
);

export const ToggleButton = forwardRef<HTMLButtonElement, Toggle.ToggleProps>(
  ({ children, pressed, defaultPressed, ...props }, ref) => {
    const { theme } = useDesignSystemTheme();
    const [isPressed, setIsPressed] = React.useState(defaultPressed);

    useEffect(() => {
      setIsPressed(pressed);
    }, [pressed]);

    return (
      <Toggle.Root
        css={getStyles(theme)}
        {...props}
        pressed={isPressed}
        onPressedChange={(pressed: boolean) => {
          props.onPressedChange?.(pressed);
          setIsPressed(pressed);
        }}
        ref={ref}
      >
        {isPressed ? (
          <CheckIcon css={{ marginRight: theme.spacing.xs }} />
        ) : (
          <RectangleSvg
            css={{
              height: theme.typography.lineHeightBase,
              width: theme.typography.lineHeightSm,
              marginRight: theme.spacing.xs,
              stroke: theme.colors.border,
            }}
          />
        )}
        {children}
      </Toggle.Root>
    );
  },
);
