import type { getColors } from '../theme/colors';

export const ColorVars: Record<string, keyof ReturnType<typeof getColors>> = {
  primary: 'textPrimary',
  secondary: 'textSecondary',
  info: 'textValidationInfo',
  error: 'textValidationDanger',
  success: 'textValidationSuccess',
  warning: 'textValidationWarning',
};
