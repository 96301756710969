import {
  LIST_CONCURRENT,
  GET_CONCURRENT,
  GET_CONCURRENT_EXECUTION_LIST,
  GET_CONCURRENT_TEMPLATE_FOR_RUN,
  GET_CONCURRENT_STATUS_FOR_RUN,
  GET_PERIODICRUN
} from './actions';
import _ from 'lodash';
import { fulfilled, rejected } from '../common/utils/ActionUtils';

const getProtoField = (fieldName: any) => `${fieldName}`;

const concurrentByID = (state = {}, action: any) => {
  switch (action.type) {
    case fulfilled(LIST_CONCURRENT): {
      const concurrents = action.payload[getProtoField('xformDags')];
      const idToConcurrents: any = {};
      if (concurrents) {
        concurrents.forEach((concurrent: any) => (idToConcurrents[concurrent.dagid] = concurrent));
      }
      return {
        ...idToConcurrents,
      };
    }
    case fulfilled(GET_CONCURRENT): {
      const concurrent = action.payload.parallel;
      const concurrentID = concurrent.parallel_id;
      return {
        ...state,
        [concurrentID]: concurrent,
      };
    }
    default:
      return state;
  }
};

const concurrentExecutionByID = (state = {}, action: any) => {
  switch (action.type) {
    case fulfilled(GET_CONCURRENT_EXECUTION_LIST): {
      const concurrentExecutionList = action.payload[getProtoField('dag_execution_info')];
      const { concurrentID } = action.meta;
      return {
        ...state,
        [concurrentID]: concurrentExecutionList,
      };
    }
    default:
      return state;
  }
};

const concurrentTemplateforRun = (state = {}, action: any) => {
  switch (action.type) {
    case fulfilled(GET_CONCURRENT_TEMPLATE_FOR_RUN): {
      const newState: any = { ...state };
      if (action.payload) {
        newState[action.meta.run_id] = action.payload;
      } 
      return newState;
    }
    default:
      return state;
  }
};

export const concurrentStatusforRun = (state = {}, action: any) => {
  switch (action.type) {
    case fulfilled(GET_CONCURRENT_STATUS_FOR_RUN): { 
      const newState: any = { ...state };
      if (action.payload) {
        const result = action.payload.dag_execution_info;
        if (result) {
          newState[result.run_status.parent_run_id] = result;
        }
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}

const periodicRunByName = (state = {}, action: any) => {
  switch (action.type) {
    case fulfilled(GET_PERIODICRUN): {
      const periodicRuns = action.payload[getProtoField('periodicRuns')];
      const nameToPeriodicRuns: any = {};
      if (periodicRuns) {
        periodicRuns.forEach((run: any) => (nameToPeriodicRuns[run.name] = run));
      }
      return {
        ...nameToPeriodicRuns,
      };
    }
    default:
      return state;
  }
};

const periodicRunByDagid = (state = {}, action: any) => {
  switch (action.type) {
    case fulfilled(GET_PERIODICRUN): {
      const periodicRuns = action.payload[getProtoField('periodicRuns')];
      const dagidToPeriodicRuns: any = {};
      if (periodicRuns) {
        periodicRuns.forEach((run: any) => {
          // multiple scheduled run
          if(dagidToPeriodicRuns[run.dagid]) {
            dagidToPeriodicRuns[run.dagid].push(run);
          } else {
            dagidToPeriodicRuns[run.dagid] = [run];
          }

          //dagidToPeriodicRuns[run.dagid] = run;
        });
      }
      return {
        ...dagidToPeriodicRuns,
      };
    }
    default:
      return state;
  }
};

const reducers = {
  concurrentByID,
  concurrentExecutionByID,
  concurrentTemplateforRun,
  concurrentStatusforRun,
  periodicRunByName,
  periodicRunByDagid,
};

export const getConcurrentTags = (parallelID: string, state: any) => {
  if(state.entities.concurrentByID[parallelID]) {
    return state.entities.concurrentByID[parallelID].tags || {};
  } else {
    return {};
  }
}

export default reducers;