import React from 'react';
import { connect } from 'react-redux';
import Utils from '../../common/utils/Utils';
import { getUUID } from '../../common/utils/ActionUtils';
import { ErrorView } from '../../common/components/ErrorView';
import { injectIntl } from 'react-intl';
import { withRouterNext } from '../../common/utils/withRouterNext';
import type { WithRouterNextProps } from '../../common/utils/withRouterNext';
import { withErrorBoundary } from '../../common/utils/withErrorBoundary';
import ErrorUtils from '../../common/utils/ErrorUtils';
import { PageContainer } from '../../common/components/PageContainer';
import RequestStateWrapper, { triggerError } from '../../common/components/RequestStateWrapper';
import { getModelGatewayRouteApi, getModelGatewayRouteLimitsApi, getModelGatewayRouteAuthorizationApi } from 'experiment-tracking/actions/ModelGatewayActions';
import type { RouteEntity } from '../types';
import Routes from 'experiment-tracking/routes'
import { GatewayRouteView } from './GatewayRouteView';
import { Spinner } from '../../common/components/Spinner';
import { GateWayRouteAuthorization, ModelGatewayRouteLimits } from 'experiment-tracking/sdk/ModelGatewayService';

type GatewayRoutePageImplProps = WithRouterNextProps<{ subpage: string }> & {
  gatewayRouteName: string;
  gatewayRoute: RouteEntity;
  gatewayRouteAuthorization: GateWayRouteAuthorization[];
  gatewayLimits: any[];
  getModelGatewayRouteApi: (...args: any[]) => any;
  getModelGatewayRouteLimitsApi: (...args: any[]) => any;
  getModelGatewayRouteAuthorizationApi: (...args: any[]) => any;
  intl?: any;
};
type GatewayRoutePageImplState = any;

export class GatewayRoutePageImpl extends React.Component<GatewayRoutePageImplProps, GatewayRoutePageImplState> {
  initgetGatewayRouteLimitsApiRequestId = getUUID();
  initgetGatewayRouteApiRequestId = getUUID();
  updateRegisteredModelApiId = getUUID();
  deleteRegisteredModelApiId = getUUID();
  getModelAuthorizationApiRequestId = getUUID();

  criticalInitialRequestIds = [
    this.initgetGatewayRouteApiRequestId,
    this.initgetGatewayRouteLimitsApiRequestId,
    this.getModelAuthorizationApiRequestId
  ];

  state = {
    isDeleteModalVisible: false,
    isDeleteModalConfirmLoading: false,
    authorizationRequestPending: false,
  };

  componentDidMount(): void {
    const { gatewayRouteName } = this.props;
    this.props.getModelGatewayRouteApi(gatewayRouteName, this.initgetGatewayRouteApiRequestId);
    this.props.getModelGatewayRouteLimitsApi(gatewayRouteName, this.initgetGatewayRouteLimitsApiRequestId);
    this.props.getModelGatewayRouteAuthorizationApi(gatewayRouteName, this.getModelAuthorizationApiRequestId);
  }

  render() {
    const { gatewayRouteName, gatewayRoute, gatewayRouteAuthorization, gatewayLimits, navigate } = this.props;
    return (
      <PageContainer>
        <RequestStateWrapper requestIds={this.criticalInitialRequestIds}>
          {(loading: any, hasError: any, requests: any) => {
            if (hasError) {
               // TODO Check error and display valied error message.
              return (
                <ErrorView
                  statusCode={404}
                  subMessage={this.props.intl.formatMessage(
                    {
                      defaultMessage: 'GatewayRoute {gatewayRouteName} does not exist',
                      description: 'Sub-message text for error message on overall gateway route page',
                    },
                    {
                      gatewayRouteName: gatewayRouteName,
                    },
                  )}
                  fallbackHomePageReactRoute={Routes.gatewayRouteListPageRoute}
                />
              );
            } else if (loading) {
             return <Spinner />;
            } else if (gatewayRoute) {
              return (<GatewayRouteView
                limits= {gatewayLimits}
                authorization={gatewayRouteAuthorization}
                gatewayRoute={gatewayRoute}
                navigate={navigate}
              />)
            }
            return null;
          }}
        </RequestStateWrapper>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state: any, ownProps: WithRouterNextProps<{ routeName: string }>) => {
  const gatewayRouteName = decodeURIComponent(ownProps.params.routeName);
  const gatewayRoute = state.modelGateway.modelGatewayRoutes[gatewayRouteName];
  const gatewayRouteAuthorization = state.modelGateway.modelGatewayRoutesAuthorization[gatewayRouteName];
  const gatewayLimits = state.modelGateway.modelGatewayRouteslimits[gatewayRouteName] || [];

  return {
    gatewayRouteName,
    gatewayRoute,
    gatewayRouteAuthorization,
    gatewayLimits
  };
};

const mapDispatchToProps = {
  getModelGatewayRouteApi,
  getModelGatewayRouteLimitsApi,
  getModelGatewayRouteAuthorizationApi
};

const GatewayRoutePageWithRouter = withRouterNext(
  // @ts-expect-error TS(2769): No overload matches this call.
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(GatewayRoutePageImpl)),
);

export const GatewayRoutePage = withErrorBoundary(
  ErrorUtils.mlflowServices.MODEL_REGISTRY,
  GatewayRoutePageWithRouter,
);
