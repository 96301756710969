import { Component } from 'react';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Input, Form } from 'antd';

type Props = {
  param: any;
  innerRef: any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
};

/**
 * Component that renders a form for adding new key word arg.
 */
class AddKwargFormComponent extends Component<Props> {

  componentDidUpdate(prevProps: any) {
    this.resetFields(prevProps);
  }

  resetFields = (prevProps: any) => {
    const formRef = this.props.innerRef;
    if (!_.isEqual(prevProps.param, this.props.param)) {
      // reset input field to reset displayed initialValue
      formRef.current.resetFields();
    }
  };

  render() {
    const { param } = this.props;

    return (
      <Form ref={this.props.innerRef} layout='vertical' initialValues={param}>
        <Form.Item
          label={this.props.intl.formatMessage({
            defaultMessage: 'Key',
            description: 'Label for key',
          })}
          name='key'
          rules={[
            { required: true }
          ]}
        >
          <Input
            placeholder={this.props.intl.formatMessage({
              defaultMessage: 'kay',
              description: 'Label for key',
            })}
            autoFocus
          />
        </Form.Item>
        <Form.Item
          name='value'
          label={this.props.intl.formatMessage({
            defaultMessage: 'Value',
            description: 'Label for add Kwarg modal to enter value',
          })}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={this.props.intl.formatMessage({
              defaultMessage: 'value',
              description: 'Input placeholder to enter value',
            })}
          />
        </Form.Item>
      </Form>
    );
  }
}

//@ts-expect-error
export const AddKwargForm = injectIntl(AddKwargFormComponent);
