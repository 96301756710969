import { css } from '@emotion/react';
import type { UseComboboxReturnValue } from 'downshift';
import { forwardRef } from 'react';

import type { Theme } from '../../theme';
import { useDesignSystemTheme } from '../Hooks';
import { CheckIcon } from '../Icon';
import { InfoTooltip } from '../Tooltip';
import { getComboboxOptionItemWrapperStyles, HintRow, getInfoIconStyles } from '../_shared_/Combobox';

export interface TypeaheadComboboxMenuItemProps<T> extends React.HTMLAttributes<HTMLElement> {
  item: T;
  index: number;
  comboboxState: UseComboboxReturnValue<T>;
  textOverflowMode?: 'ellipsis' | 'multiline';
  isDisabled?: boolean;
  disabledReason?: React.ReactNode;
  hintContent?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  _TYPE?: string;
}

export const getMenuItemStyles = (theme: Theme, isHighlighted: boolean, disabled?: boolean) => {
  return css({
    ...(disabled && {
      pointerEvents: 'none',
      color: theme.colors.actionDisabledText,
    }),

    ...(isHighlighted && {
      background: theme.colors.actionTertiaryBackgroundHover,
    }),
  });
};

const getLabelStyles = (theme: Theme, textOverflowMode: 'ellipsis' | 'multiline') => {
  return css({
    marginLeft: theme.spacing.sm,
    fontSize: theme.typography.fontSizeBase,
    fontStyle: 'normal',
    fontWeight: 400,
    cursor: 'pointer',
    overflow: 'hidden',
    wordBreak: 'break-word',

    ...(textOverflowMode === 'ellipsis' && {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  });
};

export const TypeaheadComboboxMenuItem = forwardRef<HTMLLIElement, TypeaheadComboboxMenuItemProps<any>>(
  (
    {
      item,
      index,
      comboboxState,
      textOverflowMode = 'multiline',
      isDisabled,
      disabledReason,
      hintContent,
      children,
      ...restProps
    },
    ref,
  ) => {
    const { selectedItem, highlightedIndex, getItemProps } = comboboxState;
    const isSelected = selectedItem === item;
    const isHighlighted = highlightedIndex === index;
    const { theme } = useDesignSystemTheme();

    return (
      <li
        ref={ref}
        role="option"
        aria-selected={isSelected}
        disabled={isDisabled}
        css={[getComboboxOptionItemWrapperStyles(theme), getMenuItemStyles(theme, isHighlighted, isDisabled)]}
        {...getItemProps({ item, index, disabled: isDisabled })}
        {...restProps}
      >
        {isSelected ? <CheckIcon css={{ paddingTop: 2 }} /> : <div style={{ width: 16, flexShrink: 0 }} />}
        <label css={getLabelStyles(theme, textOverflowMode)}>
          {isDisabled && disabledReason ? (
            <div css={{ display: 'flex' }}>
              <div>{children}</div>
              <div css={getInfoIconStyles(theme)}>
                <InfoTooltip title={disabledReason} />
              </div>
            </div>
          ) : (
            children
          )}
          <HintRow disabled={isDisabled}>{hintContent}</HintRow>
        </label>
      </li>
    );
  },
);

TypeaheadComboboxMenuItem.defaultProps = {
  _TYPE: 'TypeaheadComboboxMenuItem',
};

export default TypeaheadComboboxMenuItem;
