import React from 'react';
import Utils from '../../common/utils/Utils';
import { Link } from 'react-router-dom';
import { 
  concurrentsListPageRoute, 
  getConcurrentTemplatePageRoute, 
  getperiodicRunPageRoute } from '../routes';
import { message } from 'antd';
import { CollapsibleSection } from '../../common/components/CollapsibleSection';
import { EditableNote } from '../../common/components/EditableNote';
import { EditableTagsTableView } from '../../common/components/EditableTagsTableView';
import { 
  getConcurrentApi,
  createConcurrentApi, 
  setConcurrentTagApi, 
  deleteConcurrentTagApi,
} from '../actions';
import { connect } from 'react-redux';
import { OverflowMenu, PageHeader } from '../../shared/building_blocks/PageHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, Button } from '@databricks/design-system';
import { Descriptions } from '../../common/components/Descriptions';
import { getConcurrentTags } from "../reducers";
import { ConcurrentExecutionTable } from "./ConcurrentExecutionTable";
import { CloneConcurrentModal } from './modal/CloneConcurrentModel';
import { CreatePeriodicRunModal } from './modal/CreatePeriodicRunModal';
import { encodeNodeURI } from '../utils/TemplateUtils';
import { withRouterNext } from '../../common/utils/withRouterNext';
import type { WithRouterNextProps } from '../../common/utils/withRouterNext';
import './ConcurrentView.css';

export const StageFilters = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
};

type Props = WithRouterNextProps & {
  tags: any;
  history: any;
  concurrent: any;
  periodicRuns: any;
  concurrents: any[];
  concurrentID: string;
  concurrentVersions: any[];
  concurrentExecutionList: any[];
  handleEditDescription: (...args: any[]) => any;
  handleDelete: (...args: any[]) => any;
  getConcurrentApi: (...args: any[]) => any;
  createConcurrentApi: (...args: any[]) => any;
  setConcurrentTagApi: (...args: any[]) => any;
  deleteConcurrentTagApi: (...args: any[]) => any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
}

type State = any;
export class ConcurrentViewImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    showDescriptionEditor: false,
    isDeleteModalVisible: false,
    isDeleteModalConfirmLoading: false,
    runsSelected: {},
    isTagsRequestPending: false,
    showCloneConcurrentModal: false,
    showCreatePeriodicRunModal: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    const pageTitle = `${this.props.concurrent.parallel_name} - MLflow Concurrent`;
    Utils.updatePageTitle(pageTitle);
  }

  handleStageFilterChange = (e: any) => {
    this.setState({ stageFilter: e.target.value });
  };

  handleCancelEditDescription = () => {
    this.setState({ showDescriptionEditor: false });
  };

  handleSubmitEditDescription = (description: any) => {
    return this.props.handleEditDescription(description).then(() => {
      this.setState({ showDescriptionEditor: false });
    });
  };

  startEditingDescription = (e: any) => {
    e.stopPropagation();
    this.setState({ showDescriptionEditor: true });
  };

  
  handleEditTemplate = () => {
    const { concurrent } = this.props;
    this.props.navigate(getConcurrentTemplatePageRoute(concurrent.dagid, 'edit'));
  }

  handleRunTemplate = () => {
    const { concurrent } = this.props;
    this.props.navigate(getConcurrentTemplatePageRoute(concurrent.dagid, 'run'));
  }

  getOverflowMenuItems() {
    const { concurrent } = this.props;
    const menuItems = [
      {
        id: 'edit',
        itemName: (
          <FormattedMessage
            defaultMessage='Edit Template'
            // eslint-disable-next-line max-len
            description='Text for disabled delete button due to active versions on concurrent view page header'
          />
        ),
        onClick: this.handleEditTemplate,
      },
      {
        id: 'clone',
        itemName: (
          <FormattedMessage
            defaultMessage='Clone Template'
            // eslint-disable-next-line max-len
            description='Text for disabled delete button due to active versions on concurrent view page header'
          />
        ),
        onClick: this.showCloneConcurrentModal,
      },
      {
        id: 'delete',
        itemName: (
          <FormattedMessage
            defaultMessage='Delete Concurrent'
            // eslint-disable-next-line max-len
            description='Text for disabled delete button due to active versions on concurrent view page header'
          />
        ),
        onClick: this.showDeleteModal,
        disabled: concurrent.scheduled,
      },
    ];

    return menuItems;
  }

  showCloneConcurrentModal = () => {
    this.setState({ showCloneConcurrentModal: true });
  };

  hideCloneConcurrentModal = () => {
    this.setState({ showCloneConcurrentModal: false });
  };
  
  showDeleteModal = () => {
    this.setState({ isDeleteModalVisible: true });
  };

  hideDeleteModal = () => {
    this.setState({ isDeleteModalVisible: false });
  };

  showConfirmLoading = () => {
    this.setState({ isDeleteModalConfirmLoading: true });
  };

  hideConfirmLoading = () => {
    this.setState({ isDeleteModalConfirmLoading: false });
  };

  handleDeleteConfirm = () => {
    const { navigate } = this.props;
    this.showConfirmLoading();
    this.props
      .handleDelete()
      .then(() => {
        navigate(concurrentsListPageRoute);
      })
      .catch((e: any) => {
        this.hideConfirmLoading();
        Utils.logErrorAndNotifyUser(e);
      });
  };

  handleAddTag = (values: any) => {
    const form: any = this.formRef.current;
    const { concurrentID } = this.props;
    this.setState({ isTagsRequestPending: true });
    this.props
      .setConcurrentTagApi(concurrentID, values.name, values.value)
      .then(() => {
        this.props.getConcurrentApi(concurrentID).then(()=> {
          this.setState({ isTagsRequestPending: false });
          form.resetFields();
        });
      })
      .catch((ex: any) => {
        this.setState({ isTagsRequestPending: false });
        console.error(ex);
        message.error('Failed to add tag. Error: ' + ex.getUserVisibleError());
      });
  };

  handleSaveEdit = ({ name, value }: any) => {
    const { concurrentID } = this.props;
    return this.props.setConcurrentTagApi(concurrentID, name, value).then(() => {
      return this.props.getConcurrentApi(concurrentID);
    }).catch((ex: any) => {
      console.error(ex);
      message.error('Failed to set tag. Error: ' + ex.getUserVisibleError());
    });
  };

  handleDeleteTag = ({ name }: any) => {
    const { concurrentID } = this.props;
    return this.props.deleteConcurrentTagApi(concurrentID, name).then(() => {
      return this.props.getConcurrentApi(concurrentID);
    }).catch((ex: any) => {
      console.error(ex);
      message.error('Failed to delete tag. Error: ' + ex.getUserVisibleError());
    });
  };

  renderDescriptionEditIcon() {
    return (
      <Button
        data-test-id='descriptionEditButton'
        type='link'
        css={styles.editButton}
        onClick={this.startEditingDescription}
      >
        <FormattedMessage
          defaultMessage='Edit'
          description='Text for the edit button next to the description section title on
             the concurrent view page'
        />
      </Button>
    );
  }

  cloneConcurrent = async (values: any) => {
    const { concurrent } = this.props;
    const concurrentTemplate = JSON.parse(concurrent.dagJson);

    const dagJson = {
      name: values.name,
      node: encodeNodeURI(concurrentTemplate.node),
      edge: concurrentTemplate.edge,
    };

    let description = "";
    if(concurrent.description) {
      description = concurrent.description;
    }
    await this.props.createConcurrentApi(values.name, dagJson, description).then((apiResponse: any) => {
      const concurrent_id = apiResponse.value.parallel_id;
      if(concurrent_id) {
        message.success(values.name + ' concurrent created.');
        this.props.navigate(concurrentsListPageRoute);
      } else {
        message.error('Clone concurrent failed. please try again');
      }
    });
  }

  handleCloseCreatePeriodicRunModal = () => {
    this.setState({showCreatePeriodicRunModal : false });
  }

  renderDetails = () => {
    const { concurrent, concurrents, concurrentExecutionList, tags } = this.props;
    const {
      showDescriptionEditor,
      isDeleteModalVisible,
      isDeleteModalConfirmLoading,
      isTagsRequestPending,
      showCloneConcurrentModal
    } = this.state;
    const concurrentName = concurrent.parallel_name;
    const dagid = concurrent.dagid;

    return (
      <div>
        <Descriptions columns={3} data-testid='concurrent-view-metadata'>
          <Descriptions.Item
            data-testid='concurrent-view-metadata-item'
            label={this.props.intl.formatMessage({
              defaultMessage: 'Created Time',
              description:
                'Label name for the created time under details tab on the concurrent view page',
            })}
          >
            {Utils.formatTimestamp(concurrent.creation_time)}
          </Descriptions.Item>
          <Descriptions.Item
            data-testid='concurrent-view-metadata-item'
            label={this.props.intl.formatMessage({
              defaultMessage: 'Creator',
              description: 'Lable name for the creator under details tab on the concurrent view page',
            })}
          >
            <div>{concurrent.creator}</div>
          </Descriptions.Item>
          <Descriptions.Item
            data-testid='concurrent-view-metadata-item'
            label={this.props.intl.formatMessage({
              defaultMessage: 'Periodic Run',
              description: 'Lable name for the Periodic Run under details tab on the concurrent view page',
            })}
          >
            <div> 
              {concurrent.scheduled ? <Link to={getperiodicRunPageRoute(concurrent.scheduled[0].name, concurrent.scheduled[0].experiment_id )}>{concurrent.scheduled[0].name}</Link> : 
              <Button data-test-id='periodicrunButton' className='create-periodic-btn'
                onClick={() => this.setState({showCreatePeriodicRunModal : true }) }
              >
                Create
              </Button> }
            </div>
          </Descriptions.Item>
        </Descriptions>
        {/* Page Sections */}
        <CollapsibleSection
          title={
            <span>
              <FormattedMessage
                defaultMessage='Description'
                description='Title text for the description section under details tab on the concurrent
                   view page'
              />{' '}
              
              {!showDescriptionEditor ? this.renderDescriptionEditIcon() : null}
            </span>
          }
          forceOpen={showDescriptionEditor}
          defaultCollapsed={!concurrent.description}
          data-test-id='concurrent-description-section'
        >
          <EditableNote
          //@ts-expect-error
            defaultMarkdown={concurrent.description}
            onSubmit={this.handleSubmitEditDescription}
            onCancel={this.handleCancelEditDescription}
            showEditor={showDescriptionEditor}
          />
        </CollapsibleSection>
        <div data-test-id='tags-section'>
          <CollapsibleSection
            title={
              <FormattedMessage
                defaultMessage='Tags'
                description='Title text for the tags section under details tab on the concurrent view
                   page'
              />
            }
            defaultCollapsed={Utils.getVisibleTagValues(tags).length === 0}
            data-test-id='concurrent-tags-section'
          >
            <EditableTagsTableView
            //@ts-expect-error
              innerRef={this.formRef}
              handleAddTag={this.handleAddTag}
              handleDeleteTag={this.handleDeleteTag}
              handleSaveEdit={this.handleSaveEdit}
              tags={tags}
              isRequestPending={isTagsRequestPending}
            />
          </CollapsibleSection>
        </div>
        <CollapsibleSection
          title={
            <>
              <div css={styles.versionsTabButtons}>
                <span>
                  <FormattedMessage
                    defaultMessage='instances'
                    description='Title text for the instances section under details tab on the
                       concurrent view page'
                  />
                </span>
              </div>
            </>
          }
          data-test-id='concurrent-run-instances-section'
        >
          <div> 
            <ConcurrentExecutionTable {...this.props}
            //@ts-expect-error
              concurrentExecutionList={concurrentExecutionList}
              concurrent={concurrent} 
            /> 
          </div>
        </CollapsibleSection>

        {/* Delete concurrent Dialog */}
        <Modal
          data-testid='mlflow-input-modal'
          title={this.props.intl.formatMessage({
            defaultMessage: 'Delete concurrent',
            description: 'Title text for delete concurrent modal on concurrent view page',
          })}
          visible={isDeleteModalVisible}
          confirmLoading={isDeleteModalConfirmLoading}
          onOk={this.handleDeleteConfirm}
          okText={this.props.intl.formatMessage({
            defaultMessage: 'Delete',
            description: 'OK text for delete concurrent modal on concurrent view page',
          })}
          cancelText={this.props.intl.formatMessage({
            defaultMessage: 'Cancel',
            description: 'Cancel text for delete concurrent modal on concurrent view page',
          })}
          //@ts-expect-error
          okType='danger'
          onCancel={this.hideDeleteModal}
        >
          <span>
            <FormattedMessage
              defaultMessage='Are you sure you want to delete {concurrentName}? This cannot be undone.'
              description='Confirmation message for delete concurrent modal on concurrent view page'
              values={{ concurrentName: concurrentName }}
            />
          </span>
        </Modal>
        <CloneConcurrentModal
        //@ts-expect-error
          isOpen={showCloneConcurrentModal}
          onClose={this.hideCloneConcurrentModal}
          handleSubmit={this.cloneConcurrent}
          parallels={concurrents}
        />
        <CreatePeriodicRunModal
          isOpen={this.state.showCreatePeriodicRunModal}
          onClose={this.handleCloseCreatePeriodicRunModal}
          dagid={dagid}
          periodicRuns={this.props.periodicRuns}
          history = {this.props.history}
        />
      </div>
    );
  };

  renderMainPanel() {
    return this.renderDetails();
  }

  render() {
    const { concurrent } = this.props;
    const concurrentName = concurrent.parallel_name;

    const breadcrumbs = [
      <Link to={concurrentsListPageRoute}>
        <FormattedMessage
          defaultMessage='Concurrents Templates'
          description='Text for link back to concurrent page under the header on the concurrent view page'
        />
      </Link>,
    ];
    return (
      <div>
        <PageHeader title={concurrentName} breadcrumbs={breadcrumbs}>
          <Button data-test-id='runButton' onClick={this.handleRunTemplate}>
            Run Template
          </Button>
          <OverflowMenu menu={this.getOverflowMenuItems()} />
        </PageHeader>
        {this.renderMainPanel()}
      </div>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const concurrentID = ownProps.concurrent.parallel_id;
  const tags = getConcurrentTags(concurrentID, state);
  const concurrents = Object.values(state.entities.concurrentByID);
  const periodicRuns = Object.values(state.entities.periodicRunByName);
  concurrents.sort((a: any, b: any) => a.dagName.localeCompare(b.dagName)); 
  return {concurrentID , tags, concurrents, periodicRuns };
};

const mapDispatchToProps = { 
  createConcurrentApi,
  getConcurrentApi,
  setConcurrentTagApi, 
  deleteConcurrentTagApi,
};

const styles = {
  editButton: (theme: any) => ({
    marginLeft: theme.spacing.md,
  }),
  versionsTabButtons: (theme: any) => ({
    display: 'flex',
    gap: theme.spacing.md,
    alignItems: 'center',
  }),
};

export const ConcurrentView = withRouterNext(
  //@ts-expect-error
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConcurrentViewImpl))
);
