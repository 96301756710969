import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import type { Theme } from '../../../theme';
import { importantify } from '../../utils/css-utils';

export const getComboboxContentWrapperStyles = (
  theme: Theme,
  {
    maxHeight = '100vh',
    maxWidth = '100vw',
    minHeight = 0,
    minWidth = 0,
    width,
  }: {
    maxHeight?: number | string;
    maxWidth?: number | string;
    minHeight?: number | string;
    minWidth?: number | string;
    width?: number | string;
  },
): SerializedStyles => {
  return css({
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    ...(width ? { width } : {}),
    background: theme.colors.backgroundPrimary,
    color: theme.colors.textPrimary,
    overflow: 'auto',
    // Making sure the content popover overlaps the remove button when opens to the right
    zIndex: theme.options.zIndexBase + 10,

    boxSizing: 'border-box',

    border: `1px solid ${theme.colors.border}`,
    boxShadow: theme.general.shadowLow,
    borderRadius: theme.general.borderRadiusBase,

    colorScheme: theme.isDarkMode ? 'dark' : 'light',
  });
};

export const getComboboxOptionItemWrapperStyles = (theme: Theme): SerializedStyles => {
  return css(
    importantify({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      alignSelf: 'stretch',

      padding: '6px 32px 6px 12px',
      lineHeight: theme.typography.lineHeightBase,
      boxSizing: 'content-box',

      cursor: 'pointer',
      userSelect: 'none',

      '&:hover, &[data-highlighted="true"]': { background: theme.colors.actionTertiaryBackgroundHover },
      '&:focus': {
        background: theme.colors.actionTertiaryBackgroundHover,
        outline: 'none',
      },
      '&[disabled]': {
        pointerEvents: 'none',
        color: theme.colors.actionDisabledText,
        background: theme.colors.backgroundPrimary,
      },
    }),
  );
};

export const getInfoIconStyles = (theme: Theme): SerializedStyles => {
  return css({
    paddingLeft: theme.spacing.xs,
    color: theme.colors.textSecondary,
    pointerEvents: 'all',
    cursor: 'pointer',
    verticalAlign: 'middle',
  });
};

export const getCheckboxStyles = (theme: Theme, textOverflowMode: 'ellipsis' | 'multiline'): SerializedStyles => {
  return css({
    pointerEvents: 'none',
    height: 'unset',
    width: '100%',

    '& > label': {
      display: 'flex',
      width: '100%',
      fontSize: theme.typography.fontSizeBase,
      fontStyle: 'normal',
      fontWeight: 400,
      cursor: 'pointer',

      '& > span:first-of-type': {
        alignSelf: 'flex-start',
        marginTop: theme.spacing.xs / 2,
      },

      '& > span:last-of-type': {
        paddingRight: 0,
        width: '100%',
        overflow: 'hidden',
        wordBreak: 'break-word',
        ...(textOverflowMode === 'ellipsis'
          ? {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }
          : {}),
      },
    },
  });
};

export const getFooterStyles = (theme: Theme): SerializedStyles => {
  return css({
    width: '100%',
    background: theme.colors.backgroundPrimary,
    padding: `${theme.spacing.sm}px ${theme.spacing.lg / 2}px ${theme.spacing.sm}px ${theme.spacing.lg / 2}px`,
    position: 'sticky',
    bottom: 0,
  });
};
