import { Component } from 'react';
import { GenericInputModal } from '../../../experiment-tracking/components/modals/GenericInputModal';
import { AddNodeForm } from './AddNodeForm';
import { injectIntl } from 'react-intl';

type Props = {
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  handleSubmit: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  isOpen: boolean;
  clusters: any;
  nodes: any[];
}

type State = any;

export class AddNodeModalImpl extends Component<Props, State>  {
  getNodeNamesAsSet = () =>
    new Set(this.props.nodes.map((values) => values['name']));

  hasSpecialChar = (s: any) => {
    const specialChars = /[` !@#$%^&*()\+=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(s);
  }

  nodeNameValidator = (rule: any, value: any, callback: any) => {
    const nodeNamesSet = this.getNodeNamesAsSet();
    callback(
      nodeNamesSet.has(value)
        ? this.props.intl.formatMessage(
            {
              defaultMessage: 'Node "{value}" already exists.',
              description: 'Validation message for nodes that already exist in nodes list in MLflow Pipeline',
            },
            {
              value: value,
            },
          )
        : this.hasSpecialChar(value) ? this.props.intl.formatMessage(
          {
            defaultMessage: 'Node name contains invalid characters(spaces or special characters).',
            description: 'Validation message for node name has spaces or special characters in MLflow Pipeline',
          }
        ) : undefined,
    );
  };
  render() {
    const { isOpen, clusters } = this.props;
    return (
      <GenericInputModal
        title='Add Node'
        okText='Add'
        isOpen={isOpen}
        handleSubmit={this.props.handleSubmit}
        onClose={this.props.onClose}
      >
        {/*//@ts-expect-error */}
        <AddNodeForm nodeNameValidator={this.nodeNameValidator} clusters={clusters}/>
      </GenericInputModal>
    );
  }
}

//@ts-expect-error
export const AddNodeModal = injectIntl(AddNodeModalImpl);
