import React from 'react';
import { Form, Input, Button, Table, Popconfirm } from 'antd/lib/index';
import { IconButton } from '../../common/components/IconButton';
import { Spacer } from '../../shared/building_blocks/Spacer';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Select } from 'antd';

const { Option } = Select;
type Props = {
  innerRef: any;
  cluster_access: any;
  isRequestPending: boolean;
  handleAddAccess: (...args: any[]) => any;
  handleDeleteAccess: (...args: any[]) => any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
}

type State = any;

export class ManageClusterAccessImpl extends React.Component<Props, State> {

  render() {
    const { isRequestPending, handleDeleteAccess, handleAddAccess, cluster_access, innerRef, } = this.props;
    const tableColumns = [
        {
          title: this.props.intl.formatMessage({
            defaultMessage: 'Type (user/group)',
            description: 'Column title for type column in editable roles in permission table',
          }),
          dataIndex: 'principal_type',
        },
        {
          title: this.props.intl.formatMessage({
            defaultMessage: 'Name',
            description: 'Column title for name column in editable roles in permission table',
          }),
          dataIndex: 'principal_name',
        },
        {
            title: 'Action',
            className: 'table-tag-container',
            render: (row: any, index: number) => {
              return <div> 
              <Popconfirm
                title='Are you sure you want to delete this cluster access？'
                okText='Yes'
                cancelText='No'
                onConfirm={() => handleDeleteAccess(row)}
              >
                <IconButton icon={<i className='far fa-trash-o' />} />
              </Popconfirm>
            </div>
            },
          },
      ];

    return (
      <Spacer direction='vertical' >
        <Table
          className='editable-table'
          dataSource={cluster_access}
          //@ts-expect-error
          columns={tableColumns}
          size='middle'
          pagination={false}
          locale={{
            emptyText: (
              <FormattedMessage
                defaultMessage='user/group access not found.'
                description='Text for user/group access not found in cluster access table'
              />
            ),
          }}
          scroll={{ y: 280 }}
          style={{ width: 670 }}
        />
        <div style={styles.addTagForm.wrapper}>
          <Form ref={innerRef} layout='inline' onFinish={handleAddAccess}>
            <Form.Item name="type" initialValue="user">
              <Select
                className='user-Type-select'
                size='large'
                data-test-id='user-Type-select-dropdown'
                style={styles.addTagForm.valueInput}
              >
                <Option value="user" key="user"> User </Option>
                <Option value="group" key="group"> Group </Option>
              </Select>
            </Form.Item>
            <Form.Item name="user" rules={
              [
                {
                  required: true,
                  message: this.props.intl.formatMessage({
                    defaultMessage: 'Name is required.',
                    description:
                      'Error message for name requirement in editable role in permission table',
                  }),
                },
              ]
            }>
              <Input
                aria-label='user name'
                placeholder={this.props.intl.formatMessage({
                  defaultMessage: 'Name',
                  description:
                    'Default text for User placeholder in editable role in permission table',
                })}
                style={styles.addTagForm.nameInput}
              />
            </Form.Item>
            <Form.Item>
              <Button loading={isRequestPending} htmlType='submit' data-test-id='add-tag-button'>
                <FormattedMessage
                  defaultMessage='Add Access'
                  description='Add button text in editable role in permission table'
                />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spacer>
    );
  }
}

const styles = {
  addTagForm: {
    wrapper: { marginLeft: 7 },
    label: {
      marginTop: 20,
    },
    nameInput: { width: 300, height: 38},
    valueInput: { width: 200 },
  },
};

//@ts-expect-error
export const ManageClusterAccess = injectIntl(ManageClusterAccessImpl);
