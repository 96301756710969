import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgFullscreenExitIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M6 1v4.25a.75.75 0 0 1-.75.75H1V4.5h3.5V1H6ZM10 15v-4.25a.75.75 0 0 1 .75-.75H15v1.5h-3.5V15H10ZM10.75 6H15V4.5h-3.5V1H10v4.25c0 .414.336.75.75.75ZM1 10h4.25a.75.75 0 0 1 .75.75V15H4.5v-3.5H1V10Z"
      />
    </svg>
  );
}
function FullscreenExitIcon(props: IconProps) {
  return <Icon {...props} component={SvgFullscreenExitIcon} />;
}
export default FullscreenExitIcon;
