import { Services } from './services';
import { getUUID } from '../common/utils/ActionUtils';

export const ADD_KUBECLUSTER_API = 'ADD_KUBECLUSTER_API';
export const addKubeClusterApi = (kubeinfo: any) => {
  return (dispatch: any) => {
    const response = dispatch({
      type: ADD_KUBECLUSTER_API,
      payload: Services.addKubeCluster(kubeinfo),
      meta: kubeinfo,
    });
    return response;
  };
};

export const REMOVE_KUBECLUSTER_API = 'REMOVE_KUBECLUSTER_API';
export const removekubeClusterApi = (kubeinfo: any) => {
  return (dispatch: any) => {
    const response = dispatch({
      type: REMOVE_KUBECLUSTER_API,
      payload: Services.removekubeCluster(kubeinfo),
      meta: kubeinfo,
    });
    return response;
  };
};

export const GET_KUBECLUSTER_API = 'GET_KUBECLUSTER_API';
export const getKubeClusterApi = (id = getUUID()) => {
  return (dispatch: any) => {
    const response = dispatch({
      type: GET_KUBECLUSTER_API,
      payload: Services.getKubeCluster({}),
      meta : {id: id}});
    return response;
  };
};

export const ADD_USERBUCKETS = 'ADD_USERBUCKETS';
export const addUserBucketsApi = (bucket: any) => {
  return (dispatch: any) => {
    const response = dispatch({
      type: ADD_USERBUCKETS,
      payload: Services.addUserBuckets(bucket),
      meta: bucket,
    });
    return response;
  };
};

export const REMOVE_USERBUCKETS = 'REMOVE_USERBUCKETS';
export const removeUserBucketsApi = (bucket: any) => {
  return (dispatch: any) => {
    const response = dispatch({
      type: REMOVE_USERBUCKETS,
      payload: Services.removeUserBuckets(bucket),
      meta: bucket,
    });
    return response;
  };
};

export const GET_USERBUCKETS = 'GET_USERBUCKETS';
export const getUserBucketsApi = (id = getUUID()) => {
  return (dispatch: any) => {
    const response = dispatch({
      type: GET_USERBUCKETS,
      payload: Services.getUserBuckets({}),
      meta : {id: id}});
    return response;
  };
};

export const ADD_CLUSTER_ACCESS = 'ADD_CLUSTER_ACCESS';
export const addClusterAccessApi = (access: any) => {
  return (dispatch: any) => {
    const response = dispatch({
      type: ADD_CLUSTER_ACCESS,
      payload: Services.addClusterAccess(access),
      meta: access,
    });
    return response;
  };
};

export const REMOVE_CLUSTER_ACCESS = 'REMOVE_CLUSTER_ACCESS';
export const removeClusterAccessApi = (access: any) => {
  return (dispatch: any) => {
    const response = dispatch({
      type: REMOVE_CLUSTER_ACCESS,
      payload: Services.removeClusterAccess(access),
      meta : access,
    });
    return response;
  };
};