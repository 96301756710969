import classnames from 'classnames';
import type { CSSProperties } from 'react';
import React, { forwardRef, useContext } from 'react';

import { SortAscendingIcon, SortDescendingIcon, SortUnsortedIcon } from '../Icon';
import { Typography } from '../Typography';
import type { TextProps } from '../Typography/Text';
import type { HTMLDataAttributes } from '../types';
import { TableContext } from './Table';
import { TableRowContext } from './TableRow';
import tableStyles, { repeatingElementsStyles, tableClassNames } from './tableStyles';

interface TableHeaderResizeHandleProps extends HTMLDataAttributes {
  /** Style property */
  style?: CSSProperties;
  /** Pass a handler to be called on pointerDown */
  resizeHandler?: React.PointerEventHandler<HTMLDivElement>;
}

const TableHeaderResizeHandle = forwardRef<HTMLDivElement, TableHeaderResizeHandleProps>(
  function TableHeaderResizeHandle({ style, resizeHandler, children, ...rest }, ref) {
    const { isHeader } = useContext(TableRowContext);

    if (!isHeader) {
      throw new Error('`TableHeaderResizeHandle` must be used within a `TableRow` with `isHeader` set to true.');
    }

    return (
      <div
        {...rest}
        ref={ref}
        onPointerDown={resizeHandler}
        css={tableStyles.resizeHandleContainer}
        style={style}
        role="separator"
      >
        <div css={tableStyles.resizeHandle} />
      </div>
    );
  },
);

export interface TableHeaderProps extends HTMLDataAttributes, React.HTMLAttributes<HTMLDivElement> {
  /** @deprecated Use `multiline` prop instead. This prop will be removed soon. */
  ellipsis?: boolean;
  /** Enables multiline wrapping */
  multiline?: boolean;
  /** Is this column sortable? */
  sortable?: boolean;
  /** The current sort direction for this column */
  sortDirection?: 'asc' | 'desc' | 'none';
  /** Callback for when the user requests to toggle `sortDirection` */
  onToggleSort?: (event: unknown) => void;
  /** Style property */
  style?: CSSProperties;
  /** Class name property */
  className?: string;
  /** Child nodes for the table header */
  children?: React.ReactNode | React.ReactNode[];
  /** Whether the table header should include a resize handler */
  resizable?: boolean;
  /** Event handler to be passed down to <TableHeaderResizeHandle /> */
  resizeHandler?: React.PointerEventHandler<HTMLDivElement>;
  /** Whether the header is currently being resized */
  isResizing?: boolean;
  /** How to horizontally align the cell contents */
  align?: 'left' | 'center' | 'right';
  /** If the content of this header should be wrapped with Typography. Should only be set to false if
   * content is not a text (e.g. images) or you really need to render custom content. */
  wrapContent?: boolean;
}

export const TableHeader = forwardRef<HTMLDivElement, TableHeaderProps>(function TableHeader(
  {
    children,
    ellipsis = false,
    multiline = false,
    sortable,
    sortDirection,
    onToggleSort,
    style,
    className,
    resizable,
    resizeHandler,
    isResizing = false,
    align = 'left',
    wrapContent = true,
    ...rest
  },
  ref,
) {
  const { size, grid } = useContext(TableContext);
  const { isHeader } = useContext(TableRowContext);

  if (!isHeader) {
    throw new Error('`TableHeader` must be used within a `TableRow` with `isHeader` set to true.');
  }

  let sortIcon = <></>;
  // While most libaries use `asc` and `desc` for the sort value, the ARIA spec
  // uses `ascending` and `descending`.
  let ariaSort: React.AriaAttributes['aria-sort'];

  if (sortable) {
    if (sortDirection === 'asc') {
      sortIcon = <SortAscendingIcon />;
      ariaSort = 'ascending';
    } else if (sortDirection === 'desc') {
      sortIcon = <SortDescendingIcon />;
      ariaSort = 'descending';
    } else if (sortDirection === 'none') {
      sortIcon = <SortUnsortedIcon />;
      ariaSort = 'none';
    }
  }
  const sortIconOnLeft = align === 'right';

  let typographySize: TextProps['size'] = 'md';

  if (size === 'small') {
    typographySize = 'sm';
  }

  const content = wrapContent ? (
    <Typography.Text
      className="table-header-text"
      ellipsis={!multiline}
      size={typographySize}
      title={(!multiline && typeof children === 'string' && children) || undefined}
    >
      {children}
    </Typography.Text>
  ) : (
    children
  );

  const resizeHandle = resizable ? <TableHeaderResizeHandle resizeHandler={resizeHandler} /> : null;

  return (
    <div
      {...rest}
      ref={ref}
      // PE-259 Use more performance className for grid but keep css= for compatibility.
      css={!grid ? [repeatingElementsStyles.cell, repeatingElementsStyles.header] : undefined}
      className={classnames(
        grid && tableClassNames.cell,
        grid && tableClassNames.header,
        { 'table-header-isGrid': grid },
        className,
      )}
      role="columnheader"
      aria-sort={(sortable && ariaSort) || undefined}
      style={{
        justifyContent: align,
        textAlign: align,
        ...style,
      }}
    >
      {sortable && !isResizing ? (
        <div
          css={[tableStyles.headerButtonTarget]}
          role="button"
          tabIndex={0}
          onClick={onToggleSort}
          onKeyDown={(event) => {
            if (sortable && (event.key === 'Enter' || event.key === ' ')) {
              event.preventDefault();
              return onToggleSort?.(event);
            }
          }}
        >
          {sortIconOnLeft ? (
            <span className="table-header-icon-container" css={[tableStyles.sortHeaderIconOnLeft]}>
              {sortIcon}
            </span>
          ) : null}
          {content}
          {!sortIconOnLeft ? (
            <span className="table-header-icon-container" css={[tableStyles.sortHeaderIconOnRight]}>
              {sortIcon}
            </span>
          ) : null}
        </div>
      ) : (
        content
      )}
      {resizeHandle}
    </div>
  );
});
