import type { CSSObject, SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { SwitchProps as AntDSwitchProps } from 'antd';
import { Switch as AntDSwitch } from 'antd';

import type { Theme } from '../../theme';
import { DesignSystemAntDConfigProvider, getAnimationCss } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks/useDesignSystemTheme';
import type { LabelProps } from '../Label/Label';
import { Label } from '../Label/Label';
import type { DangerouslySetAntdProps, HTMLDataAttributes } from '../types';
import { importantify } from '../utils/css-utils';

export interface SwitchProps
  extends Pick<
      AntDSwitchProps,
      | 'autoFocus'
      | 'checked'
      | 'checkedChildren'
      | 'className'
      | 'defaultChecked'
      | 'disabled'
      | 'unCheckedChildren'
      | 'onChange'
      | 'onClick'
    >,
    HTMLDataAttributes,
    DangerouslySetAntdProps<AntDSwitchProps> {
  id?: string;
  /**
   * Label for the Switch, provided as prop for styling purposes
   */
  label?: string;
  labelProps?: LabelProps;
}

const getSwitchWithLabelStyles = ({ clsPrefix, theme }: { clsPrefix: string; theme: Theme }): SerializedStyles => {
  // Default value
  const SWITCH_WIDTH = 28;

  const styles: CSSObject = {
    display: 'flex',
    alignItems: 'center',

    // Switch is Off
    [`&.${clsPrefix}-switch`]: {
      backgroundColor: theme.colors.backgroundPrimary,
      border: `1px solid ${theme.colors.actionDefaultBorderDefault}`,

      [`.${clsPrefix}-switch-handle:before`]: {
        boxShadow: `0px 0px 0px 1px ${theme.colors.actionDefaultBorderDefault}`,
        transition: 'none',
      },

      [`&:hover:not(.${clsPrefix}-switch-disabled)`]: {
        backgroundColor: theme.colors.actionDefaultBackgroundHover,
        border: `1px solid ${theme.colors.actionPrimaryBackgroundHover}`,

        [`.${clsPrefix}-switch-handle:before`]: {
          boxShadow: `0px 0px 0px 1px ${theme.colors.actionPrimaryBackgroundHover}`,
        },
      },

      [`&:active:not(.${clsPrefix}-switch-disabled)`]: {
        backgroundColor: theme.colors.actionDefaultBackgroundPress,
        border: `1px solid ${theme.colors.actionPrimaryBackgroundPress}`,

        [`.${clsPrefix}-switch-handle:before`]: {
          boxShadow: `0px 0px 0px 1px ${theme.colors.actionPrimaryBackgroundHover}`,
        },
      },

      [`&:focus-visible`]: {
        border: `1px solid ${theme.colors.actionPrimaryBackgroundDefault}`,
        boxShadow: 'none',
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineOffset: '1px',
        outlineColor: theme.colors.actionDefaultBorderFocus,

        [`.${clsPrefix}-switch-handle:before`]: {
          boxShadow: `0px 0px 0px 1px ${theme.colors.actionPrimaryBackgroundDefault}`,
        },
      },

      [`&:focus`]: {
        boxShadow: 'none',
      },
    },

    // Switch is On
    [`&.${clsPrefix}-switch-checked`]: {
      backgroundColor: theme.colors.actionPrimaryBackgroundDefault,
      border: `1px solid ${theme.colors.actionPrimaryBackgroundDefault}`,

      [`&:hover:not(.${clsPrefix}-switch-disabled)`]: {
        backgroundColor: theme.colors.actionPrimaryBackgroundHover,
        border: `1px solid ${theme.colors.actionPrimaryBackgroundDefault}`,
      },

      [`&:active:not(.${clsPrefix}-switch-disabled)`]: {
        backgroundColor: theme.colors.actionPrimaryBackgroundPress,
      },

      [`.${clsPrefix}-switch-handle:before`]: {
        boxShadow: `0px 0px 0px 1px ${theme.colors.actionPrimaryBackgroundDefault}`,
      },

      [`&.${clsPrefix}-switch-disabled`]: {
        backgroundColor: theme.colors.actionDisabledBackground,
        border: `1px solid ${theme.colors.actionDisabledBackground}`,

        [`.${clsPrefix}-switch-handle:before`]: {
          boxShadow: `0px 0px 0px 1px ${theme.colors.actionDisabledBackground}`,
        },
      },
    },

    [`.${clsPrefix}-switch-handle:before`]: {
      backgroundColor: theme.colors.backgroundPrimary,
    },

    [`&& + .${clsPrefix}-hint, && + .${clsPrefix}-form-message`]: {
      paddingLeft: theme.spacing.sm + SWITCH_WIDTH,
    },

    [`&& + .${clsPrefix}-form-message`]: {
      marginTop: 0,
    },

    [`.${clsPrefix}-click-animating-node`]: {
      animation: 'none',
    },
  };

  const importantStyles = importantify(styles);

  return css(importantStyles);
};

export const Switch: React.FC<SwitchProps> = ({ dangerouslySetAntdProps, label, labelProps, ...props }) => {
  const { theme, classNamePrefix } = useDesignSystemTheme();
  return label ? (
    <DesignSystemAntDConfigProvider>
      {/*We need to offer the component with embedded Label in order to control the label alignment to Switch*/}
      {/*and also control the padding of Hints and FormMessage relative to the Switch's label*/}
      <div css={getSwitchWithLabelStyles({ clsPrefix: classNamePrefix, theme })}>
        <AntDSwitch
          {...props}
          {...dangerouslySetAntdProps}
          css={{
            ...css(getAnimationCss(theme.options.enableAnimation)),
            ...getSwitchWithLabelStyles({ clsPrefix: classNamePrefix, theme }),
          }}
        />
        <Label inline={true} {...labelProps}>
          {label}
        </Label>
      </div>
    </DesignSystemAntDConfigProvider>
  ) : (
    <DesignSystemAntDConfigProvider>
      <AntDSwitch
        {...props}
        {...dangerouslySetAntdProps}
        css={{
          ...css(getAnimationCss(theme.options.enableAnimation)),
          ...getSwitchWithLabelStyles({ clsPrefix: classNamePrefix, theme }),
        }}
      />
    </DesignSystemAntDConfigProvider>
  );
};
