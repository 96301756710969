import React from 'react';
import { ConcurrentListView } from './ConcurrentListView';
import { connect } from 'react-redux';
import RequestStateWrapper from '../../common/components/RequestStateWrapper';
import { getUUID } from '../../common/utils/ActionUtils';
import Utils from '../../common/utils/Utils';
import { concurrentListApi, periodicRunListApi } from '../actions';
import LocalStorageUtils from '../../common/utils/LocalStorageUtils';
import { getConcurrentTemplatePageRoute } from '../routes';
import { withRouterNext } from '../../common/utils/withRouterNext';
import type { WithRouterNextProps } from '../../common/utils/withRouterNext';

type Props = WithRouterNextProps & {
  concurrents: any[];
  periodicRuns: any[];
  concurrentListApi: (...args: any[]) => any;
  periodicRunListApi: (...args: any[]) => any;
}

type State = any;

export class ConcurrentListPageImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orderByAsc: true,
      currentPage: 1,
      maxResultsSelection: 5,
      pageTokens: {},
      loading: false,
    };
  }

  concurrentListPageStoreKey = 'ConcurrentListPageStore';
  defaultPersistedPageTokens = { 1: null };
  concurrentListApiId = getUUID();
  periodicRunListApiId = getUUID();
  criticalInitialRequestIds = [this.concurrentListApiId, this.periodicRunListApiId];

  componentDidMount() {
    this.props.concurrentListApi(this.concurrentListApiId);
    this.props.periodicRunListApi(this.periodicRunListApiId);
  }

  getMaxResultsSelection = () => {
    return this.state['maxResultsSelection'];
  };
 
  createConcurrent = () => {
    this.props.navigate(getConcurrentTemplatePageRoute('new_concurrent', 'create'));
  };

  render() {
    const {
      orderByKey,
      orderByAsc,
      currentPage,
      pageTokens,
      searchInput,
    } = this.state;
    const { concurrents, periodicRuns } = this.props;
    return (
      <RequestStateWrapper requestIds={this.criticalInitialRequestIds}>
        <ConcurrentListView
          //@ts-expect-error
          concurrents={concurrents}
          periodicRuns={periodicRuns}
          loading={this.state['loading']}
          searchInput={searchInput}
          orderByKey={orderByKey}
          orderByAsc={orderByAsc}
          currentPage={currentPage}
          nextPageToken={pageTokens[currentPage + 1]}
          getMaxResultValue={this.getMaxResultsSelection}
          createConcurrent={this.createConcurrent}
        />
      </RequestStateWrapper>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const concurrentsobject = Object.values(state.entities.concurrentByID);
  const periodicRunsobject = Object.values(state.entities.periodicRunByName);
  const periodicRunByDagid = state.entities.periodicRunByDagid;
  const concurrentByID = state.entities.concurrentByID;

  const concurrents = concurrentsobject.map((concurrent: any) => {
    if(periodicRunByDagid[concurrent.dagid]) {
      concurrent.scheduled = periodicRunByDagid[concurrent.dagid];
    } 
    return concurrent;
  });

  const periodicRuns = periodicRunsobject.map((periodic: any) => {
    periodic.concurrent = concurrentByID[periodic.dagid];
    return periodic;
  });

  return {
    concurrents,
    periodicRuns
  };
};

const mapDispatchToProps = {
  concurrentListApi,
  periodicRunListApi
};

export const ConcurrentListPage = withRouterNext(
  connect(mapStateToProps, mapDispatchToProps)(ConcurrentListPageImpl),
);
