import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import $ from 'jquery';
import './ConcurrentTemplateView.css';
import 'butterfly-dag/dist/index.css';
import Node from './templates/node';
import Edge from './templates/edge';
import { Button, Popconfirm, List, Divider, Space, Card, Tooltip } from 'antd';
import { getGraphNodes, getGraphEdges, getTrimmedNodes, getTrimmedEdges, getRandomColor } from '../utils/TemplateUtils';
import { EditOutlined, ExpandOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { IconButton } from '../../common/components/IconButton';

import {Canvas, Arrow} from 'butterfly-dag';
import UML_IMG from 'butterfly-dag/plugins/arrow/uml-1.svg';
import { AddKwargModal } from './modal/AddKwargModal';
import { AddNodeInputModal } from './modal/AddNodeInputModal';
import { EditNodeInputModal } from './modal/EditNodeInputModal';
import { AddNodeModal } from './modal/AddNodeModal';
import { AddConnectionModal } from './modal/AddConnectionModal';
import { EditConnectionModal } from './modal/EditConnectionModal';
import { EditNodeModal } from './modal/EditNodeModal';

type Props = {
  graphElement: any;
  clusters: any[];
  concurrentAction: string;
  experiments: any[];
}

type State = any;

export class ConcurrentTemplateView extends React.Component<Props, State> {
  canvas: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      nodes: getGraphNodes(props.graphElement.node),
      edges: getGraphEdges(props.graphElement.edge),
      activeNode: null,
      showAddKwargModal: false,
      showAddNodeInputModal: false,
      showEditNodeInputModal: false,
      showAddNodeModal: false,
      showEditNodeModal: false,
      showAddConnectionModal: false,
      showEditConnectionModal: false,
      showSaveGraphModal: false,
      runDagLoading: false,
      showConnectionInspector: false,
      showNodeInspector: true,
      selectedConnection: {},
      dragEdge: false,
      removeableEdgeId: null,
      newEdge: {},
      selectedInput: {},
      selectedKwag: {},
      showeditKwargModal: false,
      inputType: 'bucket',
      dataSelection: false,
      inputDataType: 'infinsnap',
      showCustomlamda: false,
    };
  }

  formRef = React.createRef();
  componentDidMount() {
    let root = document.getElementById('template-canvas');
    this.canvas = new Canvas({
      root: root,
      disLinkable: false, 
      linkable: true,    
      draggable: true,   
      zoomable: true,    
      moveable: true,
      theme: {
        edge: {
          shapeType: 'Manhattan',
          arrow: true,
          arrowPosition: 1,
          labelPosition: 1,
          labelOffset: -20,
        }
      }
    });
    Arrow.registerArrow([{
      key: 'arrow1',
      type: 'svg',
      content: UML_IMG
    }]);

    this.canvas.draw({groups: [],nodes: this.state['nodes'],edges: this.state['edges']});
    this.canvas.on('events', (data: any) => {
      if(data.type == 'node:click') {
        this.setState({ activeNode: data.node });
        this.setState({ showConnectionInspector: false });
        this.setState({ showNodeInspector: true });
      } else if(data.type == 'link:connect') {
        if(this.state['dragEdge']){
          const edgeId = data.links[0].id;
          this.setState({ removeableEdgeId: edgeId})
          const activeNode = data.links[0].targetNode;
          this.setState({ activeNode: activeNode });
          const edgeOptions = data.links[0].options;
          const newEdge = {
            id : edgeOptions.sourceNode+'_'+edgeOptions.targetNode,
            source: edgeOptions.sourceNode,
            target: edgeOptions.targetNode,
            name: 'newEdge',
            type: 'existing_xform'
          }
          this.setState({ newEdge: newEdge})         
          if(edgeOptions.sourceNode === edgeOptions.targetNode ){
            this.canvas.removeEdge(edgeId);
          }else {
            this.handleAddConnectionModal();
          }
        }
      } else if(data.type == 'drag:move') {
        this.setState({ dragEdge : true });
      } else if(data.type == 'link:click') {
        const activeNode = data.edge.targetNode;
        this.setState({ selectedConnection: data.edge.options });
        this.setState({ showNodeInspector: false });
        this.setState({ showConnectionInspector: true });
        this.setState({ activeNode: activeNode });
        $(".base-node").css({ 'background-color' : '', 'color' : '#1a192b' });
        let id = activeNode.id;
        $('#'+id).css({"background-color": activeNode.options.color,"color": "#fff"});
      } 
    });
  }

  focusCenter = () => {
    this.canvas.focusCenterWithAnimate();
  }

  zoomIn = () => {
    this.canvas.zoom(this.canvas.getZoom() + 0.3)
  }

  zoomOut = () => {
    this.canvas.zoom(this.canvas.getZoom() - 0.3)
  }

  changePartitioner = (value: any) => {
    if(value === 'custom') {
      this.setState({showCustomlamda : true});
    } else {
      this.setState({showCustomlamda : false});
    }
  }

  changeDataSelection = (e: any) => {
    this.setState({dataSelection : e.target.checked});
  }
  
  changeInputDataType = (e: any) => {
    this.setState({inputDataType : e.target.value})
  }

  changeInputType = (e: any) => {
    this.setState({inputType: e.target.value});
  }

  handleSaveGraphModal = () => {
    this.setState({
      showSaveGraphModal: true,
    });
  };

  handleCloseSaveGraphModal = () => {
    this.setState({
      showSaveGraphModal: false,
    });
  }

  saveGraph = async () => {
    let graph = this.canvas.getDataMap();
    const dagJson = {
      node: getTrimmedNodes(graph.nodes),
      edge: getTrimmedEdges(graph.edges),
    }
    return dagJson;
  }

  deleteNode = () => {
    this.canvas.removeNode(this.state['activeNode']['id']);
    let nodes = this.state['nodes'];
    nodes = nodes.filter((node: any) => node.id !== this.state['activeNode']['id']);
    nodes.forEach((node: any) => {
      node.input = node.input.filter((input: any) => input.source !== this.state['activeNode']['id']);
    });
    const graph = this.canvas.getDataMap();
    graph.nodes.forEach((node: any) => {
      node.options.input = node.options.input.filter((input: any) => input.source !== this.state['activeNode']['id']);
    });
    this.setState({ nodes: nodes });
    this.setState({ activeNode: null });
  }
 
  deleteConnection = () => {
    const {activeNode, selectedConnection} = this.state;
    this.canvas.removeEdge(selectedConnection.id);
    this.setState((state: any) => {
      const newState = state.edges.filter((edge: any) => edge.id !== selectedConnection.id);
      return {
        newState,
      };
    });
    this.setState({ showConnectionInspector: false });
    this.setState({ showNodeInspector: true });
    const ii  = activeNode.options.input;
    activeNode.options.input = activeNode.options.input.filter((node: any) => node.name !== selectedConnection.name);
    this.setState({ activeNode: activeNode });
  }

  editNode = async (values: any) => { 
    const {activeNode} = this.state;
    let editNode = activeNode;
    editNode.options.name = values.name;
    editNode.options.transform = values.transform;
    editNode.options.instanceType = values.instanceType;
    editNode.options.parallelization = values.parallelization;
    editNode.options.xform_path = values.xform_path;
    // editNode.options.location = values.location;
    editNode.options.k8s_params = {
      "resources.requests.memory": values['resources.requests.memory'],
      "kube_context": values.kube_context,
      "namespace": values.namespace,
      // "MLFLOW_TRACKING_URI": "infinstor://" + window.InfinStorMlflowServer,
      "resources.requests.cpu": values['resources.requests.cpu'],
      "resources.requests.gpu" : values['resources.requests.gpu'],
    }
    this.setState({activeNode: editNode})
  }

  handleEditNodeModal = () => {
    const {activeNode} = this.state;
    this.setState({
      showEditNodeModal: true,
    });
  };

  handleCloseEditNodeModal = () => {
    this.setState({
      showEditNodeModal: false,
    });
  }

  editConnection = async (values: any) => { 
    const {selectedConnection, activeNode} = this.state;
    Object.keys(values).forEach(key => {
      selectedConnection[key] = values[key];
    });
    this.setState({selectedConnection: selectedConnection});
    activeNode.options.input = activeNode.options.input.map((input: any) => {
      if (input.id === selectedConnection.id) {
        input.name = values.name;
        if(values.partitioner) {
          input.partition_params = { partitioner: values.partitioner };
          if(values.partitioner == 'custom') {
            input.partition_params.lambda = values.lambda;
          }
        }
      }
      return input;
    });
    this.setState({activeNode: activeNode});
  }

  handleEditConnectionModal = () => {
    this.setState({
      showEditConnectionModal: true,
    });
  };

  handleCloseEditConnectionModal = () => {
    this.setState({
      showEditConnectionModal: false,
    });
  }

  addNode = async (values: any) => {
    let newNode: any =  {
      id: values.name,
      name: values.name,
      geometry: {x: 0, y: 0},
      xform_path: values.xform_path,
      instanceType: values.instanceType,
      transform: values.transform,
      parallelization: values.parallelization,
      color : getRandomColor(),
      kwarg: [],
      input: [],
      top: 25,
      left: 0,
      Class: Node,
      endpoints : [{
        id: 'top',
        orientation: [0, -1],
        pos: [0.5, 0]
      }, {
        id: 'bottom',
        orientation: [0, 1],
        pos: [0.5, 0]
      }]
    }

    newNode.k8s_params = {
      "resources.requests.memory": values['resources.requests.memory'],
      "kube_context": values.kube_context,
      "namespace": values.namespace,
      "resources.requests.cpu": values['resources.requests.cpu'],
      "resources.requests.gpu" : values['resources.requests.gpu'],
    }

    this.setState((state: any) => ({
      nodes: [...state.nodes, newNode]
    }))
    let newActiveNode = this.canvas.addNode(newNode);
    this.setState({ activeNode: newActiveNode });
    let id = newNode.id;
    $('#'+id).css({"background-color": newNode.color,"color": "#fff"});
  }

  handleAddNodeModal = () => {
    this.setState({
      showAddNodeModal: true,
    });
  };

  handleCloseAddNodeModal = () => {
    this.setState({
      showAddNodeModal: false,
    });
  }

  handleAddKwargModal = () => {
    this.setState({
      showAddKwargModal: true,
    });
  };

  handleCloseAddKwargModal = () => {
    this.setState({
      showAddKwargModal: false,
    });
  };

  handleEditKwargModal = (kwarg: any) => {
    this.setState({
      showeditKwargModal: true,
    });
    this.setState({
      selectedKwag: kwarg,
    });
  };

  handleCloseEditKwargModal = () => {
    this.setState({
      showeditKwargModal: false,
    });
  };

  addKwarg = async (kwarg: any) => {
    const { activeNode } = this.state;
    if (activeNode.options.kwarg.find((e: any) => e.key === kwarg.key)) {
      activeNode.options.kwarg = activeNode.options.kwarg.map((node: any) => {
        if (node.key === kwarg.key) {
          node.value = kwarg.value;
        }
        return node;
      });
    } else {
      if(Array.isArray(activeNode.options.kwarg)) {
        activeNode.options.kwarg.push(kwarg);
      } else {
        activeNode.options.kwarg = [kwarg];
      }
    }
    this.setState({ activeNode: activeNode });
  }

  deleteKwarg = (kwarg: any) => {
    const { activeNode } = this.state;
    activeNode.options.kwarg = activeNode.options.kwarg.filter((node: any) => node.key !== kwarg.key);
    this.setState({ activeNode: activeNode });
  }

  handleAddNodeInputModal = () => {
    this.setState({
      showAddNodeInputModal: true,
      inputType: 'bucket',
      dataSelection: false,
      inputDataType: 'infinsnap',
      showCustomlamda: false,
    });
  };

  handleCloseAddNodeInputModal = () => {
    this.setState({
      showAddNodeInputModal: false,
    });
  };

  addNodeInput = async (values: any) => {
    const { activeNode } = this.state;
    let newInput: any = {
      name: values.name,
      type: values.type,
      bucketname: values.bucketname,
      pathInBucket: values.pathInBucket,
    }
    if(!values.pathInBucket) {
      newInput.pathInBucket = '';
    }
    if(values.partitioner && values.partitioner !== 'null') {
      newInput.partition_params = {
        partitioner: values.partitioner
      }
    }
    if(values.partitioner == 'custom') {
      newInput.partition_params.lambda = values.lambda;
    }
    if(values.infinsnapTime) {
      newInput.time_spec = 'tm'+ moment(values.infinsnapTime).utc().format("YYYYMMDDHHmmss");
      newInput.infinsnapTime = values.infinsnapTime;
      newInput.type = 'infinsnap';
    }
    if(values.infinsliceTime) {
      let start =  moment(values.infinsliceTime[0]).utc().format("YYYYMMDDHHmmss");
      let end =  moment(values.infinsliceTime[1]).utc().format("YYYYMMDDHHmmss");
      newInput.time_spec = 'tm' + start + '-tm' + end;
      newInput.infinsliceTime = values.infinsliceTime;
      newInput.type = 'infinslice';
    }
    if(values.type === 'mlflow_artifact') {
      newInput.run_id = values.run_id;
      newInput.experiment = values.experiment;
      delete newInput.bucketname; 
      delete newInput.pathInBucket; 
    }
    if(Array.isArray(activeNode.options.input)) {
      activeNode.options.input.push(newInput);
    } else {
      activeNode.options.input = [newInput];
    }
    this.setState({ activeNode: activeNode });
  }

  handleEditNodeInputModal = (input: any) => {
    if(input.partition_params) {
      input.partitioner = input.partition_params.partitioner;
      this.setState({ showCustomlamda: false });
      if(input.partition_params.partitioner == 'custom'){
        input.lambda = input.partition_params.lambda;
        this.setState({ showCustomlamda: true });
      }
    }

    if(input.infinsnapTime) {
      input.infintype = 'infinsnap';
      input.type = 'bucket';
      this.setState({ 
        dataSelection: true,
        inputDataType: 'infinsnap', 
      });
    } else if( input.infinsliceTime) {
      input.infintype = 'infinslice';
      input.type = 'bucket';
      this.setState({ 
        dataSelection: true,
        inputDataType: 'infinslice', 
      });
    } else {
      input.infintype = 'infinsnap';
      this.setState({ 
        dataSelection: false,
        inputDataType: 'infinsnap', 
      });
    }

    this.setState({
      selectedInput: input,
      showEditNodeInputModal: true,
      inputType: input.type,
    });
  };

  handleCloseEditNodeInputModal = () => {
    this.setState({
      showEditNodeInputModal: false,
    });
  };
  editNodeInput = async (values: any) => {
    const { activeNode, selectedInput } = this.state;
    let newInput: any = {
      name: values.name,
      type: values.type,
      bucketname: values.bucketname,
      pathInBucket: values.pathInBucket,
    }
    if(!values.pathInBucket) {
      newInput.pathInBucket = '';
    }
    if(values.partitioner && values.partitioner !== 'null') {
      newInput.partition_params = {
        partitioner: values.partitioner
      }
    }
    if(values.partitioner == 'custom') {
      newInput.partition_params.lambda = values.lambda;
    }
    if(values.infinsnapTime) {
      newInput.time_spec = 'tm'+ moment(values.infinsnapTime).utc().format("YYYYMMDDHHmmss");
      newInput.infinsnapTime = values.infinsnapTime;
      newInput.type = 'infinsnap';
    } 
    
    if(values.infinsliceTime) {
      let start =  moment(values.infinsliceTime[0]).utc().format("YYYYMMDDHHmmss");
      let end =  moment(values.infinsliceTime[1]).utc().format("YYYYMMDDHHmmss");
      newInput.time_spec = 'tm' + start + '-tm' + end;
      newInput.infinsliceTime = values.infinsliceTime;
      newInput.type = 'infinslice';
    } 
    
    if(values.type === 'mlflow_artifact') {
      newInput.run_id = values.run_id;
      newInput.experiment = values.experiment;
      delete newInput.bucketname; 
      delete newInput.pathInBucket; 
    }

    activeNode.options.input = activeNode.options.input.map((input: any) => {
      if (input.name === selectedInput.name) {
        input = newInput;
      }
      return input;
    });
    this.setState({ activeNode: activeNode });  
  }

  deleteNodeInput = (selectedInput: any) => {
    const { activeNode } = this.state;
    activeNode.options.input = activeNode.options.input.filter((input: any) => input.name !== selectedInput.name);
    this.setState({ activeNode: activeNode });
  }

  addConnection = async (values: any) => {
    const { newEdge, activeNode } = this.state;
    newEdge.name = values.name;
    if(activeNode) {
      let newInput = newEdge;
      if(values.partitioner && values.partitioner !== 'null') {
        newInput.partition_params = { partitioner: values.partitioner };
        if(values.partitioner == 'custom') {
          newInput.partition_params.lambda = values.lambda;
        }
      }
      if(Array.isArray(activeNode.options.input)) {
        activeNode.options.input.push(newInput);
      } else {
        activeNode.options.input = [newInput];
      }
      this.setState({ activeNode: activeNode });
      $(".base-node").css({ 'background-color' : '', 'color' : '#1a192b' });
      let id = activeNode.id;
      $('#'+id).css({"background-color": activeNode.options.color,"color": "#fff"});
    }
    const newConnection: any = {};
    newConnection.Class = Edge;
    newConnection.id = newEdge.source+'_'+newEdge.target;
    newConnection.sourceNode = newEdge.source;
    newConnection.targetNode = newEdge.target;
    newConnection.source = 'bottom';
    newConnection.target = 'top';
    newConnection.arrow = true;
    newConnection.type = 'endpoint';
    newConnection.arrowPosition = 1;
    newConnection.name = values.name;
    newConnection.label = 'I am label';
    newConnection.color = 'purple';
    if(values.partitioner && values.partitioner !== 'null') {
      newConnection.partitioner = values.partitioner;
      if(values.partitioner == 'custom') {
        newConnection.lambda = values.lambda;
      }
    }
    this.canvas.removeEdge(this.state['removeableEdgeId']);
    this.setState({ dragEdge : false });
    this.canvas.addEdge(newConnection);
    this.setState({ removeableEdgeId : null });
    this.setState({ selectedConnection: newConnection });
    this.setState({ showNodeInspector: false });
    this.setState({ showConnectionInspector: true });
  }

  handleAddConnectionModal = () => {
    this.setState({
      showAddConnectionModal: true,
    });
  };

  handleCloseAddConnectionModal = () => {
    if(this.state['removeableEdgeId'] !== null) {
      this.canvas.removeEdge(this.state['removeableEdgeId'])
    }
    this.setState({
      showAddConnectionModal: false,
    });
  }

  defaultNodeView = () => {
    return (<div className='node-default-message'>
            <span className='preview-header'>
                <FormattedMessage
                  defaultMessage='Select a node to info'
                  description='Label to suggests users to select a file to preview the output'
                />
            </span>
      </div>
    );
  };

  showInputPath = (bucketName: any, path: any) => {
    if(path) {
      if(path.startsWith('/')) {
        return bucketName + path;
      } else {
        return bucketName+ "/" + path;
      }
    } else {
      return bucketName;
    }
  };

  nodeInputView = () => {
    const { activeNode } = this.state;
    return(
      <div className='inspector-contant'>
        <List
          className="node-input-list"
          itemLayout="horizontal"
          dataSource={this.state['activeNode'] === null ? [] : activeNode.options.input }
          renderItem={(item: any) => (
            item.type == 'existing_xform' ? null : <List.Item
            actions={[<IconButton
              icon={<EditOutlined />}
              onClick={() => this.handleEditNodeInputModal(item)}
            />, 
            <Popconfirm
              title= 'Are you sure you want to delete this input?'
              okText= 'Confirm'
              cancelText= 'Cancel'
              placement="topRight"
              onConfirm={() => this.deleteNodeInput(item)}
            >
              <IconButton icon={<i className='far fa-trash-o' />} />
            </Popconfirm>]}
          >
            <List.Item.Meta
              description= {<div style={{paddingLeft: '5px'}}> 
                <div className="node-input-container">
                  <span className="node-input-label"> Input Name </span>
                  <span className="node-input-item"> {item.name} </span>
                </div> 
                {item.source ? <div className="node-input-container">
                    <span className="node-input-label"> Source Node </span>
                    <span className="node-input-item"> {item.source} </span>
                </div>: null }
                {item.bucketname ? <div className="node-input-container">
                    <span className="node-input-label"> Bucket </span>
                    <span className="node-input-item"> {item.bucketname} </span>
                </div>: null }
                {item.pathInBucket ? <div className="node-input-container">
                    <span className="node-input-label"> Path </span>
                    <span className="node-input-item"> {item.pathInBucket} </span>
                </div>: null }
                {item.infinsnapTime ? <div className="node-input-container">
                    <span className="node-input-label"> Time </span>
                    <span className="node-input-item"> {moment(item.infinsnapTime).format("YYYY-MM-DD, HH:mm:ss")} </span>
                </div>: null }
                {item.infinsliceTime ? <div className="node-input-container">
                    <span className="node-input-label"> Range </span>
                    <span className="node-input-item"> {moment(item.infinsliceTime[0]).format("YYYY-MM-DD, HH:mm:ss")} - 
                    {moment(item.infinsliceTime[1]).format("YYYY-MM-DD, HH:mm:ss")}</span>
                </div>: null }
                {item.run_id ? <div className="node-input-container">
                    <span className="node-input-label"> Mlflow Run </span>
                    <span className="node-input-item"> {item.run_id}</span>
                </div>: null }
                {item.partition_params ? <div className="node-input-container">
                    <span className="node-input-label"> partitioner </span>
                    <span className="node-input-item">{item.partition_params.partitioner ? item.partition_params.partitioner : '-' }  </span>
                </div>: null }
                {item.partition_params ? item.partition_params.lambda ? <div className="node-input-container">
                    <span className="node-input-label"> lambda </span>
                    <span className="node-input-item"> {item.partition_params.lambda ? item.partition_params.lambda : null} </span>
                </div> : null : null }
                <div className="ant-descriptions-item-container">
                  {/* {item.source ? <div>  <span className="ant-descriptions-item-label"> Source </span> <span className="ant-descriptions-item-content"> {item.source} </span> </div> : null }
                  {item.bucketname ? <span className="ant-descriptions-item-content"> https:// {item.bucketname + item.pathInBucket} </span> : null } */}
                </div>
              </div>}
            />
          </List.Item> 
          )}
        />
      </div>
    );
  }

  nodeKwargView = () => {
    const { activeNode } = this.state;
    return(
      <div className='inspector-contant'>
        <List
          className="node-kwarg-list"
          itemLayout="horizontal"
          dataSource={this.state['activeNode'] === null ? [] : activeNode.options.kwarg }
          renderItem={(item: any) => (<List.Item
            actions={[<IconButton
              icon={<EditOutlined />}
              onClick={() => this.handleEditKwargModal(item)}
            />, 
            <Popconfirm
              title= 'Are you sure you want to delete this parameter?'
              okText= 'Confirm'
              cancelText= 'Cancel'
              placement="topRight"
              onConfirm={() => this.deleteKwarg(item)}
            >
              <IconButton icon={<i className='far fa-trash-o' />} />
            </Popconfirm>]}
          >
            <List.Item.Meta
              description= {<div className="node-input-container">
              <span className="node-input-label"> {item.key} </span>
              <span className="node-input-item"> {item.value} </span>
            </div>}
            />
          </List.Item> 
          )}
        /> 
      </div>
    )
  }

  selectedNodeView = () => {
    const { activeNode } = this.state;
    const { experiments } = this.props;
    const k8s_params = activeNode.options.k8s_params;

    return (<div className='graph-right-container'>
        <div className='node-info'> 
          <div className="node-input-container">
            <span className="node-input-label"> Node Name </span>
            <span className="node-input-item"> {activeNode.options.name} </span>
            <div>
            <IconButton icon={<EditOutlined />} onClick={this.handleEditNodeModal}/> 
            <Divider type="vertical" />
            <Popconfirm
              title={
                <FormattedMessage
                  defaultMessage='Are you sure you want to delete this node?'
                  description='Title text for confirmation pop-up to delete a node from pipeline'
                />
              }
              okText={
                <FormattedMessage
                  defaultMessage='Confirm'
                  description='OK button text for confirmation pop-up to delete a node from pipeline'
                />
              }
              cancelText={
                <FormattedMessage
                  defaultMessage='Cancel'
                  description='Cancel button text for confirmation pop-up to delete a node from pipeline'
                />
              }
              placement="topRight"
              onConfirm={() => this.deleteNode()}
            >
              <IconButton icon={<i className='far fa-trash-o' />} />
            </Popconfirm>
          </div>
          </div>
          <div className="node-input-container">
            <span className="node-input-label"> Git URL </span>
            <Tooltip title={activeNode.options.transform} >
              <span className="node-input-item text-ellipsis"> {activeNode.options.transform} </span>
            </Tooltip>
          </div>
          <div className="node-input-container">
            <span className="node-input-label"> Mlproject Path </span>
            {activeNode.options.xform_path ? <Tooltip title={activeNode.options.xform_path} >
              <span className="node-input-item text-ellipsis"> {activeNode.options.xform_path} </span>
            </Tooltip> : '-'}
          </div>
          <div className="node-input-container">
            <span className="node-input-label"> Location </span>
            <span className="node-input-item"> {activeNode.options.instanceType == "eks" ? "Elastic Kubernetes Service" : activeNode.options.instanceType == "gke" ? "Google Kubernetes Engine" : activeNode.options.instanceType == "HPE" ? "HP Kubernetes Engine" : '-'} </span>
            <span className="node-input-label"> Max Data Partitions </span>
            <span style={{paddingRight: '5px'}}> { activeNode.options.parallelization} </span>
          </div>
          {k8s_params ? 
          <div> 
            <div className="node-input-container">
              <span className="node-input-label"> Cluster Name </span>
              <span className="node-input-item"> { k8s_params.kube_context} </span>
              <span className="node-input-label"> Namespace </span>
              <span className="node-input-item"> { k8s_params.namespace} </span>
            </div>
            <div className="node-input-container">
              <span className="node-input-label"> Memory </span>
              <span className="node-input-item"> {k8s_params['resources.requests.memory']} </span>
            
              <span className="node-input-label"> CPU </span>
              <span className="node-input-item"> { k8s_params['resources.requests.cpu']} </span>
            
              <span className="node-input-label"> GPU </span>
              <span className="node-input-item"> { k8s_params['resources.requests.gpu']} </span>
            </div>
          </div>: null }
        </div>
        <AddKwargModal
        //@ts-expect-error
          title="Add Parameter"
          okText="Add"
          isOpen={this.state['showAddKwargModal']}
          onClose={this.handleCloseAddKwargModal}
          handleSubmit={this.addKwarg}
          kwarg={activeNode.options.kwarg}
          param={{}}
        />
        <AddKwargModal
        //@ts-expect-error
          title="Edit Parameter"
          okText="OK"
          isOpen={this.state['showeditKwargModal']}
          onClose={this.handleCloseEditKwargModal}
          handleSubmit={this.addKwarg}
          kwarg={activeNode.options.kwarg}
          param={this.state['selectedKwag']}
        />
        <AddNodeInputModal
        //@ts-expect-error
          isOpen={this.state['showAddNodeInputModal']}
          onClose={this.handleCloseAddNodeInputModal}
          handleSubmit={this.addNodeInput}
          changePartitioner={this.changePartitioner}
          changeInputDataType={this.changeInputDataType}
          changeDataSelection={this.changeDataSelection}
          changeInputType={this.changeInputType}
          inputType={this.state['inputType']}
          dataSelection={this.state['dataSelection']}
          inputDataType={this.state['inputDataType']}
          showCustomlamda={this.state['showCustomlamda']}
          input={activeNode.options.input}
          experiments={experiments}
          selectedInput={{pathInBucket: '/',
          type: this.state['inputType'],
          infintype:this.state['inputDataType']}}
        />

        <EditNodeInputModal
          //@ts-expect-error
          isOpen={this.state['showEditNodeInputModal']}
          onClose={this.handleCloseEditNodeInputModal}
          handleSubmit={this.editNodeInput}
          changePartitioner={this.changePartitioner}
          changeInputDataType={this.changeInputDataType}
          changeDataSelection={this.changeDataSelection}
          changeInputType={this.changeInputType}
          inputType={this.state['inputType']}
          dataSelection={this.state['dataSelection']}
          inputDataType={this.state['inputDataType']}
          showCustomlamda={this.state['showCustomlamda']}
          input={activeNode.options.input}
          selectedInput={this.state['selectedInput']}
          experiments={experiments}
        />
      </div>
    );
  };

  selectedConnectionView = () => {
    const { selectedConnection } = this.state;
    return(<div className='graph-right-container'>
      <div className='node-info'> 
        <div className="node-input-container">
          <span className="node-input-label"> Connection name </span>
          {selectedConnection.name ? <span className="node-input-item"> {selectedConnection.name} </span> : <span className="node-input-item"> {selectedConnection.id} </span>}
        </div>
        <div className="node-input-container">
          <span className="node-input-label"> Source Node</span>
          <span className="node-input-item"> {selectedConnection.sourceNode} </span>
        </div>
        <div className="node-input-container">
          <span className="node-input-label"> Target Node</span>
          <span className="node-input-item"> {selectedConnection.targetNode} </span>
        </div>
        {selectedConnection.partitioner ?  <div className="node-input-container">
          <span className="node-input-label"> Partitioner </span>
          <span className="node-input-item"> {selectedConnection.partitioner} </span>
        </div> : null}
        {selectedConnection.lambda ?  <div className="node-input-container">
          <span className="node-input-label"> Lambda </span>
          <span className="node-input-item"> {selectedConnection.lambda} </span>
        </div> : null}
      </div>
    </div>
    );
  }

  render() {
    const { nodes, edges, activeNode } = this.state;
    const { concurrentAction } = this.props;
    return (<div> 
      <div className='graph-container'>
        <div className='graph-container-left'> 
            <Card title="Design view" size="small" style={{height: "100%"}} extra={<div className="graph-zoom-btn">
                <Space size="small">
                  <Button style={{padding: "0px"}} onClick={this.focusCenter}> <ExpandOutlined /> </Button>
                  <Button style={{padding: "0px"}} onClick={this.zoomIn}> <ZoomInOutlined /> </Button>
                  <Button style={{padding: "0px"}} onClick={this.zoomOut}> <ZoomOutOutlined /> </Button>
                </Space>
              </div>}>
              <div className='graph-view'>
                <div className="concurrent-canvas" id="template-canvas">
                  {nodes.length < 1 ? <div className='preview-container' style={{height: '250px' }}>
                    <div className='preview-text'>
                      <span>
                        <FormattedMessage
                          defaultMessage=' There are no nodes in this concurrent '
                          description=' There are no nodes in this concurrent '
                        />
                      </span>
                    </div>
                  </div> : null }
                </div>
              </div>
            </Card>
        </div>
        <div className='graph-container-right'>
          <Space direction="vertical" size="small" style={{ display: 'flex' }}>
            {concurrentAction == "run" ? <Card title="Node Inspector">
              <div className='inspector-contant'>
                  {this.state['activeNode'] === null ?  <p> Select a node to view node details. </p> : this.selectedNodeView()}
              </div>
            </Card> : <Card title="Node Inspector" extra={<Button type="primary" size="small" onClick={this.handleAddNodeModal}> Add Node </Button>} size="small">
              <div className='inspector-contant'>
                  {this.state['activeNode'] === null ?  <p> Click add to create new node </p> : this.selectedNodeView()}
              </div>
            </Card>}
            {this.state['showNodeInspector'] ? <Card title="Input Inspector" extra={ this.state['activeNode'] === null ? null : <Button type="primary" size="small" onClick={this.handleAddNodeInputModal}> Add Input </Button>} size="small">
              {this.nodeInputView()}
            </Card> : null}
            {this.state['showNodeInspector'] ? <Card title="Parameters Inspector" extra={this.state['activeNode'] === null ? null : <Button type="primary" size="small" onClick={this.handleAddKwargModal}> Add Parameter </Button>} size="small">
              {this.nodeKwargView()}
            </Card> : null}
            { this.state['showConnectionInspector'] ? 
              <Card title="Connection Inspector" size="small" extra={
                <div>
                  <IconButton icon={<EditOutlined />} onClick={this.handleEditConnectionModal}/> 
                  <Divider type="vertical" />
                  <Popconfirm
                    title={
                      <FormattedMessage
                        defaultMessage='Are you sure you want to delete this connection?'
                        description='Title text for confirmation pop-up to delete a edge from pipeline'
                      />
                    }
                    okText={
                      <FormattedMessage
                        defaultMessage='Confirm'
                        description='OK button text for confirmation pop-up to delete a edge from pipeline'
                      />
                    }
                    cancelText={
                      <FormattedMessage
                        defaultMessage='Cancel'
                        description='Cancel button text for confirmation pop-up to delete a edge from pipeline'
                      />
                    }
                    placement="topRight"
                    onConfirm={() => this.deleteConnection()}
                  >
                    <IconButton icon={<i className='far fa-trash-o' />} />
                  </Popconfirm>
                </div>
              }>
                <div className='connect-inspector-contant'>
                {this.selectedConnectionView()}
                </div>
              </Card> : null
            }
          </Space>
        </div>
                                                          
        <AddNodeModal
        //@ts-expect-error
          isOpen={this.state['showAddNodeModal']}
          onClose={this.handleCloseAddNodeModal}
          handleSubmit={this.addNode}
          nodes={this.state['nodes']}
          clusters={this.props.clusters}
        />
        {this.state['activeNode'] === null ?  null : 
        <>
          <EditNodeModal
          //@ts-expect-error 
            isOpen={this.state['showEditNodeModal']}
            onClose={this.handleCloseEditNodeModal}
            handleSubmit={this.editNode}
            activeNode = {this.state['activeNode']['options']}
            nodes={this.state['nodes']}
            clusters={this.props.clusters}
          />
          <EditConnectionModal
            //@ts-expect-error 
            isOpen={this.state['showEditConnectionModal']}
            onClose={this.handleCloseEditConnectionModal}
            handleSubmit={this.editConnection}
            selectedConnection = {this.state['selectedConnection']}
            edges={this.state['edges']}
            input={activeNode.options.input}
          />
          <AddConnectionModal
          //@ts-expect-error
            isOpen={this.state['showAddConnectionModal']}
            onClose={this.handleCloseAddConnectionModal}
            handleSubmit={this.addConnection}
            edges={this.state['edges']}
            input={activeNode.options.input}
          />
        </>
      }
      </div></div>
    );
  }
}
