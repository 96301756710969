import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { useDesignSystemTheme } from '../Hooks';
import { CheckIcon, InfoIcon } from '../Icon';
import { useSelectContext } from '../SelectV2/hooks/useSelectContext';
import { Tooltip } from '../Tooltip';
import { getComboboxOptionItemWrapperStyles, getInfoIconStyles } from '../_shared_/Combobox';
import type { HTMLDataAttributes } from '../types';
import { useDialogComboboxContext } from './hooks/useDialogComboboxContext';
import { useDialogComboboxOptionListContext } from './hooks/useDialogComboboxOptionListContext';
import {
  dialogComboboxLookAheadKeyDown,
  getDialogComboboxOptionLabelWidth,
  getKeyboardNavigationFunctions,
} from './shared';

export interface DialogComboboxOptionListSelectItemProps
  extends HTMLDataAttributes,
    React.HTMLAttributes<HTMLDivElement> {
  value: string;
  checked?: boolean;
  disabled?: boolean;
  disabledReason?: React.ReactNode;
  children?: React.ReactNode;
  onChange?: (...args: any[]) => any;
  _TYPE?: string;
}

const DuboisDialogComboboxOptionListSelectItem = forwardRef<HTMLDivElement, DialogComboboxOptionListSelectItemProps>(
  ({ value, checked, disabledReason, onChange, children, _TYPE, ...props }, ref) => {
    const { theme } = useDesignSystemTheme();
    const {
      stayOpenOnSelection,
      isOpen,
      setIsOpen,
      value: existingValue,
      contentWidth,
      textOverflowMode,
      scrollToSelectedElement,
    } = useDialogComboboxContext();
    const { isInsideDialogComboboxOptionList, lookAhead, setLookAhead } = useDialogComboboxOptionListContext();
    const { isSelect } = useSelectContext();

    if (!isInsideDialogComboboxOptionList) {
      throw new Error('`DialogComboboxOptionListSelectItem` must be used within `DialogComboboxOptionList`');
    }

    const itemRef = useRef<HTMLDivElement>(null);
    const prevCheckedRef = useRef(checked);
    useImperativeHandle(ref, () => itemRef.current as HTMLDivElement);

    useEffect(() => {
      if (scrollToSelectedElement && isOpen) {
        // Check if checked didn't change since the last update, otherwise the popover is still open and we don't need to scroll
        if (checked && prevCheckedRef.current === checked) {
          // Wait for the popover to render and scroll to the selected element's position
          const interval = setInterval(() => {
            if (itemRef.current) {
              itemRef.current?.scrollIntoView?.({
                behavior: 'smooth',
                block: 'center',
              });
              clearInterval(interval);
            }
          }, 50);

          return () => clearInterval(interval);
        }
        prevCheckedRef.current = checked;
      }

      return;
    }, [isOpen, scrollToSelectedElement, checked]);

    const handleSelect = () => {
      if (onChange) {
        if (isSelect) {
          onChange({ value, label: typeof children === 'string' ? children : value });
          return;
        }
        onChange(value);

        // On selecting a previously selected value, manually close the popup, top level logic will not be triggered
        if (!stayOpenOnSelection && existingValue?.includes(value)) {
          setIsOpen(false);
        }
      }
    };

    let content: React.ReactNode = children ?? value;
    if (props.disabled && disabledReason) {
      content = (
        <div css={{ display: 'flex' }}>
          <div>{content}</div>
          <Tooltip title={disabledReason} placement="right">
            <span css={getInfoIconStyles(theme)}>
              <InfoIcon aria-hidden="false" />
            </span>
          </Tooltip>
        </div>
      );
    }

    return (
      <div
        ref={itemRef}
        css={[
          getComboboxOptionItemWrapperStyles(theme),
          {
            '&:focus': {
              background: theme.colors.actionTertiaryBackgroundHover,
              outline: 'none',
            },
          },
        ]}
        {...props}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault();
          } else {
            handleSelect();
          }
        }}
        tabIndex={-1}
        {...getKeyboardNavigationFunctions(handleSelect, {
          onKeyDown: props.onKeyDown,
          onMouseEnter: props.onMouseEnter,
          onDefaultKeyDown: (e) => dialogComboboxLookAheadKeyDown(e, setLookAhead, lookAhead),
        })}
        role="option"
        aria-selected={checked}
      >
        {checked ? <CheckIcon css={{ paddingTop: 2 }} /> : <div style={{ width: 16, flexShrink: 0 }} />}
        <label
          style={{
            marginLeft: theme.spacing.sm,
            fontSize: theme.typography.fontSizeBase,
            fontStyle: 'normal',
            fontWeight: 400,
            cursor: 'pointer',
            overflow: 'hidden',
            wordBreak: 'break-word',
            ...(textOverflowMode === 'ellipsis' && {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }),
            ...(contentWidth ? { width: getDialogComboboxOptionLabelWidth(theme, contentWidth) } : {}),
          }}
        >
          {content}
        </label>
      </div>
    );
  },
);

DuboisDialogComboboxOptionListSelectItem.defaultProps = {
  _TYPE: 'DialogComboboxOptionListSelectItem',
};

export const DialogComboboxOptionListSelectItem = DuboisDialogComboboxOptionListSelectItem;
