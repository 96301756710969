import React from 'react';
import Utils from '../../common/utils/Utils';
import { Link, NavigateFunction } from '../../common/utils/RoutingUtils';
import { message } from 'antd';
import { CollapsibleSection } from '../../common/components/CollapsibleSection';
import { EditableNote } from '../../common/components/EditableNote';
import { GatewayRouteLimits } from './GatewayRouteLimits';
import RoutePaths from 'experiment-tracking/routes';
import { connect } from 'react-redux';
import { OverflowMenu, PageHeader } from '../../shared/building_blocks/PageHeader';
import { ManageUserAuthorization } from '../../common/components/ManageUserAuthorization';
import { FormattedMessage, type IntlShape, injectIntl } from 'react-intl';
import { 
  setModelGatewayRouteLimitsApi, 
  getModelGatewayRouteLimitsApi,
  removeModelGatewayRoutesApi,
  setModelGatewayRouteAuthorizationApi,
  getModelGatewayRouteAuthorizationApi
} from 'experiment-tracking/actions/ModelGatewayActions';
import {
  Button,
  SegmentedControlGroup,
  SegmentedControlButton,
  DangerModal,
} from '@databricks/design-system';
import { Descriptions } from '../../common/components/Descriptions';
import { RouteEntity } from 'ai-gateway/types';

type GatewayRouteViewImplProps = {
  gatewayRoute: RouteEntity;
  gatewayRouteName: string;
  intl: IntlShape;
  navigate: NavigateFunction;
  setModelGatewayRouteLimitsApi: (...args: any[]) => any;
  getModelGatewayRouteLimitsApi: (...args: any[]) => any;
  removeModelGatewayRoutesApi: (...args: any[]) => any;
  setModelGatewayRouteAuthorizationApi: (...args: any[]) => any;
  getModelGatewayRouteAuthorizationApi: (...args: any[]) => any;
  limits: any[];
  authorization: any[];
};

type GatewayRouteViewImplState = any;

export class GatewayRouteViewImpl extends React.Component<GatewayRouteViewImplProps, GatewayRouteViewImplState> {
  constructor(props: GatewayRouteViewImplProps) {
    super(props);
  }

  state = {
    isDeleteModalVisible: false,
    isDeleteModalConfirmLoading: false,
    authorizationRequestPending: false,
    limitsRequestPending: false,
  };

  authorizationFormRef = React.createRef();
  limitsFormRef = React.createRef();


  getOverflowMenuItems() {
    const menuItems = [
      {
        id: 'delete',
        itemName: (
          <FormattedMessage
            defaultMessage='Delete'
            description='Text for delete button gateway route view page header'
          />
        ),
        onClick: this.showDeleteModal,
      },
    ];
    return menuItems;
  }

  showDeleteModal = () => {
    this.setState({ isDeleteModalVisible: true });
  };

  hideDeleteModal = () => {
    this.setState({ isDeleteModalVisible: false });
  };

  handleDeleteConfirm = () => {
    const { gatewayRoute, navigate } = this.props;
    this.setState({ isDeleteModalConfirmLoading: true });
    const routeData = { route_name : gatewayRoute.name  }
    this.props.removeModelGatewayRoutesApi(routeData)
      .then(() => {
        navigate(RoutePaths.gatewayRouteListPageRoute);
      }).catch((e: any) => {
        this.setState({ isDeleteModalConfirmLoading: false });
        Utils.logErrorAndNotifyUser(e);
      });
  };

  AddAuthorization =  (values: any) => {
    const form = this.authorizationFormRef;
    const { gatewayRoute } = this.props;
    const routeName = gatewayRoute.name;
    this.setState({ authorizationRequestPending: true });
    values.route_name = routeName;
    this.props
      .setModelGatewayRouteAuthorizationApi(values)
      .then((content: any) => {
        this.props.getModelGatewayRouteAuthorizationApi(routeName)
        .then((content: any) => {
          this.setState({ authorizationRequestPending: false });
        });
        if(form.current) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          form.current.resetFields();
        }
      })
      .catch((ex: any) => {
        this.setState({ authorizationRequestPending: false });
        Utils.logErrorAndNotifyUser(ex);
      });
  }

  handleSaveEditAuthorization = async (value: any) => {
    const { gatewayRoute } = this.props;
    const routeName = gatewayRoute.name;
    value.route_name = routeName;
    await this.props.setModelGatewayRouteAuthorizationApi(value).catch((ex: any) => {
      Utils.logErrorAndNotifyUser(ex);
    });
    return this.props.getModelGatewayRouteAuthorizationApi(routeName);
  };

  handleSaveEditRouteLimits = async ( value: any) => {
    const { gatewayRouteName, limits } = this.props;
    limits.forEach((limit: any) => {
      if (limit.key === value.key) {
        limit.calls = value.calls;
      }
    });
    const data = {
      route: gatewayRouteName,
      route_name: gatewayRouteName,
      limits: limits
    }
    await this.props.setModelGatewayRouteLimitsApi(data).then(async (content: any) => {
      this.props.getModelGatewayRouteLimitsApi(gatewayRouteName);
    }).catch((ex: any) => {
      Utils.logErrorAndNotifyUser(ex);
    });
  }

  setGatewayRouteLimits = async (limit: any) => {
    const { gatewayRouteName, limits } = this.props;
    const form = this.limitsFormRef;
    this.setState({ limitsRequestPending: true });    
    limits.push(limit);
    const data = {
      route: gatewayRouteName,
      route_name: gatewayRouteName,
      limits: limits
    }
    await this.props.setModelGatewayRouteLimitsApi(data).then(async (content: any) => {
      this.props.getModelGatewayRouteLimitsApi(gatewayRouteName).then(async (content: any) => {
        this.setState({ limitsRequestPending: false });
      }).catch((ex: any) => {
        Utils.logErrorAndNotifyUser(ex);
      });
    }).catch((ex: any) => {
      Utils.logErrorAndNotifyUser(ex);
    });
    if(form.current) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      form.current.resetFields();
    }
  }

  removeRouteLimits = async ( value: any) => {
    const { gatewayRouteName, limits } = this.props;   
    const data = {
      route: gatewayRouteName,
      route_name: gatewayRouteName,
      limits: limits.filter(limit => limit.key !== value.key)
    }
    await this.props.setModelGatewayRouteLimitsApi(data).then(async (content: any) => {
      this.props.getModelGatewayRouteLimitsApi(gatewayRouteName);
    }).catch((ex: any) => {
      Utils.logErrorAndNotifyUser(ex);
    });
  }

  renderMainPanel = () => {
    const { gatewayRoute, limits, authorization } = this.props;
    const { authorizationRequestPending, limitsRequestPending } = this.state;
    const gatewayRouteName = gatewayRoute.name;
    return (
      <div css={styles.wrapper}>
        {/* Metadata List */}
        <Descriptions columns={3} data-testid='gatewaty-route-view-metadata'>
          <Descriptions.Item
            data-testid='gateway-route-view-metadata-item'
            label='Route Name'
          >
            {gatewayRoute.name}
          </Descriptions.Item>
          <Descriptions.Item
            data-testid='gatewaty-route-view-metadata-item'
            label='Route Type'
          >
            {gatewayRoute.route_type}
          </Descriptions.Item>
          <Descriptions.Item
            data-testid='gatewaty-route-view-metadata-item'
            label='Model Provider'
          >
            {gatewayRoute.model.provider}
          </Descriptions.Item>
          <Descriptions.Item
            data-testid='gatewaty-route-view-metadata-item'
            label='Model Name'
          >
            {gatewayRoute.model.name}
          </Descriptions.Item>
        </Descriptions>

        {/* Page Sections */}
        <div style={{display: "flex", gap: '20px'}}> 
          <div data-test-id='authorization-section' style={{width: "50%"}}>
            <CollapsibleSection
              title={
                <FormattedMessage
                  defaultMessage='Permissions'
                  description='Title text for the authorizations section under details tab on the gatewaty route view
                    page'
                />
              }
              data-test-id='gatewaty-route-authorizations-section'
            >
              <ManageUserAuthorization
                // @ts-expect-error
                innerRef={this.authorizationFormRef}
                handleAddAuthorization={this.AddAuthorization}
                handleSaveEdit={this.handleSaveEditAuthorization}
                authorizations={authorization}
                isRequestPending={authorizationRequestPending}
              />
            </CollapsibleSection>
          </div>
          <div data-test-id='gateway-limits-section' style={{width: "50%" }}>
            <CollapsibleSection
              title='Gateway Limits'
              data-test-id='gatewaty-route-limits-section'
            >
              <GatewayRouteLimits
                // @ts-expect-error
                innerRef={this.limitsFormRef}
                setGatewayRouteLimits={this.setGatewayRouteLimits}
                handleSaveEditRouteLimits={this.handleSaveEditRouteLimits}
                removeRouteLimits={this.removeRouteLimits}
                limits={limits}
                isRequestPending={limitsRequestPending}
              /> 
            </CollapsibleSection>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { gatewayRoute, gatewayRouteName } = this.props;
    const { isDeleteModalVisible, isDeleteModalConfirmLoading } = this.state;
    const breadcrumbs = [
      <Link to={RoutePaths.gatewayRouteListPageRoute}>
        <FormattedMessage
          defaultMessage='Gateway Routes'
          description='Text for link back to gatewaty-route page under the header on the gatewaty-route view page'
        />
      </Link>
    ];
    return (
      <div>
        <PageHeader title={gatewayRoute.name} breadcrumbs={breadcrumbs}>
          <OverflowMenu menu={this.getOverflowMenuItems()} />
        </PageHeader>
        {this.renderMainPanel()}
        {/* Delete gatewaty-route Dialog */}
        <DangerModal
          data-testid='mlflow-input-modal'
          title={this.props.intl.formatMessage({
            defaultMessage: 'Delete gatewaty route',
            description: 'Title text for delete gatewaty route modal on gatewaty-route view page',
          })}
          visible={isDeleteModalVisible}
          confirmLoading={isDeleteModalConfirmLoading}
          onOk={this.handleDeleteConfirm}
          okText={this.props.intl.formatMessage({
            defaultMessage: 'Delete',
            description: 'OK text for delete gatewaty-route modal on gatewaty-route view page',
          })}
          cancelText={this.props.intl.formatMessage({
            defaultMessage: 'Cancel',
            description: 'Cancel text for delete gatewaty-route modal on gatewaty-route view page',
          })}
          onCancel={this.hideDeleteModal}
        >
          <span>
            <FormattedMessage
              defaultMessage='Are you sure you want to delete {gatewayRouteName}? This cannot be undone.'
              description='Confirmation message for delete gatewaty-route modal on gatewaty-route view page'
              values={{ gatewayRouteName: gatewayRouteName }}
            />
          </span>
        </DangerModal>
      </div>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const gatewayRouteName = ownProps.gatewayRoute.name;
  const gatewayRoute = ownProps.gatewayRoute;
  return { gatewayRoute, gatewayRouteName };
};
const mapDispatchToProps = { 
  setModelGatewayRouteLimitsApi,
  removeModelGatewayRoutesApi,
  setModelGatewayRouteAuthorizationApi,
  getModelGatewayRouteLimitsApi,
  getModelGatewayRouteAuthorizationApi
};

const styles = {
  emailNotificationPreferenceDropdown: (theme: any) => ({
    width: 300,
    marginBottom: theme.spacing.md,
  }),
  emailNotificationPreferenceTip: (theme: any) => ({
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
  }),
  wrapper: (theme: any) => ({
    '.collapsible-panel': {
      marginBottom: theme.spacing.md,
    },

    /**
     * This seems to be a best and most stable method to catch
     * antd's collapsible section buttons without hacks
     * and using class names.
     */
    'div[role="button"][aria-expanded]': {
      height: theme.general.buttonHeight,
    },
  }),
  editButton: (theme: any) => ({
    marginLeft: theme.spacing.md,
  }),
  versionsTabButtons: (theme: any) => ({
    display: 'flex',
    gap: theme.spacing.md,
    alignItems: 'center',
  }),
};

export const GatewayRouteView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GatewayRouteViewImpl));
