import borders from './borders';
import responsive from './breakpoints';
import { getColors } from './colors';
import generalVariables, { getShadowVariables } from './generalVariables';
import spacing from './spacing';
import typography from './typography';

export type ComponentTheme = ReturnType<typeof getTheme>;

export interface ThemeOptions {
  enableAnimation: boolean;
  zIndexBase: number;
}

export interface Theme {
  colors: ReturnType<typeof getColors>;
  spacing: typeof spacing;
  general: typeof generalVariables & ReturnType<typeof getShadowVariables>;
  typography: typeof typography;
  borders: typeof borders;
  responsive: typeof responsive;
  isDarkMode: boolean;
  options: ThemeOptions;
}

const defaultOptions: ThemeOptions = {
  enableAnimation: false,
  zIndexBase: 1000,
};

// Function to get variables for a certain theme.
// End users should use useDesignSystemTheme instead.
export function getTheme(isDarkMode: boolean, options: ThemeOptions = defaultOptions): Theme {
  return {
    colors: getColors(isDarkMode),
    spacing,
    general: {
      ...generalVariables,
      ...getShadowVariables(isDarkMode),
    },
    typography,
    borders,
    responsive,
    isDarkMode,
    options,
  };
}
