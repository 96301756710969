import { getUUID } from '../common/utils/ActionUtils';
import { Services } from './services';
import Utils from '../common/utils/Utils';

export const LIST_CONCURRENT = 'LIST_CONCURRENT';
export const concurrentListApi = (id = getUUID()) => {
  return({
    type : LIST_CONCURRENT,
    payload : Services.listConcurrent({}),
    meta: { id },
  });
}

export const GET_CONCURRENT = 'GET_CONCURRENT';
export const getConcurrentApi = (concurrent_id: string, id = getUUID()) => {
  return({
    type : GET_CONCURRENT,
    payload : Services.getConcurrent({parallel_id: concurrent_id}),
    meta: { id },
  });
}

export const SEARCH_CONCURRENT = 'SEARCH_CONCURRENT';
export const searchConcurrentApi = (concurrent_name: string, id = getUUID()) => {
  return({
    type : SEARCH_CONCURRENT,
    payload : Services.searchConcurrent({parallel_name: concurrent_name}),
    meta: { id },
  });
}

export const RENAME_CONCURRENT = 'RENAME_CONCURRENT';
export const renameConcurrentApi = (concurrent_id: string, new_name: string, id = getUUID()) => {
  return({
    type : RENAME_CONCURRENT,
    payload : Services.renameConcurrent({parallel_id: concurrent_id, parallel_name: new_name}),
    meta: { id },
  });
}

export const SET_CONCURRENT_TAG_API = 'SET_CONCURRENT_TAG_API';
export const setConcurrentTagApi = (parallel_id: string, tagName: string, tagValue: string, id = getUUID()) => {
  return {
    type: SET_CONCURRENT_TAG_API,
    payload: Services.setTag({
      parallel_id: parallel_id,
      key: tagName,
      value: tagValue,
    }),
    meta: { id: id, parallel_id: parallel_id, key: tagName, value: tagValue },
  };
};

export const DELETE_CONCURRENT_TAG_API = 'DELETE_CONCURRENT_TAG_API';
export const deleteConcurrentTagApi = (parallel_id: string, tagName: string, id = getUUID()) => {
  return {
    type: DELETE_CONCURRENT_TAG_API,
    payload: Services.deleteTag({
      parallel_id: parallel_id,
      key: tagName,
    }),
    meta: { id: id, parallel_id: parallel_id, key: tagName },
  };
};

export const CREATE_CONCURRENT = 'CREATE_CONCURRENT';
export const createConcurrentApi = (parallelName: string, graphJson: any, description: string, id= getUUID()) => {
  return({
    type : CREATE_CONCURRENT,
    payload : Services.createConcurrent("parallel_name=" + parallelName  + "&parallel_json=" + JSON.stringify(graphJson) + "&description=" + description),
    meta: { id },
  });
}

export const UPDATE_CONCURRENT = 'UPDATE_CONCURRENT';
export const updateConcurrentApi = (parallel_id: string, graphJson: any, description: string, id= getUUID()) => {
  return({
    type : UPDATE_CONCURRENT,
    payload : Services.updateConcurrent("parallel_id=" + parallel_id  + "&parallel_json=" + JSON.stringify(graphJson) + "&description=" + description),
    meta: { id },
  });
}

export const RUN_CONCURRENT = 'RUN_CONCURRENT';
export const runConcurrentApi = (parallelID: string, graphJson: any, id= getUUID()) => {
  return({
    type : RUN_CONCURRENT,
    payload : Services.runConcurrent("dagid=" + parallelID  + "&dagParamsJson=" + JSON.stringify(graphJson) + "&MLFLOW_TRACKING_URI=" + "infinstor://" + window.InfinStorMlflowServer +
    "&MLFLOW_TRACKING_TOKEN=" + Utils.accessCookie('aws-accessToken') +
    "&MLFLOW_CONCURRENT_URI=https://"+window.ParallelsServer),
    meta: { id },
  });
}

export const RECOVERY_CONCURRENT = 'RECOVERY_CONCURRENT';
export const recoveryConcurrentApi = (parallelID: string, graphJson: any, parallelExecutionID: string, id= getUUID()) => {
  return({
    type : RECOVERY_CONCURRENT,
    payload : Services.runConcurrent({
      dagid: parallelID,
      dagJson: graphJson,
      dagExecutionId: parallelExecutionID,
    }),
    meta: { id },
  });
}

export const GET_CONCURRENT_EXECUTION_LIST = 'GET_CONCURRENT_EXECUTION_LIST';
export const getConcurrentExecutionListApi = (concurrentID: string,id= getUUID()) => {
  return({
    type : GET_CONCURRENT_EXECUTION_LIST,
    payload : Services.getConcurrentExecution({ dagid: concurrentID }),
    meta: { id, concurrentID },
  });
}

export const DELETE_CONCURRENT = 'DELETE_CONCURRENT';
export const deleteConcurrentApi = (parallelID: string,id= getUUID()) => {
  return({
    type : DELETE_CONCURRENT,
    payload : Services.deleteConcurrent({parallel_id: parallelID }),
    meta: { id },
  });
}

export const GET_CONCURRENT_RUN_STATUS = 'GET_CONCURRENT_RUN_STATUS';
export const getConcurrentRunStatusApi = (parallelID: string, parallelExecutionID: string, id= getUUID()) => {
  return({
    type : GET_CONCURRENT_RUN_STATUS,
    payload : Services.getConcurrentExecution({
      dagid: parallelID, 
      dag_execution_id: parallelExecutionID 
    }),
    meta: { id },
  });
}

export const GET_CONCURRENT_TEMPLATE_FOR_RUN = 'GET_CONCURRENT_TEMPLATE_FOR_RUN';
export const getConcurrentTemplateForRunApi = (run_id: string, id = getUUID()) => {
  return ({
    type: GET_CONCURRENT_TEMPLATE_FOR_RUN,
    payload: Services.getConcurrentTemplateForRun({
      run_id: run_id,
    }),
    meta: { id, run_id },
  });
};

export const GET_CONCURRENT_STATUS_FOR_RUN = 'GET_CONCURRENT_STATUS_FOR_RUN';
export const getConcurrentStatusForRunApi = (parallelID: string, parallelExecutionID: string, id= getUUID()) => {
  return({
    type : GET_CONCURRENT_STATUS_FOR_RUN,
    payload : Services.getConcurrentExecution({
      dagid: parallelID, 
      dag_execution_id: parallelExecutionID 
    }),
    meta: { id },
  });
}

export const GET_CONCURRENT_AUTH = 'GET_CONCURRENT_AUTH';
export const getConcurrentAuthApi = (parallelID: string, id= getUUID()) => {
  return({
    type : GET_CONCURRENT_AUTH,
    payload : Services.getConcurrentAuth({
      parallel_id: parallelID, 
    }),
    meta: { id, parallelID },
  });
}

export const SET_CONCURRENT_AUTH = 'SET_CONCURRENT_AUTH';
export const setConcurrentAuthApi = (parallel_id: string, principal_name: string, principal_type: string, role: string, id = getUUID()) => ({
  type: SET_CONCURRENT_AUTH,
  payload: Services.addConcurrentAuth({
    parallel_id: parallel_id,
    principal_name: principal_name,
    principal_type: principal_type,
    role: role,
  }),
  meta: { id, parallel_id, principal_name, principal_type, role },
});

export const GET_PERIODICRUN = 'GET_PERIODICRUN';
export const periodicRunListApi = (id= getUUID()) => {
  return({
    type : GET_PERIODICRUN,
    payload : Services.getPeriodicRun({}),
    meta: { id },
  });
}

export const CREATE_PERIODIC_RUN = 'CREATE_PERIODIC_RUN';
export const createPeriodicRunApi = (periodicRunName: string, periodicRunJson: any, id= getUUID()) => {
  return({
    type : CREATE_PERIODIC_RUN,
    payload : Services.createPeriodicRun("periodicRunName=" + periodicRunName  + "&periodicRunJson=" + JSON.stringify(periodicRunJson)),
    meta: { id },
  });
}

export const DELETE_PERIODIC_RUN = 'DELETE_PERIODIC_RUN';
export const deletePeriodicRunApi = (periodicRunName: string, id= getUUID()) => {
  return({
    type : DELETE_PERIODIC_RUN,
    payload : Services.deletePeriodicRun("periodicRuns=" + periodicRunName),
    meta: { id },
  });
}

