import { Component } from 'react';
import { GenericInputModal } from '../../../experiment-tracking/components/modals/GenericInputModal';
import { EditNodeForm } from './EditNodeForm';
import { injectIntl } from 'react-intl';

type Props = {
  nodes: any[];
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  isOpen: boolean;
  activeNode: any;
  clusters: any;
  handleSubmit: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
}
export class EditNodeModalImpl extends Component<Props> {
  getNodeNamesAsSet = () =>
    new Set(this.props.nodes.map((values) => values['name']));

  nodeNameValidator = (rule: any, value: any, callback: any) => {
    const nodeNamesSet = this.getNodeNamesAsSet();
    callback(
      nodeNamesSet.has(value)
        ? this.props.intl.formatMessage(
            {
              defaultMessage: 'Node "{value}" already exists.',
              description: 'Validation message for nodes that already exist in nodes list in MLflow Pipeline',
            },
            {
              value: value,
            },
          )
        : value === undefined ? this.props.intl.formatMessage(
          {
            defaultMessage: 'Name is required.',
            description: 'Validation message for nodes that already exist in nodes list in MLflow Pipeline',
          }
        ): value === '' ? this.props.intl.formatMessage(
          {
            defaultMessage: 'Name is required.',
            description: 'Validation message for nodes that already exist in nodes list in MLflow Pipeline',
          }
        ) : undefined,
    );
  };
  render() {
    const { isOpen, activeNode, clusters} = this.props;
    let nodeInfo = {
      ...activeNode,
      ...activeNode.k8s_params,
    };
    
    return (
      <GenericInputModal
        title='Edit Node'
        okText='Ok'
        isOpen={isOpen}
        handleSubmit={this.props.handleSubmit}
        onClose={this.props.onClose}
      >
        {/*@ts-expect-error */}
        <EditNodeForm activeNode={nodeInfo} clusters={clusters} />
      </GenericInputModal>
    );
  }
}

//@ts-expect-error
export const EditNodeModal = injectIntl(EditNodeModalImpl);
