import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Typography as AntDTypography } from 'antd';
import type { ComponentProps } from 'react';

import type { Theme } from '../../theme';
import { DesignSystemAntDConfigProvider } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks';
import type { DangerouslySetAntdProps, TypographyColor, HTMLDataAttributes } from '../types';
import { getTypographyColor } from '../utils/css-utils';
import { safex } from '../utils/safex';

const { Paragraph: AntDParagraph } = AntDTypography;

type AntDTypographyProps = ComponentProps<typeof AntDTypography>;
type AntDParagraphProps = ComponentProps<typeof AntDTypography['Paragraph']>;

export interface ParagraphProps
  extends AntDTypographyProps,
    Pick<AntDParagraphProps, 'ellipsis' | 'disabled' | 'id' | 'title' | 'aria-label'>,
    HTMLDataAttributes,
    DangerouslySetAntdProps<AntDParagraphProps> {
  withoutMargins?: boolean;
  color?: TypographyColor;
}

function getParagraphEmotionStyles(theme: Theme, props: ParagraphProps): SerializedStyles {
  const centerIcons = safex('databricks.fe.designsystem.centerIconsInText', false);
  return css(
    {
      '&&': {
        fontSize: theme.typography.fontSizeBase,
        lineHeight: theme.typography.lineHeightBase,
        color: getTypographyColor(theme, props.color, theme.colors.textPrimary),
      },
      ...(centerIcons && {
        '& .anticon': {
          verticalAlign: 'text-bottom',
        },
      }),
    },
    props.disabled && { '&&': { color: theme.colors.actionDisabledText } },
    props.withoutMargins && {
      '&&': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  );
}

export function Paragraph(userProps: ParagraphProps): JSX.Element {
  const { dangerouslySetAntdProps, withoutMargins, color, ...props } = userProps;
  const { theme } = useDesignSystemTheme();

  return (
    <DesignSystemAntDConfigProvider>
      <AntDParagraph
        {...props}
        className={props.className}
        css={getParagraphEmotionStyles(theme, userProps)}
        {...dangerouslySetAntdProps}
      />
    </DesignSystemAntDConfigProvider>
  );
}
