import AntDIcon from '@ant-design/icons';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { ReactElement } from 'react';
import React, { useMemo } from 'react';

import type { Theme } from '../../theme';
import { DesignSystemAntDConfigProvider } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks';
import type { DangerouslySetAntdProps, HTMLDataAttributes } from '../types';

type AntDIconProps = Parameters<typeof AntDIcon>[0];
export type IconColors = 'danger' | 'warning' | 'success';
export interface IconProps
  extends Omit<AntDIconProps, 'component'>,
    DangerouslySetAntdProps<AntDIconProps>,
    HTMLDataAttributes,
    React.HTMLAttributes<HTMLSpanElement> {
  component?: (props: React.SVGProps<SVGSVGElement>) => ReactElement | null;
  color?: IconColors;
}

const getIconEmotionStyles = (theme: Theme): SerializedStyles => {
  return css({
    fontSize: theme.general.iconFontSize,
  });
};

const getIconValidationColor = (theme: Theme, color?: IconColors) => {
  switch (color) {
    case 'success':
      return theme.colors.textValidationSuccess;
    case 'warning':
      return theme.colors.textValidationWarning;
    case 'danger':
      return theme.colors.textValidationDanger;
    default:
      return color;
  }
};

export const Icon = (props: IconProps): JSX.Element => {
  const { component: Component, dangerouslySetAntdProps, color, style, ...otherProps } = props;
  const { theme } = useDesignSystemTheme();

  const MemoizedComponent = useMemo(
    () =>
      Component
        ? ({ fill, ...iconProps }: React.SVGProps<SVGSVGElement>) => (
            // We don't rely on top-level fills for our colors. Fills are specified
            // with "currentColor" on children of the top-most svg.
            <Component fill="none" {...iconProps} />
          )
        : undefined,
    [Component],
  );

  return (
    <DesignSystemAntDConfigProvider>
      <AntDIcon
        aria-hidden="true"
        css={getIconEmotionStyles(theme)}
        component={MemoizedComponent}
        style={{
          color: getIconValidationColor(theme, color),
          ...style,
        }}
        {...otherProps}
        {...dangerouslySetAntdProps}
      />
    </DesignSystemAntDConfigProvider>
  );
};
