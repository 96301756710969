import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Input, Form, Radio, Select, DatePicker, Row, Col, AutoComplete, Checkbox } from 'antd';
import { searchRunsApi } from '../../../experiment-tracking/actions';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

type Props = {
  innerRef: any;
  action: string;
  selectedInput: any;
  experiments: any; 
  changeInputType: any; 
  inputType: string; 
  showCustomlamda: boolean;
  dataSelection: boolean;
  inputDataType: string;
  changePartitioner: (...args: any[]) => any; 
  changeDataSelection: (...args: any[]) => any; 
  changeInputDataType: (...args: any[]) => any; 
  searchRuns: (...args: any[]) => any;
  nodeInputNameValidator: (...args: any[]) => any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
}
/**
 * Component that renders a form for editing node input.
 */
class EditNodeInputFormComponent extends Component<Props> {

  static defaultProps = {
    searchRuns: searchRunsApi,
  };

  state = {
    runs: [],
    loading: false,
  }

  componentDidUpdate(prevProps: any) {
    this.resetFields(prevProps);
  }

  resetFields = (prevProps: any) => {
    const formRef = this.props.innerRef;
    if(JSON.stringify(prevProps.selectedInput) !== JSON.stringify(this.props.selectedInput)) {
      if(this.props.action === 'edit')
        formRef.current.resetFields();
    }
  };

  render() {
    const { selectedInput, experiments, changeInputType, inputType, 
      changePartitioner,
      showCustomlamda,
      changeDataSelection,
      dataSelection,
      changeInputDataType,
      inputDataType,
    } = this.props;

    const { runs, loading } = this.state;
    const dateFormat = 'YYYY-MM-DD, HH:mm:ss';
    if(selectedInput.infinsnapTime) {
      selectedInput.infinsnapTime = moment(selectedInput.infinsnapTime, dateFormat);
    }
    
    if(selectedInput.infinsliceTime){
      selectedInput.infinsliceTime = selectedInput.infinsliceTime.map((input: any) => {
        return moment(input, dateFormat);
      }); 
    }  

    const handleExperimentChange = async(value: any) => {
      this.setState({loading : true});
      const data = {
        experimentIds :[value],
        filter:"",
        runViewType: "ACTIVE_ONLY",
        maxResults: 100,
        orderBy: []
      };
      const api = this.props.searchRuns(data);
      const promise = Promise.resolve(api.payload);
      promise.then((value) => {
        const runs = value.runs.map((run: any) => {
            return {value : run.info.run_id}
          });
        this.setState({runs : runs});
        this.setState({loading : false});
      });
    };

    return(
    <Form ref={this.props.innerRef} layout='vertical' requiredMark='optional' initialValues={selectedInput}>
      <Form.Item label="Select input type" name="type" rules={[{required : true, message: 'inputtype '}]}>
        <Radio.Group onChange={changeInputType} >
          <Radio value="bucket">Bucket</Radio>
          <Radio value="mlflow_artifact">Mlflow Artifact</Radio>
        </Radio.Group>
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item
            label={this.props.intl.formatMessage({
              defaultMessage: 'Enter input name',
              description: 'Label for input',
            })}
            name='name'
            rules={[
              { validator: this.props.nodeInputNameValidator },{required: true, message: 'Input name is required.'}
            ]}
          >
            <Input placeholder='Enter input name' autoFocus />
          </Form.Item>
        </Col>
        <Col span={12} style={{paddingLeft: '10px'}}>
          <Form.Item name='partitioner' label='Specify input partitioner'>
            <Select placeholder="Select node partitioner" onChange={changePartitioner}>
              <Option key="null" value="null"> no partitioners </Option>
              <Option key="object" value="object"> object </Option>
              <Option key="directory" value="directory"> directory </Option>
              <Option key="custom" value="custom"> custom </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {inputType !== 'mlflow_artifact' ? <div>
        <Row>
          <Col span={12}>
            <Form.Item
              name='bucketname'
              label={this.props.intl.formatMessage({
                defaultMessage: 'Enter bucket name',
                description: 'Label for add Kwarg modal to enter path',
              })}
              rules={[
                {
                  required: true, message: 'Bucket name is required.'
                },
                () => ({
                  validator(_, value) {
                    if(value) {
                      if(value.includes("s3:/")) {
                        return Promise.reject(new Error("Bucketname can't contain 's3://'"));
                      } else {
                        return Promise.resolve();
                      }
                    }
                    return Promise.reject();
                  },
                }),
              ]}
            >
              <Input 
                placeholder={this.props.intl.formatMessage({
                  defaultMessage: 'myawsbucket',
                  description: 'Input placeholder to aws bucket',
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{paddingLeft: '10px'}}>
            <Form.Item
              name='pathInBucket'
              label={this.props.intl.formatMessage({
                defaultMessage: 'Enter input directory path',
                description: 'Label for add Kwarg modal to enter path',
              })}
              rules={[
                {
                  required: true,
                  message: this.props.intl.formatMessage({
                    defaultMessage: 'input path is required.',
                    description: 'Error message for input path requirement ',
                  }),
                },
              ]}
            >
              <Input
                placeholder={this.props.intl.formatMessage({
                  defaultMessage: 'Enter a path for the new input',
                  description: 'Input placeholder to enter path',
                })}
              />
            </Form.Item>
          </Col>
        </Row> 
        <Form.Item name="dataSelection" >
          <Checkbox onChange={changeDataSelection} checked={dataSelection} > Data Selection <Tooltip title="Use fine grained snapshot technology for a given point in time."> <InfoCircleOutlined /> </Tooltip> </Checkbox>
        </Form.Item>     

        {dataSelection ? <div>
          <Form.Item name="infintype">
            <Radio.Group onChange= {changeInputDataType} value={inputDataType}>
              <Radio value="infinsnap">Infinsnap Time</Radio>
              <Radio value="infinslice">Infinsnap Range</Radio> 
            </Radio.Group>
          </Form.Item>

          {inputDataType === 'infinsnap' ? <div>
            <Form.Item
              name='infinsnapTime'
              label='Select infinsnap time'
              rules={[
                {
                  required: true,
                  message: 'infinsnap time is required',
                },
              ]}
            >
              <DatePicker showTime placeholder ="infinsnap Time" style={{width: '100%'}} />
            </Form.Item> </div>: null
          }

          {inputDataType === 'infinslice' ? <div>
            <Form.Item
              name='infinsliceTime'
              label='Select infinsnap range'
              rules={[
                {
                  required: true,
                  message: 'infinsnap range is required',
                },
              ]}
            >
              <RangePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                style={{width: '100%'}}
                placeholder = {['infinslice start time','infinslice end time']}
              />
            </Form.Item> </div> : null
          }
        </div> : null }
      </div>: null}
        {inputType === 'mlflow_artifact' ? <div>
        <Form.Item
          name='experiment'
          label={this.props.intl.formatMessage({
            defaultMessage: 'Select mlflow experiment',
            description: 'Label for add Kwarg modal to enter path',
          })}
        >
          <Select placeholder="Select mlflow experiment" onChange={handleExperimentChange}>
            {experiments.map(({ name, experiment_id }: any) => (
              <Option key={experiment_id} value={experiment_id}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item> 
        <Form.Item
          name='run_id'
          label={this.props.intl.formatMessage({
            defaultMessage: 'Enter mlflow Run id',
            description: 'Label for add Kwarg modal to enter path',
          })}
          rules={[
            {
              required: true,
              message: this.props.intl.formatMessage({
                defaultMessage: 'mlflow Run id is required.',
                description: 'Error message for Run id requirement',
              }),
            },
          ]}
        >
          <AutoComplete placeholder="Select mlflow run" options={runs}
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </Form.Item></div>: null}
        {showCustomlamda ? <Form.Item
          name='lambda'
          label='Enter custom partition lambda code'          
        >
            <TextArea rows={4} placeholder={this.props.intl.formatMessage({
              defaultMessage: 'Enter custom partition lambda code',
              description: 'Input placeholder to enter lambda code',
            })} />
        </Form.Item> : null}
      </Form>
    )
  }
}

//@ts-expect-error
export const EditNodeInputForm = injectIntl(EditNodeInputFormComponent);
