import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Input, Form, Radio, Space, Row, Col, Select } from '@databricks/design-system';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';

const RadioGroup = Radio.Group;
const { Option } = Select;

type Props = {
  validator?: (...args: any[]) => any;
  innerRef: any;
}

/**
 * Component that renders a form for creating a new periodic run.
 */
class CreatePeriodicRunFormComponent extends Component<Props> {

  state = {
    scheduleType: 'once',
  };


  minuteOptions = () => {
    const options = [];
    for (let i = 0; i < 60; i++) {
      options.push({
        label: i + " minute every hour",
        value: i,
      });
    }
    return options;
  }

  dayOptions = () => {
    const options = [];
    for (let i = 1; i <= 31; i++) {
      options.push({
        label: i + " day every month",
        value: i,
      });
    }
    return options;
  }

  renderExperiment(experiment: any) {
    return (
      <Option value={experiment.experiment_id} key={experiment.experiment_id}>
        {experiment.name}
      </Option>
    );
  }

  render() {
    const onChange = (e: any) => {
      this.setState({scheduleType: e.target.value})
    };

    const disabledDate = (current: any) => {
      return current && current < moment(new Date);
    };

    return (
      <Form ref={this.props.innerRef} initialValues={{'scheduleType': this.state.scheduleType}} layout='vertical'>
        <Form.Item
          label='Enter a name for the periodic run'
          name='name'
          rules={[
            {
              required: true,
              message: 'Please input a new name for the new periodic run.',
            },
            {
              validator: this.props.validator,
            }
          ]}
        >
          <Input
            placeholder='Enter periodic run name'
            autoFocus
          />
        </Form.Item>
        
        <Form.Item label="Schedule" name="scheduleType">
          <RadioGroup onChange={onChange}>
            <Space>
              <Radio value='once' data-testid='x-axis-radio'>
                Once
              </Radio>
              <Radio value='hourly' data-testid='x-axis-radio'>
                Hourly
              </Radio>
              <Radio value='daily' data-testid='x-axis-radio'>
                Daily
              </Radio>
              <Radio value='weekly' data-testid='x-axis-radio'>
                Weekly
              </Radio>
              <Radio value='monthly' data-testid='x-axis-radio'>
                Monthly
              </Radio>
              <Radio value='yearly' data-testid='x-axis-radio'>
                Yearly
              </Radio>
            </Space>
          </RadioGroup>
        </Form.Item>
      {this.state.scheduleType == 'once' ? <Form.Item label="Select Time" name='scheduleTime' 
        rules={[
          {
            required: true,
            message: 'Please select time for the new periodic run',
          },
        ]}>
        <DatePicker className='picker-input' disabledDate={disabledDate} showTime placeholder ="select time to run" style={{width: '100%'}} />
      </Form.Item> : this.state.scheduleType == 'hourly' ? <Form.Item label="Select Time" name='hourlyRunMinute'
        rules={[
          {
            required: true,
            message: 'Please select mintue for the new periodic run',
          },
        ]}>
        <Select placeholder="Select minute every hour to run" options={this.minuteOptions()}/>
      </Form.Item> : this.state.scheduleType == 'daily' ? <Form.Item label="Select Time" name='dailyRunTime'
        rules={[
          {
            required: true,
            message: 'Please select time for the new periodic run',
          },
        ]}>
        <TimePicker className='picker-input' format={'HH:mm'} style={{width: '100%'}}/>
      </Form.Item> : this.state.scheduleType == 'weekly' ? 
        <Row>
          <Col span={12}>
          <Form.Item label="Select Time" name='weeklyRunTime' 
            rules={[
              {
                required: true,
                message: 'Please select time for the new periodic run',
              },
            ]}> 
            <TimePicker className='picker-input' format={'HH:mm'} style={{width: '100%'}}/>
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item label="Select week day" name='weeklyRunDay' 
            rules={[
              {
                required: true,
                message: 'Please select day for the new periodic run',
              },
            ]}> 
            <Select className='type-select' mode="multiple" placeholder='Enter schedule week' data-test-id='type-select-dropdown'>
              <Select.Option value={0} key="Sunday"> Sunday </Select.Option>
              <Select.Option value={1} key="Monday"> Monday </Select.Option>
              <Select.Option value={2} key="Tuesday"> Tuesday </Select.Option>
              <Select.Option value={3} key="Wednesday"> Wednesday </Select.Option>
              <Select.Option value={4} key="Thursday"> Thursday </Select.Option>
              <Select.Option value={5} key="Friday"> Friday </Select.Option>
              <Select.Option value={6} key="Saturday"> Saturday </Select.Option>
            </Select>
          </Form.Item>
          </Col>
        </Row> : this.state.scheduleType == 'monthly' ? <Row>
          <Col span={12}>
          <Form.Item label="Select Time" name='monthlyRunTime'
            rules={[
              {
                required: true,
                message: 'Please select time for the new periodic run',
              },
            ]}>
            <TimePicker className='picker-input' format={'HH:mm'} style={{width: '100%'}}/>
          </Form.Item>
          </Col> 
          <Col span={12}>
            <Form.Item label="Select day" name='monthlyRunDay' 
              rules={[
                {
                  required: true,
                  message: 'Please select day for the new periodic run',
                },
              ]}>
              <Select placeholder="Select day every month to run" options={this.dayOptions()} />
            </Form.Item>
          </Col>
        </Row> : this.state.scheduleType == 'yearly' ? 
        <Row>
          <Col span={8}>
            <Form.Item label="Select time" name='yearlyRunTime' 
              rules={[
                {
                  required: true,
                  message: 'Please select time for the new periodic run',
                },
              ]}> 
              <TimePicker className='picker-input' format={'HH:mm'} style={{width: '100%'}}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Select day" name='yearlyRunDay' 
              rules={[
                {
                  required: true,
                  message: 'Please select day for the new periodic run',
                },
              ]}>
              <Select placeholder="Select day every month to run" options={this.dayOptions()} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Select month" name='yearlyRunMonth' 
              rules={[
                {
                  required: true,
                  message: 'Please select month for the new periodic run',
                },
              ]}>
              <Select placeholder="Select day every month to run"> 
                <Select.Option value={0} key="January"> January </Select.Option>
                <Select.Option value={1} key="February"> February </Select.Option>
                <Select.Option value={2} key="March"> March </Select.Option>
                <Select.Option value={3} key="April"> April </Select.Option>
                <Select.Option value={4} key="May"> May </Select.Option>
                <Select.Option value={5} key="June"> June </Select.Option>
                <Select.Option value={6} key="July"> July </Select.Option>
                <Select.Option value={7} key="August"> August </Select.Option>
                <Select.Option value={8} key="September"> September </Select.Option>
                <Select.Option value={9} key="October"> October </Select.Option>
                <Select.Option value={10} key="November"> November </Select.Option>
                <Select.Option value={11} key="December"> December </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {/* <Form.Item label="Select Time" name='scheduleTimeyear'>
            <DatePicker className='picker-input' showTime placeholder ="infinsnap Time" style={{width: '100%'}} />
          </Form.Item> */}
        </Row>
         : ""}
      </Form>
    );
  }
}

//@ts-expect-error
export const CreatePeriodicRunForm = injectIntl(CreatePeriodicRunFormComponent);
