import type { CSSObject, SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { PaginationProps as AntdPaginationProps } from 'antd';
import { Pagination as AntdPagination } from 'antd';
import { useState } from 'react';

import type { Theme } from '../../theme';
import { Button } from '../Button';
import { DesignSystemAntDConfigProvider } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks';
import { ChevronLeftIcon, ChevronRightIcon } from '../Icon';
import { Select } from '../Select';
import type { DangerouslySetAntdProps, HTMLDataAttributes } from '../types';
import { importantify } from '../utils/css-utils';

export interface PaginationProps extends HTMLDataAttributes, DangerouslySetAntdProps<AntdPaginationProps> {
  /**
   * The index of the current page. Starts at 1.
   */
  currentPageIndex: number;
  /**
   * The number of results per page.
   */
  pageSize: number;
  /**
   * The total number of results across all pages.
   */
  numTotal: number;
  /**
   * Callback that is triggered when the user navigates to a different page. Recieves the index
   * of the new page and the size of that page.
   */
  onChange: (pageIndex: number, pageSize?: number) => void;
  style?: React.CSSProperties;
}

export function getPaginationEmotionStyles(clsPrefix: string, theme: Theme): SerializedStyles {
  const classRoot = `.${clsPrefix}-pagination`;
  const classItem = `.${clsPrefix}-pagination-item`;
  const classLink = `.${clsPrefix}-pagination-item-link`;
  const classActive = `.${clsPrefix}-pagination-item-active`;
  const classEllipsis = `.${clsPrefix}-pagination-item-ellipsis`;
  const classNext = `.${clsPrefix}-pagination-next`;
  const classPrev = `.${clsPrefix}-pagination-prev`;
  const classJumpNext = `.${clsPrefix}-pagination-jump-next`;
  const classJumpPrev = `.${clsPrefix}-pagination-jump-prev`;
  const classSizeChanger = `.${clsPrefix}-pagination-options-size-changer`;
  const classOptions = `.${clsPrefix}-pagination-options`;

  const styles: CSSObject = {
    'span[role=img]': {
      color: theme.colors.textSecondary,
      '> *': {
        color: 'inherit',
      },
    },
    [classItem]: {
      backgroundColor: 'none',
      border: 'none',
      color: theme.colors.textSecondary,
      '&:focus-visible': {
        outline: 'auto',
      },
      '> a': {
        color: theme.colors.textSecondary,
        textDecoration: 'none',

        '&:hover': {
          color: theme.colors.actionDefaultTextHover,
        },
        '&:active': {
          color: theme.colors.actionDefaultTextPress,
        },
      },
      '&:hover': {
        backgroundColor: theme.colors.actionDefaultBackgroundHover,
      },
      '&:active': {
        backgroundColor: theme.colors.actionDefaultBackgroundPress,
      },
    },
    [classActive]: {
      backgroundColor: theme.colors.actionDefaultBackgroundPress,
      color: theme.colors.actionDefaultTextPress,
      border: 'none',
      '> a': {
        color: theme.colors.actionDefaultTextPress,
      },
      '&:focus-visible': {
        outline: 'auto',
      },
      '&:hover': {
        backgroundColor: theme.colors.actionDefaultBackgroundPress,
        color: theme.colors.actionDefaultTextPress,
      },
    },
    [classLink]: {
      border: 'none',
      color: theme.colors.textSecondary,
      '&[disabled]': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: theme.colors.actionDefaultBackgroundHover,
      },
      '&:active': {
        backgroundColor: theme.colors.actionDefaultBackgroundPress,
      },
      '&:focus-visible': {
        outline: 'auto',
      },
    },
    [classEllipsis]: {
      color: 'inherit',
    },
    [`${classNext}, ${classPrev}, ${classJumpNext}, ${classJumpPrev}`]: {
      color: theme.colors.textSecondary,
      '&:hover': {
        backgroundColor: theme.colors.actionDefaultBackgroundHover,
      },
      '&:active': {
        backgroundColor: theme.colors.actionDefaultBackgroundPress,
      },
      '&:focus-visible': {
        outline: 'auto',
      },
    },
    [`&${classRoot}.mini, ${classRoot}.mini`]: {
      [`${classItem}, ${classNext}, ${classPrev}, ${classJumpNext}, ${classJumpPrev}`]: {
        height: '32px',
        minWidth: '32px',
        width: 'auto',
        lineHeight: '32px',
      },
      [classSizeChanger]: {
        marginLeft: 4,
      },
      [`input,  ${classOptions}`]: {
        height: '32px',
      },
    },
  };

  const importantStyles = importantify(styles);

  return css(importantStyles);
}

export const Pagination: React.FC<PaginationProps> = function Pagination({
  currentPageIndex,
  pageSize = 10,
  numTotal,
  onChange,
  style,
  dangerouslySetAntdProps,
}: PaginationProps): JSX.Element {
  const { classNamePrefix, theme } = useDesignSystemTheme();

  return (
    <DesignSystemAntDConfigProvider>
      <AntdPagination
        css={getPaginationEmotionStyles(classNamePrefix, theme)}
        current={currentPageIndex}
        pageSize={pageSize}
        responsive={false}
        total={numTotal}
        onChange={onChange}
        showSizeChanger={false}
        showQuickJumper={false}
        size={'small'}
        style={style}
        {...dangerouslySetAntdProps}
      />
    </DesignSystemAntDConfigProvider>
  );
};

export interface CursorPaginationProps extends HTMLDataAttributes {
  /** Callback for when the user clicks the next page button. */
  onNextPage: () => void;
  /** Callback for when the user clicks the previous page button. */
  onPreviousPage: () => void;
  /** Whether there is a next page. */
  hasNextPage: boolean;
  /** Whether there is a previous page. */
  hasPreviousPage: boolean;
  /** Text for the next page button. */
  nextPageText?: string;
  /** Text for the previous page button. */
  previousPageText?: string;
  /** Page size options. */
  pageSizeSelect?: {
    /** Page size options. */
    options: number[];
    /** Default page size */
    default: number;
    /** Get page size option text from page size. */
    getOptionText?: (pageSize: number) => string;
    /** onChange handler for page size selector. */
    onChange: (pageSize: number) => void;
  };
}

export const CursorPagination: React.FC<CursorPaginationProps> = function CursorPagination({
  onNextPage,
  onPreviousPage,
  hasNextPage,
  hasPreviousPage,
  nextPageText = 'Next',
  previousPageText = 'Previous',
  pageSizeSelect: {
    options: pageSizeOptions,
    default: defaultPageSize,
    getOptionText: getPageSizeOptionText,
    onChange: onPageSizeChange,
  } = {},
}): JSX.Element {
  const { theme, classNamePrefix } = useDesignSystemTheme();
  const [pageSizeValue, setPageSizeValue] = useState(defaultPageSize);

  const getPageSizeOptionTextDefault = (pageSize: number) => `${pageSize} / page`;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing.sm,
        [`.${classNamePrefix}-select-selector::after`]: {
          content: 'none',
        },
      }}
    >
      <Button icon={<ChevronLeftIcon />} disabled={!hasPreviousPage} onClick={onPreviousPage} type="tertiary">
        {previousPageText}
      </Button>
      <Button endIcon={<ChevronRightIcon />} disabled={!hasNextPage} onClick={onNextPage} type="tertiary">
        {nextPageText}
      </Button>
      {pageSizeOptions && (
        <Select
          value={String(pageSizeValue)}
          css={{ width: 120 }}
          onChange={(pageSize) => {
            const updatedPageSize = Number(pageSize);
            onPageSizeChange?.(updatedPageSize);
            setPageSizeValue(updatedPageSize);
          }}
        >
          {pageSizeOptions.map((pageSize) => (
            <Select.Option key={pageSize} value={String(pageSize)}>
              {(getPageSizeOptionText || getPageSizeOptionTextDefault)(pageSize)}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
};
