import { Component } from 'react';
import { Form, Input, Select, Row, Col, Checkbox, Radio, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  cluster: any;
  innerRef: any;
  clusterType: string;
  use_eks_role: boolean
  changeClusterType: (...args: any[]) => any;
  changeUseEKSCheckbox: (...args: any[]) => any;
}

class ClusterConfigFormComponent extends Component<Props> {

  componentDidUpdate(prevProps: any) {
    this.resetFields(prevProps);
  }

  resetFields = (prevProps: any) => {
    const formRef = this.props.innerRef;
    // _.isEqual(prevProps.activeNode, this.props.activeNode)
    if (prevProps.cluster.cluster_name !== this.props.cluster.cluster_name) {
      // reset input field to reset displayed initialValue
      formRef.current.resetFields();
    }
  };

  render() {
    const { changeClusterType, cluster, clusterType, use_eks_role, changeUseEKSCheckbox } = this.props;
    return (
      <Form ref={this.props.innerRef} className="login-form" initialValues={cluster} layout="vertical" requiredMark="optional">        
        <div>
        <Row>
          <Col span={12}>
          <Form.Item label="Cluster name" name="cluster_name" rules={[{ required: true, message: 'Please input EKS Cluster name!' }]}>
            <Input placeholder="Enter EKS Cluster name"/>
          </Form.Item>
          </Col>
          <Col span={12} style={{paddingLeft: '10px'}}>
            <Form.Item label="Namespace" name="namespace" rules={
              [{ required: true, message: 'Please input EKS namespace!' }]
              } 
            >
              <Input placeholder="Enter EKS namespace" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Cluster type" name="cluster_type" rules={
              [{ required: true, message: 'Please select Cluster location!' }]
              } >
              <Select placeholder="Select Cluster location" onChange={changeClusterType}>
                <Option key="EKS" value="EKS"> Elastic Kubernetes Service (EKS) </Option>
                <Option key="GKE" value="GKE"> Google Kubernetes Engine (GKE) </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} style={{paddingLeft: '10px'}}>
            { 
              clusterType === "EKS" ? <div>
                <Form.Item label="Cluster Region" name="region" rules={[{ required: true, message: 'Please input EKS Region!' }]} >
                  <Input placeholder="Enter Cluster Region"/>
                </Form.Item></div>
              : <div>
                <Form.Item label="GKE Location Type" name="gke_location_type" rules={[{ required: true, message: 'Please select cluster type!' }]}>
                  <Select placeholder="Select Cluster type" >
                    <Option key="zonal" value="zonal"> zonal </Option>
                    <Option key="regional" value="regional"> regional </Option>
                  </Select>
                </Form.Item>
              </div> 
            }
          </Col>
        </Row>
        </div>
        <div>
        
        </div> 
        {clusterType === "EKS" ? <div>
          <Form.Item label="EKS Role" name="role" rules={[{ required: true, message: 'Please input EKS Role!' }]}>
            <Input placeholder="Enter EKS Role"/>
          </Form.Item>
        
          <Form.Item label="EKS External ID" name="role_ext" rules={[{ required: true, message: 'Please input EKS External ID!' }]}>
            <Input placeholder="Enter EKS External ID"/>
          </Form.Item>
           
          <Form.Item label="Cluster Node Management" name="use_fargate" rules={[{required : true, message: 'please choose node manager'}]}>
            <Radio.Group>
              <Radio value="no"> <Tooltip title="Customer Managed k8s nodes. Customer manages autoscaling for cost optimization."> Use Node Groups </Tooltip></Radio>
              <Radio value="yes"> <Tooltip title="AWS managed k8s nodes.  Aggressive autoscaling by AWS to optimize costs."> Use Fargate Profiles </Tooltip> </Radio>
            </Radio.Group>
          </Form.Item>
          
          <Form.Item label="ECR Type" name="ecr_type" rules={[{ required: true, message: 'Please select ECR type!' }]}>
            <Select placeholder="Select ECR type" >
              <Option key="private" value="private"> Private </Option>
              <Option key="public" value="public"> Public </Option>
            </Select>
          </Form.Item>
          
          <Form.Item>
            <Checkbox checked={use_eks_role} onChange={changeUseEKSCheckbox}> Use EKS Role and External ID for ECR. </Checkbox>
          </Form.Item>
          { use_eks_role ? <div></div> : <div>
              <Form.Item label="ECR Role" name="ecr_role" rules={[{ required: true, message: 'Please input ECR Role!' }]}>
                <Input placeholder="Enter ECR Role"/>
              </Form.Item>
            
              <Form.Item label="ECR External ID" name="ecr_role_ext" rules={[{ required: true, message: 'Please input ECR External ID!' }]}>
                <Input placeholder="Enter ECR External ID"/>
              </Form.Item>
          </div> }
        </div> : <div>
          <Row>
            <Col span={12}>
              <Form.Item label="GKE Location" name="gke_location" rules={[{ required: true, message: 'Please input GKE Location!' }]}>
                <Input placeholder="Enter GKE Location" />
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '10px'}}>
              <Form.Item label="GKE Project" name="gke_project" rules={[{ required: true, message: 'Please input GKE Project!' }]}>
                <Input placeholder="Enter GKE Project"/>
              </Form.Item>
            </Col>
          </Row>         
          <Form.Item label="GKE Creds" name="gke_creds" rules={[{ required: true, message: 'Please input GKE Creds!' }]}>
            <TextArea rows={4} placeholder="Enter GKE Creds" />
          </Form.Item>
        </div> }
      </Form>
    );
  }
}

//@ts-expect-error
export const ClusterConfigForm = injectIntl(ClusterConfigFormComponent);
