import { Services } from './services';
import { getUUID } from '../common/utils/ActionUtils';

export const CUSTOMER_INFO_API = 'CUSTOMER_INFO_API';
export const customerInfoApi = (id = getUUID()) => {
  return (dispatch: any) => {
    const serveResponse = dispatch({
      type: CUSTOMER_INFO_API,
      payload: Services.getCustomerInfo({Username:'',customerId:'',productCode:''}),
      meta: {Username:'',customerId:'',productCode:'', id: id},
    });
    return serveResponse;
  };
};

export const SELF_SERVE_API = 'SELF_SERVE_API';
export const selfServeApi = (artifactInfo: any) => {
  return (dispatch: any) => {
    const serveResponse = dispatch({
      type: SELF_SERVE_API,
      payload: Services.selfServe(artifactInfo),
      meta: artifactInfo,
    });
    return serveResponse;
  };
};
