import * as React from 'react';

import type { IconProps } from '../../Icon';
import { Icon } from '../../Icon';
function SvgSortDescendingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 3.5h10V2H1v1.5Zm0 8h4V10H1v1.5Zm7-4H1V6h7v1.5Zm3.5 7.56 4.03-4.03-1.06-1.06-2.22 2.22V6h-1.5v6.19L8.53 9.97l-1.06 1.06 4.03 4.03Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
function SortDescendingIcon(props: IconProps) {
  return <Icon {...props} component={SvgSortDescendingIcon} />;
}
export default SortDescendingIcon;
