import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Input, Form } from '@databricks/design-system';


type Props = {
  nameValidator?: (...args: any[]) => any;
  intl: {
    formatMessage: (...args: any[]) => any;
  };
  handleSubmit: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  isOpen: boolean;
  innerRef: any;
  parallels: any[];
}

/**
 * Component that renders a form for creating a new parallel.
 */
class CreateParallelFormComponent extends Component<Props> {

  render() {
    return (
      <Form ref={this.props.innerRef} layout='vertical'>
        <Form.Item
          label='Enter concurrent name'
          name='name'
          rules={[
            {
              required: true,
              message: 'Please input a name for the new concurrent',
            },
            {
                validator: this.props.nameValidator,
            },
          ]}
        >
          <Input
            placeholder='Enter concurrent name'
            autoFocus
          />
        </Form.Item>
        <Form.Item
          label='Enter concurrent description '
          name='description'
        >
          <textarea className='run-command text-area' />
        </Form.Item>
      </Form>
    );
  }
}

//@ts-expect-error
export const CreateParallelForm = injectIntl(CreateParallelFormComponent);
