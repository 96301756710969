import type { CSSObject } from '@emotion/react';
import unitless from '@emotion/unitless';
import _ from 'lodash';

import type { Theme } from '../../theme';
import { ColorVars } from '../constants';
import type { TypographyColor, ValidationState } from '../types';

/**
 * Recursively appends `!important` to all CSS properties in an Emotion `CSSObject`.
 * Used to ensure that we always override Ant styles, without worrying about selector precedence.
 */
export function importantify(obj: CSSObject): CSSObject {
  return _.mapValues(obj, (value, key) => {
    if (_.isString(value) || _.isNumber(value) || _.isBoolean(value)) {
      // Make sure we don't double-append important
      if (_.isString(value) && _.endsWith(value, '!important')) {
        return value;
      }

      if (_.isNumber(value)) {
        if (unitless[key]) {
          return `${value}!important`;
        }
        return `${value}px!important`;
      }

      return `${value}!important`;
    }

    if (_.isNil(value)) {
      return value;
    }

    return importantify(value as CSSObject);
  }) as CSSObject;
}

/**
 * Returns a text color, in case of invalid/missing key and missing fallback color it will return textPrimary
 * @param theme
 * @param key - key of TypographyColor
 * @param fallbackColor - color to return as fallback -- used to remove tertiary check inline
 */
export function getTypographyColor(theme: Theme, key?: TypographyColor, fallbackColor?: string): string {
  if (theme && key && Object(theme.colors).hasOwnProperty(ColorVars[key])) {
    return theme.colors[ColorVars[key]];
  }

  return fallbackColor ?? theme.colors.textPrimary;
}

/**
 * Returns validation color based on state, has default validation colors if params are not provided
 * @param theme
 * @param validationState
 * @param errorColor
 * @param warningColor
 * @param successColor
 */
export function getValidationStateColor(
  theme: Theme,
  validationState?: ValidationState,
  {
    errorColor,
    warningColor,
    successColor,
  }: {
    errorColor?: string;
    warningColor?: string;
    successColor?: string;
  } = {},
): string | undefined {
  switch (validationState) {
    case 'error':
      return errorColor || theme.colors.actionDangerPrimaryBackgroundDefault;
    case 'warning':
      return warningColor || theme.colors.textValidationWarning;
      break;
    case 'success':
      return successColor || theme.colors.textValidationSuccess;
    default:
      return undefined;
  }
}
