import type { TooltipProps as AntDTooltipProps } from 'antd';
import { Tooltip as AntDTooltip } from 'antd';
import { isNil } from 'lodash';
import type { HTMLAttributes } from 'react';
import React from 'react';

import { DesignSystemAntDConfigProvider, getAnimationCss } from '../DesignSystemProvider';
import { useDesignSystemTheme } from '../Hooks';
import type { HTMLDataAttributes } from '../types';
import { useUniqueId } from '../utils/useUniqueId';

export interface TooltipProps extends HTMLDataAttributes {
  /**
   * The element with which the tooltip should be associated.
   */
  children: React.ReactNode;
  /**
   * Plain text that will appear within the tooltip. Links and formatted content should not be use. However, we allow
   * any React element to be passed in here, rather than just a string, to allow for i18n formatting components.
   */
  title: React.ReactNode;
  /**
   * Value that determines where the tooltip will be positioned relative to the associated element.
   */
  placement?: AntDTooltipProps['placement'];
  /**
   * Escape hatch to allow passing props directly to the underlying Ant `Tooltip` component.
   */
  dangerouslySetAntdProps?: Partial<AntDTooltipProps>;
  /**
   * ID used to refer to this element in unit tests.
   */
  dataTestId?: string;
  /**
   * Prop that forces the tooltip's arrow to be centered on the target element
   */
  arrowPointAtCenter?: boolean;
  /**
   * Toggle wrapper live region off
   */
  silenceScreenReader?: boolean;
  /**
   * Toggles screen readers reading the tooltip content as the label for the hovered/focused element
   */
  useAsLabel?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  title,
  placement = 'top',
  dataTestId,
  dangerouslySetAntdProps,
  silenceScreenReader = false,
  useAsLabel = false,
  ...props
}) => {
  const { theme } = useDesignSystemTheme();
  const id = useUniqueId('dubois-tooltip-component-');

  if (!title) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const titleProps: HTMLAttributes<HTMLSpanElement> & { 'data-test-id'?: string } = silenceScreenReader
    ? {}
    : ({ 'aria-live': 'polite', 'aria-relevant': 'additions' } as const);
  if (dataTestId) {
    // TODO FEINF-1337 - this should turn into data-testid
    titleProps['data-test-id'] = dataTestId;
  }
  const liveTitle =
    title && React.isValidElement(title) ? React.cloneElement(title, titleProps) : <span {...titleProps}>{title}</span>;
  const ariaProps = { [useAsLabel ? 'aria-labelledby' : 'aria-describedby']: id, 'aria-hidden': false };
  const childWithProps = React.isValidElement(children) ? (
    React.cloneElement<any>(children, { ...ariaProps, ...children.props })
  ) : isNil(children) ? (
    children
  ) : (
    <span {...ariaProps}>{children}</span>
  );

  const { overlayInnerStyle, overlayStyle, ...delegatedDangerouslySetAntdProps } = dangerouslySetAntdProps || {};
  return (
    <DesignSystemAntDConfigProvider>
      <AntDTooltip
        id={id}
        title={liveTitle}
        placement={placement}
        // Always trigger on hover and focus
        trigger={['hover', 'focus']}
        overlayInnerStyle={{
          backgroundColor: '#2F3941',
          lineHeight: '22px',
          padding: '4px 8px',
          boxShadow: theme.general.shadowLow,
          ...overlayInnerStyle,
        }}
        overlayStyle={{
          zIndex: theme.options.zIndexBase + 70,
          ...overlayStyle,
        }}
        css={{
          ...getAnimationCss(theme.options.enableAnimation),
        }}
        {...delegatedDangerouslySetAntdProps}
        {...props}
      >
        {childWithProps}
      </AntDTooltip>
    </DesignSystemAntDConfigProvider>
  );
};
