import React from 'react';
import { Table } from 'antd';
import { Typography, Button } from '@databricks/design-system';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getConcurrentRunStatusApi } from '../actions';
import { connect } from 'react-redux';
import { Spinner } from '../../common/components/Spinner';
import Routers from '../../experiment-tracking/routes';
import Utils from '../../common/utils/Utils';
import type { WithRouterNextProps } from '../../common/utils/withRouterNext';

const { Text } = Typography;

type Props = WithRouterNextProps & {
  concurrentExecutionList: any[];
  concurrent: any;
  getConcurrentRunStatusApi: (...args: any[]) => any;
  history: any;
}
type State = any;

export class ConcurrentExecutionTableImpl extends React.Component<Props, State> {

  static defaultProps = {
    concurrentExecutionList: [],
  };

  state = {
    loading: false
  }

  onExperimentView = (executionID: string) => {
    this.setState({loading: true});
    const { concurrent } = this.props;
    this.props.getConcurrentRunStatusApi(concurrent.dagid, executionID).then((res: any) => {
      let parent_run_id = res.value.dag_execution_info.run_status.parent_run_id;
      this.props.navigate(Routers.getRunPageRoute(parent_run_id.split("-")[0], parent_run_id));
    });
  }
  
  getColumns = () => {
    const { concurrent } = this.props;
    const columns = [
      {
        title: 'Run Name',
        dataIndex: 'dag_execution_id',
        key: 'dag_execution_id',
        render: (instance: any) => (
          <FormattedMessage
          defaultMessage='<link>{versionNumber}</link>'
          description='Link to concurrent run in the concurrent execution table'
            values={{
              link: (chunks) => (
                <Button type='link' onClick={() => this.onExperimentView(instance)} style={{ padding:0 }}>
                  {concurrent.dagName + "-" + chunks}
                </Button>
              ),
              versionNumber: instance,
            }}
          />
        ),
      },
      {
        title: 'Registered at',
        dataIndex: 'start_time',
        key: 'start_time',
        render: (text: any, row: any) => <span>{Utils.formatTimestamp(row.start_time + "000")}</span>,
      },
      {
        title: 'Last Modified',
        dataIndex: 'update_time',
        key: 'update_time',
        render: (text: any, row: any) => <span>{Utils.formatTimestamp(row.update_time + "000")}</span>,
      },
    ];
    return columns;
  };

  getRowKey = (record: any) => record.dag_execution_id;

  emptyTablePlaceholder = () => {
    const learnMoreLinkUrl = 'https://docs.concurrent-ai.org/files/free-helloworld/#run-dag';
    return (
      <span>
        <FormattedMessage
          defaultMessage='No run are started yet. <link>Learn more</link> about how to run a concurrent.'
          description='Message text when no run started'
          values={{
            link: (chunks) => (
              // Reported during ESLint upgrade
              // eslint-disable-next-line react/jsx-no-target-blank
              <a target='_blank' href={learnMoreLinkUrl}>
                {chunks}
              </a>
            ),
          }}
        />
      </span>
    );
  };

  render() {
    const { concurrent, concurrentExecutionList } = this.props;
    const { loading } = this.state;
    const sorted = concurrentExecutionList.sort(
      (a, b) => parseFloat(b.start_time) - parseFloat(a.start_time),
    );
    return (
      <Table
        size='middle'
        rowKey={this.getRowKey}
        className='concurrent-version-table'
        dataSource={sorted}
        columns={this.getColumns()}
        locale={{ emptyText: this.emptyTablePlaceholder() }}
        loading={loading && { indicator: <Spinner /> }}
        pagination={{
          position: ['bottomRight'],
          size: 'default',
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  getConcurrentRunStatusApi,
};

//@ts-expect-error
export const ConcurrentExecutionTable = connect(undefined, mapDispatchToProps)(injectIntl(ConcurrentExecutionTableImpl));


