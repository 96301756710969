import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Input, Form } from 'antd';

type Props = {
  nameValidator: (...args: any[]) => any;
  innerRef: any;
}

type State = any;

/**
 * Component that renders a form for clone a concurrent.
 */
class CloneConcurrentFormComponent extends Component<Props,State> {
  render() {
    return (
      <Form ref={this.props.innerRef} layout='vertical'>
        <Form.Item
          label='Enter concurrent name'
          name='name'
          rules={[
            {
              required: true,
              message: 'Please input a name for the new concurrent',
            },
            {
              validator: this.props.nameValidator,
            },
          ]}
        >
          <Input
            placeholder='Enter concurrent name'
            autoFocus
          />
        </Form.Item>
      </Form>
    );
  }
}

//@ts-expect-error
export const CloneConcurrentForm = injectIntl(CloneConcurrentFormComponent);
